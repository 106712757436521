import { UUID } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAtom } from "jotai";
import React, { FormEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { z } from "zod";

import { eventsCommentAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { useCreateCommentMutation, useUpdateCommentMutation } from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { reloadOpportunity } from "../loaders";

export const CommentFormSchema = z.object({
  text: z.string().min(1),
  sendAsEmail: z.boolean().optional(),
  files: z.array(z.any()),
});

interface CommentFormProps {
  opportunityId: string;
  commentId?: string;
  text?: string;
  setEditMode?: (editMode: boolean) => void;
}

export const CommentForm: React.FC<CommentFormProps> = ({ opportunityId, commentId, text, setEditMode }) => {
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const { toast } = useToast();
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [, setComment] = useAtom(eventsCommentAtom);

  const ref = useHotkeys<HTMLFormElement>(
    "meta+enter",
    () => {
      void onSubmit(new Event("submit") as any);
    },
    { enableOnFormTags: true }
  );

  const formMethods = useForm<z.infer<typeof CommentFormSchema>>({
    resolver: zodResolver(CommentFormSchema),
    defaultValues: {
      text,
      sendAsEmail: false,
      files: [],
    },
  });

  const { control } = formMethods;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    const isConfirmed = await openModal(() => (
      <>
        <h3>This comment will be sent to the agent</h3>
        <div className="flex justify-between gap-2">
          <Button variant="outline" size="sm" onClick={() => closeModal(false)}>
            Cancel
          </Button>
          <Button size="sm" onClick={() => closeModal(true)}>
            Confirm
          </Button>
        </div>
      </>
    ));

    if (!isConfirmed) {
      return;
    }

    if (isValid) {
      const values = formMethods.getValues();

      if (commentId && text && setEditMode) {
        void updateComment({
          variables: {
            input: {
              id: commentId as UUID,
              text: values.text,
            },
          },
          ...reloadOpportunity,
          onCompleted: () => {
            toast({ title: "Comment updated" });
            setEditMode(false);
          },
          onError: () => {
            toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
          },
        });
      } else {
        setLoading(true);
        void createComment({
          variables: {
            input: {
              opportunityId,
              text: values.text,
            },
          },
          ...reloadOpportunity,
          onCompleted: () => {
            setLoading(false);
            formMethods.reset({ text: "" });
            setComment(true);
            toast({ title: "Comment added" });
          },
          onError: () => {
            setLoading(false);
            toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
          },
        });
      }
    }
  };

  return (
    <form ref={ref} onSubmit={(e) => onSubmit(e)}>
      <div className="bg-background relative z-1">
        <Icon
          icon="comment"
          className="absolute bg-accent bottom-0 filled h-6 left-4 my-auto rounded-full text-muted-foreground top-0 w-6"
        />
        <Controller
          name="text"
          control={control}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Input
              placeholder="Add a comment"
              value={value}
              onChange={onChange}
              className="border-none flex-auto px-14 py-8 rounded-lg focus-visible:ring-0 focus-visible:ring-offset-0"
              onBlur={onBlur}
              id={name}
            />
          )}
        />
        <Button
          type="submit"
          variant="secondary"
          size="icon"
          className="absolute bottom-0 h-8 my-auto right-4 text-primary top-0 w-8"
        >
          {loading ? <Spinner /> : <Icon icon="send" />}
        </Button>
      </div>
    </form>
  );
};
