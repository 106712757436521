import { Outlet } from "react-router";

import { useOpportunity } from "@/opportunity/loaders";
import { MarketingPlanHeader } from "./marketing-plan/marketing-plan-header";

export function InsuredMarketingPlan() {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  return (
    <div className="@container/opp flex flex-auto flex-col min-w-0" id="OpportunityRoot">
      <MarketingPlanHeader opportunity={opportunity} />
      <div className="p-3 pl-0 space-y-3">
        <Outlet />
      </div>
    </div>
  );
}
