import { useState } from "react";
import { useCreateOpportunityNoteMutation } from "../../generated/graphql";
import { Avatar } from "../components/ui/avatar";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader } from "../components/ui/card";
import { Loading } from "../components/ui/loading";
import { RelativeDate } from "../components/ui/relative-date";
import { Textarea } from "../components/ui/textarea";
import { useOpportunity } from "./loaders";

export default function OpportunityNotes() {
  const [text, setText] = useState("");
  const { opportunity, refetch } = useOpportunity();
  const [createNote] = useCreateOpportunityNoteMutation();

  if (!opportunity) {
    return <Loading />;
  }

  return (
    <>
      <h2>Notes</h2>
      <div className="grid grid-cols-6 gap-2 items-center">
        <Textarea
          name="note"
          className="col-span-5"
          rows={5}
          value={text}
          onChange={({ target }) => setText(target.value)}
        />
        <Button
          size="sm"
          onClick={() =>
            createNote({
              variables: {
                input: {
                  opportunityId: opportunity.id,
                  text,
                },
              },
              onCompleted() {
                return refetch();
              },
            })
          }
        >
          Create Note
        </Button>
      </div>
      <ul className="flex flex-col gap-2">
        {opportunity.notes.map((note) => (
          <li key={note.id}>
            <Card>
              <CardHeader className="flex flex-row gap-4 items-center space-y-0 text-xs">
                <Avatar user={note.creator} />
                <div className="flex-auto">
                  <strong>
                    {note.creator.firstName} {note.creator.lastName}
                  </strong>
                  {" created a note "}
                  <RelativeDate date={note.createdAt} className="text-muted-foreground" />
                </div>
              </CardHeader>
              <CardContent>{note.text}</CardContent>
            </Card>
          </li>
        ))}
      </ul>
    </>
  );
}
