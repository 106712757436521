import parse from "html-react-parser";
import React from "react";

import { Badge } from "@/components/ui/badge";
import { CardContent, CardHeader } from "@/components/ui/card";
import { CollapsibleCard } from "@/components/ui/collapsible-card";
import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { getAuthRequestHeaders } from "@cp/auth";
import { FileUploadFragment, File_Source, GmailMessageEventFragment } from "../../../generated/graphql";
import { useMyAccount } from "../../auth/useMyAccount";

export const GmailMessageEvent: React.FC<GmailMessageEventFragment> = ({
  gmailMessage: { replyTo, from, textAsHtml, dateReceived, headerMessageId, attachments },
}) => {
  const { data: user } = useMyAccount();

  return (
    <CollapsibleCard
      footer={
        attachments[0] && (
          <div className="flex flex-row flex-wrap gap-2 ml-10 p-4 pt-2 text-xs z-10">
            {attachments.map((file) => (
              <Attachment key={file.id} fileUpload={file} />
            ))}
          </div>
        )
      }
    >
      <CardHeader>
        <a
          href={`https://mail.google.com/mail/u/${user?.email}/#search/rfc822msgid:${encodeURIComponent(
            headerMessageId
          )} in:anywhere`}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row gap-4 items-center space-y-0 text-xs"
        >
          <Icon icon="mail" className="bg-accent rounded-full w-6" />
          <div className="flex-auto">
            <strong className="flex-auto">{replyTo ?? from}</strong>
            {" sent an email "}
            <RelativeDate date={dateReceived} className="text-muted-foreground" />
          </div>
        </a>
      </CardHeader>
      {textAsHtml && (
        <CardContent className="ml-10 text-xs" style={{ overflowWrap: "anywhere" }}>
          {parse(textAsHtml)}
        </CardContent>
      )}
    </CollapsibleCard>
  );
};

export const Attachment = ({ fileUpload: file }: { fileUpload: FileUploadFragment }) => {
  if (file.fileSource === File_Source.CorrespondencePdf) {
    return null; // Don't display correspondence record PDFs as attachments
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge variant="outline">
          <a
            href={`/api/files/${file.id}`}
            download={file.filename}
            media={file.mimeType}
            className="inline-flex items-center hover:text-primary max-w-52"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              const blob = await fetch(`/api/files/${file.id}`, {
                headers: getAuthRequestHeaders(),
              })
                .then((r) => r.blob())
                // Create a new blob with the mime type
                .then((b) => b.slice(0, b.size, file.mimeType));

              const url = URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = url;
              anchor.download = file.filename;

              document.body.append(anchor);
              anchor.click();

              URL.revokeObjectURL(url);
              anchor.remove();
            }}
          >
            <Icon icon="attachment" className="mr-1" />
            <span className="truncate">{file.filename}</span>
          </a>
        </Badge>
      </TooltipTrigger>
      <TooltipContent>{file.filename}</TooltipContent>
    </Tooltip>
  );
};
