import { createBrowserRouter, Navigate, Outlet, redirect, RouteObject } from "react-router-dom";
import { Roles } from "src/generated/graphql";
import { ActionsRoot } from "./actions/actions-root";
import { AdminRoot } from "./admin/admin-root";
import { AgencyOwnership } from "./admin/agency-ownership";
import { AppetiteManagement } from "./admin/appetite-management";
import { AppetiteNote } from "./admin/appetite-note";
import { AppetiteNotesList } from "./admin/appetite-notes-list";
import { AppetiteNotesRoot } from "./admin/appetite-notes-root";
import { Dashboard } from "./admin/dashboard";
import { EditVertical } from "./admin/edit-vertical";
import { Enablement } from "./admin/enablement";
import { GmailFilters } from "./admin/gmail-filters";
import { Vertical } from "./admin/vertical";
import { VerticalsList } from "./admin/verticals-list";
import { VerticalsRoot } from "./admin/verticals-root";
import { AppetiteRoot } from "./appetite/appetite-root";
import { AppetiteErrorPage } from "./appetite/pages/appetite-error-page";
import { AppetitePage } from "./appetite/pages/appetite-page";
import { AppetiteForInsured } from "./appetite/pages/appetite-page-for-insured";
import { SelectCoveragePage } from "./appetite/pages/select-coverage-page";
import { ActivateAccount, activateAccountLoader } from "./auth/ActivateAccount";
import { AuthGuard } from "./auth/AuthGuard";
import { ForgotPassword } from "./auth/ForgotPassword";
import { Login } from "./auth/Login";
import { getAuthedUserAndAgency } from "./auth/user";
import { BookIndex } from "./book/book-index";
import { BookRoll } from "./book/book-roll";
import { BookRoot } from "./book/book-root";
import { Centered, Page } from "./components/layout";
import { useTheme } from "./components/theme-provider";
import { ErrorPage } from "./error";
import Ethan from "./ethan/ethan";
import { FormsRoot } from "./forms/forms-root";
import { HubRoot } from "./hub/hub-root";
import { InboxRoot } from "./inbox/inbox-root";
import { InsuredEmail } from "./insured/insured-email";
import { InsuredFiles } from "./insured/insured-files";
import { InsuredIndex } from "./insured/insured-index";
import { InsuredMarketingPlan } from "./insured/insured-marketing-plan";
import { InsuredPolicies } from "./insured/insured-policies";
import { InsuredQuotes } from "./insured/insured-quotes";
import { InsuredRoot } from "./insured/insured-root";
import { MarketingPlanDetails } from "./insured/marketing-plan/marketing-plan-details";
import { planRoutes } from "./insured/plan/routes";
import { PolicyDetails } from "./insured/policy/policy-details";
import { QuoteDetails } from "./insured/quote/quote-details";
import { InsuredsRoot } from "./insureds/insureds-root";
import { QuoteBind } from "./opportunity/components/actions/quote-bind";
import { Markets } from "./opportunity/markets/markets";
import { opportunityRoutes } from "./opportunity/routes";
import { PageNotFound } from "./page-not-found";
import { FirstSubmissionPage } from "./public/first-submission-page";

const Beef = () => {
  const { setTheme } = useTheme();
  setTheme("beef");
  return <Navigate to="/" />;
};

export const roleHomepageLoader = async () => {
  const { user } = (await getAuthedUserAndAgency()) || {};
  let path = "/login";

  if (user) {
    const store = localStorage.getItem("defaultSearch");
    const defaultSearch = store ? JSON.parse(store) : "";

    if (user.roles.includes(Roles.Broker)) {
      path = `/opportunity?${defaultSearch}`;
    } else if (user.roles.includes(Roles.Admin)) {
      path = "/admin/dashboard";
    } else {
      path = "/insureds";
    }
  }

  return redirect(path);
};

const authProtectedRoutes: RouteObject[] = [
  { path: "agency", loader: () => redirect("/insureds") },
  { path: "ethan", element: <Ethan /> },
  {
    path: "admin",
    element: <AdminRoot />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "appetite",
        element: <AppetiteManagement />,
      },
      {
        path: "appetite-notes",
        element: <AppetiteNotesRoot />,
        children: [
          {
            index: true,
            element: <AppetiteNotesList />,
          },
          {
            path: "create",
            element: <AppetiteNote mode="create" />,
          },
          {
            path: ":appetiteNoteId/edit",
            element: <AppetiteNote mode="edit" />,
          },
        ],
      },
      {
        path: "agency-ownership",
        element: <AgencyOwnership />,
      },
      {
        path: "enablement",
        element: <Enablement />,
      },
      {
        path: "gmail-filters",
        element: <GmailFilters />,
      },
      {
        path: "verticals",
        element: <VerticalsRoot />,
        children: [
          {
            index: true,
            element: <VerticalsList />,
          },
          {
            path: "create",
            element: <EditVertical />,
          },
          {
            path: ":verticalId",
            element: <Vertical />,
          },
          {
            path: ":verticalId/edit",
            element: <EditVertical />,
          },
        ],
      },
    ],
  },
  {
    path: "book",
    element: <BookRoot />,
    children: [
      {
        path: ":agencyId",
        element: <BookRoll />,
      },
      {
        index: true,
        element: <BookIndex />,
      },
    ],
  },
  {
    // Note: This root path is for testing. Without a clientId, the form won't save
    path: "supplementals",
    element: <FormsRoot />,
    children: [
      {
        // Note: This path is what users should be routed to
        path: ":clientId",
      },
    ],
  },
  {
    path: "new",
    element: <AppetiteRoot />,
    errorElement: <AppetiteErrorPage />,
    children: [
      {
        index: true,
        element: <AppetitePage />,
      },
      {
        path: ":opportunityId",
        children: [
          {
            path: "coverage",
            element: <SelectCoveragePage />,
          },
        ],
      },
    ],
  },
  {
    path: "insured/:insuredId/new", // update to insureds/:insuredId/new
    element: <AppetiteForInsured />,
  },
  {
    path: "opportunity",
    element: <HubRoot />,
    children: [...opportunityRoutes({ path: "opportunity" })],
  },
  {
    path: "actions",
    element: <ActionsRoot />,
    children: [...opportunityRoutes({ path: "actions" })],
  },
  {
    path: "inbox",
    element: <InboxRoot />,
    children: [...opportunityRoutes({ path: "inbox" })],
  },
  {
    path: "insureds/:agencyId?",
    element: <InsuredsRoot />,
  },
  {
    path: "insured/:insuredId",
    element: <InsuredRoot />,
    children: [
      {
        index: true,
        element: <InsuredIndex />,
      },
      {
        path: "email",
        element: <InsuredEmail />,
      },
      {
        path: "files",
        element: <InsuredFiles />,
      },
      ...planRoutes(),
    ],
  },
  {
    path: "insured-sm/:insuredId",
    element: <InsuredRoot />,
    children: [
      {
        index: true,
        element: <InsuredIndex />,
      },
      {
        path: "email",
        element: <InsuredEmail />,
      },
      {
        path: "files",
        element: <InsuredFiles />,
      },
      {
        path: "quotes",
        element: <InsuredQuotes />,
        children: [
          {
            path: ":quoteId",
            element: <QuoteDetails />,
          },
        ],
      },
      {
        path: "quotes/:quoteId/bind",
        element: <QuoteBind />,
      },
      {
        path: "policies",
        element: <InsuredPolicies />,
        children: [
          {
            path: ":policyId",
            element: <PolicyDetails />,
          },
        ],
      },
      {
        path: ":opportunityId",
        element: <InsuredMarketingPlan />,
        children: [
          {
            index: true,
            element: <MarketingPlanDetails />,
          },
          {
            path: "markets",
            element: <Markets />,
          },
        ],
      },
    ],
  },
  { index: true, loader: roleHomepageLoader, element: <div /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Page>
        <Outlet />
      </Page>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: authProtectedRoutes,
      },
    ],
  },
  {
    path: "first-submission",
    element: <FirstSubmissionPage />,
  },
  {
    element: <Centered />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
  {
    path: "appetite/*",
    loader: () => redirect("/new"),
  },
  {
    path: "broker/*",
    loader: () => redirect("/new"),
  },
  {
    path: "beef",
    element: <Beef />,
  },
  {
    path: "*",
    element: (
      <Page>
        <PageNotFound />
      </Page>
    ),
  },
]);
