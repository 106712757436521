import { useAtom } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router";

import { eventsBotAtom, eventsCommentAtom, eventsDescAtom, eventsEmailAtom } from "@/atoms";
import { useMyAccount } from "@/auth/useMyAccount";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/utils";
import {
  OnOpportunityEventAddedDocument,
  OnOpportunityEventAddedSubscription,
  OnOpportunityEventAddedSubscriptionVariables,
  OpportunityEventType,
  useOpportunityEventsQuery,
} from "../../../generated/graphql";
import { CommentForm } from "./comment-form";
import { EventFilter } from "./event-filter";
import { EventRenderer } from "./event-renderer";

export const eventTypes = {
  opportunity: [
    OpportunityEventType.ApplicationSubmitted,
    OpportunityEventType.AssignmentChange,
    OpportunityEventType.TriageAssignment,
    OpportunityEventType.Created,
    OpportunityEventType.DesiredEffectiveDateChange,
    OpportunityEventType.OutcomeChange,
    OpportunityEventType.StatusChange,
    OpportunityEventType.CoveragesChanged,
    OpportunityEventType.BusinessClassCodesChanged,
  ],
  submission: [
    OpportunityEventType.ProductsAdded,
    OpportunityEventType.ProductsRemoved,
    OpportunityEventType.SubmissionStatusChange,
  ],
  bot: [OpportunityEventType.BotComment, OpportunityEventType.ExclusionComment, OpportunityEventType.AutomatedFollowUp],
  comment: [OpportunityEventType.UserComment],
  email: [OpportunityEventType.Email, OpportunityEventType.GmailMessage, OpportunityEventType.GmailRelay],
  upload: [OpportunityEventType.FileUpload],
};

export const Activity = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: user } = useMyAccount();

  const [comment] = useAtom(eventsCommentAtom);
  const [email] = useAtom(eventsEmailAtom);
  const [bot] = useAtom(eventsBotAtom);
  const [desc] = useAtom(eventsDescAtom);

  const types = [
    ...(bot ? eventTypes.bot : []),
    ...(email ? eventTypes.email : []),
    ...(comment ? eventTypes.comment : []),
  ];

  const { data: { opportunityEvents = [] } = {}, subscribeToMore } = useOpportunityEventsQuery({
    variables: {
      input: { opportunityId: opportunityId || "", types },
    },
    skip: !opportunityId,
  });

  useEffect(() => {
    return subscribeToMore<OnOpportunityEventAddedSubscription, OnOpportunityEventAddedSubscriptionVariables>({
      document: OnOpportunityEventAddedDocument,
      variables: { id: opportunityId || "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newEvent = subscriptionData.data.opportunityEventAdded;

        return {
          opportunityEvents: [...prev.opportunityEvents, newEvent],
        };
      },
    });
  }, []);

  if (!user || !opportunityId) {
    return null;
  }

  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle className="flex gap-4 items-center justify-between">
          Activity
          <EventFilter />
        </CardTitle>
      </CardHeader>
      <div
        className={cn("bg-border border-t flex gap-px", desc ? "flex-col" : "flex-col-reverse")}
        id="opportunityEvents"
      >
        {opportunityEvents.map((event) => (
          <EventRenderer key={event.id} event={event} user={user} />
        ))}
        <CommentForm opportunityId={opportunityId} />
      </div>
    </Card>
  );
};
