import { useRef, useState } from "react";
import { useAsync } from "react-use";
import { useDebounce } from "usehooks-ts";

import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/utils";
import commandScore from "command-score";

interface Props<T> {
  options: T[] | ((search?: string) => Promise<T[]>);
  alwaysVisibleOptions?: T[];
  selected?: T;
  align?: "start" | "center" | "end";
  side?: "top" | "right" | "bottom" | "left";
  onSelect: (option: T) => void;
  toValue: (option: T) => string; // Value to search against
  toLabel: (option: T) => string; // Label to show in list
  placeholder?: string | JSX.Element;
  className?: string;
}
export function Autocomplete<T>({
  options,
  alwaysVisibleOptions,
  selected,
  align,
  side,
  onSelect,
  toValue,
  toLabel,
  placeholder,
  className,
}: Props<T>) {
  const isAsync = typeof options === "function";

  return isAsync ? (
    <AutocompleteInternalAsync
      options={options}
      alwaysVisibleOptions={alwaysVisibleOptions}
      selected={selected}
      align={align}
      side={side}
      onSelect={onSelect}
      toValue={toValue}
      toLabel={toLabel}
      placeholder={placeholder}
      className={className}
    />
  ) : (
    <AutocompleteInternal
      options={options}
      alwaysVisibleOptions={alwaysVisibleOptions}
      selected={selected}
      align={align}
      side={side}
      onSelect={onSelect}
      toValue={toValue}
      toLabel={toLabel}
      placeholder={placeholder}
      className={className}
    />
  );
}

interface PropsInternalAsync<T> extends Props<T> {
  options: (search?: string) => Promise<T[]>;
}

export function AutocompleteInternalAsync<T>({
  options,
  alwaysVisibleOptions,
  selected,
  align,
  side,
  onSelect,
  toValue,
  toLabel,
  placeholder,
  className,
}: PropsInternalAsync<T>) {
  const [intermediateTerm, setIntermediateTerm] = useState<string | undefined>();
  const debouncedTerm = useDebounce<string | undefined>(intermediateTerm ?? undefined, 500);

  const state = useAsync(async () => {
    return options(debouncedTerm);
  }, [debouncedTerm]);

  return (
    <AutocompleteInternal
      options={state.value ?? []}
      alwaysVisibleOptions={alwaysVisibleOptions}
      onValueChange={setIntermediateTerm}
      selected={selected}
      align={align}
      side={side}
      onSelect={onSelect}
      toValue={toValue}
      toLabel={toLabel}
      placeholder={placeholder}
      className={className}
    />
  );
}

interface PropsInternal<T> extends Props<T> {
  options: T[];
  onValueChange?: (search: string) => void;
}

function AutocompleteInternal<T>({
  options,
  alwaysVisibleOptions,
  onValueChange,
  selected,
  align,
  side,
  onSelect,
  toValue,
  toLabel,
  placeholder,
  className,
}: PropsInternal<T>) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn("gap-3 justify-between px-3 w-full", className)}
          >
            <span className={cn("truncate", !selected && "text-muted-foreground")}>
              {selected ? toLabel(selected) : placeholder}
            </span>
            <Icon icon="unfold_more" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-full" container={ref.current} align={align} side={side}>
          <Command
            filter={(value, search) => {
              if (alwaysVisibleOptions?.some((option) => toValue(option).trim().toLowerCase() === value)) {
                return 1;
              }
              return commandScore(value, search);
            }}
          >
            <CommandInput placeholder="Search..." onValueChange={onValueChange} />
            <CommandEmpty>No results found</CommandEmpty>
            <CommandGroup>
              {options.map((option, i) => (
                <CommandItem
                  key={i}
                  // Search term matches against this value; value is not visible to user
                  value={toValue(option)}
                  onSelect={() => {
                    onSelect(option);
                    setOpen(false);
                  }}
                >
                  <Icon
                    icon="check"
                    className={cn(
                      "mr-2 h-4 w-4",
                      selected && toValue(selected) === toValue(option) ? "opacity-300" : "opacity-0"
                    )}
                  />
                  <span>{toLabel(option)}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
