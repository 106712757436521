import { useRouteError } from "react-router";

import { ErrorMessage } from "../../components/error-message";
import { AppetiteRoot } from "../appetite-root";

export const AppetiteErrorPage = () => {
  console.error(useRouteError());
  return (
    <AppetiteRoot>
      <div className="p-4">
        <ErrorMessage />
      </div>
    </AppetiteRoot>
  );
};
