import { Checkbox } from "../../../components/ui/checkbox";
import { Label } from "../../../components/ui/label";

export default function CheckboxElement({
  label,
  clientDataKey,
  ...props
}: {
  label: string;
  clientDataKey: string;
} & Parameters<typeof Checkbox>) {
  return (
    <div className="col-span-full">
      <Label className="flex items-center gap-2 text-sm">
        <Checkbox {...props} name={clientDataKey} />
        {label}
      </Label>
    </div>
  );
}
