import { MutationHookOptions, QueryHookOptions } from "@apollo/client";
import { useParams } from "react-router";
import {
  Exact,
  OpportunityDetailsQuery,
  OpportunityUpdateInput,
  UpdateOpportunityMutation,
  useOpportunityDetailsQuery,
  useUpdateOpportunityMutation,
} from "../../generated/graphql";

export const useOpportunity = (options?: QueryHookOptions<OpportunityDetailsQuery, Exact<{ id: string }>>) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data, loading, error, refetch } = useOpportunityDetailsQuery({
    variables: { id: opportunityId! },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
    ...options,
  });
  return {
    opportunity: data?.opportunity,
    loading,
    error,
    refetch,
  };
};

export const useUpdateOpportunity = (
  options?: MutationHookOptions<
    UpdateOpportunityMutation,
    Exact<{
      input: OpportunityUpdateInput;
    }>
  >
) => useUpdateOpportunityMutation({ refetchQueries: ["OpportunityDetails"], ...options });

export const reloadOpportunity = { refetchQueries: ["OpportunityDetails"] };
