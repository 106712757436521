import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function SendingQuoteToAgentAgentView({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Let us know whats next!</CardTitle>
      </CardHeader>
      <CardContent>
        <p>Hi {opportunity.agent.firstName}! Do you want to bind with any of the available quotes?</p>
      </CardContent>
    </Card>
  );
}
export function SendingQuoteToAgentBroker(_: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          We are waiting for the agent to confirm, which if any, quotes they want to move forward with.
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
