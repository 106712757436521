import { getAuthRequestHeaders } from "@cp/auth";

import { HasInternalRole } from "@/components/has-role";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Link, useParams } from "react-router-dom";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollarsAndCents } from "src/utils/strings";
import {
  QuoteFragment,
  QuoteState,
  QuoteStateBindRequestReceivedActions,
  QuoteStateDeliveredActions,
  QuoteStatePassedActions,
  useTransitionQuoteMutation,
} from "../../../generated/graphql";
import PendingQuote from "./pending-quote";
import ProcessedQuote from "./processed-quote";

const UnknownStateQuote = ({ quote }: { quote: QuoteFragment }) => (
  <div className="bg-gray-100">
    <h3 className="font-bold">Unknown Quote State</h3>
    <p>The current state `&quot;`{quote.state}`&quot;` is not recognized.</p>
    <p>Quote ID: {quote.id}</p>
    <p>Created At: {new Date(quote.createdAt).toLocaleString()}</p>
  </div>
);

export const QuoteStateRenderer: React.FC<{ quote: QuoteFragment; refetch?: () => void }> = ({ quote, refetch }) => {
  const renderQuoteState = () => {
    switch (quote.state) {
      case QuoteState.Pending:
        return <PendingQuote quote={quote} refetch={refetch} />;
      case QuoteState.Processed:
        return <ProcessedQuote quote={quote} />;
      case QuoteState.Delivered:
        return <DeliveredQuote quote={quote} />;
      case QuoteState.BindRequestReceived:
        return <BindRequestReceivedQuote quote={quote} />;
      case QuoteState.NotTaken:
        return <NotTakenQuote quote={quote} />;
      case QuoteState.Bound:
        return <BoundQuote quote={quote} />;
      case QuoteState.Invalid:
        return <InvalidQuote quote={quote} />;
      default:
        return <UnknownStateQuote quote={quote} />;
    }
  };

  return renderQuoteState();
};

const DeliveredQuote = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();

  const handleTransition = async (action: QuoteStateDeliveredActions) => {
    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <div className="space-y-3">
      <QuoteInfo quote={quote} />
      <div className="grid grid-cols-2 gap-3">
        <QuoteFiles quote={quote} />
        <QuoteActions>
          <Button
            onClick={() => handleTransition(QuoteStateDeliveredActions.ReceiveBindRequest)}
            variant="outline"
            size="sm"
          >
            Bind Request Received
          </Button>
          <Button
            onClick={() => handleTransition(QuoteStateDeliveredActions.ReceiveNotTaken)}
            variant="outline"
            size="sm"
          >
            Quote Not Taken
          </Button>
          <Button onClick={() => handleTransition(QuoteStateDeliveredActions.Invalidate)} variant="outline" size="sm">
            Invalidate
          </Button>
        </QuoteActions>
      </div>
    </div>
  );
};

const BindRequestReceivedQuote = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();
  const { insuredId } = useParams<"insuredId">();
  const handleTransition = async (action: QuoteStateBindRequestReceivedActions) => {
    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <div className="space-y-3">
      <QuoteInfo quote={quote} />
      <div className="grid grid-cols-2 gap-3">
        <QuoteFiles quote={quote} />
        <QuoteActions>
          <Button variant="outline" size="sm">
            <Link to={`/insured-sm/${insuredId}/quotes/${quote.id}/bind`}>Bind Policy</Link>
          </Button>
          <Button
            onClick={() => handleTransition(QuoteStateBindRequestReceivedActions.Invalidate)}
            variant="outline"
            size="sm"
          >
            Invalidate
          </Button>
        </QuoteActions>
      </div>
    </div>
  );
};

const InvalidQuote = ({ quote }: { quote: QuoteFragment }) => (
  <div className="space-y-3">
    <QuoteInfo quote={quote} />
    <div className="grid grid-cols-2 gap-3">
      <QuoteFiles quote={quote} />
    </div>
  </div>
);

const NotTakenQuote = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();

  const handleTransition = async (action: QuoteStatePassedActions) => {
    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  return (
    <div className="space-y-3">
      <QuoteInfo quote={quote} />
      <div className="grid grid-cols-2 gap-3">
        <QuoteFiles quote={quote} />
        <QuoteActions>
          <Button
            onClick={() => handleTransition(QuoteStatePassedActions.ReceiveBindRequest)}
            variant="outline"
            size="sm"
          >
            Bind Request Received
          </Button>
          <Button onClick={() => handleTransition(QuoteStatePassedActions.Invalidate)} variant="outline" size="sm">
            Invalidate
          </Button>
        </QuoteActions>
      </div>
    </div>
  );
};

const BoundQuote = ({ quote }: { quote: QuoteFragment }) => (
  <div className="space-y-3">
    <QuoteInfo quote={quote} />
    <div className="grid grid-cols-2 gap-3">
      <QuoteFiles quote={quote} />
      <QuoteActions>
        {quote.policy && (
          <Button variant="outline" size="sm" asChild>
            <Link to={`../../policies/${quote.policy.id}`}>Go to Policy</Link>
          </Button>
        )}
      </QuoteActions>
    </div>
  </div>
);

const buttonClassName = "flex gap-2 items-center justify-between text-2xs truncate";

export const QuoteFiles = ({ quote }: { quote: QuoteFragment }) => {
  const { redactedQuote, processedQuote } = quote;
  return (
    <Card>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {redactedQuote && (
          <Button variant="outline" size="sm" asChild>
            <a
              key={redactedQuote?.id}
              href={`/api/files/${redactedQuote?.id}`}
              download={redactedQuote?.filename}
              media={redactedQuote?.mimeType}
              className={buttonClassName}
              onClick={async (e) => {
                e.preventDefault();

                const blob = await fetch(`/api/files/${redactedQuote?.id}`, {
                  headers: getAuthRequestHeaders(),
                })
                  .then((r) => r.blob())
                  .then((b) => b.slice(0, b.size, redactedQuote?.mimeType));

                const url = URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = redactedQuote?.filename;

                document.body.append(anchor);
                anchor.click();

                URL.revokeObjectURL(url);
                anchor.remove();
              }}
            >
              <span className="truncate">{redactedQuote.filename}</span>
              <Badge>Redacted</Badge>
            </a>
          </Button>
        )}
        {processedQuote && (
          <Button variant="outline" size="sm" asChild>
            <a
              key={processedQuote?.id}
              href={`/api/files/${processedQuote?.id}`}
              download={processedQuote?.filename}
              media={processedQuote?.mimeType}
              className={buttonClassName}
              onClick={async (e) => {
                e.preventDefault();

                const blob = await fetch(`/api/files/${processedQuote?.id}`, {
                  headers: getAuthRequestHeaders(),
                })
                  .then((r) => r.blob())
                  .then((b) => b.slice(0, b.size, processedQuote?.mimeType));

                const url = URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = processedQuote?.filename;

                document.body.append(anchor);
                anchor.click();

                URL.revokeObjectURL(url);
                anchor.remove();
              }}
            >
              <span className="truncate">{processedQuote.filename}</span>
              <Badge>Processed</Badge>
            </a>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export const QuoteInfo = ({ quote }: { quote: QuoteFragment }) => {
  const {
    policy,
    premium,
    mep,
    commission,
    carrierFee,
    brokerFee,
    inspectionFee,
    effectiveDate,
    state,
    stateTransitions,
    createdAt,
  } = quote;
  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <Card>
      <CardHeader>
        {policy && (
          <CardTitle>
            {policy.appetiteProduct.carrierName}: {policy.appetiteProduct.carrierProductName}
          </CardTitle>
        )}
        <CardTitle>{state}</CardTitle>
      </CardHeader>
      <CardContent className="gap-4 grid grid-cols-3">
        <dl>
          <dt>Premium</dt>
          <dd>{premium && formatDollarsAndCents(premium)}</dd>
        </dl>
        <dl>
          <dt>MEP</dt>
          <dd>{mep && formatDollarsAndCents(mep)}</dd>
        </dl>
        <dl>
          <dt>Commission</dt>
          <dd>{commission}%</dd>
        </dl>
        <dl>
          <dt>Carrier Fee</dt>
          <dd>{carrierFee && formatDollarsAndCents(carrierFee)}</dd>
        </dl>
        <dl>
          <dt>Broker Fee</dt>
          <dd>{brokerFee && formatDollarsAndCents(brokerFee)}</dd>
        </dl>
        <dl>
          <dt>Inspection Fee</dt>
          <dd>{inspectionFee && formatDollarsAndCents(inspectionFee)}</dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{effectiveDate ? formatTimezoneDateString(effectiveDate, "MMMM d, yyyy") : "Date not set"}</dd>
        </dl>
        <dl>
          <dt>Last Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};

export const QuoteActions = ({ children }: { children: React.ReactNode }) => (
  <HasInternalRole>
    <Card>
      <CardHeader>
        <CardTitle>Actions</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">{children}</CardContent>
    </Card>
  </HasInternalRole>
);
