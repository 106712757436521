import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import {
  useAgenciesQuery,
  useAgentAccountsQuery,
  useInsuredCreateOpportunityMutation,
  useInsuredQuery,
} from "../../../generated/graphql";
import { Card } from "../../components/ui/card";
import { DatePicker } from "../../components/ui/date-picker";
import { Field, FieldInput } from "../../components/ui/field-inputs";
import { Icon } from "../../components/ui/icon";
import { ContinueButton } from "../components/continue-button";
import { AgentSelect } from "./appetite/agent-select";

const FormSchema = z.object({
  desiredEffectiveDate: z.string().min(1, { message: "Desired effective date is required" }),
  needByDate: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  agentEmail: z.string().email(),
  agencyId: z.string(),
});

export const AppetiteForInsured: React.FC = () => {
  const { insuredId } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const { data: { insured } = {} } = useInsuredQuery({ variables: { id: insuredId || "" }, skip: !insuredId });
  const { data: { userAccounts: agents = [] } = {} } = useAgentAccountsQuery();
  const { data: { agencies = [] } = {} } = useAgenciesQuery();
  const [createOpportunity] = useInsuredCreateOpportunityMutation();

  const formMethods = useForm<z.infer<typeof FormSchema>>({ resolver: zodResolver(FormSchema) });

  if (!insuredId) {
    return null;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = formMethods.getValues();
      setSubmitting(true);

      void createOpportunity({
        variables: {
          input: {
            insuredId: insuredId as string & { __scalar: "UUID" },
            desiredEffectiveDate: values.desiredEffectiveDate,
            needByDate: values.needByDate,
            agentInput: {
              agencyId: values.agencyId,
              email: values.agentEmail,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          },
        },
        onCompleted: (data) => {
          navigate(`/new/${data.insuredCreateOpportunity.id}/coverage`);
          data.insuredCreateOpportunity.id;
        },
      });

      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-auto max-lg:flex-col gap-4 max-w-6xl mx-auto p-4">
      <div className="flex-auto">
        <FormProvider {...formMethods}>
          <form id="appetiteForm" onSubmit={(e) => onSubmit(e)}>
            <Card className="bg-muted overflow-hidden space-y-px">
              <div className="bg-background flex flex-row gap-5 items-start p-6 pl-5">
                <Icon icon="psychology_alt" className="filled mt-0.5 text-2xl text-primary" />
                <div className="space-y-3">
                  <h2>Create Marketing Plan for {insured?.name}</h2>
                </div>
              </div>
              <div className="flex flex-col space-y-px">
                <FieldInput icon="business_center" placeholder={insured?.name} disabled />
              </div>
              <div className="flex flex-row gap-1 justify-between">
                <Field icon="event" className="w-full">
                  <DatePicker
                    placeholder="Desired Effective Date"
                    selected={formMethods.watch("desiredEffectiveDate")}
                    onSelect={(e) => {
                      e && formMethods.setValue("desiredEffectiveDate", e?.toISOString());
                      void formMethods.trigger();
                    }}
                    className="pl-3 pr-3.5"
                  />
                </Field>
              </div>
            </Card>
            <AgentSelect
              agents={agents}
              agencies={agencies}
              register={formMethods.register}
              setValues={(valPairs) => {
                valPairs.forEach(([k, v]) => formMethods.setValue(k as keyof z.infer<typeof FormSchema>, v));
                void formMethods.trigger();
              }}
            />
          </form>
        </FormProvider>
        <div className="bottom-4 portrait:bottom-16 flex justify-end mt-4 sticky z-10">
          <ContinueButton form="appetiteForm" disabled={!formMethods.formState.isValid} submitting={submitting} />
        </div>
      </div>
    </div>
  );
};
