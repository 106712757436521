import * as React from "react";

import { cn } from "@/utils";

export const Panel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("bg-background p-6 space-y-4 text-sm", className)} {...props} />
);

export const PanelTitle = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <header
    className={cn("flex font-semibold gap items-center justify-between text-lg leading-tight", className)}
    {...props}
  />
);
