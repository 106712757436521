import { useParams } from "react-router";
import {
  AppetiteNotesForOpportunityQuery,
  AppetiteNoteTagType,
  useAppetiteNotesForOpportunityQuery,
} from "../../../generated/graphql";
import { Badge } from "../../components/ui/badge";

export const AppetiteNotes: React.FC = () => {
  const { opportunityId = "" } = useParams();
  const { data: { appetiteNotesForOpportunity } = {} } = useAppetiteNotesForOpportunityQuery({
    variables: { opportunityId },
    skip: opportunityId === "",
  });

  if (!appetiteNotesForOpportunity || appetiteNotesForOpportunity.length === 0) {
    return null;
  }

  return (
    <>
      <h3>Guidance from Our Producer Team</h3>
      {appetiteNotesForOpportunity?.map(({ appetiteNote: { id, note }, matchedTags }) => (
        <div key={id} className="flex flex-row justify-between">
          <span>{note}</span>
          <div className="flex gap-2">
            {matchedTags.map((tag) => (
              <AppetiteNoteTag key={`${tag.type}:${tag.value}`} tag={tag} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

type AppetiteNoteTag = AppetiteNotesForOpportunityQuery["appetiteNotesForOpportunity"][number]["matchedTags"][number];
export const AppetiteNoteTag: React.FC<{ tag: AppetiteNoteTag }> = ({ tag }) => {
  const text = tag.type === AppetiteNoteTagType.CglCode ? `CGL: ${tag.value}` : tag.value;
  return (
    <Badge variant="outline" className="bg-slate-300">
      {text}
    </Badge>
  );
};
