import { startCase } from "lodash";
import { ExpeditedOpportunityStatus } from "../../../generated/graphql";
import { SearchParamMultiselect } from "../../components/ui/search-param-multiselect";

export const SelectExpeditedStatus = () => (
  <SearchParamMultiselect name="expeditedStatuses" metadata={statusesWithLabels()} />
);

// Sort the keys in the order we want them to appear
// Any new enum values will be appended to the end
const sortedKeys: ExpeditedOpportunityStatus[] = [
  ExpeditedOpportunityStatus.NotApplicable,
  ExpeditedOpportunityStatus.WaitingForCoverages,
  ExpeditedOpportunityStatus.WaitingForInitialRequirements,
  ExpeditedOpportunityStatus.AssigningBroker,
  ExpeditedOpportunityStatus.VerifyDetails,
  ExpeditedOpportunityStatus.VerifyBindingMarketingPlan,
  ExpeditedOpportunityStatus.WaitingOnUnderwriting,
  ExpeditedOpportunityStatus.WaitingOnInitialBrokerReview,
  ExpeditedOpportunityStatus.WaitingOnSubmissionInformationFromAgent,
  ExpeditedOpportunityStatus.SubmittingToCarrier,
  ExpeditedOpportunityStatus.WaitingOnCarrier,
  ExpeditedOpportunityStatus.SendingQuoteToAgent,
  ExpeditedOpportunityStatus.WaitingOnQuoteReview,
  ExpeditedOpportunityStatus.Declined,
  ExpeditedOpportunityStatus.Complete,
];

// Function to create a sorted object with labels
const statusesWithLabels = () => {
  const statusWithLabels: { [key in ExpeditedOpportunityStatus]?: { label: string } } = {};

  // Add existing sorted keys first
  sortedKeys.forEach((key) => {
    statusWithLabels[key] = { label: startCase(key) };
  });

  // Check for any new enum values not in the initial list
  Object.values(ExpeditedOpportunityStatus).forEach((key) => {
    if (!statusWithLabels[key]) {
      // If not already added
      statusWithLabels[key] = { label: startCase(key) }; // Append to the end
    }
  });

  return statusWithLabels;
};
