import { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useMyAccount } from "@/auth/useMyAccount";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { LeadsDetails } from "@/opportunity/info/leads-details";
import { FetchResult } from "@apollo/client";
import { useLeadsQuery } from "../../../generated/graphql";
import { uploadFile } from "../../../utils/file.utils";
import { OrEmailDisplay } from "../../components/or-email";
import { SubmissionTipCard, UploadTipCard } from "./appetite/appetite-cards";
import { AppetiteForm } from "./appetite/appetite-form";
import { BrokerFormSchema, useCreateOpportunity } from "./appetite/use-create-opportunity";
import { useLoadFromDoc } from "./appetite/use-load-from-doc";
import { usePrefillLeads } from "./appetite/use-prefill-leads";

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export const AppetitePage: React.FC = () => {
  const { data: user } = useMyAccount();
  const [searchParams] = useSearchParams();
  const term = searchParams.get("term");
  const [file, setFile] = useState<File>();

  const capitalizedTerm = term
    ? decodeURI(term)
        .split(" ")
        .map((w) => w[0]?.toUpperCase() + w.slice(1, w.length))
        .join(" ")
    : undefined;

  const leadIds = searchParams.get("leadIds")?.split(",") || [];

  const { data: { leads = [] } = {} } = useLeadsQuery({
    variables: { input: { ids: leadIds } },
    skip: leadIds.length === 0,
  });

  const [submitting, setSubmitting] = useState(false);

  const { zodResolver, createOpportunity } = useCreateOpportunity(user);

  const formMethods = useForm<z.infer<typeof BrokerFormSchema>>({
    resolver: zodResolver,
    defaultValues: {
      leadIds,
      clientName: capitalizedTerm,
    },
  });

  const setFormValues: SetValuesFn = (keyVals) => {
    keyVals.forEach(([k, v]) => {
      formMethods.setValue(k as keyof z.infer<typeof BrokerFormSchema>, v);
    });
    formMethods.trigger();
  };

  usePrefillLeads(leads, setFormValues);

  const { parentProps, onClick, ...docUploadMethods } = useLoadFromDoc(setFormValues, setFile);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = formMethods.getValues();
      setSubmitting(true);
      void createOpportunity(values).then(async (res) => {
        let oppId;
        if (isBrokerCreateOpportunity(res)) {
          oppId = res.data?.brokerCreateOpportunity.id; // Access BrokerCreateOpportunity properties
        } else if (isAgentCreateOpportunity(res)) {
          oppId = res.data?.agentCreateOpportunity.id; // Access AgentCreateOpportunity properties
        }

        if (file && oppId) {
          await uploadFile(file, oppId);
        }

        setSubmitting(false);
      });
    }
  };

  return (
    <div className="flex flex-auto max-lg:flex-col gap-4 max-w-6xl mx-auto p-4" {...parentProps}>
      <AppetiteForm
        formMethods={formMethods}
        uploadInProgress={docUploadMethods.loading}
        onSubmit={onSubmit}
        submitting={submitting}
      />
      <div className="flex-none space-y-4 lg:w-1/3">
        {leads[0] && (
          <Card className="[&>div]:rounded-lg">
            <LeadsDetails leads={leads} />
          </Card>
        )}
        <SubmissionTipCard />
        <UploadTipCard clickToUpload={onClick} documentUploadMethods={docUploadMethods} />
        <OrEmailDisplay className="w-full" />
      </div>
      {docUploadMethods.dragging ? (
        <div className="absolute w-screen h-screen opacity-70 bg-popover flex justify-center items-center">
          <p className="mb-48 flex items-center gap-2 text-xl font-semibold ">
            <Icon icon="upload" className="animate-bounce" />
            Drop file to start upload
          </p>
        </div>
      ) : null}
    </div>
  );
};

interface BrokerCreateOpportunityMutation {
  brokerCreateOpportunity: { id: string };
}

interface AgentCreateOpportunityMutation {
  agentCreateOpportunity: { id: string };
}

function isBrokerCreateOpportunity(
  res: FetchResult<BrokerCreateOpportunityMutation> | FetchResult<AgentCreateOpportunityMutation>
): res is FetchResult<BrokerCreateOpportunityMutation> {
  return (res as FetchResult<BrokerCreateOpportunityMutation>)?.data?.brokerCreateOpportunity !== undefined;
}

function isAgentCreateOpportunity(
  res: FetchResult<BrokerCreateOpportunityMutation> | FetchResult<AgentCreateOpportunityMutation>
): res is FetchResult<AgentCreateOpportunityMutation> {
  return (res as FetchResult<AgentCreateOpportunityMutation>)?.data?.agentCreateOpportunity !== undefined;
}
