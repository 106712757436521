import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  useUpdateVerticalMarketingPlanTemplateProductRulesMutation,
  VerticalMarketingPlanTemplateFragment,
  VerticalMarketingPlanTemplateProductFragment,
} from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { Button } from "../../components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../components/ui/collapsible";
import { Icon } from "../../components/ui/icon";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "../../opportunity/components/editor";
import EditRules from "./edit-rules";
import { ClientDataRuleItem, ClientDataRuleValueType, Rule, Rules, RuleSchema, RuleType } from "./rule";

export default function VerticalMarketingPlanRules({
  template,
  refetchVertical,
}: {
  template: VerticalMarketingPlanTemplateFragment;
  refetchVertical: () => void;
}) {
  const { openForm, openConfirmation } = useModal();

  const [updateVerticalMarketingPlanTemplateProductRulesTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
      onCompleted() {
        void refetchVertical();
      },
    });

  const addRules = async () => {
    const fd = await openForm(
      z.object({
        rule: z.array(
          z.object({
            type: z.string(),
            key: z.string().optional(),
            valueType: z.string().optional(),
          })
        ),
      }),
      <AddRules />
    );

    if (!fd) {
      return;
    }

    await Promise.all(
      template.products.map((p) => {
        const parsedRules = JSON.parse(p.rules);
        const updatedRules = [...parsedRules, fd.rule];
        return updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
        });
      })
    );
  };

  const editRules = async (ruleKey: string) => {
    const firstMatch = template.products
      .flatMap((p) => JSON.parse(p.rules) as Rules)
      .find((rule) => getRuleKey(rule) === ruleKey);
    if (!firstMatch) {
      return;
    }
    const fd = await openForm(
      z.object({
        rule: z.array(
          z.object({
            type: z.string(),
            key: z.string().optional(),
            valueType: z.string().optional(),
          })
        ),
      }),
      <AddRules
        existingRule={[
          firstMatch[0].type === "client-data"
            ? {
                type: firstMatch[0].type,
                key: firstMatch?.[0].key,
                valueType: firstMatch[0].valueType,
              }
            : { type: firstMatch[0].type },
        ]}
      />,
      {
        defaultValues: {
          rule: [
            {
              type: firstMatch[0].type,
              key: "key" in firstMatch[0] ? firstMatch?.[0].key : "",
              valueType: "valueType" in firstMatch[0] ? firstMatch[0].valueType : "",
            },
          ],
        },
      }
    );

    if (!fd) {
      return;
    }

    await Promise.all(
      template.products.map((p) => {
        const parsedRules = JSON.parse(p.rules) as Rules;
        const updatedRules = parsedRules.map((rule) => {
          const key = getRuleKey(rule);
          if (key !== ruleKey) {
            return rule;
          }

          return [{ ...rule[0], ...fd.rule[0] }];
        });
        return updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
        });
      })
    );
  };

  const updateRule = async (product: VerticalMarketingPlanTemplateProductFragment, ruleKey: string) => {
    const rules = JSON.parse(product.rules) as Rules;
    const ruleIndex = rules.findIndex((rule) => getRuleKey(rule) === ruleKey);
    const fd = await openForm(
      z.object({
        rule: RuleSchema,
      }),
      <EditRules rule={rules[ruleIndex][0]} />
    );

    if (!fd) {
      return;
    }

    const updatedRules = rules.map((rule, i: number) => {
      if (i !== ruleIndex) {
        return rule;
      }

      return [fd.rule];
    });

    await updateVerticalMarketingPlanTemplateProductRulesTrigger({
      variables: { input: { id: product.id, rules: JSON.stringify(updatedRules) } },
    });
  };

  const deleteRules = async (ruleKey: string) => {
    const isConfirmed = await openConfirmation({ title: "Delete Rule", variant: "destructive" });
    if (!isConfirmed) {
      return;
    }

    const rules = template.products.map((product) => {
      const parsedRules = JSON.parse(product.rules) as Rules;
      return parsedRules.filter((r) => getRuleKey(r) !== ruleKey);
    });

    await Promise.all(
      template.products.map((product, i) =>
        updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: product.id, rules: JSON.stringify(rules[i]) } },
        })
      )
    );
  };

  const rules = groupBy(
    template.products.flatMap((p) => {
      const rules = JSON.parse(p.rules) as Rules;

      return rules.map((rule) => ({
        id: getRuleKey(rule),
        product: p,
        rule,
      }));
    }),
    (rule) => rule.id
  );

  return (
    <>
      <div className="flex justify-end mb-1">
        <Button size="sm" variant="outline" onClick={addRules}>
          <Icon icon="add" />
          Add Rule
        </Button>
      </div>
      <Editor>
        <EditorHeader columns={["type", "", ""]} />
        {Object.entries(rules).map(([ruleKey, rules]) => (
          <Collapsible key={ruleKey}>
            <EditorItem>
              <EditorRow>
                <RuleKeyDisplay ruleKey={ruleKey} />
                <div>
                  <CollapsibleTrigger asChild>
                    <Button size="sm" variant="outline" className="group" disabled={false}>
                      <span className="text-xs">{rules.length}</span>
                      <Icon icon="unfold_more" className="group-data-[state=open]:hidden" />
                      <Icon icon="unfold_less" className="group-data-[state=closed]:hidden" />
                    </Button>
                  </CollapsibleTrigger>
                </div>
                <div className="flex gap">
                  <Button variant="ghost" size="icon-sm" onClick={() => editRules(ruleKey)}>
                    <Icon icon="edit" />
                  </Button>
                  <Button variant="ghost" size="icon-sm" onClick={() => deleteRules(ruleKey)}>
                    <Icon icon="delete" />
                  </Button>
                </div>
              </EditorRow>
              <CollapsibleContent>
                <Editor>
                  <EditorHeader columns={["Carrier name", "Carrier Product", "Rule", ""]}></EditorHeader>
                  {rules.map((rule, i) => (
                    <EditorItem key={i}>
                      <EditorRow>
                        <div>{rule.product.appetiteProduct.carrierName}</div>
                        <div>{rule.product.appetiteProduct.carrierProductName}</div>
                        <RuleDisplay rule={rule.rule} />
                        <div>
                          <Button variant="outline" size="icon-sm" onClick={() => updateRule(rule.product, ruleKey)}>
                            <Icon icon="edit" />
                          </Button>
                        </div>
                      </EditorRow>
                    </EditorItem>
                  ))}
                </Editor>
              </CollapsibleContent>
            </EditorItem>
          </Collapsible>
        ))}
      </Editor>
    </>
  );
}

function getRuleKey(rule: Rule) {
  return rule.map((r) => `${r.type}--${"key" in r ? r.key : ""}`).join("---");
}

function getRuleFromKey(ruleKey: string) {
  return ruleKey.split("---").map((part) => {
    const [type, key] = part.split("--");
    return { type, key };
  });
}

function RuleKeyDisplay({ ruleKey }: { ruleKey: string }) {
  const rule = getRuleFromKey(ruleKey);

  return (
    <div>
      {rule.map((r, i) => (
        <div key={i} className="flex gap">
          <EditorCount>{r.type}</EditorCount>
          <span>{r.key}</span>
        </div>
      ))}
    </div>
  );
}

function RuleDisplay({ rule }: { rule: Rule }) {
  return (
    <div>
      {rule.map((r, i) => (
        <div key={i} className="flex gap">
          <span>{"key" in r ? r.key : ""}</span>
          <span>{"operator" in r ? r.operator : ""}</span>
          <span>{r.value}</span>
        </div>
      ))}
    </div>
  );
}

function AddRules({ existingRule }: { existingRule?: Rule }) {
  const { setValue } = useFormContext();
  const [currentRule, setCurrentRule] = useState<Rule | undefined>(existingRule);

  useEffect(() => {
    setValue("rule", currentRule);
  }, [currentRule]);

  return (
    <>
      <h3>Add Rule</h3>

      <Select
        value={currentRule?.[0]?.type}
        onValueChange={(v: RuleType) =>
          setCurrentRule(
            v === RuleType.ClientData
              ? [{ type: RuleType.ClientData, key: "", valueType: ClientDataRuleValueType.Text }]
              : [{ type: v }]
          )
        }
      >
        <SelectTrigger>
          <SelectValue placeholder="Select Rule Type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={RuleType.MinimumPremium}>Minimum Premium</SelectItem>
          <SelectItem value={RuleType.ClientData}>Client Data</SelectItem>
          <SelectItem value={RuleType.State}>State</SelectItem>
          <SelectItem value={RuleType.CGL}>CGL</SelectItem>
        </SelectContent>
      </Select>

      <RuleEditor rule={currentRule} onRuleChange={setCurrentRule} />

      <div className="flex justify-center mt-4">
        <Button size="sm">Save</Button>
      </div>
    </>
  );
}

function RuleEditor({ rule, onRuleChange }: { rule?: Rule; onRuleChange: (rule: Rule) => void }) {
  switch (rule?.[0]?.type) {
    case RuleType.MinimumPremium:
    case RuleType.State:
    case RuleType.CGL:
      return null;
    case RuleType.ClientData:
      return <ClientDataRule rule={rule as ClientDataRuleItem[]} onRuleChange={onRuleChange} />;
    default:
      return null;
  }
}

function ClientDataRule({ rule, onRuleChange }: { rule?: ClientDataRuleItem[]; onRuleChange: (rule: Rule) => void }) {
  const parsedRule = rule ?? [];
  return (
    <div>
      <Label>Client Data Key</Label>
      <Input
        type="text"
        value={parsedRule[0]?.key}
        onChange={({ target }) =>
          onRuleChange([
            {
              type: RuleType.ClientData,
              key: target.value,
              valueType: parsedRule[0]?.valueType ?? ClientDataRuleValueType.Text,
            },
          ])
        }
      />

      <Label>Value Type</Label>
      <Select
        value={parsedRule[0]?.valueType}
        onValueChange={(v: ClientDataRuleValueType) =>
          onRuleChange([{ type: RuleType.ClientData, key: parsedRule[0]?.key, valueType: v }])
        }
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {Object.values(ClientDataRuleValueType).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
