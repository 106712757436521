import { Link } from "react-router-dom";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { parseError } from "@/utils";
import {
  ProductExclusionStatus,
  useFindProductExclusionsQuery,
  useUpdateProductExclusionMutation,
} from "../../generated/graphql";
import { Input, TextArea } from "../forms/components/FormFields";

export function AppetiteManagement() {
  const { toast } = useToast();
  const { data, refetch } = useFindProductExclusionsQuery({
    variables: {
      where: {
        statuses: [ProductExclusionStatus.PendingReview],
      },
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });
  const [updateProductExclusion] = useUpdateProductExclusionMutation({
    onCompleted: () => {
      void refetch();
      toast({ title: "Product Exclusion Updated" });
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });

  const { openForm } = useModal();

  return (
    <Table className="bg-background overflow-hidden rounded">
      <TableHeader>
        <TableRow>
          <TableHead>Carrier</TableHead>
          <TableHead>State</TableHead>
          <TableHead>Naics</TableHead>
          <TableHead>Reason</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.productExclusionsFind.map((exclusion) => (
          <TableRow key={exclusion.id}>
            <TableCell>
              {exclusion.appetiteProduct.carrierName} <br /> {exclusion.appetiteProduct.carrierProductName}
            </TableCell>
            <TableCell>{exclusion.state ?? "-"}</TableCell>
            <TableCell>{exclusion.naicsCode ?? "-"}</TableCell>
            <TableCell>{exclusion.reason ?? "-"}</TableCell>
            <TableCell className="flex flex-col gap-3">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="icon" variant="link">
                    <Icon icon="more_vert" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem>
                    <Link to={`/opportunity/${exclusion.submission.opportunityId}`} className="w-full h-full">
                      <Icon className="mr-2" icon="open_in_new" />
                      View Opportunity
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={async () => {
                      const formData = await openForm(
                        z.object({
                          state: z.string().nullish(),
                          naicsCode: z.string().nullish(),
                          approvedReason: z.string(),
                        }),
                        <UpdateForm />,
                        {
                          defaultValues: {
                            state: exclusion.state,
                            naicsCode: exclusion.naicsCode,
                            approvedReason: exclusion.reason ?? "",
                          },
                        }
                      );

                      if (formData) {
                        void updateProductExclusion({
                          variables: {
                            input: {
                              id: exclusion.id,
                              status: ProductExclusionStatus.Approved,
                              approvedReason: formData.approvedReason,
                              state: formData.state ?? "",
                              naics: formData.naicsCode ?? "",
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Icon className="mr-2" icon="edit" />
                    Modify & Approve
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      updateProductExclusion({
                        variables: {
                          input: {
                            id: exclusion.id,
                            status: ProductExclusionStatus.Approved,
                          },
                        },
                      })
                    }
                  >
                    <Icon className="mr-2 text-emerald-600" icon="check" />
                    Approve
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      updateProductExclusion({
                        variables: {
                          input: {
                            id: exclusion.id,
                            status: ProductExclusionStatus.Rejected,
                          },
                        },
                      })
                    }
                  >
                    <Icon className="mr-2 text-destructive" icon="delete" />
                    Reject
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const UpdateForm: React.FC = () => (
  <div className="flex flex-col gap-4">
    <h3>Current</h3>
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col">
        <h4>State</h4>
        <Input name="state" />
      </div>
      <div className="flex flex-col">
        <h4>Naics</h4>
        <Input name="naicsCode" />
      </div>
      <div className="flex flex-col col-span-2">
        <h4>Reason</h4>
        <TextArea name="approvedReason" className="w-full" />
      </div>
    </div>

    <div className="flex justify-between">
      <Button type="reset" variant="destructive">
        Cancel
      </Button>
      <Button type="submit">Submit</Button>
    </div>
  </div>
);
