import { NavLink, useSearchParams } from "react-router-dom";

import { STATUS_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { OpportunityListItemFragment, OpportunityStatus } from "../../generated/graphql";
import { formatTimezoneDateString } from "../../utils/date";
import { HasInternalRole } from "./has-role";
import { Avatar } from "./ui/avatar";
import { Badge } from "./ui/badge";
import { Icon } from "./ui/icon";
import { OpportunityPin } from "./ui/opportunity-pin";

export const OpportunityListItem = ({
  path = "/opportunity/",
  opportunity,
  params,
}: {
  path?: string;
  opportunity: OpportunityListItemFragment;
  params?: Record<string, string>;
}) => {
  const [searchParams] = useSearchParams();
  const appendParams = new URLSearchParams({ ...Object.fromEntries(searchParams), ...params });

  return (
    <NavLink
      to={`${path}${opportunity.id}?${appendParams.toString()}`}
      className={({ isActive, isPending }) =>
        cn(
          "@container/item bg-background gap-3 flex h-16 items-center pl-3 pr-1.5 overflow-hidden text-foreground/70 transition-all w-full",
          isPending && "text-foreground shadow-unselected",
          isActive && "text-foreground shadow-selected"
        )
      }
    >
      <Avatar user={opportunity.broker} isExpedited={opportunity.isExpedited} className="flex-none text-xs" />
      <div className="flex-auto gap-4 grid @2xl:grid-cols-2 items-center">
        <div className="leading-4 space-y-2 truncate">
          <div className="flex gap-2 items-center">
            <div className="font-semibold leading-4 text-xs truncate">{opportunity.insured.name}</div>
            {opportunity.isTest && (
              <HasInternalRole>
                <Badge>Test</Badge>
              </HasInternalRole>
            )}
          </div>
          <div className="flex gap-2 items-center text-2xs leading-3">
            <Icon
              icon={STATUS_METADATA[OpportunityStatus[opportunity.status]].icon}
              className={STATUS_METADATA[OpportunityStatus[opportunity.status]].className}
            />
            {formatTimezoneDateString(opportunity.desiredEffectiveDate, "MMM d")}
          </div>
        </div>
        <HasInternalRole>
          <div className="hidden opacity-0 @2xl/item:block @2xl/item:opacity-100 leading-4 space-y-2 transition-opacity truncate">
            <div className="font-semibold leading-4 text-xs truncate">{opportunity.agent.agency.name}</div>
            <div className="text-2xs leading-3">
              {opportunity.agent.firstName} {opportunity.agent.lastName}
            </div>
          </div>
        </HasInternalRole>
      </div>
      <OpportunityPin id={opportunity.id} />
    </NavLink>
  );
};
