import { groupBy, uniq } from "lodash";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Field } from "@/components/ui/field-inputs";
import { Form, FormField } from "@/components/ui/form";
import { Loading } from "@/components/ui/loading";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/utils";
import {
  MarketTypes,
  useExpeditedOpportunityTransitionMutation,
  useSearchAppetiteQuery,
} from "../../../../generated/graphql";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function AddAdditionalBrokerageMarkets({ opportunity, refetch }: ExpeditedOpportunityStatusViewProps) {
  const { data, loading } = useSearchAppetiteQuery({
    variables: {
      id: opportunity.id,
    },
  });

  const brokerageMarkets = data?.appetiteSearchForOpportunity.scoredProducts
    .filter((p) => p.product.marketType === MarketTypes.Brokerage)
    .map((p) => ({
      ...p,
      appetiteProduct: p.product,
    }));

  const methods = useForm({
    defaultValues: { products: [] },
  });
  const [transition, { loading: loadingTransition }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        opportunityId: opportunity.id,
      },
    },
    onCompleted: () => refetch(),
  });

  const transitionNext = () =>
    transition({
      variables: {
        input: {
          opportunityId: opportunity.id,
          payload: JSON.stringify(methods.getValues().products),
        },
      },
    });

  if (brokerageMarkets?.length === 0 || loading) {
    return <Loading />;
  }

  const groups = groupBy(brokerageMarkets, (m) => (uniq(m.product.authority) ?? []).join("/"));

  return (
    <Form {...methods}>
      <Card>
        <CardHeader>
          <CardTitle>Add Additional Brokerage Markets</CardTitle>
          <CardDescription>
            Please confirm the brokerage markets that you would like to quote this risk with.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap">
            {Object.entries(groups)
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(([authority, markets]) => (
                <div key={authority} className="bg-muted p-2">
                  <h3>Markets</h3>
                  {markets
                    .sort((a, b) => (a.appetiteProduct.carrierName > b.appetiteProduct.carrierName ? 1 : -1))
                    .map((m) => (
                      <FormField
                        key={m.product.id}
                        name="products"
                        render={({ field }) => {
                          const checked = field.value?.includes(m.appetiteProduct.id);
                          return (
                            <Field
                              className={cn("gap-6 bg-muted", checked ? "text-foreground" : "text-muted-foreground")}
                            >
                              <Switch
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  return checked
                                    ? field.onChange([...field.value, m.appetiteProduct.id])
                                    : field.onChange(
                                        field.value?.filter((value: string) => value !== m.appetiteProduct.id)
                                      );
                                }}
                              />
                              {m.appetiteProduct.carrierName} - {m.appetiteProduct.carrierProductName}
                            </Field>
                          );
                        }}
                      />
                    ))}
                </div>
              ))}
          </div>
        </CardContent>
        <CardFooter>
          <Button size="sm" disabled={loadingTransition} onClick={() => transitionNext()}>
            Verify
          </Button>
        </CardFooter>
      </Card>
    </Form>
  );
}
