import { cn } from "@/utils";

export const Editor = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("first:rounded-t-lg last:rounded-b-lg overflow-hidden space-y-px", className)} {...props} />
);

export const EditorRow = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("auto-cols-fr gap-2 grid grid-flow-col items-center text-xs", className)} {...props} />
);

export const EditorItem = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("odd:bg-background even:bg-accent border-t p-4 space-y-4", className)} {...props} />
);

export const EditorHeader = ({
  className,
  children,
  title,
  columns,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { title?: string; columns?: React.ReactNode[] }) => (
  <>
    <header className={cn("bg-accent p-4 rounded-t space-y-4", columns && "pb-2", className)} {...props}>
      {(title || children) && (
        <div className="flex gap-2 items-center">
          {title && <h4 className="mr-auto">{title}</h4>}
          {children}
        </div>
      )}
      {columns && (
        <EditorRow className="text-muted-foreground text-2xs">
          {columns?.map((c, key) => (
            <div key={`c${key}`} className="truncate">
              {c}
            </div>
          ))}
        </EditorRow>
      )}
    </header>
    <div className="bg-accent border-t h-px" />
  </>
);

export const EditorCount = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      "bg-foreground inline-flex justify-center min-w-[1.5em] px-1 rounded text-background text-2xs",
      className
    )}
    {...props}
  />
);
