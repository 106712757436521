import { SUBMISSION_STATUS_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { MarketingEffortSubmission } from "./marketing-efforts";

const className = "leading-relaxed py-2 text-xs text-muted-foreground";

export const SubmissionStatusAgent: React.FC<{
  submission: MarketingEffortSubmission;
}> = ({ submission }) => {
  if (!submission.status) {
    return <div className={className}>-</div>;
  }

  const meta = SUBMISSION_STATUS_METADATA[submission.status];

  return <div className={cn(className, meta.agentColor)}>{meta.agentLabel}</div>;
};
