import { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";

import { HasExternalRole, HasInternalRole, HasRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SwitchParam } from "@/components/ui/switch-param";
import { cn } from "@/utils";
import { OpportunityGroupBy, Roles } from "../../../generated/graphql";
import { SelectAgency } from "./select-agency";
import { SelectAgencyAgent, SelectAgent } from "./select-agent";
import { SelectBroker } from "./select-broker";
import { SelectCreatedWithin } from "./select-created-within";
import { SelectEffectiveDate } from "./select-effective-date";
import { SelectExpedited } from "./select-expedited";
import { SelectExpeditedStatus } from "./select-expedited-status";
import { SelectGroup } from "./select-group";
import { SelectOpportunityStatus } from "./select-opportunity-status";
import { SelectSubmissionStatus } from "./select-submission-status";

export const ListToolBar: React.FC<PropsWithChildren<{}>> = () => {
  const [searchParams] = useSearchParams();
  const { groupBy, isTest, ...rest } = Object.fromEntries(searchParams);
  const numFilters = Object.keys(rest).length;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className={cn(
            "h-6 font-semibold gap-2 px-1.5 rounded-full text-xs z-1",
            numFilters === 0 ? "aspect-square" : "bg-accent pl-2.5"
          )}
        >
          {!!numFilters && <span className="text-2xs">{numFilters}</span>}
          <Icon icon="filter_list" />
        </Button>
      </PopoverTrigger>
      <ListParameters />
    </Popover>
  );
};

export const ListParameters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const notDashboard = searchParams.get("groupBy") !== OpportunityGroupBy.Dashboard;

  return (
    <PopoverContent className="p-0 w-96">
      <Section>
        <RowLabel>Group By</RowLabel>
        <SelectGroup />
      </Section>
      <Section className="border-t flex-auto">
        <RowLabel>Status</RowLabel>
        <SelectOpportunityStatus />

        <HasInternalRole>
          <RowLabel>Agency</RowLabel>
          <SelectAgency />
          <RowLabel>Agent</RowLabel>
          <SelectAgent />
          <RowLabel>Broker</RowLabel>
          <SelectBroker />
        </HasInternalRole>
        <HasExternalRole>
          <RowLabel>Agent</RowLabel>
          <SelectAgencyAgent />
        </HasExternalRole>
        <RowLabel>Created Within</RowLabel>
        <SelectCreatedWithin />
        <RowLabel>Effective Date</RowLabel>
        <SelectEffectiveDate />
        <HasInternalRole>
          {notDashboard && (
            <>
              <RowLabel>Submission Status</RowLabel>
              <SelectSubmissionStatus />
            </>
          )}
          <RowLabel>Expedited</RowLabel>
          <SelectExpedited />
          <HasRole roles={[Roles.Admin]}>
            <RowLabel>Expedited Status</RowLabel>
            <SelectExpeditedStatus />
          </HasRole>
        </HasInternalRole>
      </Section>
      <Section className="border-t flex items-center py-3">
        {notDashboard && (
          <HasInternalRole>
            <SwitchParam name="isTest" label="Include Test Opportunities" />
          </HasInternalRole>
        )}
        <Button
          variant="outline"
          size="sm"
          className="ml-auto"
          onClick={() =>
            setSearchParams(
              {},
              {
                replace: true,
              }
            )
          }
        >
          Reset
        </Button>
      </Section>
    </PopoverContent>
  );
};

const Section = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <section className={cn("gap-2 grid grid-cols-[6rem_1fr] p-4", className)}>{children}</section>
);

const RowLabel = ({ children }: { children: React.ReactNode }) => (
  <div className="self-center text-xs text-muted-foreground">{children}</div>
);
