import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Panel, PanelTitle } from "@/components/ui/panel";
import { UserAccountFragment } from "../../../generated/graphql";

interface Props {
  agent?: UserAccountFragment;
  broker?: UserAccountFragment;
  agencyOwner?: UserAccountFragment;
}

export const Team: React.FC<Props> = ({ agent, agencyOwner, broker }: Props) => (
  <Panel>
    <PanelTitle>Team</PanelTitle>
    {broker && (
      <UserInfo user={broker} title={agencyOwner?.id === broker.id ? "Lead Broker, Agency Contact" : "Lead Broker"} />
    )}
    {agencyOwner && broker?.id !== agencyOwner?.id && <UserInfo user={agencyOwner} title="Agency Contact" />}
    {agent && <UserInfo user={agent} title={agent.agency.name} />}
  </Panel>
);

const UserInfo = ({ user, title }: { user: UserAccountFragment; title: string }) => (
  <div className="flex gap-4 items-center">
    <Avatar user={user} className="text-base" />
    <div className="flex-auto space-y-0.5">
      <div className="font-semibold text-sm truncate">{`${user?.firstName} ${user?.lastName}`}</div>
      <div className="italic text-muted-foreground text-xs truncate">{title}</div>
    </div>
    <Button variant="ghost" size="icon" className="rounded-full" asChild>
      <a href={`mailto:${user?.email}`} target="_blank" rel="noreferrer">
        <Icon icon="mail" />
      </a>
    </Button>
  </div>
);
