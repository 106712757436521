import { coerceBoolean } from "@cp/toolkit";
import {
  ExpeditedOpportunityStatus,
  OpportunitiesFilterInput,
  OpportunitiesSortBy,
  OpportunityGroupBy,
  OpportunityOutcome,
  OpportunityStatus,
  SubmissionStatus,
  SubmissionStatusCategory,
} from "../../generated/graphql";

export type ValidSearchKey = keyof Omit<OpportunitiesFilterInput, "ids"> | "page" | "groupBy";

export const adaptSearchParams = (
  params: URLSearchParams
): OpportunitiesFilterInput & { page: number; groupBy?: OpportunityGroupBy } => {
  const getParam = (key: ValidSearchKey) => params.get(key) ?? undefined;

  return {
    agencyId: getParam("agencyId"),
    agentId: getParam("agentId"),
    term: getParam("term"),
    isTest: coerceBoolean(getParam("isTest")),
    isExpedited: getParam("isExpedited") ? coerceBoolean(getParam("isExpedited")) : undefined,
    expeditedStatuses: getParam("expeditedStatuses")
      ? (getParam("expeditedStatuses")!.split(",") as ExpeditedOpportunityStatus[])
      : undefined,
    outcome: getParam("outcome") as OpportunityOutcome,
    hoursSinceLastActivity: getParam("hoursSinceLastActivity")
      ? Number.parseInt(getParam("hoursSinceLastActivity")!)
      : undefined,
    effectiveDate: getParam("effectiveDate") ? Number.parseInt(getParam("effectiveDate")!) : undefined,
    dateRange: getParam("dateRange") ? Number.parseInt(getParam("dateRange")!) : undefined,
    statuses: getParam("statuses")
      ? (getParam("statuses")!.split(",") as OpportunityStatus[])
      : getParam("term") // Update this
      ? undefined
      : [OpportunityStatus.Active],
    sortBy: getParam("sortBy") as OpportunitiesSortBy,
    brokerId: getParam("brokerId") === "null" ? null : getParam("brokerId") || undefined,
    submissionStatuses: getParam("submissionStatuses")
      ? (getParam("submissionStatuses")!.split(",") as SubmissionStatus[])
      : undefined,
    submissionStatusCategory: (getParam("submissionStatusCategory") as SubmissionStatusCategory) || undefined,
    submissionCoreLines: getParam("submissionCoreLines") ? getParam("submissionCoreLines")!.split(",") : undefined,
    page: Number(getParam("page")) || 1,
    groupBy: (getParam("groupBy") as OpportunityGroupBy) ?? undefined,
  };
};
