import { getAuthRequestHeaders } from "@cp/auth";
import { FormEvent, useState } from "react";
import { useParams } from "react-router";
import { useMap } from "usehooks-ts";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading, Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { toast, useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSet } from "react-use";
import { z } from "zod";
import {
  ExpeditedOpportunityQuery,
  ExpeditedOpportunityQueryResult,
  SubmissionFragment,
  SubmissionStatus,
  useCreateQuotePdfMutation,
  useExpeditedOpportunityTransitionMutation,
  useSubmissionDetailsQuery,
  useUpdateSubmissionMutation,
} from "../../../../generated/graphql";
import { uploadFile } from "../../../../utils/file.utils";
import { Checkbox } from "../../../components/ui/checkbox";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Reform } from "../../../forms/Reform";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";
interface QuoteState {
  insuredName?: string;
  carrierName?: string;
  mep?: string;
  premium?: string;
  subjectivities?: string;
  fileId?: string;
  loading: boolean;
  agentQuoteFileId?: string;
  agentQuoteUrl?: string;
}

const FullSupportCarriers = new Set(["Ategrity"]);

export function ReviewFinalMarketingPlan({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const [submitting, setSubmitting] = useState(false);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [submissionId, setSubmissionId] = useState<string | undefined>(undefined);
  const { opportunityId } = useParams<"opportunityId">();
  const [quotes, quoteActions] = useMap<string, QuoteState>();

  const updateQuote = (id: string, key: keyof QuoteState, value: string | boolean) => {
    const currentQuote = quotes.get(id) || {};
    const updatedQuote = { ...currentQuote, [key]: value } as QuoteState;
    quoteActions.set(id, updatedQuote);
  };
  const [loading, setLoading] = useState(false);

  const [transition, { loading: transitioning }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        action: "Continue",
        opportunityId: opportunityId!,
      },
    },
  });

  const [updateSubmission] = useUpdateSubmissionMutation();
  const [createQuotePDF, { loading: processingQuote }] = useCreateQuotePdfMutation({
    onError() {
      setSubmitting(false);
      toast({ title: "Unable to generate quote cover letter" });
    },
  });

  const updateFileForProcessing = async (key: string, value: string | null, subId: string) => {
    await updateSubmission({
      variables: {
        input: {
          id: subId,
          [key]: [value],
        },
      },
    });
  };

  const declineSubmission = (id: string) => {
    return updateSubmission({
      variables: {
        input: {
          id,
          status: SubmissionStatus.ClosedDeclined,
        },
      },
    });
  };

  const generateQuote = async (submission: SubmissionFragment, fileId: string) => {
    await createQuotePDF({
      variables: {
        input: {
          submissionId: submission.id,
          quoteFileId: fileId,
        },
      },
      onCompleted: () => {
        setLoading(false);
      },
    });

    toast({ title: "Quote proposal generated" });
  };

  const getTitle = (s: SubmissionType) => {
    let content;
    switch (s.status) {
      case SubmissionStatus.ClosedDeclined:
        content = (
          <span>
            <span className="text-red-500">DECLINED</span> - {s.appetiteProduct.carrierName} -{" "}
            {s.appetiteProduct.carrierProductName}
          </span>
        );
        break;
      case SubmissionStatus.QuotingReferred:
        content = (
          <span>
            <span className="text-blue-500">REFERRED</span> - {s.appetiteProduct.carrierName} -{" "}
            {s.appetiteProduct.carrierProductName}
          </span>
        );
        break;
      default:
        content = (
          <span>
            {s.appetiteProduct.carrierName} - {s.appetiteProduct.carrierProductName}
          </span>
        );
        break;
    }

    return <CardTitle>{content}</CardTitle>;
  };
  const getContent = (s: SubmissionType) => {
    let content;
    switch (s.status) {
      case SubmissionStatus.QuotingReferred:
      case SubmissionStatus.ClosedDeclined:
        content = null;
        break;
      default:
        content = (
          <>
            <div className="flex justify-between mx-4 my-1 gap-4 items-center">
              <div className="flex flex-wrap justify-start gap-2">
                {s.quoteFilesForProcessing.map((file) => {
                  return (
                    <a
                      key={file.id}
                      href={`/api/files/${file.id}`}
                      download={file.filename}
                      media={file.mimeType}
                      className="flex gap-2 items-start leading-tight hover:text-primary"
                      onClick={async (e) => {
                        e.preventDefault();

                        const blob = await fetch(`/api/files/${file.id}`, {
                          headers: getAuthRequestHeaders(),
                        })
                          .then((r) => r.blob())
                          .then((b) => b.slice(0, b.size, file.mimeType));

                        const url = URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = file.filename;

                        document.body.append(anchor);
                        anchor.click();

                        URL.revokeObjectURL(url);
                        anchor.remove();
                      }}
                    >
                      <Card className="flex flex-col gap-2 content-center p-4">
                        <span>
                          <Badge variant="outline" className="text-xs">
                            Original
                          </Badge>
                        </span>
                        <div className="flex gap-2 content-center">
                          <Icon icon="attachment" className="scale-150" />
                          {file.filename}
                        </div>
                      </Card>
                    </a>
                  );
                })}

                {s.processedQuoteFiles.map((file) => {
                  return (
                    <a
                      key={file.id}
                      href={`/api/files/${file.id}`}
                      download={file.filename}
                      media={file.mimeType}
                      className="flex gap-2 items-start leading-tight hover:text-primary"
                      onClick={async (e) => {
                        e.preventDefault();

                        const blob = await fetch(`/api/files/${file.id}`, {
                          headers: getAuthRequestHeaders(),
                        })
                          .then((r) => r.blob())
                          .then((b) => b.slice(0, b.size, file.mimeType));

                        const url = URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = file.filename;

                        document.body.append(anchor);
                        anchor.click();

                        URL.revokeObjectURL(url);
                        anchor.remove();
                      }}
                    >
                      <Card className="flex flex-col gap-2 content-center p-4">
                        <span>
                          <Badge className="text-xs">Processed</Badge>
                        </span>
                        <div className="flex gap-2 content-center">
                          <Icon icon="attachment" className="scale-150" />
                          {file.filename}
                        </div>
                      </Card>
                    </a>
                  );
                })}
              </div>

              <Button asChild variant="ghost" size="icon-sm">
                <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
                  <input
                    type="file"
                    name="file"
                    className="hidden"
                    onChange={async (e) => {
                      setSubmitting(true);
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        const id = await uploadFile(file, opportunity.id, "INTERNAL");
                        setFileId(id);
                        await updateFileForProcessing("quoteFilesForProcessingIds", id, s.id);

                        if (FullSupportCarriers.has(s.appetiteProduct.carrierName)) {
                          await generateQuote(s, id);
                        } else {
                          setSubmissionId(s.id);
                        }

                        setSubmitting(false);
                      }
                    }}
                  />
                  {submitting ? (
                    <Spinner />
                  ) : (
                    <Icon icon="upload" className="text-xl border border-black rounded-md p-4" />
                  )}
                </label>
              </Button>
            </div>
            <div className="flex justify-end gap-6 ">
              <Button variant="destructive" size="sm" onClick={() => declineSubmission(s.id)}>
                Decline
              </Button>
              <ReferButton submission={s} opportunity={opportunity} />
            </div>
          </>
        );
        break;
    }

    return <CardContent>{content}</CardContent>;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Review all markets</CardTitle>
        <CardDescription>These are the markets that have been selected and we are actively working on.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {[...(opportunity.submissions ?? [])]
          .sort((a, b) => a?.createdAt?.localeCompare(b?.createdAt))
          .map((s) => (
            <Card key={s.id} className="text-sm">
              <CardHeader>{getTitle(s)}</CardHeader>
              {getContent(s)}
              <SubmissionQuoteDialog
                setFileId={setFileId}
                setSubmissionId={setSubmissionId}
                submissionId={submissionId}
                submission={s}
                updateQuote={updateQuote}
                quote={quotes.get(s.id)}
                fileId={fileId}
                setLoading={setLoading}
                loading={loading}
              />
            </Card>
          ))}
      </CardContent>
      <CardFooter>
        <Button size="sm" onClick={() => transition()} disabled={processingQuote || transitioning || loading}>
          Send Quotes to Agent
        </Button>
      </CardFooter>
    </Card>
  );
}
type Submission = NonNullable<
  NonNullable<ExpeditedOpportunityQueryResult["data"]>["opportunity"]
>["submissions"][number];

const SubmissionQuoteDialog = ({
  submission,
  submissionId,
  updateQuote,
  fileId,
  setFileId,
  setSubmissionId,
  quote,
  setLoading,
  loading,
}: {
  submission: Submission;
  submissionId: string | undefined;
  fileId: string | undefined;
  setFileId: (id: string) => void;
  setSubmissionId: (id: string) => void;
  updateQuote: (id: string, field: keyof QuoteState, value: string | boolean) => void;
  quote: QuoteState | undefined;
  setLoading: (v: boolean) => void;
  loading: boolean;
}) => {
  const { data } = useSubmissionDetailsQuery({
    variables: { id: submission.id },
    skip: !submission.id,
  });
  const { toast } = useToast();

  const [createQuotePDF] = useCreateQuotePdfMutation();
  const generateQuote = async () => {
    setLoading(true);
    if (!submission.id) {
      setLoading(false);
      return null;
    }
    if (!quote) {
      setLoading(false);
      return null;
    }

    return createQuotePDF({
      variables: {
        input: {
          submissionId: submission.id,
          insuredName: quote.insuredName,
          carrierName: quote.carrierName ?? submission.appetiteProduct.carrierName,
          mep: quote.mep ?? "25%",
          premium: quote.premium!,
          subjectivities:
            quote.subjectivities
              ?.replaceAll("-", "")
              .split("\n")
              .map((s) => s.trim()) ?? [],
          quoteFileId: fileId!,
        },
      },

      onError() {
        setLoading(false);
        toast({ title: "Unable to generate quote cover letter" });
      },

      onCompleted() {
        setLoading(false);
        setSubmissionId("");
        toast({ title: "Quote proposal generated" });
        setFileId("");
      },
    });
  };

  const updateSubmission = async (key: keyof QuoteState, value: string | null) => {
    if (!submission.id) {
      return;
    }
    if (!value) {
      return;
    }
    updateQuote(submission.id, key, value);
  };

  if (loading) {
    return <Loading />;
  }

  if (!submission.id) {
    return null;
  }

  return (
    <Dialog
      open={submission.id === submissionId}
      onOpenChange={(open) => {
        if (!open) {
          setSubmissionId("");
          setFileId("");
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Provide Details About the Quote:</DialogTitle>
          <DialogTitle>{submission.appetiteProduct.carrierProductName}</DialogTitle>
        </DialogHeader>
        <div className="mt-2 flex justify-center flex-col">
          <div>
            <div className="grid grid-cols-3 gap-2">
              <div className="mt-2">
                <Input
                  name="insuredName"
                  placeholder="First Named Insured"
                  onChange={({ target }) => updateSubmission(target.name as keyof QuoteState, target.value)}
                />
              </div>

              <div className="mt-2">
                <Input
                  name="carrierName"
                  placeholder="Carrier"
                  value={quote?.carrierName ?? data?.submission.appetiteProduct.carrierName}
                  onChange={({ target }) => updateSubmission(target.name as keyof QuoteState, target.value)}
                />
              </div>

              <div className="mt-2">
                <Input
                  name="mep"
                  placeholder="MEP"
                  value={quote?.mep ?? "25%"}
                  onChange={({ target }) => updateSubmission(target.name as keyof QuoteState, target.value)}
                />
              </div>

              <div className="mt-2">
                <Input
                  name="premium"
                  placeholder="Premium"
                  onChange={({ target }) => updateSubmission(target.name as keyof QuoteState, target.value)}
                />
              </div>
            </div>
            <div className="mt-2">
              <Textarea
                rows={8}
                name="subjectivities"
                placeholder="List Subjectivities"
                onChange={({ target }) => updateSubmission(target.name as keyof QuoteState, target.value)}
              />
            </div>
          </div>
          <Button onClick={generateQuote}>Close and Exit</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ReferralFormSchema = z.object({
  referralReason: z.string().min(1),
});

type ReferralForm = z.infer<typeof ReferralFormSchema>;
type SubmissionType = NonNullable<ExpeditedOpportunityQuery["opportunity"]>["submissions"][number];

function ReferButton({
  submission,
  opportunity,
}: {
  submission: SubmissionType;
  opportunity: Omit<ExpeditedOpportunityStatusViewProps, "refetch">["opportunity"];
}) {
  const formMethods = useForm<z.infer<typeof ReferralFormSchema>>({
    resolver: zodResolver(ReferralFormSchema),
    defaultValues: {
      referralReason: "",
    },
  });

  const [fileIds, fileActions] = useSet();

  const [transition] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        payload: JSON.stringify({
          submissionId: submission.id,
          fileIds: [...fileIds],
        }),
        action: "SendReferralToCarrier",
        opportunityId: opportunity.id,
      },
    },
  });

  const [updateSubmission] = useUpdateSubmissionMutation();

  const handleSubmit = (_e: FormEvent<HTMLFormElement>, values: ReferralForm) => {
    return updateSubmission({
      variables: {
        input: {
          id: submission.id,
          status: SubmissionStatus.QuotingReferred,
          referralReason: values.referralReason,
        },
      },
      onCompleted: () => {
        return transition();
      },
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="default">
          Refer
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Refer Details</DialogTitle>
          <DialogDescription>Explain why this submission is being marked as a referral.</DialogDescription>
          <DialogDescription>
            We'll email these details and any selected files to the carrier contact for this market product.
          </DialogDescription>
        </DialogHeader>
        <Reform
          schema={ReferralFormSchema}
          id={"REFERRAL_REASON"}
          onSubmit={handleSubmit}
          methods={formMethods}
          className="space-y-4"
        >
          <div className="grid gap-4 py-4">
            <Label htmlFor="referralReason" className="text-left">
              Reason
            </Label>
            <div className="grid items-center">
              <Input {...formMethods.register("referralReason")} className="col-span-3" />
            </div>
            <div>
              {opportunity.files.map((f) => {
                return (
                  <Label key={f.id} className="my-1 flex  justify-between">
                    {f.filename}
                    <Checkbox
                      checked={fileIds.has(f.id)}
                      onCheckedChange={(value) => {
                        if (value) {
                          fileActions.add(f.id);
                        } else {
                          fileActions.remove(f.id);
                        }
                      }}
                      aria-label="Select"
                    />
                  </Label>
                );
              })}
            </div>
          </div>
          <DialogFooter>
            <DialogTrigger asChild>
              <Button type="submit">Send Referral To Carrier</Button>
            </DialogTrigger>
          </DialogFooter>
        </Reform>
      </DialogContent>
    </Dialog>
  );
}
