import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/utils";
import { SelectAgency } from "../hub/components/select-agency";

export const InsuredsListFilter = () => {
  const navigate = useNavigate();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className={cn("h-6 font-semibold gap-2 px-1.5 rounded-full text-xs z-1")}>
          <Icon icon="filter_list" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-96">
        <Section>
          <RowLabel>Agency</RowLabel>
          <SelectAgency path="/insureds" />
        </Section>
        <Section className="border-t flex items-center py-3">
          <Button variant="outline" size="sm" className="ml-auto" onClick={() => navigate("/insureds")}>
            Reset
          </Button>
        </Section>
      </PopoverContent>
    </Popover>
  );
};

const Section = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <section className={cn("gap-2 grid grid-cols-[6rem_1fr] p-4", className)}>{children}</section>
);

const RowLabel = ({ children }: { children: React.ReactNode }) => (
  <div className="self-center text-xs text-muted-foreground">{children}</div>
);
