import { head } from "lodash";
import { LeadFragment, useAgencyQuery, useAgentAccountsQuery } from "../../../../generated/graphql";
import { useMyAccount } from "../../../auth/useMyAccount";
import { SetValuesFn } from "../appetite-page";

export const usePrefillLeads = (leads: LeadFragment[], setValues: SetValuesFn) => {
  const { data: user } = useMyAccount();

  // In practice, all Leads should have the same, or very similar information
  // So use the first Lead's data to populate the form
  const lead = head(leads);
  const { data: { userAccounts: agentsFromLeads = [] } = {} } = useAgentAccountsQuery({
    variables: {
      input: {
        agencyId: lead?.agencyId || null,
        firstName: lead?.agentFirstName || null,
        lastName: lead?.agentLastName || null,
      },
    },
    skip: !lead || !user || !user.internal,
  });

  // In practice, there should only be one Agent in the leadsAgents array
  // So use the first Agent to populate the form
  const agentFromLeads = head(agentsFromLeads);
  useAgencyQuery({
    variables: { id: agentFromLeads?.agency.id ?? "" },
    skip: !agentFromLeads,
    onCompleted: ({ agency: agencyFromLead }) => {
      const { accountName, expirationDate, description } = lead || {};

      const agentFirstName = agentFromLeads?.firstName || lead?.agentFirstName;
      const agentLastName = agentFromLeads?.lastName || lead?.agentLastName;
      const agentEmail = agentFromLeads?.email;
      const agencyName = agentFromLeads?.agency.name || agencyFromLead?.name;

      setValues(
        [
          ["clientName", accountName],
          ["desiredEffectiveDate", expirationDate],
          ["description", description],
          ["firstName", agentFirstName],
          ["lastName", agentLastName],
          ["agentEmail", agentEmail],
          ["agencyName", agencyName],
        ].filter((pair): pair is [string, string] => pair[1] !== undefined)
      );
    },
  });
};
