import { useAtom } from "jotai";
import { first } from "lodash";
import { CSSProperties } from "react";
import { useParams } from "react-router";

import { foldersVisibleAtom, selectTagsAtom } from "@/atoms";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { useFileTagsQuery } from "../../../generated/graphql";

export const TagRow = ({ label, depth }: { label: string; depth: number }) => {
  const { opportunityId } = useParams<"opportunityId">();
  const [selectedTags, setSelectedTags] = useAtom(selectTagsAtom);
  const [, setFoldersVisible] = useAtom(foldersVisibleAtom);
  const { data: tagData } = useFileTagsQuery({
    variables: {
      input: {
        label,
        opportunityId: opportunityId ?? "",
      },
    },
  });
  const tag = first(tagData?.fileTags);
  const tagLabel = tag?.label ?? "";
  const selected = selectedTags.includes(tagLabel);

  if (!tag?.hasFile) {
    return null;
  }

  return (
    <div className={cn("px-4", depth === 0 ? "border-y font-medium mt-px py-3 text-sm" : "font-normal text-xs")}>
      <div
        style={{ "--depth": depth } as CSSProperties}
        className={cn(
          "cursor-pointer flex gap-3 items-center py-2 pl-[calc((var(--depth))_*_0.75rem)]",
          selected ? "filled" : "text-muted-foreground"
        )}
        onClick={() => {
          setFoldersVisible(false);
          setSelectedTags((curr) => (curr === tagLabel ? "" : tagLabel));
        }}
      >
        <Icon icon={selected ? "folder_open" : "folder"} />
        <span className={cn("truncate")}>{tag?.label}</span>
      </div>
      {tag.label !== "Acords" &&
        tag?.childTags?.map((t) => <TagRow key={t?.label} label={t?.label} depth={depth + 1} />)}
    </div>
  );
};
