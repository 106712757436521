import { Outlet, useParams } from "react-router-dom";

import { PageContent } from "@/components/layout";
import { Bar } from "@/components/ui/bar";
import { useDocumentTitle } from "usehooks-ts";
import { useAgencyQuery } from "../../generated/graphql";
import { HasInternalRole } from "../components/has-role";
import { Avatar } from "../components/ui/avatar";
import { SelectAgency } from "../hub/components/select-agency";
import { BookToolbar } from "./components/book-toolbar";

export const BookRoot: React.FC = () => {
  const { agencyId } = useParams<"agencyId">();
  const { data: { agency = null } = {} } = useAgencyQuery({
    variables: { id: agencyId! },
    skip: !agencyId,
  });
  useDocumentTitle(`${agency?.name || "Book Roll"}: QuoteWell`);
  const { owner } = agency || {};

  return (
    <PageContent>
      <Bar as="header" position="sticky">
        <HasInternalRole elseShow={<div></div>}>
          <SelectAgency path="/book" hasLeads />
        </HasInternalRole>
        <div className="flex flex-row gap-2">
          <BookToolbar />
          <HasInternalRole>
            <Avatar user={owner} className="text-sm" />
          </HasInternalRole>
        </div>
      </Bar>
      <Outlet />
    </PageContent>
  );
};
