// import { DevTool } from "@hookform/devtools";
import { Maps } from "@cp/toolkit";
import { SentryLogger } from "@qw/sentry";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { PageContent } from "@/components/layout";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SupplementalForm, useClientDataLazyQuery } from "../../generated/graphql";
import { Autocomplete } from "../components/ui/autocomplete";
import { useUpsertSearchParams } from "../hooks/useUpsertSearchParams";
import "./anvil-pdf-generation.css";
import { SubmitSupplementalButton } from "./components/CompleteSupplementButton";
import { GenerateAnvilButton } from "./components/GenerateAnvilButton";
import { AutoGarage } from "./supplementals/auto-garage";
import { BuildersRisk } from "./supplementals/builders-risk";
import { ClientDataContext } from "./supplementals/client-data-context";
import { ContractorsGeneralLiability } from "./supplementals/contractors-general-liability";
import { ContractorsPollutionLiability } from "./supplementals/contractors-pollution-liability";
import { Cyber } from "./supplementals/cyber";
import { Daycare } from "./supplementals/daycare";
import { SubmissionTemplate } from "./supplementals/submission-template";

type FormProps = Array<{ displayName: string; key: string; element: React.ReactNode; filename: string }>;
export const SUPPLEMENTAL_FORMS: FormProps = [
  {
    key: SupplementalForm.ContractorsGeneralLiability,
    displayName: "Contractors General Liability",
    element: <ContractorsGeneralLiability />,
    filename: "QuoteWell-Contractors-GL-Supplemental",
  },
  {
    key: SupplementalForm.ContractorsPollutionLiability,
    displayName: "Contractors Pollution Liability",
    element: <ContractorsPollutionLiability />,
    filename: "QuoteWell-CPL-Supplemental",
  },
  {
    key: SupplementalForm.Cyber,
    displayName: "Cyber",
    element: <Cyber />,
    filename: "QuoteWell-Cyber-Supplemental",
  },
  {
    key: SupplementalForm.Daycare,
    displayName: "Daycare",
    element: <Daycare />,
    filename: "QuoteWell-Daycare-Supplemental",
  },
  {
    key: SupplementalForm.BuildersRisk,
    displayName: "Builder's Risk",
    element: <BuildersRisk />,
    filename: "QuoteWell-Builders-Risk-Supplemental",
  },
  {
    key: SupplementalForm.Garage,
    displayName: "Garage",
    element: <AutoGarage />,
    filename: "QuoteWell-Garage-Supplemental",
  },
  {
    key: SupplementalForm.SubmissionSupplemental,
    displayName: "Submission Supplemental",
    element: <SubmissionTemplate />,
    filename: "QuoteWell-Submission-Supplemental",
  },
];

export const FormsRoot: React.FC = () => {
  useDocumentTitle("Supplementals: QuoteWell");
  const [changed, setChanged] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [searchParams, setSearchParams] = useUpsertSearchParams();
  const { form, opportunityId } = Object.fromEntries(searchParams);
  const selectedForm = SUPPLEMENTAL_FORMS.find((f) => f.key === form) ?? SUPPLEMENTAL_FORMS[0];
  const { toast } = useToast();

  const { clientId } = useParams();

  const [clientData] = useClientDataLazyQuery();

  const methods = useForm({
    defaultValues: async () => {
      if (!clientId) {
        console.warn("No clientId present. Data won't be saved.");
        return {};
      }
      const { data: supplementalResult, error } = await clientData({ variables: { clientId } });
      if (!supplementalResult) {
        SentryLogger.warn(`Supplemental result was undefined for Client(${clientId})`);
        return {};
      }
      if (error) {
        SentryLogger.exception(error);
        toast({
          title: "Error",
          description: "There was an error loading your supplemental data.",
          variant: "destructive",
        });
        return {};
      }

      const supplementalData = Maps.collectBy(
        supplementalResult.clientData,
        (val) => `${val.key}${val.index === null ? "" : `--${val.index}`}`,
        (val) => val.value
      );
      return Object.fromEntries(supplementalData.entries());
    },
  });

  const handleBlur = () => {
    setBlurred(true);
    if (changed) {
      const timeout = setTimeout(function () {
        setBlurred(false);
        setChanged(false);
      }, 1000);

      return function () {
        clearTimeout(timeout);
      };
    }
    setBlurred(false);
    return;
  };

  return (
    <PageContent id="FormsRoot">
      <Bar as="header" position="sticky">
        <Autocomplete
          placeholder="Search Forms"
          options={SUPPLEMENTAL_FORMS}
          selected={selectedForm}
          onSelect={(o) => setSearchParams({ form: o.key })}
          toValue={(o) => o.key}
          toLabel={(o) => o.displayName}
          className="mr-auto"
        />
        {clientId && blurred && changed && <Spinner />}
        {opportunityId && <SubmitSupplementalButton filename={selectedForm.filename} />}
        <GenerateAnvilButton filename={selectedForm.filename}>Download PDF</GenerateAnvilButton>
        {opportunityId && (
          <Button asChild>
            <Link to={`/opportunity/${opportunityId}`}>Save & Return</Link>
          </Button>
        )}
      </Bar>
      {/* <DevTool control={methods.control} /> */}
      <form
        id="pdf-input"
        onChange={() => setChanged(true)}
        onBlur={() => handleBlur()}
        onSubmit={(e) => e.preventDefault()}
      >
        <ClientDataContext.Provider value={{ insuredId: clientId }}>
          <FormProvider {...methods}>{selectedForm.element}</FormProvider>
        </ClientDataContext.Provider>
      </form>
    </PageContent>
  );
};
