import { Outlet } from "react-router";

import { cn } from "@/utils";
import { Sidebar } from "./sidebar";

export interface BaseProps {
  className?: string;
  children?: React.ReactNode;
  id?: string;
  onClick?: VoidFunction;
}

export const Page: React.FC<BaseProps> = ({ className, children }) => (
  <>
    <Sidebar />
    <div className={cn("flex min-h-dvh w-dvw", "landscape:pl-16", "portrait:pb-[--nav-height]", className)} id="Page">
      <div className="flex-none portrait:hidden max-lg:hidden w"></div>
      {children}
    </div>
  </>
);

export const PageContent: React.FC<BaseProps> = ({ className, children, id }) => (
  <div className={cn("bg-accent flex-auto shadow-xl", className)} id={id}>
    {children}
  </div>
);

export const Centered = () => (
  <div className="flex flex-col items-center justify-center min-h-dvh p-4">
    <Outlet />
  </div>
);
