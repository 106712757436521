import { addHours, formatDistanceToNow, parseISO } from "date-fns";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  ActionExecutionOperation,
  ActionsQuery,
  useDeleteActionMutation,
  useUpdateActionMutation,
} from "../../../../generated/graphql";

const cancelledReasonSchema = z.object({
  cancelledReason: z.string(),
});

interface ActionProps {
  action: ActionsQuery["actions"][number];
}

export const ExecutedAction: React.FC<ActionProps> = ({ action }) => {
  const cancelledReason = cancelledReasonSchema.safeParse(JSON.parse(action.metadata || ""));

  const getExecutedDisplayText =
    action.executedOperation === ActionExecutionOperation.Approved
      ? cancelledReason.success
        ? `Cancelled (${cancelledReason.data.cancelledReason})`
        : "Sent"
      : "Cancelled";

  return (
    <div className="border-t px-6 py-4">
      <span className="font-medium">{getExecutedDisplayText}</span>{" "}
      <span className="text-muted-foreground">
        {formatDistanceToNow(parseISO(action.executedAt), { addSuffix: true })}
      </span>
    </div>
  );
};

export const ScheduledAction: React.FC<ActionProps> = ({ action }) => {
  const [updateAction] = useUpdateActionMutation();
  const [deleteAction] = useDeleteActionMutation({
    variables: { id: action.id },
    refetchQueries: ["Actions"],
  });
  const { toast } = useToast();

  const approveAction = () =>
    updateAction({
      variables: {
        input: {
          id: action.id,
          executedOperation: ActionExecutionOperation.Approved,
        },
      },
    });

  return (
    <div className="border-t px-6 py-4 relative text-primary">
      <span className="font-medium">Scheduled</span>{" "}
      <span className="opacity-75">{formatDistanceToNow(parseISO(action.autoExecuteAt), { addSuffix: true })}</span>
      <nav className="absolute flex items-center my-auto top-0 bottom-0 right-6">
        <DatePicker
          inputClassName="bg-transparent hover:bg-transparent -mr-0.5"
          placeholder=""
          selected={action.executedAt}
          onSelect={(e) => {
            if (!e || e < new Date()) {
              toast({ title: "Date must be in the future", variant: "destructive" });
            } else {
              void updateAction({
                variables: {
                  input: {
                    id: action.id,
                    autoExecuteAt: addHours(e, 10)?.toISOString(), // add 10 hours, so the email gets sent around 10am
                  },
                },
              });
            }
          }}
        />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={() => approveAction()}>
              <Icon icon="send" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Send Immediately</TooltipContent>
        </Tooltip>
        <Button variant="ghost" size="icon" onClick={() => deleteAction()}>
          <Icon icon="delete" />
        </Button>
      </nav>
    </div>
  );
};
