import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { first } from "lodash";
import { useParams } from "react-router";
import { useFileTagsQuery, useRemoveFIleTagMutation, useUpdateFileTagMutation } from "../../../generated/graphql";
import { Table, TableBody, TableCell, TableRow } from "../../components/ui/table";

import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Icon } from "../../components/ui/icon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useToast } from "../../components/ui/use-toast";

export const TagMenu = ({ labelId }: { labelId: string }) => {
  const [update] = useUpdateFileTagMutation();
  const { toast } = useToast();
  const { opportunityId } = useParams<"opportunityId">();

  const { data, refetch } = useFileTagsQuery({
    variables: {
      input: {
        label: labelId,
        opportunityId: opportunityId ?? "",
      },
    },
  });

  const { data: labelsData } = useFileTagsQuery({
    variables: {
      input: {
        opportunityId: opportunityId ?? "",
      },
    },
  });

  const label = first(data?.fileTags);
  const labels = labelsData?.fileTags ?? [];
  const parentLabels = label?.parentTags ?? [];
  const childLabels = label?.childTags ?? [];

  const SelectLabelParent = () => {
    return (
      <Select
        onValueChange={(value) => {
          void update({
            variables: {
              input: {
                label: label?.label ?? "",
                parentTag: value,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Tag added` });
              void refetch();
            },
            refetchQueries: ["FileTags"],
          });
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Label" />
        </SelectTrigger>
        <SelectContent>
          {labels?.map((label) => (
            <SelectItem key={label.label} value={label.label}>
              {label.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };
  const SelectLabelChild = () => {
    return (
      <Select
        onValueChange={(value) => {
          void update({
            variables: {
              input: {
                label: label?.label ?? "",
                childTag: value,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Tag added` });
              void refetch();
            },
            refetchQueries: ["FileTags"],
          });
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Tag" />
        </SelectTrigger>
        <SelectContent>
          {labels?.map((label) => (
            <SelectItem key={label.label} value={label.label}>
              {label.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  return (
    <Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="text-base">
            <Icon icon="more_vert" className="font-bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-64">
          <DropdownMenuLabel>Options</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DialogTrigger asChild>
            <DropdownMenuItem>
              <>
                <Icon icon="edit" className="mr-3" /> Edit Tag
              </>
            </DropdownMenuItem>
          </DialogTrigger>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Tag Details</DialogTitle>
          <DialogDescription>Edit the hierarchy of this tag.</DialogDescription>
        </DialogHeader>
        <div>
          <h3>Tag: {label?.label}</h3>
          <div className="min-h-96 grid grid-cols-2 gap-x-4">
            <div className="space-y-3">
              <h3>Parent Tags</h3>
              <SelectLabelParent />
              {parentLabels.length === 0 ? (
                "No tags"
              ) : (
                <TagTableParents labels={parentLabels} rootLabel={label?.label ?? ""} refetch={refetch} />
              )}
            </div>
            <div className="space-y-3">
              <h3>Child Tags</h3>
              <SelectLabelChild />
              {childLabels.length === 0 ? (
                "No tags"
              ) : (
                <TagTableChildren labels={childLabels} rootLabel={label?.label ?? ""} refetch={refetch} />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TagTableParents = ({
  rootLabel,
  labels,
  refetch,
}: {
  refetch: () => void;
  rootLabel: string;
  labels: Array<{
    __typename?: "FileTag" | undefined;
    label: string;
  }>;
}) => {
  const [update] = useRemoveFIleTagMutation();
  const { toast } = useToast();
  return (
    <Table className="text-xs">
      <TableBody>
        {labels.map(({ label }) => {
          return (
            <TableRow key={label}>
              <TableCell>{label}</TableCell>
              <TableCell className="flex justify-end items-center">
                <span
                  onClick={() => {
                    void update({
                      variables: {
                        input: {
                          label: rootLabel,
                          parentTag: label,
                        },
                      },
                      onError: (e) => {
                        toast({ title: e.message });
                      },
                      onCompleted: () => {
                        toast({ title: `Tag removed` });
                        refetch();
                      },
                    });
                    return;
                  }}
                >
                  <Icon icon="cancel" className="p-2 h-8" />
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
const TagTableChildren = ({
  rootLabel,
  labels,
  refetch,
}: {
  refetch: () => void;
  rootLabel: string;
  labels: Array<{
    __typename?: "FileTag" | undefined;
    label: string;
  }>;
}) => {
  const [update] = useRemoveFIleTagMutation();
  const { toast } = useToast();
  return (
    <Table className="text-xs">
      <TableBody>
        {labels.map(({ label }) => {
          return (
            <TableRow key={label}>
              <TableCell>{label}</TableCell>
              <TableCell className="flex justify-end items-center">
                <span
                  onClick={() => {
                    void update({
                      variables: {
                        input: {
                          label: rootLabel,
                          childTag: label,
                        },
                      },
                      onError: (e) => {
                        toast({ title: e.message });
                      },
                      onCompleted: () => {
                        toast({ title: `Tag removed` });
                        refetch();
                      },
                    });
                    return;
                  }}
                >
                  <Icon icon="cancel" className="p-2 h-8" />
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
