import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { AppetiteNoteFragment, useAppetiteNotesQuery } from "../../generated/graphql";
import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { Icon } from "../components/ui/icon";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";

export function AppetiteNotesList() {
  useDocumentTitle("Admin: Appetite Notes");
  const { data: { appetiteNotes = [] } = {} } = useAppetiteNotesQuery();

  return <AppetiteNotesTable appetiteNotes={appetiteNotes} />;
}

const AppetiteNotesTable: React.FC<{ appetiteNotes: AppetiteNoteFragment[] }> = ({ appetiteNotes }) => {
  const navigate = useNavigate();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>CGL(s)</TableHead>
          <TableHead>State(s)</TableHead>
          <TableHead>Line(s) Of Business</TableHead>
          <TableHead>Note</TableHead>
          <TableHead>
            <Button variant="ghost" size="icon" className="flex justify-end gap-1" onClick={() => navigate("create")}>
              <span className="text-xs">Create New Note</span>
            </Button>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {appetiteNotes.map((note) => (
          <TableRow key={note.id}>
            <TableCell>
              <Pills items={note.isoCglCodes} />
            </TableCell>
            <TableCell>
              <Pills items={note.states} />
            </TableCell>
            <TableCell>
              <Pills items={note.lobs} />
            </TableCell>
            <TableCell>
              <span className="text-xs">{note.note}</span>
            </TableCell>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={() => navigate(`${note.id}/edit`)}>
                <Icon icon="edit" />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const Pills: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Badge variant="outline" className="bg-slate-300" key={item}>
          {item}
        </Badge>
      ))}
    </div>
  );
};
