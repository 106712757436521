import { cn } from "@/utils";
import { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { Field, fieldInputClassName } from "./field-inputs";
import { FormField } from "./form";
import { IconString } from "./icon";
import { Input } from "./input";

interface OuterFieldProps {
  className?: string;
  iconClassName?: string;
  icon?: IconString;
}
interface CurrencyFieldProps {
  autoFocus?: boolean;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
  allowDecimal?: boolean;
}

export const CurrencyFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  allowDecimal = false,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & OuterFieldProps & CurrencyFieldProps) => {
  return (
    <Field icon={props.icon} iconClassName={props.iconClassName} className={props.className}>
      <FormField
        {...props}
        render={({ field: { onChange, onBlur, value, name } }: { field: ControllerRenderProps<any, any> }) => {
          const parseNumOrUndefined = (value: string) => {
            const numberValue = allowDecimal ? Number.parseFloat(value) : Number.parseInt(value);
            return Number.isNaN(numberValue) ? "" : numberValue;
          };
          const handleChange = (values: NumberFormatValues) => {
            const { value } = values;
            onChange(parseNumOrUndefined(value));
          };
          return (
            <NumberFormat
              autoFocus={props.autoFocus}
              thousandSeparator
              prefix="$"
              customInput={Input}
              className={cn(props.inputClassName, fieldInputClassName)}
              placeholder={props.placeholder}
              name={name}
              required={props.required}
              datatype="currency"
              value={value}
              decimalScale={allowDecimal ? 2 : 0}
              onBlur={onBlur}
              onValueChange={handleChange}
            />
          );
        }}
      />
    </Field>
  );
};
