import { useAtom } from "jotai";

import { dashboardCategoryAtom } from "@/atoms";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { DashboardCategory } from "../../generated/graphql";
import { useTheme } from "./theme-provider";

interface PepperSelectorProps {
  categoryCounts?: Record<DashboardCategory, number>;
}

const OppTrigger = ({
  label,
  count,
  value,
  tooltip,
}: {
  label: string;
  count?: number;
  value: string;
  tooltip: string;
}) => (
  <Tooltip>
    <TooltipTrigger>
      <TabsTrigger value={value} className="gap-2 p-2">
        {label} {count != null && <span className="text-xs">{count}</span>}
      </TabsTrigger>
    </TooltipTrigger>
    <TooltipContent>{tooltip}</TooltipContent>
  </Tooltip>
);

export const PepperSelector: React.FC<PepperSelectorProps> = ({ categoryCounts }) => {
  const [tab, setTab] = useAtom(dashboardCategoryAtom);
  const { theme } = useTheme();

  return (
    <Tabs value={tab} onValueChange={(e) => setTab(e as DashboardCategory)}>
      <TabsList className="bg-muted gap-1 p-1 shadow-inner">
        <OppTrigger
          value={DashboardCategory.Lusty}
          label={theme === "beef" ? "🥩" : "🌶️"}
          tooltip="Opportunities awaiting broker action"
          count={categoryCounts?.[DashboardCategory.Lusty]}
        />
        <OppTrigger
          value={DashboardCategory.Musty}
          label={theme === "beef" ? "🍖" : "⏳"}
          tooltip="Opportunities awaiting third-party action"
          count={categoryCounts?.[DashboardCategory.Musty]}
        />
        <OppTrigger
          value={DashboardCategory.Dusty}
          label={theme === "beef" ? "🥓" : "🧹"}
          tooltip="Opportunities that are missing data"
          count={categoryCounts?.[DashboardCategory.Dusty]}
        />
      </TabsList>
    </Tabs>
  );
};
