import { OpportunityListItem } from "@/components/opportunity-list-item";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { OpportunityGroupBy, OpportunityListItemFragment } from "src/generated/graphql";

export const DashboardLists = ({
  lists,
}: {
  lists: Array<{ label: string; opportunities: OpportunityListItemFragment[] | undefined }>;
}) => (
  <>
    {lists?.map(
      (list) =>
        list.opportunities &&
        list.opportunities.length > 0 && (
          <CollapsibleList key={list.label} defaultOpen>
            <CollapsibleListTrigger count={list.opportunities.length}>{list.label}</CollapsibleListTrigger>
            <CollapsibleListContent>
              {list.opportunities.map((opp) => (
                <OpportunityListItem
                  key={opp.id}
                  opportunity={opp}
                  params={{ groupBy: OpportunityGroupBy.Dashboard }}
                />
              ))}
            </CollapsibleListContent>
          </CollapsibleList>
        )
    )}
  </>
);
