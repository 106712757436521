import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";

export const ContinueButton: React.FC<{
  submitting: boolean;
  disabled?: boolean;
  className?: string;
  onClick?(): void | null | Promise<unknown>;
  form?: string;
}> = ({ submitting, disabled = false, onClick, form, className }) => (
  <Button type="submit" onClick={onClick} disabled={disabled || submitting} form={form} className={className}>
    Continue
    {submitting ? <Spinner /> : <Icon icon="arrow_forward" />}
  </Button>
);
