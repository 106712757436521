import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "@/opportunity/components/editor";
import { useEffect } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/forms/components/FormFields";
import {
  ClientPremiseBuildingFragment,
  useEditClientPremiseBuildingMutation,
  useExpeditedOpportunityQuery,
} from "../../../generated/graphql";
import { RequirementElementProps } from "../requirements/requirement-element-props";

const WriteBuildingSchema = z.object({
  wiringYear: z.string().min(4),
  roofingYear: z.string().min(4),
  heatingYear: z.string().min(4),
  plumbingYear: z.string().min(4),
  roofType: z.string().min(2),
  constructionType: z.string().min(2),
  yearBuilt: z.string().min(4),
  burglarAlarmType: z.string().min(2),
  sprinklerPercent: z.string().min(1),
});

export default function PropertyEditor({
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: RequirementElementProps) {
  const { opportunityId } = useParams();
  const { openForm } = useModal();
  const { toast } = useToast();

  const { data, loading, refetch } = useExpeditedOpportunityQuery({ variables: { id: opportunityId! } });

  useEffect(() => {
    const reqsMet =
      data?.opportunity?.locations.every((location) =>
        location.buildings.every((building) => {
          return (
            building.wiringYear &&
            building.roofingYear &&
            building.heatingYear &&
            building.plumbingYear &&
            building.roofType &&
            building.constructionType &&
            building.yearBuilt &&
            building.burglarAlarmType
          );
        })
      ) ?? false;

    if (reqsMet) {
      onSubmissionRequirementsMet();
    } else {
      onSubmissionRequirementsNotMet("Fill in all building information");
    }
  }, [data?.opportunity?.locations]);

  const [editBuildingTrigger] = useEditClientPremiseBuildingMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Building edited" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error editing building: duplicate location number" });
      } else {
        toast({ title: "Error editing building" });
      }
    },
  });

  if (loading || !data || !data.opportunity) {
    return <Loading />;
  }

  const opportunity = data.opportunity;

  const editBuilding = async (building: ClientPremiseBuildingFragment & { locationNumber: number }) => {
    const fd = await openForm(WriteBuildingSchema, <WriteBuildingForm />, {
      defaultValues: {
        wiringYear: building.wiringYear ?? undefined,
        roofingYear: building.roofingYear ?? undefined,
        heatingYear: building.heatingYear ?? undefined,
        plumbingYear: building.plumbingYear ?? undefined,
        roofType: building.roofType ?? undefined,
        constructionType: building.constructionType ?? undefined,
        yearBuilt: building.yearBuilt ?? undefined,
        burglarAlarmType: building.burglarAlarmType ?? undefined,
        sprinklerPercent: building.sprinklerPercent ?? undefined,
      },
    });
    if (!fd) {
      return;
    }

    await editBuildingTrigger({
      variables: {
        input: {
          locationId: building.locationId,
          buildingId: building.id,
          buildingNumber: building.buildingNumber,
          annualRevenue: building.annualRevenue?.toString() ?? "",
          ...fd,
        },
      },
    });
  };

  return (
    <Editor>
      <EditorHeader
        title="Edit Property Details"
        columns={[
          "Bldg. #",
          "Wiring Year",
          "Roofing Year",
          "Heating Year",
          "Plumbing Year",
          "Roof Type",
          "Construction Type",
          "Year Built",
          "Alarm Type",
          "Sprinkler Percent",
          "",
        ]}
      ></EditorHeader>
      {opportunity.locations
        .flatMap((location) =>
          location.buildings.map((building) => ({ ...building, locationNumber: location.locationNumber }))
        )
        .map((building) => (
          <EditorItem key={building.id}>
            <EditorRow>
              <div>
                <EditorCount>
                  L{building.locationNumber} - B{building.buildingNumber}
                </EditorCount>
              </div>
              <div>{building.wiringYear}</div>
              <div>{building.roofingYear}</div>
              <div>{building.heatingYear}</div>
              <div>{building.plumbingYear}</div>
              <div>{building.roofType}</div>
              <div>{building.constructionType}</div>
              <div>{building.yearBuilt}</div>
              <div>{building.burglarAlarmType}</div>
              <div>{building.sprinklerPercent}</div>
              <div className="flex gap items-center justify-end">
                <Button type="button" size="icon-sm" variant="ghost" onClick={() => editBuilding(building)}>
                  <Icon icon="edit" />
                </Button>
              </div>
            </EditorRow>
          </EditorItem>
        ))}
    </Editor>
  );
}

function WriteBuildingForm() {
  return (
    <div className="flex flex-col gap-4">
      <h3>Edit Building</h3>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col">
          <h4>Wiring Year</h4>
          <Input name="wiringYear" />
        </div>

        <div className="flex flex-col">
          <h4>Roofing Year</h4>
          <Input name="roofingYear" />
        </div>

        <div className="flex flex-col">
          <h4>Heating Year</h4>
          <Input name="heatingYear" />
        </div>

        <div className="flex flex-col">
          <h4>Plumbing Year</h4>
          <Input name="plumbingYear" />
        </div>

        <div className="flex flex-col">
          <h4>Roof Type</h4>
          <Input name="roofType" />
        </div>

        <div className="flex flex-col">
          <h4>Construction Type</h4>
          <Input name="constructionType" />
        </div>

        <div className="flex flex-col">
          <h4>Year Built</h4>
          <Input name="yearBuilt" />
        </div>

        <div className="flex flex-col">
          <h4>Burglar Alarm Type</h4>
          <Input name="burglarAlarmType" />
        </div>

        <div className="flex flex-col">
          <h4>Sprinkler Percent</h4>
          <Input name="sprinklerPercent" />
        </div>
      </div>

      <div className="flex justify-between">
        <Button type="reset" variant="destructive">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </div>
  );
}
