import { Paper } from "../components/paper";

export const Daycare = () => (
  <>
    <Paper>
      <section>
        <h1>Daycare Supplemental</h1>
        <h3>Coming Soon!</h3>
      </section>
    </Paper>
  </>
);
