import { Maps } from "@cp/toolkit";
import { useAtom } from "jotai";
import { mapValues } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { dashboardCategoryAtom } from "@/atoms";
import { PepperSelector } from "@/components/pepper-selector";
import { Bar } from "@/components/ui/bar";
import { Loading } from "@/components/ui/loading";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { SelectBroker } from "@/hub/components/select-broker";
import { DashboardCategory, OpportunityGroupBy, useGroupedOpportunityListQuery } from "src/generated/graphql";
import { DashboardLists } from "./dashboard-lists";

export const Dashboard = () => {
  const [tab] = useAtom(dashboardCategoryAtom);
  const [searchParams] = useSearchParams();
  const brokerId = searchParams.get("brokerId") || undefined;
  useDocumentTitle("Dashboard: QuoteWell");

  const { data, loading } = useGroupedOpportunityListQuery({
    variables: {
      args: {
        groupBy: OpportunityGroupBy.Dashboard,
        filter: {
          brokerId: brokerId === "null" ? null : brokerId,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const byParent = Maps.multiMap(data?.groupedOpportunities.groups ?? [], (g) => g.parent ?? "");
  const getList = (cat: DashboardCategory) => byParent.get(cat) ?? [];

  return (
    <>
      <Bar as="header" className="justify-start">
        <div className="flex-none w-48">
          <SelectBroker />
        </div>
        {!loading && (
          <PepperSelector
            categoryCounts={
              mapValues(
                Object.fromEntries(byParent.entries()),
                (groups) => groups.flatMap((g) => g.opportunities).length
              ) as Record<DashboardCategory, number>
            }
          />
        )}
      </Bar>
      {loading ? (
        <Loading className="p-4" />
      ) : (
        <Tabs value={tab}>
          <TabsContent value={DashboardCategory.Lusty}>
            <DashboardLists lists={getList(DashboardCategory.Lusty)} />
          </TabsContent>
          <TabsContent value={DashboardCategory.Musty}>
            <DashboardLists lists={getList(DashboardCategory.Musty)} />
          </TabsContent>
          <TabsContent value={DashboardCategory.Dusty}>
            <DashboardLists lists={getList(DashboardCategory.Dusty)} />
          </TabsContent>
        </Tabs>
      )}
    </>
  );
};
