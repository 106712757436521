import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { PropsWithChildren } from "react";
import { useCreateBindingAuthorityExclusionMutation } from "../../../generated/graphql";
import { Reform } from "../../forms/Reform";

const FormSchema = z.object({
  tiv: z.string().optional(),
  revenue: z.string().optional(),
  limit: z.string().optional(),
  state: z.string().optional(),
  operations: z.string().optional(),
  other: z.string().optional(),
});

export const EscapeHatchModalButton: React.FC<PropsWithChildren & { onComplete?: () => void }> = ({
  children = null,
  onComplete,
}) => {
  const { opportunityId } = useParams<"opportunityId">();
  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });
  const { toast } = useToast();

  const [update] = useCreateBindingAuthorityExclusionMutation();

  const errors = formMethods.formState.errors;

  return (
    <>
      <Dialog>
        {children ?? (
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>
                <Button variant="ghost" size="icon" title="Binding Authority Escape Hatch">
                  <Icon icon="eject" />
                </Button>
              </DialogTrigger>
            </TooltipTrigger>
            <TooltipContent>Binding Authority Escape Hatch</TooltipContent>
          </Tooltip>
        )}
        <DialogContent className="sm:max-w-[550px]">
          <DialogHeader>
            <DialogTitle>
              Binding Authority <a href="https://www.youtube.com/watch?v=cmCFT0qxzb8">Escape</a> Hatch
            </DialogTitle>
            <DialogDescription className="text-wrap">
              Please provide details on why this opportunity is not a fit for binding authority
            </DialogDescription>
            <DialogDescription className="text-wrap">
              All fields are optional but please be as descriptive as possible{" "}
            </DialogDescription>
          </DialogHeader>

          <Reform
            className="py-4 space-y-4"
            schema={FormSchema}
            methods={formMethods}
            id="ESCAPE_HATCH_FORM"
            onSubmit={(_, values) => {
              void update({
                variables: {
                  input: {
                    ...values,
                    opportunityId: opportunityId ?? "",
                  },
                },
                onError: (e: any) => {
                  toast({ title: e.message });
                },
                onCompleted: () => {
                  toast({ title: `Opportunity reassigned with details` });
                  if (onComplete) {
                    onComplete();
                  }
                },
                refetchQueries: ["OpportunityDetails"],
              });
            }}
            defaultValues={{}}
          >
            <div>
              <h5>TIV threshold</h5>
              <span className="text-destructive">{errors?.tiv?.message}</span>
              <Input {...formMethods.register("tiv")} />
            </div>
            <div>
              <h5>Revenue threshold</h5>
              <span className="text-destructive">{errors?.revenue?.message}</span>
              <Input {...formMethods.register("revenue")} />
            </div>
            <div>
              <h5>Limit reason</h5>
              <span className="text-destructive">{errors?.limit?.message}</span>
              <Input {...formMethods.register("limit")} />
            </div>
            <div>
              <h5>State</h5>
              <span className="text-destructive">{errors?.state?.message}</span>
              <Input {...formMethods.register("state")} />
            </div>
            <div>
              <h5>Operations</h5>
              <span className="text-destructive">{errors?.operations?.message}</span>
              <Input {...formMethods.register("operations")} />
            </div>
            <div>
              <h5>Other</h5>
              <span className="text-destructive">{errors?.other?.message}</span>
              <Input type="text" {...formMethods.register("other")} />
            </div>
            <DialogTrigger asChild>
              <Button type="submit" className="w-full">
                Save
              </Button>
            </DialogTrigger>
          </Reform>
        </DialogContent>
      </Dialog>
    </>
  );
};
