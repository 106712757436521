import { getAuthRequestHeaders } from "@cp/auth";
import { ActionFunctionArgs } from "react-router";

export const opportunityDetailsAction = async ({ request, params }: ActionFunctionArgs) => {
  const { opportunityId } = params;

  // window.location is allowed. Types are wrong.
  const apiUrl = new URL(`/api/opportunities/${opportunityId}/files`, window.location as any);
  // Proxy form request via fetch
  const response = await fetch(
    new Request(apiUrl, { body: await request.formData(), method: request.method, headers: getAuthRequestHeaders() })
  );
  if (!response.ok) {
    // TODO: Handle errors
    console.error(await response.text());
  }
  return null;
};
