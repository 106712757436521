import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { getAuthRequestHeaders } from "@cp/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { first } from "lodash";
import React, { FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { z } from "zod";
import {
  SubmissionDetailsQueryResult,
  useCreateQuotePdfMutation,
  useQuoteProposalValuesQuery,
  useSubmissionDetailsQuery,
  useUpdateSubmissionMutation,
} from "../../../generated/graphql";
import { uploadFile } from "../../../utils/file.utils";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Icon } from "../../components/ui/icon";
import { Input } from "../../components/ui/input";
import { Loading, Spinner } from "../../components/ui/loading";
import { Textarea } from "../../components/ui/textarea";
import { toast, useToast } from "../../components/ui/use-toast";
import { Reform } from "../../forms/Reform";
import { cn } from "../../utils";
import FileJobs from "./jobs";

export const GenerateQuote: React.FC = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { submissionId } = useParams<"submissionId">();

  const [submitting, setSubmitting] = useState(false);
  const { data: submissionData, loading: loadingSubmission } = useSubmissionDetailsQuery({
    variables: {
      id: submissionId!,
    },
    skip: !submissionId,
    pollInterval: 1000,
  });

  const [updateSubmission] = useUpdateSubmissionMutation();

  const updateFileForProcessing = async (key: string, value: string | null, subId: string) => {
    await updateSubmission({
      variables: {
        input: {
          id: subId,
          [key]: [value],
        },
      },
      onCompleted: () => {
        setSubmitting(false);
      },
    });
  };

  const submission = submissionData?.submission;

  if (loadingSubmission) {
    return <Loading label="Loading information" />;
  }

  if (!submission) {
    return null;
  }

  return (
    <>
      <Card className="bg-orange-400 p-6">
        <CardTitle>
          Warning! Proposal generation only accounts for non-admitted markets. Please refer to STS.{" "}
        </CardTitle>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            Generate a quote Proposal for {submission.appetiteProduct.carrierName} -{" "}
            {submission.appetiteProduct.carrierProductName}
          </CardTitle>
        </CardHeader>

        <CardContent>
          <div>
            Start by uploading a redacted quote
            <Button asChild variant="outline" size="sm">
              <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
                <input
                  type="file"
                  name="file"
                  className="hidden"
                  onChange={async (e) => {
                    setSubmitting(true);
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];

                      if (file.type !== "application/pdf") {
                        toast({ title: "We only accept PDF files" });
                        setSubmitting(false);
                        return;
                      }
                      if (!opportunityId || !submissionId) {
                        toast({ title: "Error" });
                        setSubmitting(false);
                        return;
                      }
                      const id = await uploadFile(file, opportunityId, "INTERNAL", "MANUAL_UPLOAD");
                      await updateFileForProcessing("quoteFilesForProcessingIds", id, submissionId);
                    }
                  }}
                />
                {submitting ? (
                  <Spinner />
                ) : (
                  <>
                    Upload Redacted Quote <Icon icon="upload" />
                  </>
                )}
              </label>
            </Button>
          </div>

          <div>
            <div className="flex flex-wrap justify-start gap-2">
              {submission.quoteFilesForProcessing.map((file) => {
                return (
                  <a
                    key={file.id}
                    href={`/api/files/${file.id}`}
                    download={file.filename}
                    media={file.mimeType}
                    className="flex gap-2 items-start leading-tight hover:text-primary"
                    onClick={async (e) => {
                      e.preventDefault();

                      const blob = await fetch(`/api/files/${file.id}`, {
                        headers: getAuthRequestHeaders(),
                      })
                        .then((r) => r.blob())
                        .then((b) => b.slice(0, b.size, file.mimeType));

                      const url = URL.createObjectURL(blob);
                      const anchor = document.createElement("a");
                      anchor.href = url;
                      anchor.download = file.filename;

                      document.body.append(anchor);
                      anchor.click();

                      URL.revokeObjectURL(url);
                      anchor.remove();
                    }}
                  >
                    <Card className="flex flex-col gap-2 content-center p-4">
                      <span>
                        <Badge variant="outline" className="text-xs">
                          Original
                        </Badge>
                      </span>
                      <div className="flex gap-2 content-center">
                        <Icon icon="attachment" className="scale-150" />
                        {file.filename}
                      </div>
                    </Card>
                  </a>
                );
              })}

              {submission.processedQuoteFiles.map((file) => {
                return (
                  <a
                    key={file.id}
                    href={`/api/files/${file.id}`}
                    download={file.filename}
                    media={file.mimeType}
                    className="flex gap-2 items-start leading-tight hover:text-primary"
                    onClick={async (e) => {
                      e.preventDefault();

                      const blob = await fetch(`/api/files/${file.id}`, {
                        headers: getAuthRequestHeaders(),
                      })
                        .then((r) => r.blob())
                        .then((b) => b.slice(0, b.size, file.mimeType));

                      const url = URL.createObjectURL(blob);
                      const anchor = document.createElement("a");
                      anchor.href = url;
                      anchor.download = file.filename;

                      document.body.append(anchor);
                      anchor.click();

                      URL.revokeObjectURL(url);
                      anchor.remove();
                    }}
                  >
                    <Card className="flex flex-col gap-2 content-center p-4">
                      <span>
                        <Badge className="text-xs">Processed</Badge>
                      </span>
                      <div className="flex gap-2 content-center">
                        <Icon icon="attachment" className="scale-150" />
                        {file.filename}
                      </div>
                    </Card>
                  </a>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
      <SubmissionQuoteDialog submission={submission} />
    </>
  );
};

type Submission = NonNullable<NonNullable<SubmissionDetailsQueryResult["data"]>["submission"]>;

const QuoteProposalValuesSchema = z.object({
  stampingFee: z.string().optional(),
  mep: z.string().optional(),
  carrierName: z.string().optional(),
  premium: z.string().optional(),
  subjectivities: z.string().optional(),
  insuredName: z.string().optional(),
  effectiveDate: z.string().optional(),
  homeState: z.string().optional(),
  agentFirstName: z.string().optional(),
  agencyName: z.string().optional(),
  commission: z.string().optional(),
  surplusLinesTaxes: z.string().optional(),
  brokerFee: z.string().optional(),
  inspectionFee: z.string().optional(),
  totalPremium: z.string().optional(),
  carrierFee: z.string().optional(),
});

const QUOTE_FORM_ID = "QUOTE_PROPOSAL";

const SubmissionQuoteDialog = ({ submission }: { submission: Submission }) => {
  const {
    data: quoteProposalData,
    error,
    loading: loadingValues,
  } = useQuoteProposalValuesQuery({
    variables: {
      input: {
        submissionId: submission.id,
      },
    },
    pollInterval: 2000,
    skip: submission.quoteFilesForProcessing.length === 0,
  });

  const quoteValues = quoteProposalData?.quoteProposalValues;

  const formMethods = useForm<z.infer<typeof QuoteProposalValuesSchema>>({
    resolver: zodResolver(QuoteProposalValuesSchema),
    defaultValues: {
      stampingFee: "",
      mep: "",
      carrierName: "",
      premium: "",
      subjectivities: "",
      insuredName: "",
      effectiveDate: "",
      homeState: "",
      agentFirstName: "",
      agencyName: "",
      commission: "",
      surplusLinesTaxes: "",
      brokerFee: "",
      inspectionFee: "",
      totalPremium: "",
      carrierFee: "",
    },
  });

  useEffect(() => {
    if (quoteValues) {
      const defaultValues = {
        stampingFee: quoteValues.stampingFee ?? "",
        mep: quoteValues?.mep ?? "25%",
        carrierName: quoteValues?.carrierName ?? "",
        commission: quoteValues?.commission ?? "",
        brokerFee: quoteValues?.brokerFee?.toString() ?? "",
        premium: quoteValues?.premium ?? "",
        insuredName: quoteValues?.insuredName ?? "",
        inspectionFee: quoteValues?.inspectionFee?.toString() ?? "",
        effectiveDate: quoteValues?.effectiveDate
          ? format(new Date(quoteValues?.effectiveDate ?? new Date()), "yyyy-MM-dd")
          : "",
        homeState: quoteValues?.homeState ?? "",
        subjectivities: (quoteValues.subjectivities ?? []).join("\n"),
      };
      formMethods.reset(defaultValues);
    }
  }, [quoteValues, formMethods]);

  const { toast } = useToast();

  const [createQuotePDF, { loading: creatingPDF, called }] = useCreateQuotePdfMutation();
  const generateQuote = async (_e: FormEvent<HTMLFormElement>, values: z.infer<typeof QuoteProposalValuesSchema>) => {
    const isValid = await formMethods.trigger();

    if (!isValid) {
      return toast({ title: "Invalid form values" });
    }

    return createQuotePDF({
      variables: {
        input: {
          submissionId: submission.id,
          insuredName: values.insuredName,
          carrierName: values.carrierName ?? submission.appetiteProduct.carrierName,
          inspectionFee: values.inspectionFee,
          commission: values.commission,
          brokerFee: values.brokerFee,
          mep: values.mep ?? "25%",
          premium: values.premium,
          carrierFee: values.carrierFee,
          subjectivities:
            values.subjectivities
              ?.replaceAll("-", "")
              .split("\n")
              .map((s: string) => s.trim()) ?? [],
          quoteFileId: submission.quoteFilesForProcessing[0].id,
          quoteEffectiveDate: values.effectiveDate ?? undefined,
        },
      },

      onError(error) {
        toast({ title: error.message });
      },

      onCompleted() {
        toast({ title: "Quote proposal generated" });
      },
    });
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Sorry! An error occurred while fetching the proposal data.</CardTitle>
          <CardDescription>
            Message: {error.message ?? "An error occurred while fetching the proposal data."}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  if (submission.quoteFilesForProcessing.length === 0) {
    return <EnhancedLoading label="Waiting for file upload" />;
  }

  if (loadingValues || submission.quoteFilesForProcessing.length === 0 || quoteValues === null) {
    return <EnhancedLoading label="Loading quote data" />;
  }

  if (creatingPDF && called) {
    return <EnhancedLoading label="Creating Quote Proposal" />;
  }

  return (
    <>
      <Card>
        <div className="mt-2">
          <CardHeader>Provide Details About the Quote:</CardHeader>
          <CardDescription className="ml-4 mb-5">
            {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
          </CardDescription>
        </div>
        <CardContent>
          <Reform schema={QuoteProposalValuesSchema} id={QUOTE_FORM_ID} onSubmit={generateQuote} methods={formMethods}>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <h5>Mep</h5>
                <span className="text-destructive">{formMethods.formState.errors?.mep?.message}</span>
                <Input {...formMethods.register("mep")} />
              </div>
              <div>
                <h5>Inspection Fee (total)</h5>
                <span className="text-destructive">{formMethods.formState.errors?.inspectionFee?.message}</span>
                <Input {...formMethods.register("inspectionFee")} />
              </div>
              <div>
                <h5>Carrier Fee (if applicable)</h5>
                <span className="text-destructive">{formMethods.formState.errors?.carrierFee?.message}</span>
                <Input {...formMethods.register("carrierFee")} />
              </div>
              <div>
                <h5>Broker Fee (total)</h5>
                <span className="text-destructive">{formMethods.formState.errors?.brokerFee?.message}</span>
                <Input {...formMethods.register("brokerFee")} />
              </div>
              <div>
                <h5> Commission % </h5>
                <span className="text-destructive">{formMethods.formState.errors?.commission?.message}</span>
                <Input {...formMethods.register("commission")} />
              </div>
              <div>
                <h5>Carrier Name</h5>
                <span className="text-destructive">{formMethods.formState.errors?.carrierName?.message}</span>
                <Input {...formMethods.register("carrierName")} />
              </div>
              <div>
                <h5>Premium</h5>
                <span className="text-destructive">{formMethods.formState.errors?.premium?.message}</span>
                <Input {...formMethods.register("premium")} />
              </div>
              <div>
                <h5>Insured Name</h5>
                <span className="text-destructive">{formMethods.formState.errors?.insuredName?.message}</span>
                <Input {...formMethods.register("insuredName")} />
              </div>
              <div>
                <h5>Effective Date</h5>
                <span className="text-destructive">{formMethods.formState.errors?.effectiveDate?.message}</span>
                <Input type="date" {...formMethods.register("effectiveDate")} />
              </div>
              <div>
                <h5>Home State</h5>
                <span className="text-destructive">{formMethods.formState.errors?.homeState?.message}</span>
                <Input {...formMethods.register("homeState")} />
              </div>
              <div className="col-span-2">
                <h5>Subjectivities</h5>
                <span className="text-destructive">{formMethods.formState.errors?.subjectivities?.message}</span>
                <Textarea
                  {...formMethods.register("subjectivities")}
                  rows={8}
                  name="subjectivities"
                  placeholder="List Subjectivities"
                />
              </div>
            </div>
            <Button type="submit" form={QUOTE_FORM_ID} className="w-full my-4">
              Save
            </Button>
          </Reform>
        </CardContent>
      </Card>
      {first(submission?.quoteFilesForProcessing)?.id && (
        <FileJobs fileId={first(submission?.quoteFilesForProcessing)?.id} />
      )}
    </>
  );
};

const EnhancedLoading = ({ label }: { label: string }) => (
  <Card className="w-full max-w-md mx-auto my-8">
    <CardContent className="flex flex-col items-center justify-center p-8">
      <Spinner className="w-8 h-8 mb-4" />
      <p className="text-lg font-medium text-center text-gray-700">{label}</p>
    </CardContent>
  </Card>
);
