import { Card, CardHeader, CardTitle } from "./ui/card";

export function OrEmailDisplay({ className }: { className: string }) {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>Email Your Submission</CardTitle>

        <p>
          You can always send us submissions to{" "}
          <a href="mailto:submissions@quotewell.com" className="text-blue-600 hover:underline">
            submissions@quotewell.com
          </a>
        </p>
      </CardHeader>
    </Card>
  );
}

export function OrDivider() {
  return (
    <div className="relative flex py-5 items-center">
      <div className="flex-grow border-t-4 border-black"></div>
      <span className="flex-shrink mx-4 text-gray-400">Or</span>
      <div className="flex-grow border-t border-black"></div>
    </div>
  );
}
