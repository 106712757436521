import { useForm } from "react-hook-form";
import { DatePicker } from "../../../components/ui/date-picker";
import { Label } from "../../../components/ui/label";

export default function DateElement({ label, clientDataKey }: { label: string; clientDataKey: string }) {
  const formMethods = useForm();

  return (
    <div className="col-span-1 relative space-y-1">
      <Label className="text-sm">{label}</Label>
      <DatePicker
        inputClassName="border relative"
        className="absolute bottom-0 bg-transparent hover:bg-transparent right z-1"
        selected={formMethods.watch(clientDataKey)}
        onSelect={(v) => v && formMethods.setValue(clientDataKey, v.toISOString())}
        {...formMethods.register(clientDataKey, { required: true })}
      />
    </div>
  );
}
