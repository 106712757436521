import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  CarrierContactFragment,
  CarrierContactType,
  useRemoveCarrierContactFromAppetiteProductMutation,
} from "../../../../generated/graphql";
import { CarrierContactForm } from "./CarrierContactForm";

interface Props {
  contact: CarrierContactFragment;
  type: CarrierContactType;
  appetiteProductId: string;
}

export const CarrierContactCard: React.FC<Props> = ({ contact, type, appetiteProductId }) => {
  const { id, firstName, lastName, email, phone, title } = contact;
  const { toast } = useToast();

  const [editMode, setEditMode] = useState(false);

  const [detachCarrierContact] = useRemoveCarrierContactFromAppetiteProductMutation();

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleDelete = () => {
    void detachCarrierContact({
      variables: {
        input: {
          id,
          appetiteProductId,
          type,
        },
      },
      onCompleted: () => {
        toast({ title: "Success" });
      },
      refetchQueries: ["OpportunityDetails"],
    });
  };

  return (
    <div className="border rounded border-slate-200">
      {editMode ? (
        <CarrierContactForm
          carrierContact={contact}
          type={type}
          appetiteProductId={appetiteProductId}
          onCompleted={() => setEditMode(false)}
        />
      ) : (
        <div className="flex m-2">
          <div className="flex-1">
            <p>{`Name:  ${firstName} ${lastName}`}</p>
            <p>{`Email: ${email}`}</p>
            <p>{`Title: ${title ?? "None Listed"}`}</p>
            <p>{`Phone: ${phone ?? "None Listed"}`}</p>
          </div>
          <div className="flex flex-col gap-3">
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="icon" onClick={toggleEdit}>
                  <Icon icon="edit" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit Contact</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="icon" onClick={handleDelete}>
                  <Icon icon="delete" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Remove Contact</TooltipContent>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
