import { zodResolver } from "@hookform/resolvers/zod";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { CurrencyFormField } from "@/components/ui/currency-form-field";
import { Field } from "@/components/ui/field-inputs";
import { Form, FormField } from "@/components/ui/form";
import { SearchInput } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import {
  useAppetiteFilterOptionsQuery,
  useOpportunityDetailsQuery,
  useSelectOpportunityCoveragesMutation,
} from "../../generated/graphql";
import { ContinueButton } from "../appetite/components/continue-button";

const PROPERTY_FIELD = "Property";

const SelectCoverageFormSchema = z.object({
  selectedLinesOfBusiness: z.string().array().min(1, { message: "You must select at least one coverage" }),
  propertyTIV: z.number().optional(),
});

// TODO: Bring back if we want this to be required
// .superRefine((values, ctx) => {
//   if (values.selectedLinesOfBusiness.includes(PROPERTY_FIELD) && !values.propertyTIV) {
//     ctx.addIssue({
//       message: "Property TIV Required to Proceed",
//       code: z.ZodIssueCode.custom,
//       path: ["propertyTIV"],
//     });
//   }
// });

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const Coverage = ({ skipNavigation = false, navigateTo }: { skipNavigation?: boolean; navigateTo?: string }) => {
  const { opportunityId } = useParams();
  const { data, loading } = useAppetiteFilterOptionsQuery({});
  const { data: opportunityData } = useOpportunityDetailsQuery({
    variables: {
      id: opportunityId ?? "",
    },
  });
  const [selectCoveragesMutation, { loading: submitting }] = useSelectOpportunityCoveragesMutation();
  const navigate = useNavigate();
  const [coverageTerm, setCoverageTerm] = useState("");
  const { toast } = useToast();

  const { opportunity } = opportunityData ?? {};

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(coverageTerm.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  const selectCoverageFormMethods = useForm<z.infer<typeof SelectCoverageFormSchema>>({
    resolver: zodResolver(SelectCoverageFormSchema),
    defaultValues: {
      selectedLinesOfBusiness: opportunity?.selectedLinesOfBusiness ?? [],
    },
  });

  const {
    control,
    formState: { isValid },
  } = selectCoverageFormMethods;

  const { selectedLinesOfBusiness, propertyTIV } = selectCoverageFormMethods.watch();

  const handleSubmit = async () => {
    if (!opportunityId) {
      return null;
    }

    const validate = await selectCoverageFormMethods.trigger();

    if (!validate) {
      return;
    }

    void selectCoveragesMutation({
      variables: {
        input: {
          id: opportunityId,
          selectedLinesOfBusiness,
          propertyTIV,
        },
      },
      onCompleted: () => {
        toast({ title: "Coverage Updated" });
        opportunity;
        if (!skipNavigation) {
          navigate(navigateTo ?? `/opportunity/${opportunityId}/markets`);
        }
      },
      refetchQueries: ["OpportunityKitchenSink"],
    });
    return null;
  };

  return (
    <Form {...selectCoverageFormMethods}>
      <Card>
        <CardHeader>
          <CardTitle className="flex gap-4 items-center justify-between">
            Select Coverage
            <SearchInput
              name="search"
              placeholder="Search Coverage"
              value={coverageTerm}
              onChange={(event) => setCoverageTerm(event.currentTarget.value)}
            />
          </CardTitle>
        </CardHeader>
      </Card>

      {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error && (
        <p className="text-destructive text-sm">
          {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error?.message}
        </p>
      )}

      <Card className="bg-muted overflow-hidden space-y-px">
        <CardHeader className="bg-background">
          <CardTitle>Core Lines</CardTitle>
        </CardHeader>
        {loading ? (
          <Loading className="bg-background p-4" />
        ) : coreLines[0] ? (
          coreLines.map(({ name }) => (
            <>
              <FieldCheckbox key={name} label={name} name="selectedLinesOfBusiness" />
              {name === PROPERTY_FIELD && selectedLinesOfBusiness.includes(PROPERTY_FIELD) && (
                <CurrencyFormField
                  className="pl-8 placeholder-red-700"
                  icon="paid"
                  iconClassName={isValid ? "" : "text-destructive hover:text-destructive"}
                  control={control}
                  name="propertyTIV"
                  required={true}
                  placeholder="Total Insurable Value"
                  inputClassName="!placeholder-red-700"
                />
              )}
            </>
          ))
        ) : (
          <div className="bg-background p-4">
            No coverage found matching <strong>{coverageTerm}</strong>.
          </div>
        )}
      </Card>
      <Card className="bg-muted overflow-hidden space-y-px">
        <CardHeader className="bg-background">
          <CardTitle>Other</CardTitle>
        </CardHeader>
        {loading ? (
          <Loading className="bg-background p-4" />
        ) : otherLines[0] ? (
          otherLines.map(({ name }) => <FieldCheckbox key={name} label={name} name="selectedLinesOfBusiness" />)
        ) : (
          <div className="bg-background p-4">
            No coverage found matching <strong>{coverageTerm}</strong>.
          </div>
        )}
      </Card>

      <footer className="-bottom bg-gradient-to-b from-transparent to-[hsl(var(--muted))] flex items-center !-mt-4 pb-4 pt-8 pointer-events-none sticky">
        <ContinueButton
          onClick={handleSubmit}
          disabled={!isValid}
          submitting={submitting}
          className="ml-auto pointer-events-auto shadow-xl"
        />
      </footer>
    </Form>
  );
};

const FieldCheckbox = ({ label, name }: { label: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(label);
      return (
        <Field className={cn("gap-6", checked ? "text-foreground" : "text-muted-foreground")}>
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-foreground" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, label])
                : field.onChange(field.value?.filter((value: string) => value !== label));
            }}
          />
          {label}
        </Field>
      );
    }}
  />
);
