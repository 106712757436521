import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useAllDataQuery } from "../../generated/graphql";

const Ethan = () => {
  const { data } = useAllDataQuery({
    variables: {
      args: {},
      pagination: {},
    },
  });

  const renderValue = (value: any): string => {
    if (value === null) {
      return "null";
    }
    if (value === undefined) {
      return "undefined";
    }
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return String(value);
  };

  const renderObject = (obj: any, depth: number = 0) => {
    return (
      <div style={{ marginLeft: `${depth * 20}px` }}>
        {Object.entries(obj).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong>{" "}
            {typeof value === "object" && value !== null ? (
              renderObject(value, depth + 1)
            ) : (
              <span>{renderValue(value)}</span>
            )}
          </div>
        ))}
      </div>
    );
  };
  return (
    <Card className="w-full max-w-4xl mx-auto overflow-auto">
      <CardHeader>
        <CardTitle>All Data Display</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <strong>Number of Records:</strong> {data?.paginatedOpportunities.numRecords}
          </div>
          <div>
            <strong>Number of Pages:</strong> {data?.paginatedOpportunities.numPages}
          </div>
          <div>
            <strong>Opportunities:</strong>
            {data?.paginatedOpportunities.opportunities.map((opportunity, index) => (
              <Card key={opportunity.id} className="mt-4">
                <CardHeader>
                  <CardTitle>Opportunity {index + 1}</CardTitle>
                </CardHeader>
                <CardContent>{renderObject(opportunity)}</CardContent>
              </Card>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Ethan;
