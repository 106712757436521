import { useAtom } from "jotai";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { notificationFilterAtom } from "@/atoms";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { cn } from "@/utils";
import {
  OnNotificationAddedDocument,
  OnNotificationAddedSubscription,
  OnNotificationAddedSubscriptionVariables,
  OpportunityEventType,
  useMarkNotificationsReadMutation,
  useMyAccountQuery,
  useNotificationsQuery,
} from "../../generated/graphql";
import { InboxListItem } from "./inbox-list-item";

const filters = [
  {
    label: "Action Requested",
    events: [OpportunityEventType.ActionRequested],
    disabled: true,
  },
  {
    label: "Automations",
    events: [OpportunityEventType.AutomatedFollowUp],
    disabled: false,
  },
  {
    label: "Comments, Emails & Uploads",
    events: [OpportunityEventType.FileUpload, OpportunityEventType.UserComment, OpportunityEventType.GmailMessage],
    disabled: false,
  },
  {
    label: "Assignments",
    events: [OpportunityEventType.AssignmentChange, OpportunityEventType.TriageAssignment],
    disabled: false,
  },
  {
    label: "Status Changes",
    events: [OpportunityEventType.StatusChange, OpportunityEventType.SubmissionStatusChange],
    disabled: false,
  },
];

export const InboxList: React.FC = () => {
  const [selectedFilters, setSelectedFilters] = useAtom(notificationFilterAtom);
  const [showUnreads, setShowUnreads] = useState(true);
  const [mutate] = useMarkNotificationsReadMutation();

  const { data } = useMyAccountQuery({
    fetchPolicy: "cache-only",
  });

  const id = data?.myAccount?.id;

  const { data: { notifications = [] } = {}, subscribeToMore } = useNotificationsQuery({
    variables: {
      input: {
        showRead: !showUnreads,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    return subscribeToMore<OnNotificationAddedSubscription, OnNotificationAddedSubscriptionVariables>({
      document: OnNotificationAddedDocument,
      variables: { id: id ?? "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newNotification = subscriptionData.data.notificationAdded;

        return {
          notifications: [newNotification, ...prev.notifications],
        };
      },
    });
  }, []);

  const selectedFilterSet = new Set(JSON.parse(selectedFilters));
  const filteredNotifications = notifications.filter((n) => selectedFilterSet.has(n.event.type));
  const groups = groupBy(filteredNotifications, (n) => n.event.opportunity.insured.name);

  const handleFilter = (checked: boolean, eventTypes: OpportunityEventType[]) => {
    void setSelectedFilters((selectedFilters) => {
      const priorFilters = new Set(JSON.parse(selectedFilters));

      eventTypes.forEach((v) => {
        checked ? priorFilters.add(v) : priorFilters.delete(v);
      });

      return JSON.stringify([...priorFilters]);
    });
  };

  const readAll = (ids: string[]) => {
    void mutate({
      variables: {
        input: {
          ids,
          read: false,
        },
      },
      refetchQueries: ["Notifications"],
    });
  };

  const selectAllFilters = () => {
    void setSelectedFilters(JSON.stringify(filters.flatMap((f) => f.events)));
  };

  return (
    <PaneGroup>
      <Bar as="header">
        Inbox
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div
              className={cn(
                "bg-accent cursor-pointer flex gap-2 h-8 items-center justify-center min-w-8 px-2.5 rounded-full text-sm",
                notifications.length > 0 && "text-primary"
              )}
            >
              {notifications.length > filteredNotifications.length ? (
                <span className="font-semibold text-2xs">
                  {filteredNotifications.length} / {notifications.length}
                </span>
              ) : (
                notifications.length > 0 && <span className="font-semibold text-2xs">{notifications.length}</span>
              )}
              <Icon icon="filter_list" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filters.map((f) => {
              const checked = f.events.every((v) => selectedFilterSet.has(v));
              return (
                <DropdownMenuCheckboxItem
                  key={f.label}
                  checked={checked}
                  disabled={f.disabled}
                  onCheckedChange={(checked) => handleFilter(checked, f.events)}
                  className="text-xs gap-4"
                >
                  {f.label}
                  <Badge variant={checked ? "default" : "secondary"} className="ml-auto text-2xs">
                    {f.events.reduce((acc, e) => acc + notifications.filter((n) => n.event.type === e).length, 0)}
                  </Badge>
                </DropdownMenuCheckboxItem>
              );
            })}
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem checked={showUnreads} onCheckedChange={setShowUnreads} className="gap-4">
              Unread Only
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Bar>
      <Pane layout="list">
        {Object.keys(groups).map((key) => (
          <NavLink
            key={key}
            to={`/inbox/${groups[key][0].event.opportunityId}`}
            onClick={() => {
              const notActions = groups[key].filter((n) => n.event.type !== OpportunityEventType.ActionRequested);
              readAll(notActions.map((n) => n.id));
            }}
            className={({ isActive, isPending }) =>
              cn(
                "group bg-accent block border-b p-4 text-muted-foreground",
                (isPending || isActive) && "text-foreground",
                isActive && "bg-background"
              )
            }
          >
            <ContextMenu>
              <ContextMenuTrigger asChild>
                <div className="font-semibold group-aria-[current=page]:text-foreground text-xs truncate">{key}</div>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuLabel className="text-xs">{key}</ContextMenuLabel>
                <ContextMenuSeparator />
                <ContextMenuItem onClick={() => readAll(groups[key].map((n) => n.id))}>
                  Mark all notifcations as Read
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
            <div className="pt-3 space-y-1">
              {groups[key].map((n) => (
                <InboxListItem key={n.id} notification={n} />
              ))}
            </div>
          </NavLink>
        ))}
        <div className="bg-accent py-4 space-y-4 text-center text-muted-foreground text-xs">
          {showUnreads && notifications.length === 0 && <div>No unread notifications.</div>}
          {notifications.length - filteredNotifications.length > 0 && (
            <>
              <div className="text-gradient-fuchs">
                {notifications.length - filteredNotifications.length} hidden notification(s).
              </div>
              <Button variant="outline" size="sm" onClick={selectAllFilters}>
                Reset Filters
              </Button>
            </>
          )}
        </div>
      </Pane>
    </PaneGroup>
  );
};
