import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import { NotificationFragment } from "../../generated/graphql";

interface Props {
  notification: NotificationFragment;
}

export const NotificationRenderer: React.FC<Props> = ({ notification }) => {
  // only certain events product notifications
  // as of 2/2/2024, the following events are the only ones that produce notifications
  switch (notification.event.__typename) {
    case "UserComment":
      return <UserCommentNotification notification={notification} />;

    case "StatusChangeEvent":
      return <StatusChangeNotification notification={notification} />;

    case "SubmissionStatusChangeEvent":
      return <SubmissionStatusChangeNotification notification={notification} />;

    case "AssignmentChangeEvent":
      return <AssignmentChangeNotification notification={notification} />;

    case "TriageAssignmentEvent":
      return <TriageAssignmentNotification notification={notification} />;

    case "FileUploadEvent":
      return <FileUploadNotification notification={notification} />;

    case "GmailMessageEvent":
      return <GmailMessageNotification notification={notification} />;

    case "AutomatedFollowUpEvent":
      return <AutomatedFollowUpNotification notification={notification} />;

    case "ActionRequestedEvent":
      return <ActionRequestedNotification notification={notification} />;

    default:
      return null;
  }
};

const UserCommentNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const StatusChangeNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const SubmissionStatusChangeNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const AssignmentChangeNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const AutomatedFollowUpNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const ActionRequestedNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const TriageAssignmentNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const FileUploadNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const GmailMessageNotification: React.FC<Props> = ({ notification }) => {
  return <BaseNotification notification={notification} />;
};

const BaseNotification: React.FC<Props> = ({ notification }) => {
  const { event } = notification;
  return (
    <div className="flex-auto truncate">
      <ReactMarkdown remarkPlugins={[remarkBreaks, remarkGfm]} className="[&>p]:truncate">
        {event.text}
      </ReactMarkdown>
    </div>
  );
};
