import { format } from "date-fns";
import { join } from "lodash";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Panel, PanelTitle } from "@/components/ui/panel";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/utils";
import { LeadFragment } from "../../../generated/graphql";

interface Props {
  leads: LeadFragment[];
}

export const LeadsDetails: React.FC<Props> = ({ leads }) => {
  if (leads.length === 0) {
    return null;
  }

  return (
    <Panel>
      <PanelTitle>Expiring Policies</PanelTitle>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className={cellClassname}>Carrier / Lines</TableHead>
            <TableHead className={cellClassname}>Premium</TableHead>
            <TableHead className={cn(cellClassname, "text-right")}>Renewal Date</TableHead>
            <TableHead className={cn(cellClassname, "text-right")}></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {leads.map((lead) => (
            <TableRow key={lead.id}>
              <TableCell className={cellClassname}>
                <div className="flex flex-col">
                  <strong>{displayCarriers(lead.carriers)}</strong>
                  <span>{displayLines(lead.rawLines)}</span>
                </div>
              </TableCell>
              <TableCell className={cellClassname}>
                {lead.premium
                  ? Number.parseFloat(lead.premium).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"}
              </TableCell>
              <TableCell className={cn(cellClassname, "text-right")}>
                {format(new Date(lead.expirationDate), "M/d/yy")}
              </TableCell>
              <TableCell className={cn(cellClassname, "text-right")}>
                {lead.notes && (
                  <HasInternalRole>
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button size="icon" variant="ghost">
                          <Icon icon="description" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Notes</DialogTitle>
                          <DialogDescription className="whitespace-pre-wrap">{lead.notes}</DialogDescription>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  </HasInternalRole>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Panel>
  );
};

const displayLines = (lines: string[]) => {
  return lines.length > 0 ? join(lines, ", ") : "-";
};

const displayCarriers = (carriers: string[]) => {
  return carriers.length > 0 ? join(carriers, ", ") : "-";
};

const cellClassname = "p-0 text-xs";
