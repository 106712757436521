import { useDocumentTitle } from "usehooks-ts";

import { Spinner } from "@/components/ui/loading";
import { Outlet } from "react-router";
import { useOpportunity } from "../loaders";

export const RequirementsRoot: React.FC = () => {
  const { opportunity, loading } = useOpportunity();

  useDocumentTitle(`${opportunity ? opportunity.insured.name : "Opportunity Requirements"}: QuoteWell`);

  return loading ? (
    <div className="flex flex-auto justify-center self-center">
      <Spinner />
    </div>
  ) : (
    <Outlet />
  );
};
