import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";

export const SwitchParam = ({ name, label }: { name: string; label?: string }) => {
  const [searchParams, upsertSearchParams, setSearchParams] = useUpsertSearchParams();

  const handleChange = (value: boolean) => {
    if (value === false) {
      const { [name]: removed, page, ...rest } = Object.fromEntries(searchParams);
      return setSearchParams({ ...rest });
    }
    upsertSearchParams({ [name]: String(value) });
  };

  return (
    <div className="flex flex-auto items-center gap-3 text-sm">
      <Switch checked={searchParams.get(name) === "true"} onCheckedChange={(value) => handleChange(value)} />
      {label && <Label htmlFor={name}>{label}</Label>}
    </div>
  );
};
