import { UUID } from "@cp/toolkit";
import { useParams } from "react-router-dom";

import { Bar } from "@/components/ui/bar";
import { Loading } from "@/components/ui/loading";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { FileLink } from "@/opportunity/info/files";
import { useFilesQuery } from "src/generated/graphql";

export const InsuredFiles: React.FC = () => {
  const { insuredId } = useParams();
  const { data, loading } = useFilesQuery({
    variables: {
      input: {
        insuredId: insuredId! as UUID,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !insuredId,
  });

  return (
    <PaneGroup>
      <Bar as="header">Files</Bar>
      <Pane>
        {loading ? (
          <Loading />
        ) : (
          <ul className="space-y-2">
            {data?.files.map((file) => (
              <li key={file.id}>
                <FileLink file={file} className="text-base" />
              </li>
            ))}
          </ul>
        )}
      </Pane>
    </PaneGroup>
  );
};
