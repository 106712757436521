import { KnownClientData } from "@qw/qw-common";
import { TdInput, TdNumber } from "../components/td-fields";

export const GeneralInformation = () => (
  <table>
    <thead>
      <tr>
        <td colSpan={4} className="big">
          General Information
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <TdInput colSpan={3} label="First Named Insured" name={KnownClientData.FirstNamedInsured} />
        <TdInput label="Business Entity Type" name={KnownClientData.BusinessEntityType} />
      </tr>
      <tr>
        <TdInput colSpan={3} label="DBA" name={KnownClientData.DBA} />
        <TdNumber label="Year Established" name={KnownClientData.YearEstablished} />
      </tr>
      <tr>
        <TdInput colSpan={4} label="States Operating In" name={KnownClientData.StatesOfOperation} />
      </tr>
    </tbody>
  </table>
);
