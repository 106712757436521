import { cva, type VariantProps } from "class-variance-authority";
import { FC, HTMLAttributes } from "react";

import { cn } from "@/utils";
import { NavLink, NavLinkProps, useSearchParams } from "react-router-dom";

const barVariants = cva("flex flex-none gap-2 items-center justify-between overflow-x-auto px-4 shadow-bar text-sm", {
  variants: {
    as: {
      footer: "bg-accent",
      header: "bg-background",
    },
    position: {
      relative: "",
      sticky: "sticky z-20",
    },
    size: {
      default: "h-16",
    },
  },
  compoundVariants: [
    {
      as: "header",
      position: "sticky",
      className: "top-0",
    },
    {
      as: "footer",
      position: "sticky",
      className: "bottom-0",
    },
  ],
  defaultVariants: {
    position: "relative",
    size: "default",
  },
});

interface BarProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof barVariants> {}

export const Bar: FC<BarProps> = ({ as, position, size, className, ...props }) => {
  const Component = as ?? "div";
  return <Component className={cn(barVariants({ as, position, size }), className)} {...props} />;
};

export const BarLink = ({
  to,
  children,
  className,
  disabled = false,
  ...props
}: NavLinkProps & { disabled?: boolean }) => {
  const [searchParams] = useSearchParams();
  const params = searchParams ? `?${searchParams.toString()}` : "";

  return (
    <NavLink
      className={({ isActive }: { isActive: boolean }) =>
        cn(
          "flex-none inline-flex gap-2 h-8 items-center px-2.5 rounded shadow-contrast text-xs transition-colors",
          isActive ? "bg-foreground filled text-background" : "bg-muted",
          disabled && "cursor-not-allowed opacity-40 pointer-events-none",
          className
        )
      }
      to={`${to}${params}`}
      role="link"
      aria-disabled={disabled}
      end
      {...props}
    >
      {children}
    </NavLink>
  );
};

export const BarItem = ({
  active,
  onClick,
  children,
}: {
  active?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}) => (
  <div
    className={cn("inline-flex gap-2 items-center px-3 py-1.5 rounded shadow-contrast text-xs", {
      "bg-foreground text-background": active,
      "bg-muted": !active,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

export const BarBadge = ({ children }: { children: React.ReactNode }) => (
  <span className="bg-background/25 flex font-semibold items-center h-4 justify-center min-w-4 px-1 rounded-full self-center shadow-contrast text-2xs">
    {children}
  </span>
);
