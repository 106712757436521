import { Select } from "@/components/ui/select";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";

export const SearchParamSelect = ({
  name,
  defaultValue,
  children,
}: {
  name: string;
  defaultValue?: string;
  children: React.ReactNode;
}) => {
  const [searchParams, upsertSearchParams, setSearchParams] = useUpsertSearchParams();

  const handleChange = (value: string) => {
    if (value === "") {
      const { [name]: removed, page, ...rest } = Object.fromEntries(searchParams);
      return setSearchParams({ ...rest });
    }
    upsertSearchParams({ [name]: value });
  };

  return (
    <Select
      name={name}
      value={searchParams.get(name) ?? defaultValue ?? ""}
      onValueChange={(value) => handleChange(value)}
    >
      {children}
    </Select>
  );
};
