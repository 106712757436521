import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { LocationEditor } from "@/opportunity/components/location-editor";
import { useExpeditedOpportunityTransitionMutation } from "../../../../generated/graphql";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function ReviewLocations({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const [transition, { loading: transitionLoading }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        opportunityId: opportunity.id,
        action: "Reviewed",
      },
    },
  });

  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle>Review Locations/Buildings</CardTitle>
        <CardDescription>Review locations and buildings from ACORD 125/823 or add more</CardDescription>
      </CardHeader>
      <LocationEditor
        opportunityId={opportunity.id}
        clientDataKey=""
        onSubmissionRequirementsMet={() => {}}
        onSubmissionRequirementsNotMet={() => {}}
      />
      <CardFooter className="border-t p-4">
        <Button
          size="sm"
          className="flex items-center justify-center gap-2 ml-auto"
          disabled={transitionLoading}
          onClick={() => transition()}
        >
          <span>Continue</span>
          <Icon icon="arrow_forward" />
        </Button>
      </CardFooter>
    </Card>
  );
}

export function ReviewLocationsDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} confirmed the markets for this opportunity.
        </CardTitle>
      </CardHeader>
      <CardContent></CardContent>
    </Card>
  );
}
