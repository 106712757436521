import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Autocomplete } from "@/components/ui/autocomplete";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Reform } from "@/forms/Reform";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  useExpeditedOpportunityTransitionMutation,
  useSearchBusinessClassesLazyQuery,
  useSearchBusinessClassesQuery,
  useUpdateInsuredMutation,
} from "../../../../generated/graphql";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

const ClientFormSchema = z.object({
  naicsCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 6, { message: "NAICS code must be 5 digits" })
    .nullable()
    .optional(),
  cglCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 5, { message: "CGL code must be 5 digits" })
    .nullable()
    .optional(),
});
type ClientForm = z.infer<typeof ClientFormSchema>;

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;

export function VerifyDetails({ opportunity, refetch }: ExpeditedOpportunityStatusViewProps) {
  const formMethods = useForm<z.infer<typeof ClientFormSchema>>({
    resolver: zodResolver(ClientFormSchema),
    defaultValues: {
      naicsCode: opportunity.insured.naicsCodes[0] ?? undefined,
      cglCode: opportunity.insured.isoCglCodes[0] ?? undefined,
    },
  });

  const [updateInsured] = useUpdateInsuredMutation();
  const [transition, { loading }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: { opportunityId: opportunity.id },
    },
    onCompleted: () => refetch(),
  });
  const [load] = useSearchBusinessClassesLazyQuery();
  const { data } = useSearchBusinessClassesQuery({
    variables: { input: { classSystems: [BusinessClassSystem.Naics, BusinessClassSystem.IsoGl], term: " " } },
  });

  const naics = data?.searchBusinessClasses.filter((c) => c.system === BusinessClassSystem.Naics) ?? [];
  const cgls = data?.searchBusinessClasses.filter((c) => c.system === BusinessClassSystem.IsoGl) ?? [];

  const errors = formMethods.formState.errors;

  const fetchCodes = async (classSystem: BusinessClassSystem, term?: string) => {
    const { data } = await load({ variables: { input: { classSystems: [classSystem], term } } });

    return data?.searchBusinessClasses ?? [];
  };

  const handleSubmit = async (_: FormEvent<HTMLFormElement>, { naicsCode, cglCode }: ClientForm) => {
    await updateInsured({
      variables: {
        input: {
          id: opportunity.insured.id,
          naicsCodes: naicsCode ? [naicsCode] : [],
          isoCglCodes: cglCode ? [cglCode] : [],
        },
      },
    });
    return transition();
  };

  return (
    <Reform schema={ClientFormSchema} onSubmit={handleSubmit} methods={formMethods}>
      <Card>
        <CardHeader>
          <CardTitle>Verify Business Classification</CardTitle>
          <CardDescription>
            We&apos;ll use these codes to confirm carrier eligibility and build our information models going forward.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <h6>NAICS</h6>
            <Autocomplete
              options={(term) => fetchCodes(BusinessClassSystem.Naics, term)}
              selected={naics.find((c) => c.code === formMethods.getValues().naicsCode)}
              onSelect={(option) => {
                formMethods.setValue("naicsCode", option.code);
              }}
              toValue={(option) => businessClassToLabel(option)}
              toLabel={(option) => businessClassToLabel(option)}
              placeholder="Search NAICS Classifications"
              className="text-xs font-normal w-full"
            />
            <div className="text-destructive">{errors?.naicsCode?.message}</div>
          </div>
          <div className="space-y-2">
            <h6>CGL</h6>
            <Autocomplete
              options={(term) => fetchCodes(BusinessClassSystem.IsoGl, term)}
              selected={cgls.find((c) => c.code === formMethods.getValues().cglCode)}
              onSelect={(option) => {
                formMethods.setValue("cglCode", option.code);
              }}
              toValue={(option) => businessClassToLabel(option)}
              toLabel={(option) => businessClassToLabel(option)}
              placeholder="Search CGL Classifications"
              className="text-xs font-normal w-full"
            />
            <div className="text-destructive">{errors?.naicsCode?.message}</div>
          </div>
        </CardContent>
        <CardFooter>
          <Button size="sm" disabled={loading}>
            Verify
          </Button>
        </CardFooter>
      </Card>
    </Reform>
  );
}

export function VerifyDetailsDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} verified business classification.
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <h6>NAICS</h6>
          {opportunity.insured.naicsCodes[0]}
        </div>
        <div className="space-y-2">
          <h6>CGL</h6>
          {opportunity.insured.isoCglCodes[0]}
        </div>
      </CardContent>
    </Card>
  );
}
