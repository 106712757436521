import { useParams, useSearchParams } from "react-router-dom";

import { Loading } from "@/components/ui/loading";
import { useLeadsQuery } from "../../generated/graphql";
import { LeadsDataTable } from "./components/leads-data-table";

export const BookRoll = () => {
  const [searchParams] = useSearchParams();
  const { agencyId } = useParams<"agencyId">();
  const agentFirstName = searchParams.get("agentFirstName") || undefined;
  const agentLastName = searchParams.get("agentLastName") || undefined;
  const expirationDateAfter = searchParams.get("expirationDateAfter") || undefined;
  const expirationDateBefore = searchParams.get("expirationDateBefore") || undefined;
  const presentationMode = searchParams.get("present") || undefined;

  const { data: { leads = [] } = {}, loading } = useLeadsQuery({
    variables: {
      input: {
        agencyId: agencyId || "",
        agentFirstName,
        agentLastName,
        expirationDateAfter,
        expirationDateBefore,
        ...(presentationMode && {
          excludeArchived: true,
          sortByHighlighted: true,
        }),
      },
    },
    skip: !agencyId,
  });

  return <>{loading ? <Loading className="p-4" /> : <LeadsDataTable leads={leads} />}</>;
};
