import { isEqual } from "lodash";
import { NavLink as UnstyledNavLink, useNavigate, useParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Dialog, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { PaneGroup } from "@/components/ui/pane";
import { cn } from "@/utils";
import {
  AppetiteOpportunityFragment,
  QuoteState,
  useAgencyAffiliationQuery,
  useInsuredCreateOpportunityMutation,
  useInsuredOpportunitiesQuery,
  useInsuredQuery,
  usePoliciesQuery,
  useQuotesQuery,
  useVerticalByCglQuery,
} from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollarsAndCents } from "src/utils/strings";
import { AddRequirements } from "../opportunity/info/add-requirements";

export const InsuredNav = ({ insuredId }: { insuredId: string }) => {
  const { opportunityId: marketingPlanId } = useParams<"opportunityId">();

  const { data: { insured } = {} } = useInsuredQuery({
    variables: {
      id: insuredId,
    },
  });

  const { data: { insuredOpportunities: opportunities } = { insuredOpportunities: [] }, refetch } =
    useInsuredOpportunitiesQuery({
      variables: {
        id: insuredId,
      },
    });

  const stateMachine = window.location.pathname.split("/")[1] === "insured-sm";

  return (
    <PaneGroup className="flex-none shadow w-64">
      <Bar as="header">
        <div className="space-y-1 truncate">
          <div className="font-semibold text-sm truncate">{insured?.name}</div>
          <div className="text-2xs truncate">{insured?.agency?.name}</div>
        </div>
      </Bar>
      <nav className="bg-accent flex flex-auto flex-col overflow-auto">
        <MarketingPlanNavSection opportunities={opportunities} />
        {stateMachine && (
          <>
            <QuotesNavSection insuredId={insuredId} />
            <PoliciesNavSection insuredId={insuredId} />
          </>
        )}
        <div className="bg-border border-t mt-auto space-y-px">
          <MarketingPlanTemplateSection
            cgl={insured?.isoCglCodes[0]}
            opportunities={opportunities}
            refetchOpportunities={refetch}
          />
          <HasInternalRole>
            {insured?.agency?.id && <Affiliations id={insured?.agency?.id} />}
            {marketingPlanId && (
              <Dialog>
                <DialogTrigger className={cn("w-full", navLinkClassName)}>
                  Add Requirement
                  <Icon icon="add_task" />
                </DialogTrigger>
                <DialogHeader className="hidden">
                  <DialogTitle>Add Requirement</DialogTitle>
                </DialogHeader>
                <AddRequirements opportunityId={marketingPlanId} />
              </Dialog>
            )}
          </HasInternalRole>
          <NavLink to="files">
            Files
            <Icon icon="upload" />
          </NavLink>
          <NavLink to="email">
            Email
            <Icon icon="mail" />
          </NavLink>
        </div>
      </nav>
    </PaneGroup>
  );
};

const sentToAgent = (quote: any) =>
  quote.state === QuoteState.Delivered ||
  quote.state === QuoteState.BindRequestReceived ||
  quote.state === QuoteState.Bound;

const QuotesNavSection: React.FC<{ insuredId: string }> = ({ insuredId }) => {
  const { data: { quotes } = { quotes: [] } } = useQuotesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const validQuotes = quotes.filter((quote) => quote.state !== QuoteState.Invalid);
  const proposals = validQuotes.filter((quote) => sentToAgent(quote));
  const processing = validQuotes.filter((quote) => !sentToAgent(quote));

  return (
    <>
      {processing[0] && (
        <CollapsibleList defaultOpen={true}>
          <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
            <div className="flex">
              <span className="flex-auto font-semibold">Quotes</span>
              <span>{processing.length}</span>
            </div>
          </CollapsibleListTrigger>
          <CollapsibleListContent>
            {processing.map((quote) => {
              const { id: quoteId, submission, premium, state } = quote;
              const { appetiteProduct } = submission;
              const { carrierName, carrierProductName } = appetiteProduct;
              return (
                <NavLink key={quoteId} to={`/insured-sm/${insuredId}/quotes/${quoteId}`} className="block space-y-1">
                  <div className="font-semibold text-xs truncate">
                    {carrierName}: {carrierProductName}
                  </div>
                  <div className="flex justify-between text-2xs">
                    <span>{state}</span>
                    <span>{premium && formatDollarsAndCents(premium)}</span>
                  </div>
                </NavLink>
              );
            })}
          </CollapsibleListContent>
        </CollapsibleList>
      )}
      {proposals[0] && (
        <CollapsibleList defaultOpen={true}>
          <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
            <div className="flex">
              <span className="flex-auto font-semibold">Proposals</span>
              <span>{proposals.length}</span>
            </div>
          </CollapsibleListTrigger>
          <CollapsibleListContent>
            {proposals.map((quote) => {
              const { id: quoteId, submission, premium, state } = quote;
              const { appetiteProduct } = submission;
              const { carrierName, carrierProductName } = appetiteProduct;
              return (
                <NavLink key={quoteId} to={`/insured-sm/${insuredId}/quotes/${quoteId}`} className="block space-y-2">
                  <div className="font-semibold text-xs truncate">
                    {carrierName}: {carrierProductName}
                  </div>
                  <div className="flex justify-between text-2xs">
                    <span>{state}</span>
                    <span>{premium && formatDollarsAndCents(premium)}</span>
                  </div>
                </NavLink>
              );
            })}
          </CollapsibleListContent>
        </CollapsibleList>
      )}
    </>
  );
};

const PoliciesNavSection: React.FC<{ insuredId: string }> = ({ insuredId }) => {
  const { data: { policies } = { policies: [] } } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  if (policies.length === 0) {
    return null;
  }

  return (
    <CollapsibleList defaultOpen={true}>
      <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
        <div className="flex">
          <span className="flex-auto font-semibold">Policies</span>
          <span>{policies.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        {policies.map((policy) => {
          const { id: policyId, state, policyNumber, appetiteProduct } = policy;
          const { carrierName, carrierProductName } = appetiteProduct;
          return (
            <NavLink key={policyId} to={`/insured-sm/${insuredId}/policies/${policyId}`} className="block space-y-1">
              <div className="font-semibold text-xs truncate">
                {carrierName}: {carrierProductName}
              </div>
              <div className="flex justify-between text-2xs">
                <span>#{policyNumber}</span>
                <span>{state}</span>
              </div>
            </NavLink>
          );
        })}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};

const MarketingPlanNavSection: React.FC<{ opportunities: AppetiteOpportunityFragment[] }> = ({ opportunities }) => {
  if (opportunities.length === 0) {
    return null;
  }

  return (
    <CollapsibleList defaultOpen={true}>
      <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
        <div className="flex">
          <span className="flex-auto font-semibold">Marketing Plans</span>
          <span className="text-2xs">{opportunities.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        {opportunities.map((opp) => (
          <NavLink key={opp.id} to={opp.id} className="block space-y-1">
            <div className="font-semibold space-y-0.5 text-xs">
              {opp.selectedLinesOfBusiness.map((lob) => (
                <div key={lob}>{lob}</div>
              ))}
            </div>
            <div className="flex justify-between text-2xs">
              <span>{opp.status}</span>
              <span>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d")}</span>
            </div>
          </NavLink>
        ))}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};

const navLinkClassName =
  "bg-background flex items-center justify-between px-4 py-3 text-sm text-foreground hover:text-primary transition-all";

const NavLink = ({ to, children, className }: { to: string; children: React.ReactNode; className?: string }) => (
  <UnstyledNavLink
    to={to}
    className={({ isActive, isPending }) =>
      cn(navLinkClassName, isPending && "opacity-75 shadow-unselected", isActive && "shadow-selected", className)
    }
  >
    {children}
  </UnstyledNavLink>
);

export const Affiliations = ({ id }: { id: string }) => {
  const { data } = useAgencyAffiliationQuery({
    variables: {
      id: id ?? "",
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {data.agency.affiliations?.map((affiliation) => (
        <div key={affiliation.name} className="bg-background flex gap-2 h-12 items-center px-4">
          <div className="flex-auto text-sm">
            {affiliation.logoUrl ? (
              <img src={affiliation?.logoUrl ?? ""} className="max-h-8 max-w-24" />
            ) : (
              <span className="truncate">{affiliation?.shortName}</span>
            )}
          </div>
          <div className="flex-none gap grid grid-cols-2 items-center leading-none text-2xs w-24">
            {affiliation.brokerageCommission && (
              <>
                <span>Brokerage:</span>
                <span className="text-right">{`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
              </>
            )}
            {affiliation.bindingCommission && (
              <>
                <span>Binding:</span>
                <span className="text-right">{`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const MarketingPlanTemplateSection = ({
  cgl,
  opportunities,
  refetchOpportunities,
}: {
  cgl: string | undefined;
  opportunities: AppetiteOpportunityFragment[];
  refetchOpportunities: () => void;
}) => {
  const navigate = useNavigate();
  const { insuredId } = useParams();
  const [createOpportunity] = useInsuredCreateOpportunityMutation({
    onCompleted(data) {
      refetchOpportunities();
      navigate(`${data.insuredCreateOpportunity.id}`);
    },
  });
  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: cgl || "" } },
    skip: !cgl,
  });
  const { marketingPlanTemplates } = verticalByCGL || {};

  const validMarketingPlanTemplates =
    marketingPlanTemplates?.filter(
      (template) => !opportunities.some((opportunity) => isEqual(opportunity.selectedLinesOfBusiness, template.lobs))
    ) || [];

  return (
    <CollapsibleList defaultOpen={false}>
      <CollapsibleListTrigger>
        <div className="flex">
          <span className="flex-auto font-semibold text-2xs text-muted-foreground uppercase">
            Create Marketing Plans
          </span>
          <span className="text-2xs">{validMarketingPlanTemplates.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        <NavLink to="new">
          Create Custom Marketing Plan
          <Icon icon="add" />
        </NavLink>
        {validMarketingPlanTemplates.map((template) => (
          <Button
            variant="outline"
            className="flex w-full justify-between rounded-none py-0 h-auto"
            key={template.id}
            onClick={() => {
              void createOpportunity({
                variables: {
                  input: {
                    insuredId: insuredId as string & {
                      __scalar: "UUID";
                    },
                    desiredEffectiveDate: opportunities[0].desiredEffectiveDate,
                    selectedLinesOfBusiness: template.lobs,
                    agentInput: {
                      agencyId: opportunities[0].agent.agencyId,
                      email: opportunities[0].agent.email,
                      firstName: opportunities[0].agent.firstName,
                      lastName: opportunities[0].agent.lastName,
                    },
                  },
                },
              });
            }}
          >
            <div className="text-sm py-4">
              {template.lobs.map((lob) => (
                <div key={lob} className="text-left">
                  {lob}
                </div>
              ))}
            </div>
            <Icon icon="add" />
          </Button>
        ))}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};
