import { getAuthRequestHeaders } from "@cp/auth";
import parse from "html-react-parser";
import PostalMime, { Address, Attachment, Email } from "postal-mime";
import { useEffect, useState } from "react";
import { FileUploadFragment } from "../../../../generated/graphql";

export const PDFViewer = ({ file }: { file: FileUploadFragment }) => {
  const [url, setURL] = useState("");
  const [email, setEmail] = useState<Email | undefined>();
  const postal = new PostalMime();

  useEffect(() => {
    if (file.mimeType.includes("message")) {
      void fetch(`/api/files/${file.id}`, {
        headers: getAuthRequestHeaders(),
      })
        .then((r) => r.blob())
        .then((b) => b.slice(0, b.size, file.mimeType))
        .then((blob) => postal.parse(blob))
        .then((email) => setEmail(email));
    } else {
      void fetch(`/api/files/${file.id}`, {
        headers: getAuthRequestHeaders(),
      })
        .then((r) => r.blob())
        .then((b) => b.slice(0, b.size, file.mimeType))
        .then((blob) => setURL(URL.createObjectURL(blob)));
      setEmail(undefined);
    }
  }, [file]);

  if (email?.html) {
    return <EmailDetails email={email} />;
  }

  if (!url) {
    return <>Loading...</>;
  }

  return (
    <iframe
      src={`${url}#zoom=100`}
      style={{
        width: "80vw",
        height: "80vh",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2vh",
      }}
    />
  );
};

const EmailDetails = ({ email }: { email: Email }) => {
  // Function to display email addresses nicely
  const renderAddresses = (addresses: Address[] | undefined) =>
    addresses?.map((address, index) => (
      <span key={index}>
        {address.name ? `${address.name} <${address.address}>` : address.address}
        {index < addresses.length - 1 ? ", " : ""}
      </span>
    ));

  // Function to list attachments
  const renderAttachments = (attachments: Attachment[]) =>
    attachments.map((attachment, index) => <li key={index}>{attachment.filename}</li>);

  return (
    <div
      style={{
        width: "80vw",
        height: "80vh",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2vh",
        overflow: "scroll",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <h2>{email.subject || "No Subject"}</h2>
      <div>
        <strong>From:</strong> {renderAddresses([email.from])}
      </div>
      <div>
        <strong>To:</strong> {renderAddresses(email.to)}
      </div>
      {email.cc && (
        <div>
          <strong>CC:</strong> {renderAddresses(email.cc)}
        </div>
      )}
      {email.date && (
        <div>
          <strong>Date:</strong> {email.date}
        </div>
      )}
      <hr />
      <div style={{ margin: "20px 0" }}>{email.html ? parse(email.html) : <pre>{email.text}</pre>}</div>
      {email.attachments.length > 0 && (
        <div>
          <strong>Attachments:</strong>
          <ul>{renderAttachments(email.attachments)}</ul>
        </div>
      )}
    </div>
  );
};

export default EmailDetails;
