import { getAuthRequestHeaders } from "@cp/auth";
import { Code_Path, File_Audience } from "../generated/graphql";

export const downloadBlob = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name;
  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export async function uploadFile(file: File, opportunityId: string, audience?: string, source?: string) {
  const fd = new FormData();
  fd.set("file", file);
  if (audience) {
    fd.set("audience", audience);
  }
  if (source) {
    fd.set("source", source);
  }

  const res = await fetch(`/api/opportunities/${opportunityId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return json.fileId;
}
export async function uploadRedactedQuote(file: File, submissionId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("submissionId", submissionId);

  const res = await fetch(`/api/quotes`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}
export async function uploadProcessedQuote(file: File, quoteId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("codePath", Code_Path.ProcessedQuote);

  const res = await fetch(`/api/quotes/${quoteId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}
export async function uploadInsuredDocument(file: File, insuredId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("audience", File_Audience.Internal);

  const res = await fetch(`/api/files/${insuredId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}
export async function uploadPolicyDocument(file: File, policyId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("audience", File_Audience.Internal);

  const res = await fetch(`/api/policies/${policyId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}
