import { useParams } from "react-router";
import { ScheduleOfHazards } from "../../components/schedule-of-hazards";
import { RequirementElementProps } from "../requirement-element-props";

export default function ScheduleOfHazardsElement({
  clientDataKey,
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: RequirementElementProps) {
  const { opportunityId } = useParams();

  if (!opportunityId) {
    return null;
  }

  return (
    <div className="col-span-full -m-4">
      <ScheduleOfHazards
        opportunityId={opportunityId}
        clientDataKey={clientDataKey}
        onSubmissionRequirementsMet={onSubmissionRequirementsMet}
        onSubmissionRequirementsNotMet={onSubmissionRequirementsNotMet}
      />
    </div>
  );
}
