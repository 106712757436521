import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";

import { pinnedOppAtom } from "@/atoms";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { SelectBroker } from "@/hub/components/select-broker";
import { SelectOpportunityStatus } from "@/hub/components/select-opportunity-status";
import { STATUS_METADATA } from "@/metadata";
import { cn } from "@/utils";
import {
  OpportunityListItemFragment,
  OpportunityStatus,
  usePaginatedOpportunityListQuery,
} from "src/generated/graphql";
import { formatDateString } from "src/utils/date";
import { useMyAccount } from "../auth/useMyAccount";
import { adaptSearchParams } from "./adapt-search-params";
import { buttonClassName } from "./sidebar";
import { Avatar } from "./ui/avatar";
import { Button } from "./ui/button";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Icon } from "./ui/icon";
import { OpportunityPin } from "./ui/opportunity-pin";

export function Search() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(!open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <>
      <Button variant="secondary" size="sm" className={cn(buttonClassName)} onClick={() => setOpen(true)}>
        <Icon icon="search" />
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogHeader className="hidden">
          <DialogTitle>Search</DialogTitle>
        </DialogHeader>
        <DialogContent className="bg-transparent border-0 p-0 shadow-none">
          <CommandK setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function CommandK({ setOpen }: { setOpen: (open: boolean) => void }) {
  const [pinnedOpps] = useAtom(pinnedOppAtom);
  const navigate = useNavigate();
  const { data: user } = useMyAccount();
  const [searchParams, upsertSearchParams] = useUpsertSearchParams();
  const { page, groupBy, ...filterArgs } = adaptSearchParams(searchParams);
  const term = useDebounce(filterArgs.term ?? "", 333);

  const { data: pinnedData } = usePaginatedOpportunityListQuery({
    variables: {
      args: {
        ids: Object.keys(pinnedOpps),
      },
      pagination: {},
    },
    fetchPolicy: "cache-first",
  });
  const { paginatedOpportunities } = pinnedData ?? {};
  const pinnedOpportunities = paginatedOpportunities?.opportunities ?? [];

  // HACK: We can't just includeTests for all agencies with isTest == true, because QuoteWell is marked as a test agency
  // So instead we include tests for users without internal roles who are part of a test agency, since this does not apply for QW

  const forceIncludeTests = (user && !user.internal && user?.agency.isTest) ?? false;

  const { data, loading } = usePaginatedOpportunityListQuery({
    variables: {
      args: {
        ...filterArgs,
        isTest: forceIncludeTests || filterArgs.isTest,
        term,
      },
      pagination: {
        page,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const { numPages = 1, numRecords, opportunities = [] } = data?.paginatedOpportunities ?? {};

  const selectOpportunity = (v: string) => {
    setOpen(false);
    navigate(`/opportunity/${v}?${searchParams.toString()}`);
  };

  return (
    <Command shouldFilter={false} className="bg-transparent gap-1 overflow-visible">
      <CommandCard>
        <CommandInput
          placeholder="Search Opportunities"
          value={filterArgs.term || ""}
          onValueChange={(term) => upsertSearchParams({ term })}
        />
        <div className="bg-background flex gap-2 items-center -mt-px pb-2 px-2 rounded-b-md text-xs">
          <SelectOpportunityStatus className={selectClassName} />
          <SelectBroker className={selectClassName} />
        </div>
      </CommandCard>
      <CommandCard>
        {loading ? (
          <CommandCardHeader>Loading...</CommandCardHeader>
        ) : (
          <CommandCardHeader>
            {numRecords} {numRecords === 1 ? `opportunity` : `opportunities`}
            {term && ` matched "${term}"`}
            {numPages > 1 && (
              <div className="flex items-center ml-auto">
                <Button
                  className="text-base"
                  variant="ghost"
                  size="icon-xs"
                  disabled={page === 1 || page === 0}
                  onClick={() => upsertSearchParams({ page: (page - 1).toString() })}
                >
                  <Icon icon="chevron_left" />
                </Button>
                <div className="flex-auto font-mono mx-2 text-center text-2xs">
                  {page ?? 1} <span className="text-foreground/20">/</span> {numPages}
                </div>
                <Button
                  className="text-base"
                  variant="ghost"
                  disabled={page === numPages}
                  size="icon-xs"
                  onClick={() => upsertSearchParams({ page: ((page === 0 ? 1 : page) + 1).toString() })}
                >
                  <Icon icon="chevron_right" />
                </Button>
              </div>
            )}
          </CommandCardHeader>
        )}
        {opportunities.length > 0 && (
          <CommandList>
            <CommandGroup>
              {opportunities.map((opp) => (
                <CommandListItem key={opp.id} opp={opp} handleSelect={selectOpportunity} />
              ))}
            </CommandGroup>
          </CommandList>
        )}
      </CommandCard>
      <CommandCard>
        <CommandList>
          <CommandGroup>
            <CommandItem
              className="gap-4 h-12 pl-3 pr-10 py-2"
              onSelect={() => {
                setOpen(false);
                navigate(`/new${term && `?term=${encodeURIComponent(term)}`}`);
              }}
            >
              <div className="bg-primary flex gradient-fuchs h-6 items-center justify-center rounded-full text-background text-lg w-6">
                <Icon icon="add" />
              </div>
              <div className="text-sm">Create New Opportunity{term && ` for ${term}`}</div>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandCard>
      {pinnedOpportunities.length > 0 && (
        <CommandCard>
          <CommandList>
            <CommandCardHeader>Pinned Opportunities</CommandCardHeader>
            <CommandGroup>
              {pinnedOpportunities.map((opp) => (
                <CommandListItem key={opp.id} opp={opp} pin handleSelect={selectOpportunity} />
              ))}
            </CommandGroup>
          </CommandList>
        </CommandCard>
      )}
    </Command>
  );
}

const selectClassName = "h-8 px-2 text-xs";

const CommandCard = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-background rounded-md shadow-xl transition-all">{children}</div>
);

const CommandCardHeader = ({ children }: { children: React.ReactNode }) => (
  <header className="border-b flex font-medium gap-4 mb-px py-2 px-4 text-muted-foreground text-2xs">{children}</header>
);

const CommandListItem = ({
  opp,
  handleSelect,
  pin = false,
}: {
  opp: OpportunityListItemFragment;
  handleSelect: (v: string) => void;
  pin?: boolean;
}) => (
  <div className="relative">
    <CommandItem
      value={pin ? `${opp.id}-pin` : opp.id}
      className="gap-4 h-12 pl-3 pr-10 text-xs"
      onSelect={(v) => handleSelect(v.replace("-pin", ""))}
    >
      <Avatar user={opp.broker} isExpedited={opp.isExpedited} />
      <div className="flex flex-auto flex-col">
        <div className="text-xs">{opp.insured.name}</div>
        <div className="flex items-center gap-1.5 text-muted-foreground text-2xs">
          <Icon
            icon={STATUS_METADATA[OpportunityStatus[opp.status as OpportunityStatus]].icon}
            className={cn("text-3xs", STATUS_METADATA[OpportunityStatus[opp.status as OpportunityStatus]].className)}
          />
          {formatDateString(opp.desiredEffectiveDate, "MMM d, yyyy")} &middot; {opp.agent.agency.name} &middot;{" "}
          {opp.agent.firstName} {opp.agent.lastName}
        </div>
      </div>
    </CommandItem>
    <OpportunityPin id={opp.id} className="absolute top-0 bottom-0 right-1 my-auto" />
  </div>
);
