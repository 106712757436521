import { format } from "date-fns";
import { useAtom } from "jotai";
import { useState } from "react";
import { useParams } from "react-router";

import { correspondenceAtom, deletedFilesAtom, fileLabelsAtom, selectTagsAtom } from "@/atoms";
import { Badge } from "@/components/ui/badge";
import { CardTitle } from "@/components/ui/card";
import { Dialog, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { cn } from "@/utils";
import { FileUploadFragment, useFilesByLabelQuery } from "../../../generated/graphql";
import { FileMenu, ViewPDF } from "./file-menu";
import { FilesOptions } from "./files-options";

export const FilesList = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { insuredId } = useParams<"insuredId">();
  const [selectedTags] = useAtom(selectTagsAtom);
  const [deletedFiles] = useAtom(deletedFilesAtom);
  const [fileLabels] = useAtom(fileLabelsAtom);
  const [correspondence] = useAtom(correspondenceAtom);
  const [selectedFile, setSelectedFile] = useState<FileUploadFragment | undefined>(undefined);

  // Load files with direct link to opportunity first, since they're quick, and likely to include most files
  const { data: { filesByLabel: linkedFiles = [] } = {}, loading } = useFilesByLabelQuery({
    variables: {
      input: {
        opportunityId,
        insuredId,
        includeGmailAttachments: false,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {} } = useFilesByLabelQuery({
    variables: {
      input: {
        opportunityId,
        insuredId,
        includeGmailAttachments: true,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
  });

  let files = allFiles.length > 0 ? allFiles : linkedFiles;

  files = correspondence ? files : files.filter((file) => !file.labels.includes("Correspondence"));

  return (
    <div className="col-span-3 @5xl:border-l @5xl:block">
      <header className="hidden @5xl:flex items-center justify-between p-4 pb-0">
        <CardTitle>{selectedTags || "All Files"}</CardTitle>
        <FilesOptions />
      </header>
      <Row className="border-b h-12 mb-px text-muted-foreground">
        <Cell>File Name</Cell>
        <Cell>Uploaded By</Cell>
        <Cell>Date</Cell>
        <Cell />
      </Row>
      {loading ? (
        <Loading className="p-4" />
      ) : (
        <Dialog open={!!selectedFile} onOpenChange={() => setSelectedFile(undefined)}>
          <DialogHeader className="hidden">
            <DialogTitle>Files</DialogTitle>
          </DialogHeader>
          {files.map((file) => (
            <Row key={file?.id} className={cn("border-t", file?.deletedAt && "text-destructive")}>
              <Cell
                className="truncate"
                onClick={() =>
                  setSelectedFile({
                    filename: file.filename,
                    id: file.id,
                    mimeType: file.mimeType,
                  })
                }
              >
                <div className="truncate">{file?.filename}</div>
                {fileLabels && file.labels[0] && (
                  <div className="flex flex-wrap gap-2 mt-1.5 text-3xs">
                    <Icon icon="subdirectory_arrow_right" className="ml-1.5 text-muted-foreground" />
                    {file.labels.map((label) => (
                      <Badge key={label} variant="secondary">
                        {label}
                      </Badge>
                    ))}
                  </div>
                )}
              </Cell>
              <Cell>
                {file.uploader?.firstName} {file.uploader?.lastName}
              </Cell>
              <Cell>{format(new Date(file.deletedAt ?? file.createdAt), "M/d/yy")}</Cell>
              <Cell>
                <FileMenu file={file} />
              </Cell>
            </Row>
          ))}
          {selectedFile && <ViewPDF file={selectedFile} files={files} setSelectedFile={setSelectedFile} />}
        </Dialog>
      )}
    </div>
  );
};

const Row = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "grid gap-6 grid-flow-col grid-cols-[1fr_6rem_3.5rem_2rem] items-center px-4 py-3 text-xs",
      className
    )}
    {...props}
  />
);

const Cell = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("", className)} {...props} />
);
