import { useForgotPassword } from "@cp/auth";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "../forms/components/FormFields";
import { AuthCard } from "./AuthCard";

interface ForgotPasswordFormData {
  email: string;
}

export function ForgotPassword() {
  const methods = useForm<ForgotPasswordFormData>();
  const { handleSubmit } = methods;

  const { mutate, isLoading, isSuccess, error } = useForgotPassword();

  const onSubmit = (formData: ForgotPasswordFormData) => {
    mutate(formData);
  };

  if (isSuccess) {
    return <AuthCard header="Success!" subHeader="Please check your inbox." />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthCard
          header="Forgot password"
          subHeader="Hey, it happens to the best of us &ndash; input your email and we'll send a password reset email."
          error={error}
        >
          <CardContent>
            <Input type="email" required={true} name="email" placeholder="Email" disabled={isLoading} />
          </CardContent>
          <CardFooter>
            <Button type="submit" disabled={isLoading} className="flex-auto">
              Send me the Email
            </Button>
          </CardFooter>
        </AuthCard>
      </form>
    </FormProvider>
  );
}
