import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useClientPropertyDataQuery, useExpeditedOpportunityTransitionMutation } from "../../../../generated/graphql";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../components/ui/carousel";
import { Loading } from "../../../components/ui/loading";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function WaitingOnPropertiesReview({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const [toInspect, setToInspect] = useState<string[]>([]);

  const { data: propertyData, loading: propertyDataLoading } = useClientPropertyDataQuery({
    variables: { id: opportunity.insured.id },
  });

  const [transition, { loading: transitionLoading }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        opportunityId: opportunity.id,
        action: "Reviewed",
        payload: JSON.stringify({ propertiesToInspect: toInspect.length }),
      },
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Review Properties</CardTitle>
        <CardDescription>Review properties for inspection fees.</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <ul>
          {propertyDataLoading ? (
            <Loading />
          ) : (
            propertyData?.clientPropertyData?.map((premises) => {
              const locationNumber = premises.premisesInformation.find(({ key }) => key === "Location_Number")?.value;
              const buildingNumber = premises.premisesInformation.find(({ key }) => key === "Building_Number")?.value;
              const fullAddress = premises.premisesInformation.find(({ key }) => key === "Full_Address")?.value;
              const shouldInspect = toInspect.includes(`${locationNumber}-${buildingNumber}`);
              return (
                <li key={`${locationNumber}-${buildingNumber}`} className="p-4">
                  <details>
                    <summary className="w-full">
                      Loc {locationNumber}, Bldg {buildingNumber} - {fullAddress}
                      <span className="float-end">
                        <Button
                          variant={shouldInspect ? "destructive" : "default"}
                          size="sm"
                          onClick={() =>
                            setToInspect((toInspect) =>
                              shouldInspect
                                ? toInspect.filter((bn) => bn !== `${locationNumber}-${buildingNumber}`)
                                : [...toInspect, `${locationNumber}-${buildingNumber}`]
                            )
                          }
                        >
                          {shouldInspect ? "Remove" : "Inspect"}
                        </Button>
                      </span>
                    </summary>
                    <Carousel className="w-full max-w-3xl m-auto">
                      <Link target="_blank" to={`https://www.google.com/maps/place/${fullAddress}`}>
                        <CarouselContent>
                          {premises.streetViews.map((url) => (
                            <CarouselItem key={url}>
                              <img className="m-auto" src={url} />
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                      </Link>
                      <CarouselPrevious />
                      <CarouselNext />
                    </Carousel>
                    <dl className="divide-y py-4 px-2 text-center">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Violent Crime Score</dt>
                        <dd className="mt-1 text-left text-sm leading-6 text-gray-700 sm:mt-0">
                          {premises.violentCrimeScore}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Property Crime Score</dt>
                        <dd className="mt-1 text-left text-sm leading-6 text-gray-700 sm:mt-0">
                          {premises.propertyCrimeScore}
                        </dd>
                      </div>
                      {premises.premisesInformation.map(({ key, value }) => (
                        <div key={key} className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">{key}</dt>
                          <dd className="mt-1 text-left text-sm leading-6 text-gray-700 sm:mt-0">{value}</dd>
                        </div>
                      ))}
                    </dl>
                  </details>
                </li>
              );
            })
          )}
        </ul>
      </CardContent>
      <CardFooter>
        <Button size="sm" disabled={propertyDataLoading || transitionLoading} onClick={() => transition()}>
          Add inspection fees for {toInspect.length} properties
        </Button>
      </CardFooter>
    </Card>
  );
}

export function WaitingOnPropertiesReviewDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} verified business classification.
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <h6>NAICS</h6>
          {opportunity.insured.naicsCodes[0]}
        </div>
        <div className="space-y-2">
          <h6>CGL</h6>
          {opportunity.insured.isoCglCodes[0]}
        </div>
      </CardContent>
    </Card>
  );
}
