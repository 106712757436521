import parse from "html-react-parser";
import React from "react";

import { useMyAccount } from "@/auth/useMyAccount";
import { CardContent, CardHeader } from "@/components/ui/card";
import { CollapsibleCard } from "@/components/ui/collapsible-card";
import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { GmailRelayEventFragment } from "../../../generated/graphql";
import { getRealGmailRelayEmails } from "../../../utils/email";
import { Attachment } from "./gmail-message-event";

export const GmailRelayEvent: React.FC<GmailRelayEventFragment> = ({
  gmailRelay: { initiator, recipientEmail },
  gmailMessage: { to, from, html, dateReceived, headerMessageId, attachments },
}) => {
  const { data: user } = useMyAccount();

  const { to: realTo, from: realFrom } = getRealGmailRelayEmails({
    to,
    from,
    initiatorEmail: initiator.email,
    recipientEmail,
  });

  return (
    <CollapsibleCard
      footer={
        attachments[0] && (
          <div className="flex flex-row flex-wrap gap-2 ml-10 p-4 pt-2 text-xs z-10">
            {attachments.map((file) => (
              <Attachment key={file.id} fileUpload={file} />
            ))}
          </div>
        )
      }
    >
      <CardHeader>
        <a
          href={`https://mail.google.com/mail/u/${user?.email}/#search/rfc822msgid:${encodeURIComponent(
            headerMessageId
          )} in:anywhere`}
          target="_blank"
          rel="noreferrer"
          className="flex flex-row gap-4 items-center space-y-0 text-xs"
        >
          <Icon icon="mail" className="bg-accent rounded-full w-6" />
          <div className="flex-auto">
            <strong className="flex-auto">{realFrom}</strong>
            {" relayed an email to "}
            <strong className="flex-auto">{realTo}</strong>{" "}
            <RelativeDate date={dateReceived} className="text-muted-foreground" />
          </div>
        </a>
      </CardHeader>
      {html && (
        <CardContent className="ml-10 text-xs" style={{ overflowWrap: "anywhere" }}>
          {parse(html)}
        </CardContent>
      )}
    </CollapsibleCard>
  );
};
