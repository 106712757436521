import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import {
  SubmissionFragment,
  SubmissionStateReferredActions,
  useTransitionSubmissionMutation,
} from "../../../generated/graphql";
import { SubmissionActions, SubmissionDetails } from "./submission";

export const SubmissionReferred = ({ submission }: { submission: SubmissionFragment }) => {
  const [transition] = useTransitionSubmissionMutation();

  const handleTransition = async (action: SubmissionStateReferredActions) => {
    await transition({
      variables: {
        input: {
          submissionId: submission.id,
          action,
        },
      },
    });
  };

  return (
    <>
      <SubmissionDetails submission={submission} />
      <SubmissionActions>
        <HasInternalRole>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleTransition(SubmissionStateReferredActions.BlockMarket)}
                variant="ghost"
                size="icon"
              >
                <Icon icon="front_hand" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Blocked</TooltipContent>
          </Tooltip>
          <Button onClick={() => handleTransition(SubmissionStateReferredActions.Decline)} variant="outline" size="sm">
            Declined
          </Button>
          <Button onClick={() => handleTransition(SubmissionStateReferredActions.Quote)} variant="outline" size="sm">
            Quoted
          </Button>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleTransition(SubmissionStateReferredActions.RuleOut)}
                variant="ghost"
                size="icon"
              >
                <Icon icon="close" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Rule Out</TooltipContent>
          </Tooltip>
        </HasInternalRole>
      </SubmissionActions>
    </>
  );
};
