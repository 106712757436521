import { join } from "lodash";
import { useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Loading } from "@/components/ui/loading";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import {
  useFindFileUploadQuery,
  useSubmissionPacketQuery,
  useUploadFileFromGDriveMutation,
} from "../../../../generated/graphql";
import { FileMenu } from "../../files/file-menu";

interface Props {
  opportunityId: string;
  fileIds: string[];
  setFileIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SubmissionTemplateAttachments: React.FC<Props> = ({ opportunityId, setFileIds, fileIds }) => {
  const { data, loading, refetch } = useSubmissionPacketQuery({
    variables: { id: opportunityId },
    skip: !opportunityId,
  });

  const [uploadFromGDrive, { loading: loadingUpdate }] = useUploadFileFromGDriveMutation();

  useEffect(() => {
    if (fileIds) {
      setFileIds(fileIds);
    }
  }, [fileIds]);

  if (loading || loadingUpdate) {
    return <Loading label="Loading..... Retrieving related files" />;
  }

  if (data?.opportunity?.submissionPacket.files.length === 0) {
    return (
      <>
        <h3>No files found for this opportunity</h3>
      </>
    );
  }

  return (
    <>
      <h3>Select files to add as attachments</h3>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>File Name</TableHead>
            <TableHead>Labels</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.opportunity?.submissionPacket.files.map(({ file, labels, link }) => (
            <TableRow key={file?.id}>
              <TableCell>
                {link && file?.id ? (
                  <Button
                    className="text-wrap"
                    variant="ghost"
                    onClick={() => {
                      void uploadFromGDrive({
                        variables: {
                          input: {
                            storageId: file?.id,
                            opportunityId: opportunityId,
                          },
                        },
                        onCompleted: () => refetch(),
                      });
                    }}
                  >
                    Click to sync from G-Drive{" "}
                  </Button>
                ) : (
                  <Checkbox
                    checked={fileIds.includes(file?.id ?? "")}
                    onCheckedChange={(checked) => {
                      checked
                        ? setFileIds((prev: string[]) => [...prev, file?.id ?? ""])
                        : setFileIds((prev: string[]) => prev.filter((id) => id !== file?.id));
                    }}
                  />
                )}
              </TableCell>
              <TableCell>{file?.filename}</TableCell>
              <TableCell className="text-xs">{join(labels, ", ")}</TableCell>
              <TableCell>
                <TableCell>{link ? "View in G-Drive" : file?.id ? <FileMenuWrapper id={file.id} /> : null}</TableCell>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const FileMenuWrapper = ({ id }: { id: string }) => {
  const { data } = useFindFileUploadQuery({
    variables: {
      input: {
        id,
      },
    },
  });

  if (!data?.findFile) {
    return null;
  }

  return <FileMenu file={data?.findFile} />;
};
