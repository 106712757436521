import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./collapsible";

interface Props {
  footer?: React.ReactNode;
}

export const CollapsibleCard: React.FC<PropsWithChildren<Props>> = ({ children, footer }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [collapsible, setCollapsible] = useState(false);
  const [init, setInit] = useState(true);
  const [open, setOpen] = useState(false);
  const acceptableHeight = 256;

  const handleResize = () => {
    if (contentRef.current?.offsetHeight !== undefined) {
      setCollapsible(contentRef.current.offsetHeight > acceptableHeight);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bg-background z-1">
      <Collapsible
        className="overflow-hidden relative"
        open={open}
        defaultOpen={collapsible}
        onOpenChange={() => {
          setInit(false);
          setOpen(!open);
        }}
      >
        <CollapsibleContent
          forceMount
          className={cn(
            collapsible && [
              open && "animate-collapsible-card-down",
              !open && "animate-collapsible-card-up",
              init && "h-[var(--collapsible-card-height)]",
            ]
          )}
          style={{ animationFillMode: "both" }}
        >
          <div ref={contentRef}>{children}</div>
        </CollapsibleContent>
        {collapsible && (
          <>
            <CollapsibleTrigger className="aspect-square bg-muted flex items-center justify-center rounded-full shadow-xl text-foreground text-sm w-6 absolute top-4 left-4 z-1">
              <Icon icon={open ? "arrow_upward" : "arrow_downward"} />
            </CollapsibleTrigger>
            <div
              className={cn(
                "flex items-end justify-center pb-3 rounded-b-lg w-full z-1",
                !open && "bg-gradient-to-b from-transparent to-background h-28 absolute bottom-0"
              )}
            />
          </>
        )}
      </Collapsible>
      {footer}
    </div>
  );
};
