import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "../../../components/ui/icon";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";
import { GenericAgentWaiting } from "./generic-agent";

export function AgentIndications(props: ExpeditedOpportunityStatusViewProps) {
  return props.opportunity.indication.total.min === "0" && props.opportunity.indication.total.max === "0" ? (
    <GenericAgentWaiting {...props} />
  ) : (
    <AgentIndication {...props} />
  );
}

function AgentIndication({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>QuoteWell Indication Eligible!</CardTitle>
        <CardDescription>
          This submission is eligible for a preliminary QuoteWell indication. Please note, this is a non-binding premium
          indication only that does not include any applicable fees and taxes. Final premium quotation is subject to
          completion of an insurance application and underwriting review.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <p className="font-semibold">General Liability Indication</p>
        <dl className="max-w-[400px] p-4">
          {opportunity.indication.indicationPerCgl
            .filter((indication) => indication.cgl)
            .map((indication, i) => (
              <div key={i} className="grid grid-cols-2 gap border-b">
                <dt className="font-semibold">CGL {indication.cgl}</dt>
                <dd>
                  {indication.min && indication.max
                    ? `${indication.minCurrency} - ${indication.maxCurrency}`
                    : "Indication Unavailable"}
                </dd>
              </div>
            ))}
          <div className="grid grid-cols-2 gap border-b">
            <dt className="font-semibold">Total GL Indication</dt>
            <dd>
              {opportunity.indication.total.minCurrency} - {opportunity.indication.total.maxCurrency}
            </dd>
          </div>
        </dl>
      </CardContent>
      <CardFooter>
        <p className="text-muted-foreground flex items-center gap-4">
          <Icon icon="emergency" />
          <span>
            This is a non-binding premium indication only. The premium indication does not obligate QuoteWell or any
            carrier to bind coverage or issue an insurance policy. Final premium quotation is subject to completion of
            an insurance application and underwriting review. There is no guarantee a firm quote will be offered or
            coverage provided.
          </span>
        </p>
      </CardFooter>
    </Card>
  );
}
