import { useAtom } from "jotai";

import { pinnedOppAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";

export const OpportunityPin = ({ id, className }: { id: string; className?: string }) => {
  const [pinnedOpps, setPinnedOpps] = useAtom(pinnedOppAtom);

  const addPin = () => {
    const pins = {
      [id]: id,
      ...pinnedOpps,
    };
    setPinnedOpps(pins);
  };

  const removePin = () => {
    const { [id]: deleted, ...rest } = pinnedOpps;
    setPinnedOpps(rest);
  };

  const pinned = id in pinnedOpps;

  return (
    <Button
      variant="ghost"
      size="icon"
      className={cn(
        "!bg-transparent",
        pinned ? "text-amber-500 hover:text-amber-600" : "text-muted-foreground/20 hover:text-amber-500",
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        pinned ? removePin() : addPin();
      }}
    >
      <Icon icon="star" className="filled" />
    </Button>
  );
};
