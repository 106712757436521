import { getAuthRequestHeaders } from "@cp/auth";
import { useState } from "react";
import Dropzone from "react-dropzone";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Panel, PanelTitle } from "@/components/ui/panel";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { FileUploadFragment, useCreateBrokerageFolderForOpportunityMutation } from "../../../generated/graphql";
import { uploadFile } from "../../../utils/file.utils";
import { reloadOpportunity } from "../loaders";

interface Props {
  opportunityId: string;
  files: FileUploadFragment[];
  brokerageFolderId: string | null | undefined;
  refetch: () => void;
}
export const Files: React.FC<Props> = ({ files, opportunityId, brokerageFolderId, refetch }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Panel>
      <Dropzone
        noClick
        onDrop={async (files) => {
          setSubmitting(true);
          await uploadFile(files[0], opportunityId);
          refetch();
          setSubmitting(false);
        }}
      >
        {({ getRootProps }) => (
          <div {...getRootProps()} className="space-y-4">
            <PanelTitle>
              Files
              {submitting ? (
                <Spinner />
              ) : (
                <UploadFileButton opportunityId={opportunityId} refetch={refetch} setSubmitting={setSubmitting} />
              )}
            </PanelTitle>
            <FilesList files={files} />
            <HasInternalRole>
              <GoogleDriveButton opportunityId={opportunityId} brokerageFolderId={brokerageFolderId} />
            </HasInternalRole>
          </div>
        )}
      </Dropzone>
    </Panel>
  );
};

const FilesList = ({ files }: { files: FileUploadFragment[] }) => {
  if (files.length === 0) {
    return <div className="italic text-muted-foreground">No files uploaded</div>;
  }

  return (
    <ul className="space-y-2">
      {files.map((file) => (
        <li key={file.id}>
          <FileLink file={file} />
        </li>
      ))}
    </ul>
  );
};

export const FileLink = ({ file, className }: { file: FileUploadFragment; className?: string }) => (
  <a
    href={`/api/files/${file.id}`}
    download={file.filename}
    media={file.mimeType}
    className={cn("inline-flex gap-3 items-center leading-tight hover:text-primary text-xs", className)}
    onClick={async (e) => {
      e.preventDefault();
      // Not the best since it loads the entire file into memory before giving the user feedback.
      // But good enough for now
      const blob = await fetch(`/api/files/${file.id}`, {
        headers: getAuthRequestHeaders(),
      })
        .then((r) => r.blob())
        // Create a new blob with the mime type
        .then((b) => b.slice(0, b.size, file.mimeType));

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = file.filename;

      document.body.append(anchor);
      anchor.click();

      URL.revokeObjectURL(url);
      anchor.remove();
    }}
  >
    <Icon icon="download" />
    <span className="truncate">{file.filename}</span>
  </a>
);

const UploadFileButton = ({
  opportunityId,
  refetch,
  setSubmitting,
}: {
  opportunityId: string;
  refetch: () => void;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Button asChild variant="ghost" size="icon-sm">
      <label>
        <input
          type="file"
          name="file"
          className="hidden"
          onChange={async (e) => {
            setSubmitting(true);
            if (e.target.files && e.target.files.length > 0) {
              const file = e.target.files[0]; // Access the first file
              await uploadFile(file, opportunityId);
              refetch();
              setSubmitting(false);
            }
          }}
        />
        <Icon icon="upload" />
      </label>
    </Button>
  );
};

const GoogleDriveButton = ({
  opportunityId,
  brokerageFolderId,
}: {
  opportunityId: string;
  brokerageFolderId: string | null | undefined;
}) => {
  return (
    <>
      {brokerageFolderId ? (
        <Button variant="secondary" size="sm" className="gap-2" asChild>
          <a href={`https://drive.google.com/drive/folders/${brokerageFolderId}`} target="_blank" rel="noreferrer">
            Google Drive
            <Icon icon="open_in_new" />
          </a>
        </Button>
      ) : (
        <CreateFolderButton opportunityId={opportunityId} />
      )}
    </>
  );
};

const CreateFolderButton = ({ opportunityId }: { opportunityId: string }) => {
  const { toast } = useToast();
  const [createBrokerageFolder, { loading }] = useCreateBrokerageFolderForOpportunityMutation({
    variables: {
      id: opportunityId,
    },
    ...reloadOpportunity,
    onCompleted: () => {
      toast({ title: "Google Drive folder created" });
    },
    onError: () => {
      toast({ title: "Error", description: "Failed to create Google Drive folder", variant: "destructive" });
    },
  });

  return (
    <Button variant="secondary" size="sm" className="gap-2" onClick={() => createBrokerageFolder()}>
      {loading ? (
        <>
          Creating
          <Spinner />
        </>
      ) : (
        <>
          Create Google Drive Folder
          <Icon icon="create_new_folder" />
        </>
      )}
    </Button>
  );
};
