import { useParams } from "react-router";

import { useMyAccount } from "@/auth/useMyAccount";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import {
  ExpeditedOpportunityStatus,
  Roles,
  useExpeditedOpportunityQuery,
  useOpportunityRequirementsQuery,
} from "../../../generated/graphql";
import RequirementsIndex from "../requirements/requirements-index";
import { AddAdditionalBrokerageMarkets } from "./status-views/add-additional-brokerage-markets";
import { AddInformationRequests } from "./status-views/adding-information-requests";
import { AgentIndications } from "./status-views/agent-indications";
import Complete from "./status-views/complete";
import { ConfirmAdditionalBrokerageMarkets } from "./status-views/confirm-additional-brokerage-markets";
import Declined from "./status-views/declined";
import ExpeditedOpportunityStatusViewProps from "./status-views/expedited-opportunity-status-view-props";
import { GenericAgentWaiting } from "./status-views/generic-agent";
import { ReviewFinalMarketingPlan } from "./status-views/review-final-marketing-plan";
import { ReviewHazards } from "./status-views/review-hazards";
import { ReviewLocations } from "./status-views/review-locations";
import { SendingQuoteToAgentAgentView, SendingQuoteToAgentBroker } from "./status-views/sending-quote-to-agent";
import { VerifyBindingMarketingPlan } from "./status-views/verify-binding-marketing-plan";
import { VerifyBrokerageMarketingPlan } from "./status-views/verify-brokerage-marketing-plan";
import { VerifyDetails } from "./status-views/verify-details";
import { WaitingForInitialRequirements } from "./status-views/waiting-for-initial-requirements";
import { WaitingOnBrokerageReview } from "./status-views/waiting-on-brokerage-review";
import WaitingOnCarrier from "./status-views/waiting-on-carrier";
import WaitingOnInitialBrokerReview from "./status-views/waiting-on-initial-broker-review";
import { WaitingOnPropertiesReview } from "./status-views/waiting-on-properties-review";
import WaitingOnQuoteReview from "./status-views/waiting-on-quote-review";
import WaitingOnSubmissionInformationFromAgent from "./status-views/waiting-on-submission-information-from-agent";
import { WaitingOnUnderwriting } from "./status-views/waiting-on-underwriting";

interface RoleComponents {
  [Roles.Broker]: React.ComponentType<ExpeditedOpportunityStatusViewProps> | null;
  [Roles.Agent]: React.ComponentType<ExpeditedOpportunityStatusViewProps> | null;
}

interface RouterMap {
  [key: string]: RoleComponents | React.ComponentType<ExpeditedOpportunityStatusViewProps>;
}

const router: RouterMap = {
  [ExpeditedOpportunityStatus.WaitingForInitialRequirements]: {
    [Roles.Broker]: WaitingForInitialRequirements,
    [Roles.Agent]: RequirementsIndex,
  },
  [ExpeditedOpportunityStatus.WaitingForCoverages]: {
    [Roles.Broker]: WaitingForInitialRequirements,
    [Roles.Agent]: RequirementsIndex,
  },
  [ExpeditedOpportunityStatus.AssigningBroker]: {
    [Roles.Broker]: null,
    [Roles.Agent]: null,
  },
  [ExpeditedOpportunityStatus.ReviewLocations]: {
    [Roles.Broker]: ReviewLocations,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.ReviewHazards]: {
    [Roles.Broker]: ReviewHazards,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.VerifyDetails]: {
    [Roles.Broker]: VerifyDetails,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.WaitingOnPropertiesReview]: {
    [Roles.Broker]: WaitingOnPropertiesReview,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.WaitingOnInitialBrokerReview]: {
    [Roles.Broker]: WaitingOnInitialBrokerReview,
    [Roles.Agent]: AgentIndications,
  },
  [ExpeditedOpportunityStatus.VerifyBindingMarketingPlan]: {
    [Roles.Broker]: VerifyBindingMarketingPlan,
    [Roles.Agent]: AgentIndications,
  },
  [ExpeditedOpportunityStatus.VerifyBrokerageMarketingPlan]: {
    [Roles.Broker]: VerifyBrokerageMarketingPlan,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.WaitingOnUnderwriting]: {
    [Roles.Broker]: WaitingOnUnderwriting,
    [Roles.Agent]: AgentIndications,
  },
  [ExpeditedOpportunityStatus.WaitingOnSubmissionInformationFromAgent]: {
    [Roles.Broker]: WaitingOnSubmissionInformationFromAgent,
    [Roles.Agent]: WaitingOnSubmissionInformationFromAgent,
  },
  [ExpeditedOpportunityStatus.SubmittingToCarrier]: {
    [Roles.Broker]: null,
    [Roles.Agent]: null,
  },
  [ExpeditedOpportunityStatus.WaitingOnCarrier]: {
    [Roles.Broker]: WaitingOnCarrier,
    [Roles.Agent]: WaitingOnCarrier,
  },
  [ExpeditedOpportunityStatus.WaitingOnQuoteReview]: {
    [Roles.Broker]: WaitingOnQuoteReview,
    [Roles.Agent]: null,
  },
  [ExpeditedOpportunityStatus.WaitingOnBrokerageReview]: {
    [Roles.Broker]: WaitingOnBrokerageReview,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.Declined]: {
    [Roles.Broker]: Declined,
    [Roles.Agent]: Declined,
  },
  [ExpeditedOpportunityStatus.SendingQuoteToAgent]: {
    [Roles.Broker]: SendingQuoteToAgentBroker,
    [Roles.Agent]: SendingQuoteToAgentAgentView,
  },
  [ExpeditedOpportunityStatus.Complete]: Complete,
  [ExpeditedOpportunityStatus.NotApplicable]: {
    [Roles.Broker]: null,
    [Roles.Agent]: null,
  },
  [ExpeditedOpportunityStatus.ConfirmAdditionalBrokerageMarkets]: {
    [Roles.Broker]: ConfirmAdditionalBrokerageMarkets,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.AddAdditionalBrokerageMarkets]: {
    [Roles.Broker]: AddAdditionalBrokerageMarkets,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.ReviewFinalMarketingPlan]: {
    [Roles.Broker]: ReviewFinalMarketingPlan,
    [Roles.Agent]: GenericAgentWaiting,
  },
  [ExpeditedOpportunityStatus.AddingInformationRequests]: {
    [Roles.Broker]: AddInformationRequests,
    [Roles.Agent]: GenericAgentWaiting,
  },
};

export default function ExpeditedActions() {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: user } = useMyAccount();

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useExpeditedOpportunityQuery({
    variables: { id: opportunityId! },
    skip: !opportunityId,
    pollInterval: 1000,
  });
  const { data: { opportunity: requirementsData } = {} } = useOpportunityRequirementsQuery({
    variables: {
      id: opportunityId!,
    },
    pollInterval: 1000,
  });

  if (loading) {
    return (
      <ActionCard
        title={
          <>
            <Spinner /> Loading Actions...
          </>
        }
      />
    );
  }

  if (!opportunity || !opportunityId || !user) {
    return null;
  }

  // Could use internal flag to determine this
  const role = user.internal ? Roles.Broker : Roles.Agent;
  const incompleteReqs = requirementsData?.agentActions?.filter((f) => {
    return !f.complete;
  });

  const statusComponents = router[opportunity.expeditedStatus] as RoleComponents;
  const BrokerComponent = statusComponents?.[Roles.Broker] ?? null;
  let AgentComponent = statusComponents?.[Roles.Agent] ?? null;

  if ((incompleteReqs ?? [])?.length > 0) {
    AgentComponent = RequirementsIndex;
  }

  if (role === Roles.Broker) {
    return (
      <>
        {BrokerComponent ? (
          <div className="rounded-lg shadow-[0_0_0_1px_hsla(var(--primary)/0.2),0_0.125rem_1rem_-0.25rem_hsla(var(--primary)_/_0.4)]">
            <BrokerComponent opportunity={opportunity} refetch={refetch} />
          </div>
        ) : (
          <ActionCard
            title={
              <>
                <Icon icon="check_circle" /> All Actions Complete
              </>
            }
          />
        )}
        {AgentComponent ? (
          <div className="rounded-lg shadow-[0_0_0_1px_hsla(var(--primary)/0.2),0_0.125rem_1rem_-0.25rem_hsla(var(--primary)_/_0.4)]">
            <h3 className="px-4 py-2">Agent&apos;s View</h3>
            <AgentComponent opportunity={opportunity} refetch={refetch} />
          </div>
        ) : null}
      </>
    );
  } else {
    return AgentComponent ? (
      <div className="rounded-lg shadow-[0_0_0_1px_hsla(var(--primary)/0.2),0_0.125rem_1rem_-0.25rem_hsla(var(--primary)_/_0.4)]">
        <AgentComponent opportunity={opportunity} refetch={refetch} />
      </div>
    ) : null;
  }
}

const ActionCard = ({ children, title }: { children?: React.ReactNode; title: React.ReactNode }) => (
  <div className="rounded-lg shadow-[0_0_0_1px_hsla(var(--primary)/0.2),0_0.125rem_1rem_-0.25rem_hsla(var(--primary)_/_0.4)]">
    <Card>
      <CardHeader>
        <CardTitle className="filled flex gap-3 items-center text-primary">{title}</CardTitle>
      </CardHeader>
      {children}
    </Card>
  </div>
);
