import { sortBy } from "lodash";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Panel, PanelTitle } from "@/components/ui/panel";
import { Sheet, SheetTrigger } from "@/components/ui/sheet";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import {
  AgentActionType,
  OpportunityRequirementsQuery,
  SupplementalForm,
  useCompleteAgentActionMutation,
  useDeleteAgentActionMutation,
  useOpportunityRequirementsQuery,
} from "../../../generated/graphql";
import { CardDescription } from "../../components/ui/card";
import { AddRequirements } from "./add-requirements";

export const Requirements: React.FC = () => {
  const { opportunityId } = useParams();
  const { toast } = useToast();
  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: {
      id: opportunityId!,
    },
    pollInterval: 5000,
  });

  const [triggerCompleteAgentAction] = useCompleteAgentActionMutation({
    onCompleted() {
      toast({ title: "Agent Action marked complete" });
      return refetch();
    },
    onError(e) {
      toast({ title: `Error marking agent action complete: ${e}` });
    },
  });
  const [triggerDeleteAgentAction] = useDeleteAgentActionMutation({
    onCompleted() {
      toast({ title: "Agent Action deleted" });
      return refetch();
    },
    onError(e) {
      toast({ title: `Error deleting agent action: ${e}` });
    },
  });

  const completeAgentAction = async (id: string) => {
    return triggerCompleteAgentAction({ variables: { input: { id } } });
  };

  const deleteAgentAction = async (id: string) => {
    return triggerDeleteAgentAction({ variables: { id } });
  };

  if (!loading && !opportunity) {
    return null;
  }

  const taskCopyString = getTaskCopyString(opportunity);

  return (
    <Panel>
      <PanelTitle>
        Add Additional Requirements
        <HasInternalRole>
          <Button
            variant="ghost"
            size="icon-sm"
            onClick={() => {
              void navigator.clipboard.writeText(taskCopyString);
              toast({ title: "Copied Email Template to Clipboard!" });
            }}
            className="ml-auto"
          >
            <Icon icon="copy_all" />
          </Button>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon-sm">
                <Icon icon="add" />
              </Button>
            </SheetTrigger>
            {opportunity && <AddRequirements opportunityId={opportunity.id} />}
          </Sheet>
        </HasInternalRole>
      </PanelTitle>
      <CardDescription className="text-sm">
        Add any additional requirements needed from the agent. Information request emails will automatically send after
        completing this step, you will be notified when complete.
      </CardDescription>
      {loading ? (
        <Loading />
      ) : opportunity?.agentActions[0] ? (
        <ul className="space-y-2">
          {/* Using order of the array for the key. Good enough for now */}
          {sortBy(opportunity?.agentActions, [(action) => !action.expeditedRequirement, "title"]).map((action, i) => (
            <li key={i}>
              <AgentAction
                action={action}
                opportunity={opportunity}
                clientId={opportunity.insured.id}
                onCompleteAgentAction={completeAgentAction}
                onDeleteAgentAction={deleteAgentAction}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </Panel>
  );
};

const AgentAction = ({
  action,
  opportunity,
  clientId,
  onCompleteAgentAction,
  onDeleteAgentAction,
}: {
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunity: NonNullable<OpportunityRequirementsQuery["opportunity"]>;
  clientId: string;
  onCompleteAgentAction: (id: string) => void;
  onDeleteAgentAction: (id: string) => void;
}) => {
  return (
    <AgentActionWrapper action={action} opportunityId={opportunity.id} clientId={clientId}>
      <div className="filled flex items-center justify-between w-full">
        <div className="flex gap-3 items-center">
          <Icon
            icon={
              action.complete
                ? "check_circle"
                : opportunity.filesProcessing && action.actionType === AgentActionType.UploadFile
                ? "cached"
                : "circle"
            }
            className={cn("", {
              "text-green-600": action.complete,
              "filled opacity-10": !action.complete,
              "animate-spin":
                opportunity.filesProcessing && !action.complete && action.actionType === AgentActionType.UploadFile,
            })}
          />
          <span className="text-xs">{action.title}</span>
        </div>
        {!action.complete && (
          <HasInternalRole>
            <div className="flex justify-end">
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  return onDeleteAgentAction(action.id);
                }}
              >
                <Icon icon="block" className="text-destructive" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  return onCompleteAgentAction(action.id);
                }}
              >
                <Icon icon="check" className="text-success" />
              </Button>
            </div>
          </HasInternalRole>
        )}
      </div>
    </AgentActionWrapper>
  );
};

const AgentActionWrapper = ({
  action,
  opportunityId,
  clientId,
  children,
}: React.PropsWithChildren<{
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunityId: string;
  clientId: string;
}>) => {
  if (action.supplementalForm) {
    const link = (() => {
      switch (action.supplementalForm) {
        case SupplementalForm.Daycare:
          return "/ategrity-daycare-supplemental.pdf";
        case SupplementalForm.ResidentialLro:
          return "/Please fill out if vacant - GenStar - LRO Supplemental.pdf";
        default:
          return `/supplementals/${clientId}?form=${action.supplementalForm}&opportunityId=${opportunityId}`;
      }
    })();

    return (
      <Link to={link} target="_blank" className="w-full">
        {children}
      </Link>
    );
  }

  return <div className="w-full">{children}</div>;
};

const getTaskCopyString = (opportunity: OpportunityRequirementsQuery["opportunity"]) => {
  return `Please see below for the outstanding items we need for your submissions! Documents can be uploaded here: ${window.location.toString()}.

Your Applications:
${opportunity?.agentActions.map((t) => t.title).join("\n")}

You can also provide any requested documents by replying to this email with attachments.`;
};
