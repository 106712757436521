import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function GenericAgentWaiting({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Thanks for sending the requested information!</CardTitle>
        <CardDescription>No further action required right now.</CardDescription>
      </CardHeader>
      <CardContent>
        <p>
          Your broker, {opportunity.broker?.firstName}, has been assigned and is working on getting quotes! We will
          follow up with additional questions and updates as we have them!
        </p>
      </CardContent>
    </Card>
  );
}
