import { isDefined } from "@cp/toolkit";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useMyAccount } from "@/auth/useMyAccount";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { submissionStatusKeys, SUBMISSION_STATUS_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { SubmissionStatus, UpdateSubmissionInput, useUpdateSubmissionMutation } from "../../../../generated/graphql";
import { FieldContainer, Input, TextArea } from "../../../forms/components/FormFields";
import { Checkbox } from "../../../forms/inputs/Checkbox";
import { reloadOpportunity } from "../../loaders";
import { MarketingEffortSubmission } from "./marketing-efforts";
import { FieldWatcher } from "./submission-drawer";

const DeclinationFormSchema = z.object({
  declinedDueToState: z.boolean().optional(),
  declinedDueToNaics: z.boolean().optional(),
  declinationReason: z.string().optional(),
});

const DollarFieldSchema = z
  .string()
  .trim()
  .transform((v) => v.replaceAll(/[ $,]/g, ""))
  .transform((v) => (v === "" ? undefined : v));
const PremiumFormSchema = z.object({
  quotePremium: DollarFieldSchema,
});

const BindHQSchema = z.object({
  bindHqUrl: z.string().optional(),
});

const EffectiveExpirationDatesSchema = z.object({
  effectiveDate: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val || undefined),
  expirationDate: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val || undefined),
});

const CancellationDateSchema = z.object({
  cancellationDate: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val || undefined),
});

export const SubmissionStatusDropdown: React.FC<{
  submission: MarketingEffortSubmission;
}> = ({ submission }) => {
  const { data: user } = useMyAccount();
  const [updateSubmission] = useUpdateSubmissionMutation({
    ...reloadOpportunity,
  });

  const { openForm } = useModal();

  const handleStatusChange = async (status: SubmissionStatus) => {
    let input: UpdateSubmissionInput = {
      id: submission.id,
      status,
    };

    if (status === SubmissionStatus.ClosedDeclined) {
      const result = await openForm(
        DeclinationFormSchema,
        <div className="space-y-2">
          <h3>Declination Reason</h3>
          <Checkbox name="declinedDueToState">Declined in part or wholly due to State</Checkbox>

          <Checkbox name="declinedDueToNaics">Declined in part due or wholly to NAICS</Checkbox>

          <FieldContainer name="declinationReason" label="Reasons beyond the above">
            <TextArea name="declinationReason" />
          </FieldContainer>
          <div className="flex justify-between">
            <Button type="reset" variant="destructive">
              Cancel
            </Button>
            <Button type="submit">Continue</Button>
          </div>
        </div>
      );
      input = { ...input, ...result };
    }

    if (PROMPT_FOR_QUOTE_PREMIUM.has(status) && !isDefined(submission.quotePremium)) {
      const result = await openForm(
        PremiumFormSchema,
        <div className="space-y-2">
          <FieldContainer label="Quote Premium" name="quotePremium">
            <Input name="quotePremium" />
          </FieldContainer>
          <div className="flex justify-between">
            <Button type="reset" variant="destructive">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </div>
      );
      input = {
        ...input,
        ...result,
      };
    }

    if (PROMPT_FOR_BIND_HQ_LINK.has(status) && !submission.bindHqUrl) {
      const result = await openForm(
        BindHQSchema,
        <div className="space-y-2">
          <FieldContainer label="Bind HQ URL" name="bindHqUrl">
            <Input type="url" name="bindHqUrl" />
          </FieldContainer>
          <div className="flex justify-between">
            <Button type="reset" variant="destructive">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </div>
      );
      input = {
        ...input,
        ...result,
      };
    }
    if (
      PROMPT_FOR_EFFECTIVE_AND_EXPIRATION_DATES.has(status) &&
      (!submission.effectiveDate || !submission.expirationDate)
    ) {
      const result = await openForm(
        EffectiveExpirationDatesSchema,
        <FieldWatcher>
          <div className="space-y-2">
            <FieldContainer
              className="flex flex-row items-center justify-between"
              label="Effective Date"
              name="effectiveDate"
            >
              <ControlledDatePicker name="effectiveDate" />
            </FieldContainer>
            <FieldContainer
              className="flex flex-row items-center justify-between"
              label="Expiration Date"
              name="expirationDate"
            >
              <ControlledDatePicker name="expirationDate" />
            </FieldContainer>
            <div className="flex justify-between">
              <Button type="reset" variant="destructive">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </div>
        </FieldWatcher>,
        {
          defaultValues: {
            effectiveDate: submission.effectiveDate,
            expirationDate: submission.expirationDate,
          },
        }
      );
      input = {
        ...input,
        ...result,
      };
    }

    if (status === SubmissionStatus.IssuedPolicyCancelled && !submission.cancellationDate) {
      const result = await openForm(
        CancellationDateSchema,
        <div className="space-y-2">
          <FieldContainer
            className="flex items-center justify-between"
            label="Cancellation Date"
            name="cancellationDate"
          >
            <ControlledDatePicker name="cancellationDate" />
          </FieldContainer>
          <div className="flex justify-between">
            <Button type="reset" variant="destructive">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </div>,
        {
          defaultValues: {
            cancellationDate: submission.cancellationDate,
          },
        }
      );
      input = {
        ...input,
        ...result,
      };
    }

    await updateSubmission({
      variables: {
        input,
      },
    });
  };

  const color = submission.status && SUBMISSION_STATUS_METADATA[submission.status].color;
  const agentColor = !user?.internal && submission.status && SUBMISSION_STATUS_METADATA[submission.status].color;
  const [group, description] = submission.status
    ? SUBMISSION_STATUS_METADATA[submission.status].label.split(" - ")
    : ["-", "-"];

  const MenuGroup = ({ label, keys }: { label: string; keys: SubmissionStatus[] }) => (
    <DropdownMenuSub key={label}>
      <DropdownMenuSubTrigger className="gap-3 text-muted-foreground">
        <Icon icon="circle" className={cn("filled invisible text-[0.5em]", group === label && "visible", color)} />
        <span className={cn(group === label && "font-semibold text-foreground")}>{label}</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {keys.map((key) => {
            const [, description] = SUBMISSION_STATUS_METADATA[key].label.split(" - ");
            return (
              <DropdownMenuCheckboxItem
                key={key}
                checked={submission.status === key}
                onCheckedChange={() => handleStatusChange(key)}
                className={cn("text-muted-foreground", submission.status === key && "font-semibold text-foreground")}
              >
                {description}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className={cn("border-0 flex-auto h-8 justify-between px-3 truncate", color)}>
          <div className={cn("leading-relaxed text-left text-xs truncate", agentColor)}>{description}</div>
          <Icon icon="unfold_more" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-full">
        {statusMenu.map((group) => (
          <MenuGroup key={group.label} label={group.label} keys={group.keys} />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const groups = ["Marketing", "Quoting", "Quoted", "Binding", "Bound", "Issuing", "Issued", "Closed"];

const statusMenu = groups.map((group) => ({
  label: group,
  keys: submissionStatusKeys.filter((key) => key.startsWith(group)),
}));

const ControlledDatePicker = ({ name }: { name: string }) => {
  const { watch, setValue } = useFormContext();

  return (
    <DatePicker
      placeholder="Select Date"
      selected={watch(name)}
      onSelect={(e) => setValue(name, e?.toISOString())}
      dateFormat="MMMM d, yyyy"
      className="w-fit"
    />
  );
};

const PROMPT_FOR_QUOTE_PREMIUM = new Set([
  SubmissionStatus.QuotingIndication,
  SubmissionStatus.QuotingReferred,
  SubmissionStatus.QuotingBrokerActionRequired,
  SubmissionStatus.QuotedSentForProcessing,
  SubmissionStatus.QuotedSentToAgent,
  SubmissionStatus.QuotedDidNotPresentToAgent,
  SubmissionStatus.BindingBindRequestReceivedFromAgent,
  SubmissionStatus.BindingBindRequestSentToCarrier,
  SubmissionStatus.BindingBrokerActionRequired,
  SubmissionStatus.BindingSentForProcessing,
  SubmissionStatus.BoundAwaitingSubjectivities,
  SubmissionStatus.BoundAwaitingPolicyFromCarrier,
  SubmissionStatus.IssuingReadyToSendToBpo,
  SubmissionStatus.IssuingPolicySentToBpo,
  SubmissionStatus.IssuingPolicyNeedsToBeStamped,
  SubmissionStatus.IssuedAwaitingSubjectivities,
  SubmissionStatus.IssuedFileComplete,
  SubmissionStatus.IssuedEndorsementRequestedByAgent,
  SubmissionStatus.IssuedEndorsementRequestSentToCarrier,
]);

const PROMPT_FOR_BIND_HQ_LINK = new Set([
  SubmissionStatus.BoundAwaitingSubjectivities,
  SubmissionStatus.BoundAwaitingPolicyFromCarrier,
  SubmissionStatus.IssuingReadyToSendToBpo,
  SubmissionStatus.IssuingPolicySentToBpo,
  SubmissionStatus.IssuingPolicyNeedsToBeStamped,
  SubmissionStatus.IssuedAwaitingSubjectivities,
  SubmissionStatus.IssuedFileComplete,
  SubmissionStatus.IssuedEndorsementRequestedByAgent,
  SubmissionStatus.IssuedEndorsementRequestSentToCarrier,
]);

const PROMPT_FOR_EFFECTIVE_AND_EXPIRATION_DATES = new Set([
  SubmissionStatus.BoundAwaitingSubjectivities,
  SubmissionStatus.BoundAwaitingPolicyFromCarrier,
  SubmissionStatus.IssuingReadyToSendToBpo,
  SubmissionStatus.IssuingPolicySentToBpo,
  SubmissionStatus.IssuingPolicyNeedsToBeStamped,
  SubmissionStatus.IssuedAwaitingSubjectivities,
  SubmissionStatus.IssuedFileComplete,
  SubmissionStatus.IssuedEndorsementRequestedByAgent,
  SubmissionStatus.IssuedEndorsementRequestSentToCarrier,
  SubmissionStatus.IssuedPolicyCancelled,
]);
