import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useFileJobsQuery } from "../../../generated/graphql";
import { Loading } from "../../components/ui/loading";

const FileJobs = ({ fileId }: { fileId: string | undefined }) => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const { loading, error, data } = useFileJobsQuery({
    variables: {
      input: {
        fileId: fileId!,
      },
    },
    pollInterval: 5000,
  });

  if (loading || error) {
    return null;
  }

  const isAnyJobRunning = data?.fileJobs.some((job) => job.status === "running");

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          If any jobs are pending you can wait a few minutes for them to complete or ignore and complete the form
          manually.
        </CardTitle>
      </CardHeader>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="flex items-center gap-2">
          File Jobs ({data?.fileJobs.filter((j) => j.status !== "completed").length} jobs still pending)
          {isAnyJobRunning && <Loading label="Some jobs are still running" />}
        </CardTitle>
        <CardDescription>
          <div>{data?.fileJobs.filter((j) => j.status === "completed").length} jobs completed</div>
          <div>{data?.fileJobs.filter((j) => j.status === "error").length} jobs failed</div>
          <div>{data?.fileJobs.filter((j) => j.status === "running").length} jobs running</div>
        </CardDescription>
        <Button variant="ghost" size="sm" onClick={() => setIsTableVisible(!isTableVisible)}>
          {isTableVisible ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </Button>
      </CardHeader>
      <CardContent>
        {isTableVisible && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Id</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Start Time </TableHead>
                <TableHead>Pipeline</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.fileJobs.map((job) => (
                <TableRow key={job.id}>
                  <TableCell>{job.id}</TableCell>
                  <TableCell>{job.status}</TableCell>
                  <TableCell>{formatDistanceToNow(parseISO(job.createdAt), { addSuffix: true })}</TableCell>
                  <TableCell>{job.pipeline}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default FileJobs;
