import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { NotificationFragment, useMarkNotificationsReadMutation } from "../../generated/graphql";
import { NotificationRenderer } from "./notification-renderer";

export const InboxListItem: React.FC<{
  notification: NotificationFragment;
}> = ({ notification }) => {
  const { readAt, event } = notification;
  const [mutate] = useMarkNotificationsReadMutation();

  const toggleRead = ({ notification }: { notification: NotificationFragment }) => {
    const { id, readAt } = notification;

    void mutate({
      variables: {
        input: {
          ids: [id],
          read: !!readAt,
        },
      },
      refetchQueries: ["Notifications"],
    });
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger
        className={cn("flex gap-2 max-w-full text-[11px] text-inherit transition-all", readAt && "opacity-50")}
      >
        <Icon
          icon={readAt ? "check" : "circle"}
          className={cn(
            "block filled flex-none pt-1 text-center text-[7px] w-4",
            readAt ? "text-current" : "text-primary"
          )}
        />
        <NotificationRenderer notification={notification} />
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuLabel className="text-xs">{event.opportunity.insured.name}</ContextMenuLabel>
        <ContextMenuSeparator />
        <ContextMenuItem onClick={() => toggleRead({ notification })}>
          Mark as {readAt ? "Unread" : "Read"}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};
