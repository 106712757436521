import { TdInput, TdNumber } from "../components/td-fields";

export const Address: React.FC<{ header: string; rootPath: string }> = ({ header, rootPath }) => (
  <table>
    <thead>
      <tr>
        <td colSpan={4} className="big">
          {header}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <TdInput colSpan={4} label="Street Address Line 1" name={`${rootPath}Address:address1`} />
      </tr>
      <tr>
        <TdInput colSpan={4} label="Street Address Line 2" name={`${rootPath}Address:address2`} />
      </tr>
      <tr>
        <TdInput colSpan={2} label="City" name={`${rootPath}Address:city`} />
        <TdInput label="State" name={`${rootPath}Address:state`} minLength={2} maxLength={2} placeholder="TX" />
        <TdNumber label="Zip Code" name={`${rootPath}Address:zip`} minLength={4} maxLength={5} placeholder="78701" />
      </tr>
    </tbody>
  </table>
);
