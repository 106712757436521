import { RouteObject } from "react-router";
import { ErrorPage } from "../../error";
import RequirementForm from "./requirement-form";
import RequirementsIndex from "./requirements-index";
import { RequirementsRoot } from "./requirements-root";

export const requirementsRoutes = (): RouteObject[] => [
  {
    path: "requirements",
    element: <RequirementsRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <RequirementsIndex />,
      },
      {
        path: ":requirementId",
        element: <RequirementForm />,
      },
    ],
  },
];
