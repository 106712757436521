import { Outlet, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { oppListAtom } from "@/atoms";
import { HasInternalRole } from "@/components/has-role";
import { cn } from "@/utils";
import { useAtom } from "jotai";
import { HubList } from "./hub-list";
import { HubPresets } from "./hub-presets";

const transitionClasses = "lg:transition-all lg:duration-500 lg:ease-in-out-quart";

export const HubRoot: React.FC = () => {
  const [oppList] = useAtom(oppListAtom);
  useDocumentTitle("Opportunities: QuoteWell");
  const { opportunityId } = useParams();

  return (
    <>
      <HasInternalRole>
        <div
          className={cn(
            "flex flex-none w-56 z-0",
            // hide if opp selected at wide width
            opportunityId && "hidden lg:opacity-0 lg:flex lg:-mr-56",
            transitionClasses
          )}
        >
          <HubPresets />
        </div>
      </HasInternalRole>
      <div
        className={cn(
          "flex flex-auto relative lg:w-96 z-1",
          // full width if no opp selected
          !opportunityId && "ml-1",
          // hide if opp selected at narrow width but not wide width:
          opportunityId && "hidden lg:flex",
          opportunityId && !oppList && "lg:-ml-[24.25rem] lg:opacity-0",
          transitionClasses
        )}
      >
        <HubList />
      </div>
      <div className={cn("flex flex-none w-0 z-[2]", opportunityId && "flex-auto w-full", transitionClasses)}>
        <Outlet />
      </div>
    </>
  );
};
