import { useMyAccount } from "@/auth/useMyAccount";
import { OpportunityListItem } from "@/components/opportunity-list-item";
import { Bar } from "@/components/ui/bar";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { assertNever } from "@cp/toolkit";
import { groupBy } from "lodash";
import {
  ExpeditedOpportunityStatus,
  OpportunityListItemFragment,
  OpportunityStatus,
  usePaginatedOpportunityListQuery,
  UserAccountFragment,
} from "../../generated/graphql";

export const getActionsListArgs = (user?: UserAccountFragment) => {
  return {
    isExpedited: true,
    brokerId: user?.id,
    statuses: [OpportunityStatus.Active],
    expeditedStatuses,
  };
};

export const ActionsList: React.FC = () => {
  const { data: user } = useMyAccount();

  const { data: opportunityData } = usePaginatedOpportunityListQuery({
    variables: {
      pagination: {},
      args: getActionsListArgs(user),
    },
    pollInterval: 1000,
  });

  if (!user?.id) {
    return null;
  }

  const byStatus = groupBy(opportunityData?.paginatedOpportunities.opportunities, (opp) => opp.expeditedStatus);
  const actions = Object.entries(byStatus);

  return (
    <PaneGroup>
      <Bar as="header">Pending Actions</Bar>
      <Pane layout="list">
        {actions.length === 0 ? (
          <div className="p-4 text-center text-muted-foreground text-xs">No pending actions.</div>
        ) : (
          actions.map(([key, os]) => (
            <CollapsibleList key={key} defaultOpen={true}>
              <CollapsibleListTrigger count={os.length}>{key}</CollapsibleListTrigger>
              <CollapsibleListContent>
                <Actions opportunities={os} />
              </CollapsibleListContent>
            </CollapsibleList>
          ))
        )}
      </Pane>
    </PaneGroup>
  );
};

export const Actions = ({ opportunities }: { opportunities: OpportunityListItemFragment[] }) => {
  return (
    <>
      {opportunities.map((opportunity) => (
        <OpportunityListItem key={opportunity.id} opportunity={opportunity} path="/actions/" />
      ))}
    </>
  );
};

// Enforce all new statuses to be categorized as either included or excluded in this page
const includeStatus = (status: ExpeditedOpportunityStatus): boolean => {
  switch (status) {
    case ExpeditedOpportunityStatus.AddAdditionalBrokerageMarkets:
    case ExpeditedOpportunityStatus.ConfirmAdditionalBrokerageMarkets:
    case ExpeditedOpportunityStatus.ReviewFinalMarketingPlan:
    case ExpeditedOpportunityStatus.ReviewHazards:
    case ExpeditedOpportunityStatus.ReviewLocations:
    case ExpeditedOpportunityStatus.WaitingOnUnderwriting:
    case ExpeditedOpportunityStatus.WaitingOnBrokerageReview:
    case ExpeditedOpportunityStatus.WaitingOnInitialBrokerReview:
    case ExpeditedOpportunityStatus.WaitingOnPropertiesReview:
    case ExpeditedOpportunityStatus.WaitingOnQuoteReview:
    case ExpeditedOpportunityStatus.VerifyBrokerageMarketingPlan:
    case ExpeditedOpportunityStatus.VerifyBindingMarketingPlan:
    case ExpeditedOpportunityStatus.VerifyDetails:
      return true;

    case ExpeditedOpportunityStatus.AddingInformationRequests:
    case ExpeditedOpportunityStatus.AssigningBroker:
    case ExpeditedOpportunityStatus.Complete:
    case ExpeditedOpportunityStatus.Declined:
    case ExpeditedOpportunityStatus.NotApplicable:
    case ExpeditedOpportunityStatus.SendingQuoteToAgent:
    case ExpeditedOpportunityStatus.SubmittingToCarrier:
    case ExpeditedOpportunityStatus.WaitingForCoverages:
    case ExpeditedOpportunityStatus.WaitingForInitialRequirements:
    case ExpeditedOpportunityStatus.WaitingOnCarrier:
    case ExpeditedOpportunityStatus.WaitingOnSubmissionInformationFromAgent:
      return false;

    default:
      assertNever(status);
      return false;
  }
};
export const expeditedStatuses = Object.values(ExpeditedOpportunityStatus).filter(includeStatus);
