import { Bar } from "@/components/ui/bar";
import { Pane, PaneGroup } from "@/components/ui/pane";

export const InsuredIndex: React.FC = () => {
  return (
    <PaneGroup>
      <Bar as="header"></Bar>
      <Pane></Pane>
    </PaneGroup>
  );
};
