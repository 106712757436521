import { authDataAtom, useLogout } from "@cp/auth";
import { useAtom } from "jotai";
import { PropsWithChildren, useEffect } from "react";
import { NavLink as Link, NavLinkProps } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import { defaultSearchAtom } from "@/atoms";
import { cn } from "@/utils";
import {
  OnNotificationAddedDocument,
  OnNotificationAddedSubscription,
  OnNotificationAddedSubscriptionVariables,
  Roles,
  useNotificationsQuery,
  usePaginatedOpportunityListQuery,
} from "../../generated/graphql";
import { getActionsListArgs } from "../actions/actions-list";
import { useMyAccount } from "../auth/useMyAccount";
import { useIsLoggedIn } from "../auth/user";
import { HasExternalRole, HasInternalRole, HasRole } from "./has-role";
import { Search } from "./search";
import { Theme, useTheme } from "./theme-provider";
import { Avatar } from "./ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/dropdown-menu";
import { Icon, type IconString } from "./ui/icon";
import { QuoteWellLogo } from "./ui/quotewell-logo";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const Sidebar: React.FC = () => {
  const loggedIn = useIsLoggedIn();
  const [authData] = useAtom(authDataAtom);
  const [defaultSearch] = useAtom(defaultSearchAtom);
  const { mutate: logout } = useLogout();
  const { data: user } = useMyAccount();
  const { theme, setTheme } = useTheme();

  const { data: opportunityData } = usePaginatedOpportunityListQuery({
    variables: {
      pagination: {},
      args: getActionsListArgs(user),
    },
    pollInterval: 30_000,
  });

  const { data: { notifications = [] } = {}, subscribeToMore } = useNotificationsQuery({
    variables: {
      input: {
        showRead: false,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    return subscribeToMore<OnNotificationAddedSubscription, OnNotificationAddedSubscriptionVariables>({
      document: OnNotificationAddedDocument,
      variables: { id: user?.id ?? "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newNotification = subscriptionData.data.notificationAdded;

        return {
          notifications: [newNotification, ...prev.notifications],
        };
      },
    });
  }, []);

  if (!loggedIn || !user) {
    return null;
  }

  const actionCount = opportunityData?.paginatedOpportunities.numRecords ?? 0;

  return (
    <nav
      className={cn(
        "bg-background bottom-0 fixed flex flex-none justify-center left-0 overflow-auto shadow-xl z-30",
        "landscape:flex-col landscape:h-dvh landscape:w-16",
        "portrait:h-nav portrait:w-full"
      )}
      id="Sidebar"
    >
      <div className="aspect-square flex items-center justify-center">
        <QuoteWellLogo className={cn("h-7 md:h-9", theme === "dark" && "invert")} />
      </div>
      <div
        className={cn(
          "flex flex-auto items-center gap-0.5 md:gap-2",
          "landscape:flex-col landscape:justify-start",
          "portrait:justify-center"
        )}
      >
        <Search />
        <NavLink
          to="/new"
          icon="add_circle"
          label={user.internal ? "New Opportunity" : "New Marketing Plan"}
          className="filled"
        />
        <HasInternalRole>
          <HasRole roles={[Roles.Admin]}>
            <NavLink to={`/admin/dashboard`} icon="token" label="Admin" />
          </HasRole>
          <HasRole roles={[Roles.PreferredBroker]}>
            <NavLink
              to="/actions"
              icon={actionCount > 0 ? "local_fire_department" : "check_circle"}
              label="Pending Actions"
              className={actionCount > 0 ? "text-destructive" : "text-success"}
            />
          </HasRole>
          <NavLink to="/inbox" icon="mail" label="Inbox" unread={notifications.length} />
          <NavLink to={`/opportunity?${defaultSearch}`} icon="inbox" label="Opportunities" />
          <NavLink to="/book" icon="menu_book" label="Book" />
          <NavLink to="/supplementals" icon="description" label="Supplementals" />
        </HasInternalRole>
        <HasExternalRole>
          <NavLink to="/insureds" icon="home" label="Home" />
          <NavLink to="/opportunity" icon="inbox" label="Marketing Plans" />
          <NavLink to={`/book/${user.agencyId}`} icon="menu_book" label="Book" />
          <NavLink
            to="https://quotewell.notion.site/Help-Center-eedceb626b79430386d913e33f9e8428"
            icon="help"
            label="Help Center"
            target="_blank"
          />
          <NavLink
            to="mailto:support@quotewell.com?subject=QuoteWell+Help"
            icon="mail"
            label="Email QuoteWell Technical Support"
            target="_blank"
          />
        </HasExternalRole>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger className="aspect-square flex items-center justify-center">
          <Avatar user={user} className="max-md:text-xs" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="space-y-1">
          <HasInternalRole>
            <Tabs value={theme as string} onValueChange={(v) => setTheme(v as Theme)} className="w-full">
              <TabsList className="bg-muted gap-1 p-1 shadow-inner items-stretch w-full">
                <TabsTrigger value="light" className="flex-auto p-2">
                  <Icon icon="light_mode" />
                </TabsTrigger>
                <TabsTrigger value="dark" className="flex-auto p-2">
                  <Icon icon="dark_mode" />
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </HasInternalRole>
          <DropdownMenuItem
            className="gap-2"
            onClick={() => {
              if (authData) {
                logout(
                  { refreshToken: authData.token.refreshToken ?? authData.token.accessToken },
                  { onSuccess: () => window.location.replace("/") }
                );
              }
            }}
          >
            <Icon icon="logout" />
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
};

const NavLink: React.FC<
  PropsWithChildren<{ to: string; label: string; icon: IconString; unread?: number; className?: string } & NavLinkProps>
> = ({ to, icon, label, unread = 0, className, ...props }) => {
  const portrait = useMediaQuery("(orientation: portrait)");
  const { theme } = useTheme();

  return (
    <Tooltip delayDuration={167}>
      <TooltipTrigger>
        <Link
          className={({ isActive, isPending }: { isActive: boolean; isPending: boolean }) =>
            cn(
              buttonClassName,
              "group",
              {
                "text-primary filled": isActive,
                "text-green-600": isActive && theme === "beef",
                "text-muted-foreground hover:text-foreground": !isActive,
                "opacity-50": isPending,
              },
              className
            )
          }
          to={to}
          aria-label={label}
          {...props}
        >
          <Icon icon={icon} className={"group-aria-[current=page]:text-gradient-fuchs"} />
          {unread > 0 && (
            <div className="absolute bg-destructive gradient-fuchs h-1.5 items-center justify-center right-2.5 ring-2 ring-background rounded-full top-2.5 translate-x-1/3 w-1.5" />
          )}
        </Link>
      </TooltipTrigger>
      <TooltipContent
        side={portrait ? "top" : "right"}
        className={cn(
          "bg-foreground text-background flex font-semibold items-center h-8 pointer-events-none px-3 rounded-md shadow-none text-xs",
          "portrait:-mb-2"
        )}
      >
        {label}
      </TooltipContent>
    </Tooltip>
  );
};

export const buttonClassName = [
  "aspect-square cursor-pointer flex h-10 tems-center justify-center p-0 relative rounded text-lg md:text-xl transition-colors transition-opacity",
  "h-8 md:h-10",
];
