import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useUndeleteOpportunityMutation } from "../../../generated/graphql";
import { reloadOpportunity } from "../loaders";

export const UndeleteOpportunity: React.FC = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const { toast } = useToast();
  const [undeleteOpportunity] = useUndeleteOpportunityMutation();

  const handleDelete = async () => {
    await undeleteOpportunity({
      variables: {
        id: opportunityId!,
      },
      onCompleted: () => {
        toast({ title: "Opportunity Undeleted" });
        navigate(`/opportunity/${opportunityId}`);
      },
      onError: () => {
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
      ...reloadOpportunity,
    });
  };

  return (
    <div
      className="aspect-[16/9] bg-center bg-cover flex items-center justify-center m-4 max-w-sm p-8 rounded w-full"
      style={{
        backgroundImage:
          "url(https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcjlueWVybmhlYzlhZHR4M3R3dXFpcm5wbms0a3owcjIyYjF2ZjRvNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/g01ZnwAUvutuK8GIQn/giphy.gif)",
      }}
    >
      <Button variant="outline" className="backdrop-blur-md bg-zinc-500/10 shadow-xl text-white" onClick={handleDelete}>
        Undelete Opportunity
      </Button>
    </div>
  );
};
