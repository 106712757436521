import { orderBy } from "lodash";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Card } from "@/components/ui/card";
import { Icon, IconString } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Pane } from "@/components/ui/pane";
import {
  InsuredDeepFragment,
  OpportunityStatus,
  useAgencyInsuredsDeepQuery,
  useAgencyQuery,
} from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const InsuredsList = ({ agencyId }: { agencyId: string }) => {
  const { data: { agency } = {}, loading: loadingAgency } = useAgencyQuery({
    variables: {
      id: agencyId,
    },
    skip: !agencyId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { agencyInsureds = [] } = {}, loading: loadingInsureds } = useAgencyInsuredsDeepQuery({
    variables: {
      id: agencyId,
    },
    skip: !agencyId,
    fetchPolicy: "cache-and-network",
  });

  useDocumentTitle(agency ? `${agency.name}: QuoteWell` : "Insureds: QuoteWell");

  if (loadingAgency || loadingInsureds) {
    return (
      <Pane>
        <Loading />
      </Pane>
    );
  }

  const sortedInsureds = orderBy(
    agencyInsureds,
    (insured) => Math.max(...insured.opportunities.map((opp) => Date.parse(opp.updatedAt)), 0),
    "desc"
  );

  const infoGathering =
    sortedInsureds.filter((insured) => insured.opportunities.some((opp) => opp.broker === null && opp.isExpedited)) ??
    [];

  const activeInsureds =
    sortedInsureds.filter((insured) => insured.opportunities.some((opp) => opp.status === OpportunityStatus.Active)) ??
    [];

  const trackingInsureds =
    sortedInsureds.filter((insured) =>
      insured.opportunities.some((opp) => opp.status === OpportunityStatus.Tracking)
    ) ?? [];

  return (
    <Pane layout="list">
      <div className="px-4 py-6 space-y-6">
        {infoGathering[0] ? (
          <>
            <H3>
              <Badge icon="priority_high" color="amber" />
              {agency?.name} has {infoGathering.length} outstanding information request
              {infoGathering.length > 1 && "s"}.
            </H3>
            <div className="gap-4 grid sm:grid-cols-2 md:grid-cols-3 xl:md:grid-cols-4 2xl:md:grid-cols-5 4xl:md:grid-cols-6 items-start">
              {infoGathering.map((insured) => (
                <InsuredCard key={insured.id} insured={insured} />
              ))}
            </div>
          </>
        ) : (
          <H3>
            <Badge icon="sentiment_very_satisfied" color="green" />
            {agency?.name} has completed all information requests. Nice!
          </H3>
        )}
      </div>
      {activeInsureds[0] && (
        <div className="border-t px-4 py-6 space-y-6">
          <H3>
            <Badge icon="campaign" color="blue" />
            {agency?.name} has {activeInsureds.length} active Marketing Plans.
          </H3>
          <Card>
            <InsuredHeader />
            {activeInsureds.map((insured) => (
              <InsuredRow key={insured.id} insured={insured} />
            ))}
          </Card>
        </div>
      )}
      {trackingInsureds[0] && (
        <div className="border-t px-4 py-6 space-y-6">
          <H3>
            <Badge icon="visibility" color="amber" />
            {agency?.name} has {trackingInsureds.length} tracked Marketing Plans.
          </H3>
          <Card>
            <InsuredHeader />
            {trackingInsureds.map((insured) => (
              <InsuredRow key={insured.id} insured={insured} />
            ))}
          </Card>
        </div>
      )}
    </Pane>
  );
};

const Badge = ({ color, icon }: { color: string; icon: IconString }) => (
  <span
    className={`bg-background border-2 flex flex-none h-8 items-center justify-center leading-none rounded-full w-8`}
  >
    <Icon icon={icon} className={`filled text-lg text-${color}-600`} />
  </span>
);

const H3 = ({ children }: { children: React.ReactNode }) => (
  <h3 className="flex gap-3 items-center leading-tight text-base">{children}</h3>
);

const InsuredCard = ({ insured }: { insured: InsuredDeepFragment }) => (
  <div key={insured.id} className="flex flex-col">
    <header className="group bg-background border border-b-0 flex gap-1 items-center justify-between px-4 pt-3 pb-5 rounded-t-lg text-xs">
      <span className="font-semibold truncate">{insured.name}</span>
    </header>
    <div className="-mt-2">
      {insured.opportunities.map((opp) => (
        <Link
          key={opp.id}
          to={`/insured/${insured.id}/${opp.id}`}
          className="group bg-amber-50 border border-b-0 last:border-b border-amber-400 flex flex-col px-4 py-3 space-y-1.5 first:rounded-t-lg last:rounded-b-lg text-amber-800"
        >
          <div className="flex font-semibold gap-4 justify-between text-xs">
            <div className="space-y-1">
              {opp.selectedLinesOfBusiness.map((lob) => (
                <div key={lob}>{lob}</div>
              ))}
            </div>
            <Icon icon="arrow_forward" className="opacity-25 group-hover:opacity-100 transition-opacity" />
          </div>
          <div className="flex font-semibold gap-4 justify-between text-2xs">
            {opp.agent.firstName} {opp.agent.lastName}
          </div>
        </Link>
      ))}
    </div>
  </div>
);

const InsuredHeader = () => (
  <header className="border-b gap-4 grid grid-cols-7 font-semibold mb-px px-4 py-3 text-2xs text-muted-foreground">
    <div>Insured</div>
    <div>Lines</div>
    <div>Agent</div>
    <div>Broker</div>
    <div>Eff. Date</div>
    <div>Status</div>
    <div>Updated</div>
  </header>
);

const InsuredRow = ({ insured }: { insured: InsuredDeepFragment }) => (
  <div key={insured.id} className="even:bg-background odd:bg-accent border-t py-2 last:rounded-b-lg text-xs">
    {insured.opportunities.map((opp, key) => (
      <Link to={`/insured/${insured.id}/${opp.id}`} key={opp.id} className="gap-4 grid grid-cols-7 px-4 py-2">
        <div>{key === 0 && insured.name}</div>
        <div className="font-semibold space-y-1">
          {opp.selectedLinesOfBusiness[0] ? (
            opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
          ) : (
            <div>No lines of business</div>
          )}
        </div>
        <div>
          {opp.agent.firstName} {opp.agent.lastName}
        </div>
        <div>{opp.broker ? `${opp.broker?.firstName} ${opp.broker?.lastName}` : "Unassigned"}</div>
        <div>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, YYY")}</div>
        <div>{opp.status}</div>
        <div>{formatTimezoneDateString(opp.updatedAt, "MMM d, YYY")}</div>
      </Link>
    ))}
  </div>
);
