import { UUID } from "@cp/toolkit";
import * as Popover from "@radix-ui/react-popover";
import * as Select from "@radix-ui/react-select";
import { useAtom } from "jotai";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { oppInfoAtom, oppListAtom } from "@/atoms";
import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import { Badge } from "@/components/ui/badge";
import { Bar, BarLink } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { OpportunityPin } from "@/components/ui/opportunity-pin";
import { PopoverContent } from "@/components/ui/popover";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { statusKeys, STATUS_METADATA } from "@/metadata";
import { cn } from "@/utils";
import {
  OpportunityDetailsFragment,
  OpportunityStatus,
  useBrokerAccountsQuery,
  useUserAccountsQuery,
} from "../../../generated/graphql";
import { formatTimezoneDateString } from "../../../utils/date";
import { useUpdateOpportunity } from "../loaders";
import { ScheduledFollowUpCount } from "./actions/scheduled-follow-up-count";
import { OpportunityDropdown } from "./opportunity-dropdown";

export const OpportunityHeader = ({ opportunity, path }: { opportunity: OpportunityDetailsFragment; path: string }) => {
  const [oppInfo, setOppInfo] = useAtom(oppInfoAtom);
  const [oppList, setOppList] = useAtom(oppListAtom);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();

  const [updateOpportunity] = useUpdateOpportunity({
    onCompleted: () => {
      toast({ title: "Success", description: "Opportunity updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const { data: user } = useMyAccount();

  const { data: brokers } = useBrokerAccountsQuery({
    skip: !user?.internal,
    fetchPolicy: "cache-first",
  });

  const { data: agents } = useUserAccountsQuery({
    variables: { input: {} },
    fetchPolicy: "cache-first",
  });

  const now = new Date();
  const { id, agent, broker, insured, desiredEffectiveDate, isTest, renewal, renewalOf, status } = opportunity;

  const SelectStatus = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={status ?? undefined}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: { id, status: e as OpportunityStatus },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          {statusKeys(opportunity).map((key) => (
            <SelectItem key={key} value={key}>
              {STATUS_METADATA[key].label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  const SelectEffectiveDate = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Popover.Root>
        <Trigger popover={true}>{children}</Trigger>
        <PopoverContent className="p-0 w-auto">
          <Calendar
            mode="single"
            selected={desiredEffectiveDate}
            onSelect={(e) =>
              updateOpportunity({
                variables: {
                  input: { id, desiredEffectiveDate: e },
                },
              })
            }
            initialFocus
          />
        </PopoverContent>
      </Popover.Root>
    </HasInternalRole>
  );

  const SelectAgent = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={agent.id}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: {
                id,
                agentId: e as UUID,
              },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          {agents?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  const SelectBroker = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={broker ? broker.id : "-"}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: { id: id, brokerId: e === "-" ? null : (e as UUID) },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          <SelectItem value="-">Unassigned</SelectItem>
          {brokers?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  return (
    <Card className="rounded-none landscape:rounded-bl-lg landscape:ml-3 sticky top-0 z-20">
      <Bar className="gap-4">
        <Button variant="ghost" size="icon" onClick={() => navigate(`/${path}/?${searchParams.toString()}`)}>
          <Icon icon="arrow_back" />
        </Button>
        <div className="flex-auto gap-1 grid grid-flow-col auto-cols-fr h-16 items-center">
          <SelectStatus>
            <HeaderCell
              title={insured.name}
              subtitle={
                <span className={cn("flex gap-2 items-center", STATUS_METADATA[OpportunityStatus[status]].className)}>
                  <Icon icon={STATUS_METADATA[OpportunityStatus[status]].icon} className="text-3xs" />
                  {STATUS_METADATA[OpportunityStatus[status]].label}
                </span>
              }
            />
          </SelectStatus>
          <SelectEffectiveDate>
            <HeaderCell
              title={formatTimezoneDateString(desiredEffectiveDate, "MMMM d")}
              subtitle="Eff. Date"
              className={cn(
                !desiredEffectiveDate && "text-muted-foreground",
                now.toISOString() > desiredEffectiveDate && "text-destructive"
              )}
            />
          </SelectEffectiveDate>
          <SelectAgent>
            <HeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
          </SelectAgent>
          <SelectBroker>
            <HeaderCell
              title={broker ? `${broker.firstName} ${broker.lastName}` : "Unassigned"}
              subtitle="Broker"
              className={cn(broker === null && "text-destructive")}
            />
          </SelectBroker>
        </div>
        <div className="flex gap-1 items-center">
          {isTest ? <Badge>Test</Badge> : <OpportunityPin id={id} />}
          <OpportunityDropdown />
        </div>
      </Bar>
      <Bar className="border-t gap-4 shadow-none">
        <Button variant="ghost" size="icon" onClick={() => setOppList((oppList) => !oppList)} className="max-lg:hidden">
          <Icon icon={oppList ? "left_panel_close" : "left_panel_open"} />
        </Button>
        <nav className="flex gap-2 items-center">
          <BarLink to=".">Details</BarLink>
          <BarLink to="coverage">Coverage</BarLink>
          <HasInternalRole>
            <BarLink to="files">Files</BarLink>
            <BarLink to="history">History</BarLink>
            <BarLink to="notes">Notes</BarLink>
            <BarLink to="quotes">Quotes</BarLink>
            <BarLink to="policies">Policies</BarLink>
          </HasInternalRole>
          <BarLink to="data">Data</BarLink>
        </nav>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setOppInfo((oppInfo) => !oppInfo)}
          className="aspect-auto inline-flex gap-2 h-8 items-center ml-auto self-center w-auto px-2"
        >
          {(renewal || renewalOf) && <Icon icon="cycle" className="mr-0.5 text-amber-600 text-xs" />}
          <HasInternalRole>
            <ScheduledFollowUpCount />
          </HasInternalRole>
          <Icon icon={oppInfo ? "right_panel_close" : "right_panel_open"} />
        </Button>
      </Bar>
    </Card>
  );
};

const Trigger = ({ children, popover = false }: { children: React.ReactNode; popover?: boolean }) => {
  const Component = popover ? Popover.Trigger : Select.Trigger;

  return (
    <Component className="group bg-accent flex items-center justify-between outline-none rounded shadow-contrast">
      {children}
      <Icon
        icon="keyboard_arrow_down"
        className="hidden @2xl/opp:inline mr-3 opacity-50 group-hover:opacity-100 transition-opacity"
      />
    </Component>
  );
};

const HeaderCell = ({
  className,
  title,
  subtitle,
}: {
  className?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => (
  <div className={cn("flex flex-col gap-0.5 px-3 py-1.5 relative rounded text-left truncate", className)}>
    <h5 className="text-2xs @lg/opp:text-xs truncate">{title}</h5>
    <h6 className="text-muted-foreground truncate">{subtitle}</h6>
  </div>
);
