import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/utils";
import { formatTimezoneDateString, formatTimezoneDateStringDistance } from "../../../utils/date";

export const RelativeDate = ({ date, className }: { date: string; className?: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className={cn("inline-flex", className)}>{formatTimezoneDateStringDistance(date)} ago</span>
      </TooltipTrigger>
      <TooltipContent className="text-2xs">{formatTimezoneDateString(date, "MMMM d, yyyy, h:mm aa")}</TooltipContent>
    </Tooltip>
  );
};
