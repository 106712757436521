import { SearchParamSelect } from "@/components/ui/search-param-select";
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const SelectCreatedWithin = () => (
  <SearchParamSelect name="dateRange">
    <SelectTrigger>
      <SelectValue placeholder="Select Date Range" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="">All</SelectItem>
      <SelectItem value="24">Last 24 hours</SelectItem>
      <SelectItem value="72">Last 3 days</SelectItem>
      <SelectItem value="168">Last 7 days</SelectItem>
      <SelectItem value="720">Last 30 days</SelectItem>
    </SelectContent>
  </SearchParamSelect>
);
