import { Maps } from "@cp/toolkit";
import { useEffect, useState } from "react";

import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { SearchInput } from "@/components/ui/input";
import { useWatch } from "react-hook-form";
import { ScoredAppetiteProductFragment } from "../../../generated/graphql";
import { AppetiteNotes } from "./appetite-notes";
import { FormSchemaType } from "./markets";
import { ProductChecklist } from "./product-list";

export const MarketsList: React.FC<{
  scoredProducts: ScoredAppetiteProductFragment[];
  requestedLines: string[];
  directAppointments: string[];
}> = ({ scoredProducts, requestedLines, directAppointments }) => {
  const [searchResults, setSearchResults] = useState<ScoredAppetiteProductFragment[]>([]);
  const [marketsTerm, setMarketsTerm] = useState("");
  const { productIds: selectedProductIds } = useWatch<FormSchemaType>();
  // Group by requested lines
  const productGroups = getProductGroups(scoredProducts)
    .map((grp) => {
      return {
        ...grp,
        isDirectAppointment: directAppointments.includes(grp.carrierSlug),
        products: grp.products.filter((p) => {
          return searchResults?.find((result) => result.product.id === p.product.id);
        }),
      };
    })
    .sort((a, b) => b.highestScore - a.highestScore);

  useEffect(() => {
    const results = scoredProducts.filter((p) => {
      return JSON.stringify({ name: p.product.carrierProductName, carrier: p.product.carrierName })
        .toLowerCase()
        .includes(marketsTerm);
    });
    setSearchResults(results);
  }, [marketsTerm, scoredProducts]);

  const filteredGroups = productGroups.filter((group) => group.products.length > 0);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="flex gap-4 items-center justify-between">
            Select Markets
            <SearchInput
              name="search"
              placeholder="Search Markets"
              value={marketsTerm}
              onChange={(event) => setMarketsTerm(event.currentTarget.value)}
            />
          </CardTitle>
        </CardHeader>
      </Card>
      <AppetiteNotes />
      {filteredGroups.map((group) => (
        <ProductChecklist
          key={group.carrierName}
          label={group.carrierName}
          products={group.products}
          selectedProductIds={selectedProductIds}
          requestedLines={requestedLines}
          isDirectAppointment={group.isDirectAppointment}
        />
      ))}
      {marketsTerm && !filteredGroups[0] && (
        <div>
          No products matched <strong>{marketsTerm}</strong>.
        </div>
      )}
    </>
  );
};

interface ProductGroup {
  highestScore: number;
  carrierName: string;
  carrierSlug: string;
  products: ScoredAppetiteProductFragment[];
}

const getProductGroups = (products: ScoredAppetiteProductFragment[]): ProductGroup[] => {
  const productsByLines = Maps.multiMap(products, ({ product }) => product.carrierName);

  return [...productsByLines.entries()].map(
    ([carrierName, products]): ProductGroup => ({
      carrierName,
      carrierSlug: products[0]?.product.carrierSlug ?? "",
      products,
      highestScore: products.reduce((currentMax, p) => Math.max(currentMax, p.score), 0),
    })
  );
};
