import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useExpeditedOpportunityTransitionMutation } from "../../../../generated/graphql";
import { Button } from "../../../components/ui/button";
import { Loading } from "../../../components/ui/loading";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function ConfirmAdditionalBrokerageMarkets({ opportunity, refetch }: ExpeditedOpportunityStatusViewProps) {
  const [transition, { loading: loadingTransition }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        opportunityId: opportunity.id,
      },
    },
    onCompleted: () => refetch(),
  });

  const transitionNext = (action: string) =>
    transition({
      variables: {
        input: {
          opportunityId: opportunity.id,
          action,
        },
      },
    });

  if (loadingTransition) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Add Any Brokerage Markets?</CardTitle>
        <CardDescription>Would you like to add any additional brokerage markets?</CardDescription>
      </CardHeader>
      <CardContent className="flex justify-evenly">
        <Button onClick={() => transitionNext("AddMarkets")}>Add Brokerage Markets</Button>
        <Button onClick={() => transitionNext("ReviewFinalMarketingPlan")}>Review Marketing Plan</Button>
      </CardContent>
    </Card>
  );
}
