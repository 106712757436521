import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { ScheduleOfHazards } from "@/opportunity/components/schedule-of-hazards";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { useExpeditedOpportunityTransitionMutation } from "../../../../generated/graphql";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function ReviewHazards({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const [transition, { loading: transitionLoading }] = useExpeditedOpportunityTransitionMutation();

  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle>Review Hazards</CardTitle>
        <CardDescription>Review hazards from Acord 126 or add more</CardDescription>
      </CardHeader>
      <ScheduleOfHazards
        opportunityId={opportunity.id}
        clientDataKey=""
        onSubmissionRequirementsMet={() => {}}
        onSubmissionRequirementsNotMet={() => {}}
      />
      <CardFooter className="border-t p-4">
        <Button
          size="sm"
          variant="outline"
          className="flex items-center justify-center gap-2"
          disabled={transitionLoading}
          onClick={() =>
            transition({
              variables: {
                input: {
                  opportunityId: opportunity.id,
                  action: "GoBack",
                },
              },
            })
          }
        >
          <Icon icon="arrow_back" />
          <span>Edit Locations</span>
        </Button>
        <Button
          size="sm"
          className="flex items-center justify-center gap-2 ml-auto"
          disabled={transitionLoading}
          onClick={() =>
            transition({
              variables: {
                input: {
                  opportunityId: opportunity.id,
                  action: "Reviewed",
                },
              },
            })
          }
        >
          <span>Continue</span>
          <Icon icon="arrow_forward" />
        </Button>
      </CardFooter>
    </Card>
  );
}

export function ReviewHazardsDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} confirmed the markets for this opportunity.
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
