import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Tooltip } from "@radix-ui/react-tooltip";
import { useExpeditedOpportunityTransitionMutation } from "../../../../generated/graphql";
import { EscapeHatchModalButton } from "../../../binding/binding-escape-modal/escape-modal";
import { AddRequirements } from "../../info/add-requirements";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export default function WaitingOnInitialBrokerReview({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const [transition, { loading }] = useExpeditedOpportunityTransitionMutation();

  const transitionToBinding = () =>
    transition({
      variables: {
        input: { opportunityId: opportunity.id, action: "ContinueToBinding" },
      },
    });

  const transitionToBrokerage = () =>
    transition({
      variables: {
        input: { opportunityId: opportunity.id, action: "ContinueToBrokerage" },
      },
    });

  const transitionToRequest = () =>
    transition({
      variables: {
        input: { opportunityId: opportunity.id, action: "Request" },
      },
    });

  const transitionToDeclined = () =>
    transition({
      variables: {
        input: { opportunityId: opportunity.id, action: "Decline" },
      },
    });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Review Expedited Opportunity</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-2 grid-cols-2">
        <EscapeHatchModalButton onComplete={transitionToBrokerage}>
          <Tooltip>
            <DialogTrigger asChild>
              <Button size="sm" variant="outline" disabled={loading}>
                Send To Brokerage
              </Button>
            </DialogTrigger>
          </Tooltip>
        </EscapeHatchModalButton>
        <Dialog>
          <DialogTrigger asChild>
            <Button size="sm" variant="outline" disabled={loading}>
              Request More Information
            </Button>
          </DialogTrigger>
          <DialogHeader className="hidden">
            <DialogTitle>Add Requirements</DialogTitle>
          </DialogHeader>
          <AddRequirements opportunityId={opportunity.id} onAdded={() => transitionToRequest()} />
        </Dialog>
        <Button size="sm" variant="destructive" onClick={() => transitionToDeclined()} disabled={loading}>
          Decline
        </Button>
        <Button size="sm" onClick={() => transitionToBinding()} disabled={loading}>
          Continue To Binding Markets
        </Button>
      </CardContent>
    </Card>
  );
}

export function WaitingOnInitialBrokerReviewDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} reviewed this opportunity.
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
