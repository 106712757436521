import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { PageContent } from "@/components/layout";
import { Bar } from "@/components/ui/bar";

export const AppetiteRoot: React.FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
  useDocumentTitle("Appetite: QuoteWell");
  return (
    <PageContent id="AppetiteRoot">
      <Bar as="header" position="sticky">
        <span className="font-semibold pl-1">Create a New Insured</span>
      </Bar>
      {children}
    </PageContent>
  );
};
