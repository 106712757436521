import { Outlet } from "react-router";

import { Loading } from "@/components/ui/loading";
import { useOpportunity } from "@/opportunity/loaders";
import { PlanHeader } from "./plan-header";
import { PlanInfo } from "./plan-info";

export const Plan: React.FC = () => {
  const { opportunity, loading } = useOpportunity();

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!opportunity) {
    return <div>error</div>;
  }

  return (
    <div className="@container/opp flex flex-auto flex-col relative">
      <PlanHeader opportunity={opportunity} />
      <div className="flex flex-auto items-stretch relative">
        <div className="flex-auto py-3 pr-3 space-y-3">
          <Outlet />
        </div>
        <PlanInfo />
      </div>
    </div>
  );
};
