import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useSet } from "react-use";
import {
  MarketTypes,
  SubmissionStatus,
  useExpeditedOpportunityTransitionMutation,
} from "../../../../generated/graphql";
import { Checkbox } from "../../../components/ui/checkbox";
import { Label } from "../../../components/ui/label";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function WaitingOnBrokerageReview({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const brokerageSubmissions = opportunity.submissions.filter((s) => {
    return s.appetiteProduct.marketType === MarketTypes.Brokerage;
  });

  const [fileIds, fileActions] = useSet();
  const { opportunityId } = useParams<"opportunityId">();

  const [transition] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        payload: JSON.stringify({ fileIds: [...fileIds] }),
        opportunityId: opportunityId!,
      },
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Thanks!</CardTitle>
        <CardDescription>
          Please review the markets and instructions below to send these submissions to carriers!
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {brokerageSubmissions.map((s) => (
          <Card key={s.id} className="text-sm">
            <CardHeader>
              <CardTitle>
                {s.status === SubmissionStatus.ClosedDeclined ? (
                  <span>
                    <span className="text-red-500">DECLINED</span> - {s.appetiteProduct.carrierName} -{" "}
                    {s.appetiteProduct.carrierProductName}
                  </span>
                ) : (
                  <span>
                    {s.appetiteProduct.carrierName} - {s.appetiteProduct.carrierProductName}
                  </span>
                )}
              </CardTitle>
            </CardHeader>
          </Card>
        ))}

        <div>
          <div>Select the files you want to send along to the carriers.</div>
        </div>
        {opportunity.files.map((f) => {
          return (
            <Label key={f.id} className="my-1 flex  justify-between">
              {f.filename}
              <Checkbox
                checked={fileIds.has(f.id)}
                onCheckedChange={(value) => {
                  if (value) {
                    fileActions.add(f.id);
                  } else {
                    fileActions.remove(f.id);
                  }
                }}
                aria-label="Select"
              />
            </Label>
          );
        })}
      </CardContent>
      <CardFooter>
        <Button size="sm" onClick={() => transition()}>
          All Set for Now. Send to Carriers
        </Button>
      </CardFooter>
    </Card>
  );
}
