import { Card } from "@/components/ui/card";
import { Outlet } from "react-router";

export function InsuredPolicies() {
  return (
    <div className="@container/opp flex flex-auto flex-col min-w-0" id="OpportunityRoot">
      <Card className="font-semibold rounded-none landscape:rounded-bl-lg p-4 sticky text-xl top-0 z-20">Policies</Card>
      <div className="p-3 pl-0">
        <Outlet />
      </div>
    </div>
  );
}
