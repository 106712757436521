import React, { useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { useToast } from "@/components/ui/use-toast";
import { useCreateClientDataMutation } from "../../../generated/graphql";

interface InsuredContext {
  insuredId?: string;
}
export const ClientDataContext = React.createContext<InsuredContext>({});

const noop = () => {
  console.warn("No clientId present for supplemental");
};
export const useSupplementalHandlers = (name: string, index?: number) => {
  const { insuredId } = useContext(ClientDataContext);
  const { toast } = useToast();
  const [upsertData] = useCreateClientDataMutation({
    onError: () => {
      toast({
        title: "Error",
        description: "An error occurred while saving. Your data may not be saved",
        variant: "destructive",
      });
    },
  });
  const methods = useFormContext();
  const focusValue = useRef<unknown>();

  if (!insuredId) {
    return { onFocus: noop, onBlur: noop };
  }

  const onFocus = () => {
    focusValue.current = methods.getValues()[name];
  };

  const onBlur = () => {
    const newValue = methods.getValues()[name];
    if (newValue !== focusValue.current) {
      void upsertData({
        variables: {
          input: {
            insuredId,
            clientDataInputs: [
              {
                index: index ?? undefined,
                key: name.replace(`--${index}`, ""),
                value: newValue,
              },
            ],
          },
        },
      });
    }
  };

  return { onFocus, onBlur };
};
