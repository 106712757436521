import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { QuoteFragment, QuoteStatePendingActions, useTransitionQuoteMutation } from "../../../generated/graphql";
import { uploadProcessedQuote, uploadRedactedQuote } from "../../../utils/file.utils";
import { QuoteFiles } from "./quote";
import { QuoteProposalForm } from "./quote-proposal-form";

const PendingQuote = ({ quote, refetch }: { quote: QuoteFragment; refetch?: () => void }) => {
  const { opportunityId } = useParams<"opportunityId">();
  const [submitting, setSubmitting] = useState(false);
  const [transition] = useTransitionQuoteMutation();

  const { id: quoteId, redactedQuoteId, processedQuoteId, id } = quote;

  return (
    <div className="space-y-3">
      {redactedQuoteId && <QuoteProposalForm quote={quote} />}

      {(redactedQuoteId || processedQuoteId) && <QuoteFiles quote={quote} />}

      {!redactedQuoteId && (
        <Button asChild variant="outline" size="sm">
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  if (!quote) {
                    toast({ title: "Error" });
                    setSubmitting(false);
                    return;
                  }
                  await uploadRedactedQuote(file, quoteId).then((res) => {
                    if (res.success) {
                      toast({ title: "Redacted Quote Uploaded" });
                      refetch?.();
                    } else {
                      toast({ title: "Error" });
                    }
                  });
                }
                setSubmitting(false);
              }}
            />
            {submitting ? (
              <Spinner />
            ) : (
              <>
                <Icon icon="upload" /> Redacted Quote
              </>
            )}
          </label>
        </Button>
      )}

      {!redactedQuoteId && (
        <Button asChild variant="outline" size="sm">
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  if (!quote) {
                    toast({ title: "Error" });
                    setSubmitting(false);
                    return;
                  }
                  if (!opportunityId) {
                    return;
                  }
                  await uploadProcessedQuote(file, quoteId).then((res) => {
                    if (res.success) {
                      void transition({
                        variables: {
                          input: {
                            quoteId: id,
                            action: QuoteStatePendingActions.Process,
                          },
                        },
                        onCompleted: () => {
                          toast({ title: "Processed Quote Uploaded" });
                        },
                      });
                    } else {
                      toast({ title: "Error" });
                    }
                  });
                }
                setSubmitting(false);
              }}
            />
            {submitting ? (
              <Spinner />
            ) : (
              <>
                <Icon icon="upload" /> Processed Quote
              </>
            )}
          </label>
        </Button>
      )}
    </div>
  );
};

export default PendingQuote;
