import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useToast } from "@/components/ui/use-toast";
import { submissionStatusKeys, SUBMISSION_STATUS_METADATA } from "@/metadata";
import { waitMs } from "@/utils";
import { Link, useParams } from "react-router-dom";
import {
  CarrierContactType,
  OpportunityDetailsDocument,
  SubmissionStatus,
  UpdateSubmissionInput,
  useUpdateAppetiteProductMutation,
  useUpdateSubmissionMutation,
} from "../../../../generated/graphql";
import { DatePicker } from "../../../components/ui/date-picker";
import { FieldContainer, Input, TextArea } from "../../../forms/components/FormFields";
import { Checkbox } from "../../../forms/inputs/Checkbox";
import { createControlledFormComponent } from "../../../forms/inputs/createBoundFormComponent";
import { Reform } from "../../../forms/Reform";
import { CarrierContactList } from "./CarrierContactList";
import { MarketingEffortSubmission } from "./marketing-efforts";

const DollarFieldSchema = z
  .string()
  .trim()
  .transform((v) => v.replaceAll(/[ $,]/g, ""))
  .transform((v) => (v === "" ? undefined : v));

const FormSchema = z.object({
  status: z.union([z.nativeEnum(SubmissionStatus), z.literal("-")]).optional(),
  declinedDueToState: z.boolean().optional(),
  declinedDueToNaics: z.boolean().optional(),
  declinationReason: z.string().optional(),
  quotePremium: DollarFieldSchema.optional(),
  bindHqUrl: z.string().optional(),
  effectiveDate: z.string().optional(),
  expirationDate: z.string().optional(),
  cancellationDate: z.string().optional(),
});

const AppetiteProductFormSchema = z.object({
  portalLink: z.string().url().optional().or(z.literal("")),
  submissionInstructions: z.string().optional(),
});

export const SubmissionDrawer: React.FC<{
  submission: MarketingEffortSubmission;
}> = ({ submission }) => {
  const { toast } = useToast();
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("edit");

  const [submitOpportunity] = useUpdateSubmissionMutation({
    refetchQueries: [OpportunityDetailsDocument],
    onCompleted: () => {
      void waitMs(333).then(() => setOpen(false));
    },
    onError: () => {
      toast({ title: "Error", description: "We're sorry, an error occurred.", variant: "destructive" });
    },
  });

  const [updateAppetiteProduct] = useUpdateAppetiteProductMutation({
    refetchQueries: [OpportunityDetailsDocument],
    onCompleted: () => {
      void waitMs(333).then(() => setEditMode(false));
    },
    onError: () => {
      toast({ title: "Error", description: "We're sorry, an error occurred.", variant: "destructive" });
    },
  });

  const submissionContacts = submission.appetiteProduct.submissionCarrierContacts ?? [];
  const referenceContacts = submission.appetiteProduct.referenceCarrierContacts ?? [];

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              setOpen(true);
              setTab("submit");
            }}
          >
            <Icon icon="account_circle" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="flex"
            onClick={() => {
              setOpen(true);
              setTab("edit");
            }}
          >
            <Icon icon="edit_note" />
          </Button>
        </>
      </SheetTrigger>
      <SheetContent className="overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{submission.appetiteProduct.carrierName}</SheetTitle>
          <SheetDescription>{submission.appetiteProduct.carrierProductName}</SheetDescription>
        </SheetHeader>
        <Tabs value={tab} onValueChange={(e) => setTab(e)}>
          <TabsList className="bg-muted gap-1 p-1 shadow-inner w-full">
            <TabsTrigger value="submit" className="flex-auto p-1 text-xs">
              Submit
            </TabsTrigger>
            <TabsTrigger value="edit" className="flex-auto p-1 text-xs">
              Edit
            </TabsTrigger>
          </TabsList>
          <TabsContent value="submit" className="py-4 space-y-4">
            {!editMode && (
              <div>
                <div className="flex justify-between">
                  <h5>Submission Instructions</h5>
                  <Button variant="ghost" size="icon" onClick={() => setEditMode(true)}>
                    <Icon icon="edit" />
                  </Button>
                </div>
                <p>{submission.appetiteProduct.submissionInstructions ?? "None listed"}</p>
                {submission.appetiteProduct.portalLink ? (
                  <p>
                    <a
                      href={submission.appetiteProduct.portalLink}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      {submission.appetiteProduct.portalLink}
                    </a>
                  </p>
                ) : (
                  <p>No Link Available - Click Pencil to add</p>
                )}
              </div>
            )}
            {editMode && (
              <Reform
                className="py-4 space-y-4"
                schema={AppetiteProductFormSchema}
                onSubmit={(_, values) => {
                  void updateAppetiteProduct({
                    variables: {
                      input: {
                        id: submission.appetiteProduct.id,
                        portalLink: values.portalLink,
                        submissionInstructions: values.submissionInstructions,
                      },
                    },
                  });
                }}
                defaultValues={{
                  portalLink: submission.appetiteProduct.portalLink ?? undefined,
                  submissionInstructions: submission.appetiteProduct.submissionInstructions ?? undefined,
                }}
              >
                <FieldContainer label="Portal Link" name="portalLink">
                  <Input name="portalLink" />
                </FieldContainer>

                <FieldContainer label="Submission Instructions" name="submissionInstructions">
                  <Input name="submissionInstructions" />
                </FieldContainer>

                <SheetFooter>
                  <Button variant="destructive" onClick={() => setEditMode(false)}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </SheetFooter>
              </Reform>
            )}
            <CarrierContactList
              type={CarrierContactType.Submission}
              contacts={submissionContacts}
              appetiteProductId={submission.appetiteProduct.id}
            />
            <CarrierContactList
              type={CarrierContactType.Reference}
              contacts={referenceContacts}
              appetiteProductId={submission.appetiteProduct.id}
            />
          </TabsContent>
          <TabsContent value="edit">
            <Reform
              className="py-4 space-y-4"
              schema={FormSchema}
              onSubmit={(_, values) => {
                void submitOpportunity({
                  variables: {
                    input: {
                      id: submission.id,
                      ...(values as Omit<UpdateSubmissionInput, "id">),
                      ...(values.status === "-" ? { status: null } : {}),
                      quotePremium: values.quotePremium ?? "",
                    },
                  },
                });
              }}
              defaultValues={{
                status: submission.status ?? undefined,
                declinationReason: submission.exclusionReason?.reason ?? "",
                declinedDueToNaics: Boolean(submission.exclusionReason?.naicsCode),
                declinedDueToState: Boolean(submission.exclusionReason?.state),
                quotePremium: submission.quotePremium ?? undefined,
                bindHqUrl: submission.bindHqUrl ?? undefined,
                effectiveDate: submission.effectiveDate ?? undefined,
                expirationDate: submission.expirationDate ?? undefined,
                cancellationDate: submission.cancellationDate ?? undefined,
              }}
            >
              <FieldContainer label="Status" name="status">
                <SelectStatus name="status" />
              </FieldContainer>

              <FieldContainer label="Quote Premium" name="quotePremium">
                <Input name="quotePremium" />
              </FieldContainer>

              <FieldContainer label="BindHQ URL" name="bindHqUrl">
                <Input type="url" name="bindHqUrl" />
              </FieldContainer>

              <FieldContainer label="Policy Number" name="bindHqUrl" className="flex flex-row justify-between">
                {submission.policyNumber ? (
                  <PolicyNumber number={submission.policyNumber} />
                ) : submission.createBindHqPolicyActionId ? (
                  <CreateBindHqPolicyLink submission={submission} />
                ) : (
                  "No Policy Number"
                )}
              </FieldContainer>

              <FieldWatcher>
                <FieldContainer
                  label="Effective Date"
                  name="effectiveDate"
                  className="flex items-center justify-between mt-0"
                >
                  <ControlledDatePicker name="effectiveDate" />
                </FieldContainer>

                <FieldContainer
                  label="Expiration Date"
                  name="expirationDate"
                  className="flex items-center justify-between mt-0"
                >
                  <ControlledDatePicker name="expirationDate" />
                </FieldContainer>

                <FieldContainer
                  label="Cancellation Date"
                  name="cancellationDate"
                  className="flex items-center justify-between mt-0"
                >
                  <ControlledDatePicker name="cancellationDate" />
                </FieldContainer>
              </FieldWatcher>

              <h3>Declination Reason</h3>
              <Checkbox name="declinedDueToState">Declined in part or wholly due to State</Checkbox>

              <Checkbox name="declinedDueToNaics">Declined in part due or wholly to NAICS</Checkbox>

              <FieldContainer name="declinationReason" label="Reasons beyond the above">
                <TextArea name="declinationReason" />
              </FieldContainer>
              <SheetFooter>
                <Button type="submit">Save</Button>
              </SheetFooter>
            </Reform>
          </TabsContent>
        </Tabs>
      </SheetContent>
    </Sheet>
  );
};

const PolicyNumber = ({ number }: { number: string }) => {
  const { toast } = useToast();
  return (
    <span
      onClick={() => {
        void navigator.clipboard.writeText(number);
        toast({ title: "Copied policy number to Clipboard!" });
      }}
    >
      {number}
      <Icon icon="copy_all" className="ml-1" />
    </span>
  );
};

const CreateBindHqPolicyLink = ({ submission }: { submission: MarketingEffortSubmission }) => {
  const { opportunityId } = useParams();
  return (
    <Link to={`/opportunity/${opportunityId}/create-policy/${submission.createBindHqPolicyActionId}`}>
      Create BindHQ Policy
    </Link>
  );
};

const UncontrolledSelectStatus = ({ ...props }) => (
  <Select {...props} onValueChange={props.onChange}>
    <SelectTrigger>
      <SelectValue />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="-">-</SelectItem>
      {submissionStatusKeys.map((value) => (
        <SelectItem key={value} value={value}>
          {SUBMISSION_STATUS_METADATA[value].label}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
);

const SelectStatus = createControlledFormComponent(UncontrolledSelectStatus);

const ControlledDatePicker = ({ name }: { name: string }) => {
  const { watch, setValue } = useFormContext();

  return (
    <DatePicker
      placeholder="Select Date"
      selected={watch(name)}
      onSelect={(e) => setValue(name, e?.toISOString())}
      dateFormat="MMMM d, yyyy"
      className="w-fit"
    />
  );
};

// This component is essentially a wrapper used to watch the effectiveDate field
// When effectiveDate is updated, default the expirationDate to 1 year later
export const FieldWatcher = ({ children }: { children: React.ReactNode }) => {
  const { getValues, setValue, watch } = useFormContext();

  const effectiveDate = watch("effectiveDate");
  const expirationDate = getValues("expirationDate");
  useEffect(() => {
    // Don't overwrite expirationDate if it's already set
    if (effectiveDate && !expirationDate) {
      const oneYearLater = new Date(new Date(effectiveDate).setFullYear(new Date(effectiveDate).getFullYear() + 1));
      setValue("expirationDate", oneYearLater.toISOString());
    }
  }, [effectiveDate, setValue]);

  return <>{children}</>;
};
