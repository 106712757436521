import { cn } from "@/utils";
import { HasInternalRole } from "./has-role";
import { Button, ButtonProps } from "./ui/button";
import { Icon, IconString } from "./ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { toast } from "./ui/use-toast";

export const Clippy = ({
  content,
  icon = "link",
  label,
  title = "Copied to clipboard",
  className,
  ...props
}: ButtonProps & {
  content: string;
  icon?: IconString;
  label?: string;
  title?: string;
}) => (
  <HasInternalRole>
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={cn(className)}
          onClick={async (e) => {
            e.preventDefault();
            await navigator.clipboard.writeText(content);
            toast({ title, description: content });
          }}
          {...props}
        >
          <Icon icon={icon} className="filled" />
          {label}
        </Button>
      </TooltipTrigger>
      <TooltipContent>Copy Link to Clipboard</TooltipContent>
    </Tooltip>
  </HasInternalRole>
);
