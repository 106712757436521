import { MouseEventHandler } from "react";
import { Button } from "../../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { Icon } from "../../../components/ui/icon";
import { Spinner } from "../../../components/ui/loading";
import { useLoadFromDoc } from "./use-load-from-doc";

interface UploadTipCardProps {
  clickToUpload?: MouseEventHandler<HTMLElement>;
  documentUploadMethods: Omit<ReturnType<typeof useLoadFromDoc>, "onClick" | "parentProps">;
}

export const UploadTipCard: React.FC<UploadTipCardProps> = ({
  clickToUpload,
  documentUploadMethods: { dragging, loading, completed, fileName, fileUrl, reset },
}) => {
  const canUpload = !loading && !completed;

  return (
    <Card onClick={(e) => canUpload && clickToUpload?.(e)}>
      <CardHeader>
        <CardTitle>Submission Tip</CardTitle>
        <CardDescription>Speed through the submission process by starting from an ACORD 125!</CardDescription>
      </CardHeader>
      <CardContent className="prose prose-sm leading-normal">
        {dragging ? (
          <p>Drop file to start upload.</p>
        ) : loading ? (
          <Spinner />
        ) : completed ? (
          <div className=" flex justify-between">
            <a className="w-full overflow-x-scroll" href={fileUrl} target="_blank" rel="noreferrer">
              {fileName}
            </a>
            <Button size="icon" variant="ghost" onClick={reset}>
              <Icon icon="close" />
            </Button>
          </div>
        ) : (
          <p>Drag and drop anywhere on this page, or click here to start an upload.</p>
        )}
      </CardContent>
    </Card>
  );
};

interface PublicUploadTipCardProps {
  clickToUpload?: MouseEventHandler<HTMLElement>;
  files: File[];
  reset: () => void;
}

export const PublicUploadTipCard: React.FC<PublicUploadTipCardProps> = ({ clickToUpload, files, reset }) => {
  return (
    <Card onClick={(e) => clickToUpload?.(e)}>
      <CardHeader>
        <CardTitle>Upload Documents</CardTitle>
      </CardHeader>
      <CardContent className="prose prose-sm leading-normal">
        <p>Drag and drop anywhere on this page, or click here to start an upload.</p>
        {files.length > 0 && (
          <Button
            size="icon"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              reset();
            }}
          >
            <Icon icon="close" />
          </Button>
        )}
        <div className="flex flex-col justify-between">
          {files?.map((file) => (
            <span key={file.name} className="text-sm">
              {file.name}
            </span>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export const SubmissionTipCard = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Submission Tip</CardTitle>
        <CardDescription>
          Providing a detailed Business Description will help us automatically classify the business.
        </CardDescription>
      </CardHeader>
      <CardContent className="prose prose-sm leading-normal">
        <p>A good description includes the following information:</p>
        <ul>
          <li>Industry Class</li>
          <li>Operations</li>
          <li>Key Exposures</li>
          <li>Known Business Classification Codes</li>
        </ul>
        <p>
          We&apos;ll use this information to automatically determine <strong>CGL</strong> and <strong>NAICS</strong>{" "}
          codes for the applicant in the next step of the submission process.
        </p>
      </CardContent>
    </Card>
  );
};
