import { zodResolver } from "@hookform/resolvers/zod";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { Clippy } from "@/components/clippy";
import { Bar } from "@/components/ui/bar";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { CurrencyFormField } from "@/components/ui/currency-form-field";
import { Field } from "@/components/ui/field-inputs";
import { Form, FormField } from "@/components/ui/form";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Pane } from "@/components/ui/pane";
import { ApplicantClassification } from "@/opportunity/info/applicant-classification";
import { ApplicantDetails } from "@/opportunity/info/applicant-details";
import { LeadsDetails } from "@/opportunity/info/leads-details";
import { cn } from "@/utils";
import {
  useAppetiteFilterOptionsQuery,
  useOpportunityDetailsQuery,
  useSelectOpportunityCoveragesMutation,
} from "../../../generated/graphql";
import { ContinueButton } from "../components/continue-button";

const PROPERTY_FIELD = "Property";

const SelectCoverageFormSchema = z.object({
  selectedLinesOfBusiness: z.string().array().min(1, { message: "You must select at least one coverage" }),
  propertyTIV: z.number().optional(),
});
// TODO: Bring back if we want this to be required
// .superRefine((values, ctx) => {
//   if (values.selectedLinesOfBusiness.includes(PROPERTY_FIELD) && !values.propertyTIV) {
//     ctx.addIssue({
//       message: "Property TIV Required to Proceed",
//       code: z.ZodIssueCode.custom,
//       path: ["propertyTIV"],
//     });
//   }
// });

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const SelectCoveragePage = () => {
  const { opportunityId } = useParams();
  const { data } = useAppetiteFilterOptionsQuery({});
  const { data: opportunityData } = useOpportunityDetailsQuery({
    variables: {
      id: opportunityId ?? "",
    },
  });
  const [term, setTerm] = useState("");
  const [selectCoveragesMutation, { loading: submitting }] = useSelectOpportunityCoveragesMutation();
  const navigate = useNavigate();

  const { opportunity } = opportunityData ?? {};

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(term.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  const selectCoverageFormMethods = useForm<z.infer<typeof SelectCoverageFormSchema>>({
    resolver: zodResolver(SelectCoverageFormSchema),
    defaultValues: {
      selectedLinesOfBusiness: opportunity?.selectedLinesOfBusiness ?? [],
    },
  });

  const {
    formState: { isValid },
  } = selectCoverageFormMethods;

  const { selectedLinesOfBusiness, propertyTIV } = selectCoverageFormMethods.watch();

  const handleSubmit = async () => {
    if (!opportunityId) {
      return null;
    }

    const validate = await selectCoverageFormMethods.trigger();

    if (!validate) {
      return;
    }

    void selectCoveragesMutation({
      variables: {
        input: {
          id: opportunityId,
          selectedLinesOfBusiness,
          propertyTIV,
        },
      },
      onCompleted: ({ selectOpportunityCoverages }) => {
        const url = `/insured/${selectOpportunityCoverages.insuredId}/${selectOpportunityCoverages.id}`;

        navigate(url);
      },
    });

    return null;
  };

  return (
    <Form {...selectCoverageFormMethods}>
      <Pane layout="columns" maxWidth="wide">
        <div className="flex-auto space-y-4">
          <Card className="flex gap-5 p-6 pl-5">
            <Icon icon="verified" className="filled text-primary text-2xl" />
            <h2>Select Coverage</h2>
          </Card>
          <div className="text-sm">
            {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error && (
              <p className="text-destructive">
                {selectCoverageFormMethods.getFieldState("selectedLinesOfBusiness").error?.message}
              </p>
            )}
          </div>
          <Input
            name="search"
            type="text"
            placeholder="Search for coverage"
            className="px-4"
            onChange={(e) => {
              setTerm(e.target.value);
            }}
          />
          <Card className="bg-muted overflow-hidden space-y-px">
            <CardHeader className="bg-background">
              <CardTitle>Core Lines</CardTitle>
            </CardHeader>
            {coreLines.map(({ name }) => (
              <>
                <FieldCheckbox key={name} label={name} name="selectedLinesOfBusiness" />
                {name === PROPERTY_FIELD && selectedLinesOfBusiness.includes(PROPERTY_FIELD) && (
                  <CurrencyFormField
                    className="pl-8 placeholder-red-700"
                    icon="paid"
                    iconClassName={isValid ? "" : "text-destructive hover:text-destructive"}
                    control={selectCoverageFormMethods.control}
                    name="propertyTIV"
                    required={true}
                    placeholder="Total Insurable Value"
                    inputClassName="!placeholder-red-700"
                  />
                )}
              </>
            ))}
          </Card>
          <Card className="bg-muted overflow-hidden space-y-px">
            <CardHeader className="bg-background">
              <CardTitle>Other</CardTitle>
            </CardHeader>
            {otherLines.map(({ name }) => (
              <FieldCheckbox key={name} label={name} name="selectedLinesOfBusiness" />
            ))}
          </Card>
        </div>
        {opportunity?.insured && (
          <div className="flex-none space-y-4 lg:w-1/3">
            <Clippy
              variant="default"
              size="sm"
              content={`${window.location.origin}/opportunity/${opportunity.id}`}
              label="Copy Opportunity URL to Clipboard"
              className="w-full"
            />
            {opportunity.leads[0] && (
              <Card className="[&>div]:rounded-lg">
                <LeadsDetails leads={opportunity.leads} />
              </Card>
            )}
            <Card className="[&>div]:rounded-lg">
              <ApplicantDetails insured={opportunity.insured} />
            </Card>
            <Card className="[&>div]:rounded-lg">
              <ApplicantClassification insured={opportunity.insured} />
            </Card>
          </div>
        )}
      </Pane>
      <Bar as="footer" position="sticky">
        <div className="flex flex-auto justify-end max-w-6xl mx-auto">
          <ContinueButton onClick={handleSubmit} disabled={!isValid} submitting={submitting} />
        </div>
      </Bar>
    </Form>
  );
};

const FieldCheckbox = ({ label, name }: { label: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(label);
      return (
        <Field className={cn("gap-6", checked ? "text-foreground" : "text-muted-foreground")}>
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-foreground" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, label])
                : field.onChange(field.value?.filter((value: string) => value !== label));
            }}
          />
          {label}
        </Field>
      );
    }}
  />
);
