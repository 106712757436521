import React, { FocusEventHandler } from "react";
import { useSupplementalHandlers } from "../supplementals/client-data-context";
import { FormFieldPlain } from "./plain";

interface BaseInputProps {
  name: string;
  index?: number;
  onBlur?(e: any): void;
  onFocus?: FocusEventHandler<unknown> | undefined;
  defaultValue?: string | number | readonly string[];
}
type SupplementalProps<TProps extends BaseInputProps> = Omit<TProps, "onBlur" | "onFocus" | "defaultValue">;
export function createSupplementalField<TProps extends BaseInputProps>(component: React.FC<TProps>) {
  // Typescript is confused by the generics
  const Component = component as React.FC<BaseInputProps>;
  const NewComponent: React.FC<SupplementalProps<TProps>> = ({ name, index, ...props }) => (
    <Component {...props} name={name} {...useSupplementalHandlers(name, index)} />
  );

  NewComponent.displayName = `Supplemental${Component.displayName}`;
  return NewComponent;
}

const Input = createSupplementalField(FormFieldPlain.Input);
const TextArea = createSupplementalField(FormFieldPlain.TextArea);
const RadioBoolean = createSupplementalField(FormFieldPlain.RadioBoolean);
const Date = createSupplementalField(FormFieldPlain.Date);
const Number = createSupplementalField(FormFieldPlain.Number);
const Money = createSupplementalField(FormFieldPlain.Money);

// Primarily used for forms that will also generate PDFs
export const FormFieldSupplemental = {
  Input,
  TextArea,
  Label: FormFieldPlain.Label,
  RadioBoolean,
  Date,
  Number,
  Money,
};
