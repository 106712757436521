import { useAtom } from "jotai";
import { useDebounce } from "usehooks-ts";

import { dashboardCategoryAtom, pinnedOppAtom } from "@/atoms";
import { OpportunityListItem } from "@/components/opportunity-list-item";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import {
  OpportunityGroupBy,
  OpportunityListItemFragment,
  useGroupedOpportunityListQuery,
  usePaginatedOpportunityListQuery,
} from "../../generated/graphql";
import { useMyAccount } from "../auth/useMyAccount";
import { adaptSearchParams } from "../components/adapt-search-params";
import { PepperSelector } from "../components/pepper-selector";
import { ListToolBar } from "./components/list-toolbar";

export const HubList = () => {
  const [pinnedOpps] = useAtom(pinnedOppAtom);
  const [dashboardCategory] = useAtom(dashboardCategoryAtom);
  const [searchParams, upsertSearchParams, , removeSeachParams] = useUpsertSearchParams();
  const { page, groupBy, ...filterArgs } = adaptSearchParams(searchParams);
  const term = useDebounce(filterArgs.term, 333);
  const { data: user } = useMyAccount();

  const { data: pinnedData } = usePaginatedOpportunityListQuery({
    variables: {
      args: {
        ids: Object.keys(pinnedOpps),
      },
      pagination: {},
    },
    fetchPolicy: "cache-first",
  });
  const { opportunities: pinnedOpportunities = [] } = pinnedData?.paginatedOpportunities ?? {};

  // HACK: We can't just includeTests for all agencies with isTest == true, because QuoteWell is marked as a test agency
  // So instead we include tests for users without internal roles who are part of a test agency, since this does not apply for QW
  const forceIncludeTests = (user && !user.internal && user?.agency.isTest) ?? false;

  const { data: groupedOpps, loading: groupedLoading } = useGroupedOpportunityListQuery({
    variables: {
      args: {
        filter: {
          ...filterArgs,
          dashboardCategory: groupBy === OpportunityGroupBy.Dashboard ? dashboardCategory : undefined,
          isTest: forceIncludeTests || filterArgs.isTest,
          term,
        },
        groupBy: groupBy ?? OpportunityGroupBy.UpdatedAt,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const renderGroups = (groups: Array<{ label: string; opportunities: OpportunityListItemFragment[] }>) =>
    groups.map(
      ({ label, opportunities }, idx) =>
        opportunities.length > 0 && (
          <CollapsibleList key={label} defaultOpen={opportunities.length < 100 && idx < 16}>
            <CollapsibleListTrigger count={opportunities.length}>{label}</CollapsibleListTrigger>
            <CollapsibleListContent>
              {opportunities.map((opp) => (
                <OpportunityListItem key={opp.id} opportunity={opp} />
              ))}
            </CollapsibleListContent>
          </CollapsibleList>
        )
    );

  const opportunities = groupedOpps ? renderGroups(groupedOpps.groupedOpportunities.groups) : [];

  return (
    <PaneGroup>
      <Bar as="header" position="sticky" className="pr-2.5">
        {groupBy === OpportunityGroupBy.Dashboard ? (
          <PepperSelector />
        ) : (
          <>
            {filterArgs.term ? (
              <Button
                variant="secondary"
                size="icon-sm"
                className="bg-accent rounded-full text-muted-foreground z-1"
                onClick={() => removeSeachParams(["term"])}
              >
                <Icon icon="close" />
              </Button>
            ) : (
              <Icon icon="search" className="bg-accent h-6 rounded-full text-muted-foreground text-xs w-6" />
            )}
            <Input
              placeholder="Search"
              value={filterArgs.term || ""}
              onChange={(event) => upsertSearchParams({ term: event.currentTarget.value })}
              className="bg-transparent border-0 px-14 rounded-none text-sm absolute top-0 left-0 w-full h-full z-0"
            />
          </>
        )}
        <ListToolBar />
      </Bar>
      {pinnedOpportunities.length > 0 && (
        <CollapsibleList defaultOpen>
          <CollapsibleListTrigger>Pinned</CollapsibleListTrigger>
          <CollapsibleListContent>
            {pinnedOpportunities.map((o) => (
              <OpportunityListItem key={o.id} opportunity={o} />
            ))}
          </CollapsibleListContent>
        </CollapsibleList>
      )}
      {groupedLoading ? (
        <Pane>
          <Loading />
        </Pane>
      ) : opportunities.length > 0 ? (
        <Pane layout="list">{opportunities}</Pane>
      ) : (
        <Pane className="text-muted-foreground text-sm">No opportunities found.</Pane>
      )}
    </PaneGroup>
  );
};
