import { Outlet, useParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Loading } from "@/components/ui/loading";
import { useInsuredQuery } from "src/generated/graphql";
import { InsuredNav } from "./insured-nav";

export const InsuredRoot: React.FC = () => {
  const { insuredId } = useParams();

  const { data, loading } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  useDocumentTitle(data ? `${data?.insured?.name}: QuoteWell` : "QuoteWell");

  if (!data || !insuredId) {
    return null;
  }

  if (loading) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-auto gap-3">
      <InsuredNav insuredId={insuredId} />
      <Outlet />
    </div>
  );
};
