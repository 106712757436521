import { Paper } from "../components/paper";
import { Signature } from "../components/signature";
import { TdInput, TdMoney, TdNumber, TdTextarea, TdYesNo } from "../components/td-fields";
import { Address } from "../fieldsets/Address";
import { GeneralInformation } from "../fieldsets/GeneralInformation";
import { LossHistory } from "../fieldsets/loss-history";

export const BuildersRisk = () => (
  <>
    <Paper>
      <section>
        <h1>Builders Risk</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Limits</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TdMoney label="Project deductible" name="projectDeductible" />
            </tr>
            <tr>
              <TdMoney label="Flood limit" name="floodLimit" />
            </tr>
            <tr>
              <TdMoney label="Transit limit" name="transit" />
            </tr>
            <tr>
              <TdInput label="Temporary locations" name="temporaryLocations" />
            </tr>
            <tr>
              <TdMoney label="Earthquake limit" name="earthquakeLimit" />
            </tr>
            <tr>
              <TdMoney label="Flood deductible" name="floodDeductible" />
            </tr>
            <tr>
              <TdMoney label="Earthquake deductible" name="earthquakeDeductible" />
            </tr>
            <tr>
              <TdInput label="What is your occupancy? (for example, offices, restaurant, etc.)?" name="occupancyType" />
            </tr>
            <tr>
              <TdYesNo label="Equipment Breakdown" name="equipmentBreakdownCoverage" />
            </tr>
            <tr>
              <TdYesNo label="Time Element Coverage" name="timeElementCoverage" />
            </tr>
            <tr>
              <TdMoney label="Soft Costs" name="softCosts" />
            </tr>
            <tr>
              <TdMoney label="Rental Value" name="rentalValue" />
            </tr>
            <tr>
              <TdInput label="Limits" name="limits" />
            </tr>
            <tr>
              <TdMoney label="Completed project value/jobsite limit" name="completedProjectValueLimit" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Applicant Details</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TdInput label="Inspection Contact" name="inspectionContact" />
            </tr>
            <tr>
              <TdInput label="Phone number" name="phoneNumber" />
            </tr>
            <tr>
              <TdInput label="Email" name="email" />
            </tr>
            <tr>
              <TdYesNo
                label="Has the customer filed any bankruptcies in the last five years?"
                name="bankruptciesLastFiveYears"
              />
            </tr>
            <tr>
              <TdYesNo
                label="Has the insured or contractor had coverage cancelled or non-renewed in the past three years?"
                name="cancelledOrNonRenewedCoverage"
              />
            </tr>
            <tr>
              <TdYesNo label="Is the contractor a licensed contractor?" name="isLicensedContractor" />
            </tr>
            <tr>
              <TdInput label="Project Site Address" name="projectSiteAddress" type="text" />
            </tr>

            <tr>
              <TdYesNo label="Has the project begun?" name="hasProjectBegun" />
            </tr>
            <tr>
              <TdYesNo
                label="Is the project a renovation/addition to an existing structure?"
                name="isProjectRenovationOrAddition"
              />
            </tr>
            <tr>
              <TdTextarea label="Project Description" name="projectDescription" />
            </tr>
            <tr>
              <TdInput label="Type of Construction" name="typeOfConstruction" />
            </tr>
            <tr>
              <TdNumber label="Number of Stories" name="numberOfStories" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <LossHistory rootPath="buildersRisk" />
      <Signature />
    </Paper>
  </>
);
