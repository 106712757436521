import { Arrays } from "@cp/toolkit";

import {
  OpportunityDetailsFragment,
  OpportunityOutcome,
  OpportunityStatus,
  SubmissionStatus,
} from "src/generated/graphql";
import { IconString } from "./components/ui/icon";

interface StatusMetadata {
  order: number;
  label: string;
  className: string;
  icon: IconString;
}

export const STATUS_METADATA: Record<OpportunityStatus, StatusMetadata> = {
  [OpportunityStatus.BookRollPending]: {
    order: 1,
    label: "Book Roll - Pending",
    className: "filled text-indigo-500",
    icon: "diamond" as IconString,
  },
  [OpportunityStatus.Active]: {
    order: 2,
    label: "Active",
    className: "filled text-green-600",
    icon: "circle",
  },
  [OpportunityStatus.Tracking]: {
    order: 3,
    label: "Tracking",
    className: "filled text-amber-600",
    icon: "visibility",
  },
  [OpportunityStatus.Closed]: {
    order: 4,
    label: "Closed",
    className: "text-muted-foreground",
    icon: "block",
  },
};

export const statusKeys = (opportunity: OpportunityDetailsFragment) => {
  return Arrays.sort(
    Object.keys(STATUS_METADATA) as OpportunityStatus[],
    (a, b) => STATUS_METADATA[a].order - STATUS_METADATA[b].order
  ).filter((key) => {
    //  Hide the Book Roll Pending status if the Opportunity does not have Lead(s)
    return key !== OpportunityStatus.BookRollPending || opportunity.leads.length > 0;
  });
};

interface OutcomeMetadata {
  order: number;
  label: string;
  color?: "error" | "warning" | "success" | string;
}

export const OUTCOME_METADATA: Record<OpportunityOutcome, OutcomeMetadata> = {
  [OpportunityOutcome.InProgress]: { order: 0, label: "In Progress" },
  [OpportunityOutcome.NoMarket]: { order: 1, label: "No Market" },
  [OpportunityOutcome.NoBrokerLicenseInDesiredState]: { order: 2, label: "No Broker License in Desired State" },
  [OpportunityOutcome.MarketsBlocked]: { order: 3, label: "Markets Blocked" },
  [OpportunityOutcome.QuotedAgentDidNotBind]: { order: 4, label: "Quoted - Agent Did Not Bind" },
  [OpportunityOutcome.RenewedWithIncumbentAgency]: { order: 5, label: "Renewed With Incumbent Agency" },
  [OpportunityOutcome.RenewedWithIncumbentCarrier]: { order: 6, label: "Renewed With Incumbent Carrier" },
  [OpportunityOutcome.RenewedWithIncumbentAgencyAndCarrier]: {
    order: 7,
    label: "Renewed With Incumbent Agency and Carrier",
  },
  [OpportunityOutcome.BoundThroughQuoteWell]: { order: 8, label: "Bound through QuoteWell" },
  [OpportunityOutcome.BoundElsewhere]: { order: 9, label: "Bound Elsewhere" },
  [OpportunityOutcome.NoResponseFromAgent]: { order: 10, label: "No Response From Agent" },
  [OpportunityOutcome.NoResponseFromInsured]: { order: 11, label: "No Response From Insured" },
  [OpportunityOutcome.NoCompetitveMarketOptions]: { order: 12, label: "No Competitve Market Options" },
};

export const outcomeKeys = Arrays.sort(
  Object.keys(OUTCOME_METADATA) as OpportunityOutcome[],
  (a, b) => OUTCOME_METADATA[a].order - OUTCOME_METADATA[b].order
);

interface SubmissionStatusMetadata {
  label: string;
  color?: string;
  agentLabel: string;
  agentColor?: string;
  hide?: boolean;
}

const color = {
  red: "!bg-destructive/5 border border-red-500/10 !text-destructive dark:!text-destructive",
  orange: "!bg-orange-500/5 border border-orange-500/10 !text-orange-700 dark:!text-orange-400",
  amber: "!bg-amber-500/5 border border-amber-500/10 !text-amber-700 dark:!text-amber-400",
  green: "!bg-green-500/5 border border-green-500/10 !text-green-700 dark:!text-green-400",
  blue: "!bg-blue-500/5 border border-blue-500/10 !text-blue-700 dark:!text-blue-400",
  purple: "!bg-purple-500/5 border border-purple-500/10 !text-purple-700 dark:!text-purple-400",
  zinc: "!bg-zinc-500/5 border border-zinc-500/10 !text-zinc-700 dark:!text-zinc-400",
};

const agentColor = {
  red: "text-destructive dark:text-destructive",
  orange: "text-orange-700 dark:text-orange-400",
  amber: "text-amber-700 dark:text-amber-400",
  green: "text-green-700 dark:text-green-400",
  blue: "text-blue-700 dark:text-blue-400",
  purple: "text-purple-700 dark:text-purple-400",
  zinc: "text-zinc-700 dark:text-zinc-400",
};

export const SUBMISSION_STATUS_METADATA: Record<SubmissionStatus, SubmissionStatusMetadata> = {
  [SubmissionStatus.MarketingWaitingOnAgent]: {
    label: "Marketing - Waiting on Agent",
    color: color.blue,
    agentLabel: "Information Required",
    agentColor: agentColor.blue,
  },
  [SubmissionStatus.MarketingSubmittedToCarrier]: {
    label: "Marketing - Submitted to Carrier",
    color: color.blue,
    agentLabel: "Submitted to Carrier",
    agentColor: agentColor.blue,
  },
  [SubmissionStatus.MarketingSubmittedToInHouseAuthority]: {
    label: "Marketing - Submitted to In-House Authority",
    color: color.blue,
    agentLabel: "Submitted to In-House Authority",
    agentColor: agentColor.blue,
  },
  [SubmissionStatus.MarketingBlocked]: {
    label: "Marketing - Blocked",
    color: color.red,
    agentLabel: "Blocked",
    agentColor: agentColor.red,
  },
  [SubmissionStatus.MarketingBrokerActionRequired]: {
    label: "Marketing - Broker Action Required",
    color: color.amber,
    agentLabel: "In Progress",
    agentColor: agentColor.amber,
  },
  [SubmissionStatus.QuotingIndication]: {
    label: "Quoting - Indication",
    color: color.green,
    agentLabel: "In Progress",
    agentColor: agentColor.green,
  },
  [SubmissionStatus.QuotingReferred]: {
    label: "Quoting - Referred",
    color: color.green,
    agentLabel: "In Progress",
    agentColor: agentColor.green,
  },
  [SubmissionStatus.QuotingBrokerActionRequired]: {
    label: "Quoting - Broker Action Required",
    color: color.amber,
    agentLabel: "In Progress",
    agentColor: agentColor.amber,
  },
  [SubmissionStatus.QuotedSentForProcessing]: {
    label: "Quoted - Sent for Processing",
    color: color.green,
    agentLabel: "Processing Quote",
    agentColor: agentColor.green,
  },
  [SubmissionStatus.QuotedSentToAgent]: {
    label: "Quoted - Sent to Agent",
    color: color.green,
    agentLabel: "Quoted",
    agentColor: agentColor.green,
  },
  [SubmissionStatus.QuotedDidNotPresentToAgent]: {
    label: "Quoted - Did Not Present to Agent",
    color: color.green,
    agentLabel: "Closed",
    agentColor: agentColor.green,
  },
  [SubmissionStatus.BindingBindRequestReceivedFromAgent]: {
    label: "Binding - Bind Request Received from Agent",
    color: color.purple,
    agentLabel: "Bind Requested",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.BindingBindRequestSentToCarrier]: {
    label: "Binding - Bind Request Sent to Carrier",
    color: color.purple,
    agentLabel: "Bind Request Sent to Carrier",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.BindingBindRequestSentToInHouseAuthority]: {
    label: "Binding - Bind Request Sent to In-House Authority",
    color: color.purple,
    agentLabel: "Bind Request Sent to In-House Authority",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.BindingBrokerActionRequired]: {
    label: "Binding - Broker Action Required",
    color: color.amber,
    agentLabel: "Binding In Progress",
    agentColor: agentColor.amber,
  },
  [SubmissionStatus.BindingSentForProcessing]: {
    label: "Binding - Binding Sent for Processing",
    color: color.purple,
    agentLabel: "Binding Sent for Processing",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.BoundAwaitingSubjectivities]: {
    label: "Bound - Awaiting Subjectivities from Agent",
    color: color.orange,
    agentLabel: "Awaiting Subjectivities",
    agentColor: agentColor.orange,
  },
  [SubmissionStatus.BoundAwaitingPolicyFromCarrier]: {
    label: "Bound - Awaiting Policy from Carrier",
    color: color.purple,
    agentLabel: "Awaiting Policy from Carrier",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.IssuingReadyToSendToBpo]: {
    label: "Issuing - Ready to Send to BPO",
    color: color.purple,
    agentLabel: "Issuing",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.IssuingPolicySentToBpo]: {
    label: "Issuing - Policy Sent to BPO",
    color: color.purple,
    agentLabel: "Issuing",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.IssuingPolicyNeedsToBeStamped]: {
    label: "Issuing - Policy Needs to be Stamped",
    color: color.purple,
    agentLabel: "Issuing",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.IssuedAwaitingSubjectivities]: {
    label: "Issued - Awaiting Subjectivities from Agent",
    color: color.orange,
    agentLabel: "Awaiting Subjectivities",
    agentColor: agentColor.orange,
  },
  [SubmissionStatus.IssuedFileComplete]: {
    label: "Issued - File Complete",
    color: color.purple,
    agentLabel: "Policy Issued",
    agentColor: agentColor.purple,
  },
  [SubmissionStatus.IssuedEndorsementRequestedByAgent]: {
    label: "Issued - Endorsement Requested by Agent",
    color: color.orange,
    agentLabel: "Endorsement Requested",
    agentColor: agentColor.orange,
  },
  [SubmissionStatus.IssuedEndorsementRequestSentToCarrier]: {
    label: "Issued - Endorsement Request Sent to Carrier",
    color: color.orange,
    agentLabel: "Endorsement Request Sent to Carrier",
    agentColor: agentColor.orange,
  },
  [SubmissionStatus.IssuedPolicyCancelled]: {
    label: "Issued - Policy Cancelled",
    color: color.orange,
    agentLabel: "Policy Cancelled",
    agentColor: agentColor.orange,
  },
  [SubmissionStatus.ClosedMarketBlocked]: {
    label: "Closed - Market Blocked",
    color: color.zinc,
    agentLabel: "Blocked",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedNoResponseFromAgent]: {
    label: "Closed - No Response from Agent",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedNoResponseFromInsured]: {
    label: "Closed - No Response from Insured",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedConditionallyRuledOut]: {
    label: "Closed - Conditionally Ruled Out",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedNoCompetitiveMarket]: {
    label: "Closed - No Competitive Market",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedDeclined]: {
    label: "Closed - Declined",
    color: color.red,
    agentLabel: "Declined",
    agentColor: agentColor.red,
  },
  [SubmissionStatus.ClosedDeclinedByAi]: {
    label: "Closed - Declined by AI",
    color: color.red,
    agentLabel: "Closed",
    agentColor: agentColor.red,
    hide: true,
  },
  [SubmissionStatus.ClosedMoreCompetitiveAlternativeFound]: {
    label: "Closed - More Competitive Alternative Found",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedBoundOtherOption]: {
    label: "Closed - Bound Other Option",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedBoundElsewhere]: {
    label: "Closed - Bound Elsewhere",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedRenewedWithIncumbent]: {
    label: "Closed - Renewed with Incumbent",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
  [SubmissionStatus.ClosedNoBrokerLicenseInDesiredState]: {
    label: "Closed - No Broker License in Desired State",
    color: color.zinc,
    agentLabel: "Closed",
    agentColor: agentColor.zinc,
  },
};

export const submissionStatusKeys = Object.keys(SUBMISSION_STATUS_METADATA) as SubmissionStatus[];
