import { useAtom } from "jotai";

import { deletedFilesAtom, fileLabelsAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";

export const FilesOptions = () => {
  const [deletedFiles, setDeletedFiles] = useAtom(deletedFilesAtom);
  const [fileLabels, setFileLabels] = useAtom(fileLabelsAtom);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="rounded-full">
          <Icon icon="more_vert" className="font-bold " />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuCheckboxItem checked={fileLabels} onCheckedChange={(val) => setFileLabels(val)}>
          Display Labels
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked={deletedFiles} onCheckedChange={(val) => setDeletedFiles(val)}>
          Display Deleted Files
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
