import { getAuthRequestHeaders } from "@cp/auth";
import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading, Spinner } from "@/components/ui/loading";
import { toast, useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm, useFormContext } from "react-hook-form";
import { useSet } from "react-use";
import { z } from "zod";
import {
  ExpeditedOpportunityQuery,
  ExpeditedOpportunityQueryResult,
  MarketTypes,
  SubmissionStatus,
  useAddClientHazardIndicationMutation,
  useCreateQuotePdfMutation,
  useExpeditedOpportunityTransitionMutation,
  useQuoteProposalValuesQuery,
  useUpdateSubmissionMutation,
} from "../../../../generated/graphql";
import { uploadFile } from "../../../../utils/file.utils";
import { useModal } from "../../../components/modal-provider";
import { Checkbox } from "../../../components/ui/checkbox";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Separator } from "../../../components/ui/separator";
import { Textarea } from "../../../components/ui/textarea";
import { Input as InputFormField } from "../../../forms/components/FormFields";
import { useFieldError } from "../../../forms/inputs/useFieldError";
import { Reform } from "../../../forms/Reform";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

const AddRateSchema = z.object({
  hazardId: z.string(),
  carrierName: z.string(),
  lob: z.string(),
  productRate: z
    .string()
    .transform((s) => Number.parseFloat(s))
    .optional(),
  premisesRate: z
    .string()
    .transform((s) => Number.parseFloat(s))
    .optional(),
  consolidatedRate: z
    .string()
    .transform((s) => Number.parseFloat(s))
    .optional(),
});

export function WaitingOnUnderwriting({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  const bindingSubmissions = opportunity.submissions.filter((s) => {
    return s.appetiteProduct.marketType === MarketTypes.BindingAuthority;
  });

  const [submitting, setSubmitting] = useState(false);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [submissionId, setSubmissionId] = useState<string | undefined>(undefined);
  const { opportunityId } = useParams<"opportunityId">();
  const { openForm } = useModal();

  const [loading, setLoading] = useState(false);

  const [transition, { loading: transitioning }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        action: "Continue",
        opportunityId: opportunityId!,
      },
    },
  });

  const [updateSubmission] = useUpdateSubmissionMutation();
  const [addClientHazardIndication] = useAddClientHazardIndicationMutation({
    onError(e) {
      toast({ title: `Unable to add rate: ${e.message}` });
    },
  });

  const addRate = async () => {
    const fd = await openForm(AddRateSchema, <AddRateForm hazards={opportunity.hazards} />);
    if (!fd) {
      return toast({ title: "Unable to add rate." });
    }

    return addClientHazardIndication({
      variables: {
        input: {
          hazardId: fd.hazardId,
          opportunityId: opportunity.id,
          carrierName: fd.carrierName,
          lob: fd.lob,
          productRate: fd.productRate,
          premisesRate: fd.premisesRate,
          consolidatedRate: fd.consolidatedRate,
        },
      },
    });
  };

  const updateFileForProcessing = async (key: string, value: string | null, subId: string) => {
    await updateSubmission({
      variables: {
        input: {
          id: subId,
          [key]: [value],
        },
      },
      onCompleted: () => {
        setSubmissionId(subId);
        setSubmitting(false);
      },
    });
  };

  const declineSubmission = (id: string) => {
    return updateSubmission({
      variables: {
        input: {
          id,
          status: SubmissionStatus.ClosedDeclined,
        },
      },
    });
  };

  const getTitle = (s: SubmissionType) => {
    let content;
    switch (s.status) {
      case SubmissionStatus.ClosedDeclined:
        content = (
          <span>
            <span className="text-red-500">DECLINED</span> - {s.appetiteProduct.carrierName} -{" "}
            {s.appetiteProduct.carrierProductName}
          </span>
        );
        break;
      case SubmissionStatus.QuotingReferred:
        content = (
          <span>
            <span className="text-blue-500">REFERRED</span> - {s.appetiteProduct.carrierName} -{" "}
            {s.appetiteProduct.carrierProductName}
          </span>
        );
        break;
      default:
        content = (
          <div className="flex justify-between items-center">
            <span>
              {s.appetiteProduct.carrierName} - {s.appetiteProduct.carrierProductName}
            </span>
            <div className="flex gap-4">
              <Button asChild variant="outline" size="sm">
                <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
                  <input
                    type="file"
                    name="file"
                    className="hidden"
                    onChange={async (e) => {
                      setSubmitting(true);
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];

                        if (file.type !== "application/pdf") {
                          toast({ title: "We only accept PDF files" });
                          setSubmitting(false);
                          return;
                        }
                        const id = await uploadFile(file, opportunity.id, "INTERNAL", "MANUAL_UPLOAD");
                        setFileId(id);
                        await updateFileForProcessing("quoteFilesForProcessingIds", id, s.id);
                      }
                    }}
                  />
                  {submitting ? (
                    <Spinner />
                  ) : (
                    <>
                      Upload Redacted Quote <Icon icon="upload" />
                    </>
                  )}
                </label>
              </Button>
              <Button variant="destructive" size="sm" onClick={() => declineSubmission(s.id)}>
                Decline
              </Button>
              <ReferButton submission={s} opportunity={opportunity} />
            </div>
          </div>
        );
        break;
    }

    return <CardTitle>{content}</CardTitle>;
  };
  const getContent = (s: SubmissionType) => {
    let content;
    switch (s.status) {
      case SubmissionStatus.QuotingReferred:
      case SubmissionStatus.ClosedDeclined:
        content = null;
        break;
      default:
        content = (
          <>
            <div className="flex justify-between mx-4 my-1 gap-4 items-center">
              <div className="flex flex-wrap justify-start gap-2">
                {s.quoteFilesForProcessing.map((file) => {
                  return (
                    <a
                      key={file.id}
                      href={`/api/files/${file.id}`}
                      download={file.filename}
                      media={file.mimeType}
                      className="flex gap-2 items-start leading-tight hover:text-primary"
                      onClick={async (e) => {
                        e.preventDefault();

                        const blob = await fetch(`/api/files/${file.id}`, {
                          headers: getAuthRequestHeaders(),
                        })
                          .then((r) => r.blob())
                          .then((b) => b.slice(0, b.size, file.mimeType));

                        const url = URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = file.filename;

                        document.body.append(anchor);
                        anchor.click();

                        URL.revokeObjectURL(url);
                        anchor.remove();
                      }}
                    >
                      <Card className="flex flex-col gap-2 content-center p-4">
                        <span>
                          <Badge variant="outline" className="text-xs">
                            Original
                          </Badge>
                        </span>
                        <div className="flex gap-2 content-center">
                          <Icon icon="attachment" className="scale-150" />
                          {file.filename}
                        </div>
                      </Card>
                    </a>
                  );
                })}

                {s.processedQuoteFiles.map((file) => {
                  return (
                    <a
                      key={file.id}
                      href={`/api/files/${file.id}`}
                      download={file.filename}
                      media={file.mimeType}
                      className="flex gap-2 items-start leading-tight hover:text-primary"
                      onClick={async (e) => {
                        e.preventDefault();

                        const blob = await fetch(`/api/files/${file.id}`, {
                          headers: getAuthRequestHeaders(),
                        })
                          .then((r) => r.blob())
                          .then((b) => b.slice(0, b.size, file.mimeType));

                        const url = URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = file.filename;

                        document.body.append(anchor);
                        anchor.click();

                        URL.revokeObjectURL(url);
                        anchor.remove();
                      }}
                    >
                      <Card className="flex flex-col gap-2 content-center p-4">
                        <span>
                          <Badge className="text-xs">Processed</Badge>
                        </span>
                        <div className="flex gap-2 content-center">
                          <Icon icon="attachment" className="scale-150" />
                          {file.filename}
                        </div>
                      </Card>
                    </a>
                  );
                })}
              </div>
            </div>
          </>
        );
        break;
    }

    return <CardContent>{content}</CardContent>;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="flex justify-between items-center">
            <span>Thanks! Quote time.</span>
            <Button size="sm" onClick={() => addRate()}>
              <Icon icon="add" />
              Add Rate
            </Button>
          </div>
        </CardTitle>
        <CardDescription>
          We’ve sent an agent an email with the marketing plan and the new information requests.
        </CardDescription>
        <CardDescription>It looks like we’re ready to quote the below market(s).</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {bindingSubmissions
          .sort((a, b) => a.appetiteProduct.carrierName.localeCompare(b.appetiteProduct.carrierName))
          .map((s) => (
            <Card key={s.id} className="text-sm">
              <CardHeader>{getTitle(s)}</CardHeader>
              {getContent(s)}
              <SubmissionQuoteDialog
                setFileId={setFileId}
                setSubmissionId={setSubmissionId}
                submissionId={submissionId ?? ""}
                submission={s}
                fileId={fileId}
                setLoading={setLoading}
                loading={loading}
              />
            </Card>
          ))}
      </CardContent>
      <CardFooter>
        <Button size="sm" onClick={() => transition()} disabled={transitioning || loading || submitting}>
          Continue
        </Button>
      </CardFooter>
    </Card>
  );
}
type Submission = NonNullable<
  NonNullable<ExpeditedOpportunityQueryResult["data"]>["opportunity"]
>["submissions"][number];

const QuoteProposalValuesSchema = z.object({
  mep: z.string().optional(),
  carrierName: z.string().optional(),
  premium: z.string().optional(),
  inspectionFee: z.string().optional(),
  subjectivities: z.string().optional(),
  insuredName: z.string().optional(),
  effectiveDate: z.string().optional(),
  homeState: z.string().optional(),
  totalPremium: z.string().optional(),
});

const QUOTE_FORM_ID = "QUOTE_PROPOSALS";

const SubmissionQuoteDialog = ({
  submission,
  submissionId,
  fileId,
  setFileId,
  setSubmissionId,
  setLoading,
  loading,
}: {
  submission: Submission;
  submissionId: string;
  fileId: string | undefined;
  setFileId: (id: string) => void;
  setSubmissionId: (id: string) => void;
  setLoading: (v: boolean) => void;
  loading: boolean;
}) => {
  const { data: quoteProposalData, loading: loadingValues } = useQuoteProposalValuesQuery({
    variables: {
      input: {
        submissionId,
      },
    },
    skip: !submissionId,
  });

  const formMethods = useForm<z.infer<typeof QuoteProposalValuesSchema>>({
    resolver: zodResolver(QuoteProposalValuesSchema),
    defaultValues: {
      mep: "",
      carrierName: "",
      premium: "",
      inspectionFee: "",
      insuredName: "",
      effectiveDate: "",
      homeState: "",
      subjectivities: "",
    },
  });

  useEffect(() => {
    if (quoteProposalData?.quoteProposalValues) {
      const defaultValues = {
        mep: quoteProposalData?.quoteProposalValues?.mep ?? "",
        carrierName: quoteProposalData?.quoteProposalValues?.carrierName ?? "",
        premium: quoteProposalData?.quoteProposalValues?.premium ?? "",
        inspectionFee: quoteProposalData?.quoteProposalValues?.inspectionFee?.toString() ?? "",
        insuredName: quoteProposalData?.quoteProposalValues?.insuredName ?? "",
        effectiveDate: quoteProposalData?.quoteProposalValues?.effectiveDate
          ? format(new Date(quoteProposalData.quoteProposalValues.effectiveDate), "yyyy-MM-dd")
          : "",
        homeState: quoteProposalData?.quoteProposalValues?.homeState ?? "",
        subjectivities: (quoteProposalData.quoteProposalValues.subjectivities ?? []).join("\n"),
      };
      formMethods.reset(defaultValues);
    }
  }, [quoteProposalData, formMethods]);

  const { toast } = useToast();

  const [createQuotePDF] = useCreateQuotePdfMutation();
  const generateQuote = async (_e: FormEvent<HTMLFormElement>, values: z.infer<typeof QuoteProposalValuesSchema>) => {
    const isValid = await formMethods.trigger();

    if (!isValid) {
      return toast({ title: "Invalid form values" });
    }

    setLoading(true);
    if (!submission.id) {
      setLoading(false);
      return null;
    }

    return createQuotePDF({
      variables: {
        input: {
          submissionId: submission.id,
          insuredName: values.insuredName,
          carrierName: values.carrierName ?? submission.appetiteProduct.carrierName,
          mep: values.mep ?? "25%",
          premium: values.premium,
          inspectionFee: values.inspectionFee,
          subjectivities:
            values.subjectivities
              ?.replaceAll("-", "")
              .split("\n")
              .map((s: string) => s.trim()) ?? [],
          quoteFileId: fileId!,
          quoteEffectiveDate: values.effectiveDate ? format(new Date(values.effectiveDate), "MM/dd/yyy") : undefined,
        },
      },

      onError(error) {
        setLoading(false);
        toast({ title: error.message });
      },

      onCompleted() {
        setLoading(false);
        setSubmissionId("");
        toast({ title: "Quote proposal generated" });
        setFileId("");
      },
    });
  };

  if (loading) {
    return <Loading label="Saving PDF" />;
  }
  if (loadingValues) {
    return <Loading label="Loading extracted quote values" />;
  }

  if (!submission.id) {
    return null;
  }

  return (
    <Dialog
      open={submission.id === submissionId}
      onOpenChange={(open) => {
        if (!open) {
          setSubmissionId("");
          setFileId("");
        }
      }}
    >
      <DialogContent>
        <div className="mt-2">
          <DialogHeader>
            <DialogTitle>Provide Details About the Quote:</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
          </DialogDescription>
        </div>

        <Reform schema={QuoteProposalValuesSchema} id={QUOTE_FORM_ID} onSubmit={generateQuote} methods={formMethods}>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <h5>Mep</h5>
              <span className="text-destructive">{formMethods.formState.errors?.mep?.message}</span>
              <Input {...formMethods.register("mep")} />
            </div>
            <div>
              <h5>Carrier Name</h5>
              <span className="text-destructive">{formMethods.formState.errors?.carrierName?.message}</span>
              <Input {...formMethods.register("carrierName")} />
            </div>
            <div>
              <h5>Premium</h5>
              <span className="text-destructive">{formMethods.formState.errors?.premium?.message}</span>
              <Input {...formMethods.register("premium")} />
            </div>
            <div>
              <h5>Inspection Fees</h5>
              <span className="text-destructive">{formMethods.formState.errors?.inspectionFee?.message}</span>
              <Input {...formMethods.register("inspectionFee")} />
            </div>
            <div>
              <h5>Insured Name</h5>
              <span className="text-destructive">{formMethods.formState.errors?.insuredName?.message}</span>
              <Input {...formMethods.register("insuredName")} />
            </div>
            <div>
              <h5>Effective Date</h5>
              <span className="text-destructive">{formMethods.formState.errors?.effectiveDate?.message}</span>
              <Input type="date" {...formMethods.register("effectiveDate")} />
            </div>
            <div>
              <h5>Home State</h5>
              <span className="text-destructive">{formMethods.formState.errors?.homeState?.message}</span>
              <Input {...formMethods.register("homeState")} />
            </div>
            <div className="col-span-2">
              <h5>Subjectivities</h5>
              <span className="text-destructive">{formMethods.formState.errors?.subjectivities?.message}</span>
              <Textarea
                {...formMethods.register("subjectivities")}
                rows={8}
                name="subjectivities"
                placeholder="List Subjectivities"
              />
            </div>
          </div>
          <Button type="submit" form={QUOTE_FORM_ID} className="w-full my-4">
            Save
          </Button>
        </Reform>
      </DialogContent>
    </Dialog>
  );
};

export function WaitingOnUnderwritingDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{opportunity.broker?.firstName} has uploaded new quote(s)</CardTitle>
        <CardDescription>
          Thank you for allowing QuoteWell the opportunity to quote and earn your business! We are excited to share the
          following quote(s)
        </CardDescription>
        <CardDescription>
          Please reach out to me with any questions, changes, or bind orders you would like to make!
        </CardDescription>
      </CardHeader>
      <CardContent>
        {opportunity.submissions
          .filter((s) => s.processedQuoteFiles.length > 0)
          .flatMap((s) =>
            s.processedQuoteFiles.map((file) => {
              return (
                <a
                  key={file.id}
                  href={`/api/files/${file.id}`}
                  download={file.filename}
                  media={file.mimeType}
                  className="flex gap-2 items-start leading-tight hover:text-primary"
                  onClick={async (e) => {
                    e.preventDefault();
                    // Not the best since it loads the entire file into memory before giving the user feedback.
                    // But good enough for now
                    const blob = await fetch(`/api/files/${file.id}`, {
                      headers: getAuthRequestHeaders(),
                    })
                      .then((r) => r.blob())
                      // Create a new blob with the mime type
                      .then((b) => b.slice(0, b.size, file.mimeType));

                    const url = URL.createObjectURL(blob);
                    const anchor = document.createElement("a");
                    anchor.href = url;
                    anchor.download = file.filename;

                    document.body.append(anchor);
                    anchor.click();

                    URL.revokeObjectURL(url);
                    anchor.remove();
                  }}
                >
                  <Button variant="ghost" size="sm" className="flex gap-2 content-center p-2 my-1 w-full justify-start">
                    {s.appetiteProduct.coreLines.join(", ")} - {s.appetiteProduct.carrierName}
                    <Icon icon="attachment" className="scale-150" />
                    {file.filename}
                  </Button>
                </a>
              );
            })
          )}
      </CardContent>
    </Card>
  );
}

const ReferralFormSchema = z.object({
  referralReason: z.string().min(1),
});

type SubmissionType = NonNullable<ExpeditedOpportunityQuery["opportunity"]>["submissions"][number];

export function ReferButton({
  submission,
  opportunity,
}: {
  submission: SubmissionType;
  opportunity: Omit<ExpeditedOpportunityStatusViewProps, "refetch">["opportunity"];
}) {
  const formMethods = useForm<z.infer<typeof ReferralFormSchema>>({
    resolver: zodResolver(ReferralFormSchema),
    defaultValues: {
      referralReason: "",
    },
  });

  const [portalSubmission, setPortalSubmission] = useState(false);
  const [fileIds, fileActions] = useSet();

  const [transition, { loading: loadingTransition }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        payload: JSON.stringify({
          submissionId: submission.id,
          fileIds: [...fileIds],
          portalSubmission,
        }),
        action: "SendReferralToCarrier",
        opportunityId: opportunity.id,
      },
    },
  });

  const [updateSubmission, { loading: loadingSubmissionUpdate }] = useUpdateSubmissionMutation();

  const handleSubmit = async (_e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();

    if (!isValid) {
      return toast({ title: "A reason is required" });
    }

    return updateSubmission({
      variables: {
        input: {
          id: submission.id,
          referralReason: formMethods.getValues("referralReason"),
          status: SubmissionStatus.QuotingReferred,
        },
      },
      onCompleted: () => {
        return transition({
          onError: (error) => {
            toast({ title: error.message });
          },
          onCompleted: () => {
            toast({ title: "Referral sent!" });
          },
        });
      },
    });
  };

  return (
    <Dialog
      onOpenChange={(o) => {
        if (o) {
          setPortalSubmission(false);
          fileActions.reset();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button size="sm" variant="default">
          Refer
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Referral Details</DialogTitle>
          <DialogDescription>Explain why this submission is being marked as a referral.</DialogDescription>
          <DialogDescription>
            We'll email these details and any selected files to the carrier contact for this market product.
          </DialogDescription>
        </DialogHeader>
        <Reform
          schema={ReferralFormSchema}
          id={"REFERRAL_REASON"}
          onSubmit={handleSubmit}
          methods={formMethods}
          className="space-y-4"
        >
          <div className="grid gap-4 py-4">
            <div className="grid items-center">
              <FormField
                control={formMethods.control}
                name="referralReason"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Referral Reason</FormLabel>
                    <FormControl>
                      <Input placeholder="Why is this submission considered a referral?" {...field} />
                    </FormControl>
                    <FormDescription>This will be sent along to the carrier.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator />
            <div>
              <FormLabel>Files</FormLabel>
              {opportunity.files.length === 0 && <div>No files available</div>}
              {opportunity.files.map((f) => {
                return (
                  <Label key={f.id} className="my-1 flex justify-between text-xs">
                    {f.filename}
                    <Checkbox
                      checked={fileIds.has(f.id)}
                      onCheckedChange={(value) => {
                        if (value) {
                          fileActions.add(f.id);
                        } else {
                          fileActions.remove(f.id);
                        }
                      }}
                      aria-label="Select"
                    />
                  </Label>
                );
              })}
            </div>
          </div>

          <Separator />
          <div>
            <div className="font-bold">Does this carrier collect referral submissions via their web portal?</div>
            <Label className="my-4 flex  justify-between">
              Yes
              <Checkbox
                checked={portalSubmission}
                onCheckedChange={(value) => {
                  setPortalSubmission(value ? true : false);
                }}
                aria-label="Select"
              />
            </Label>
            <span className="text-sm">Checking this box will not send an email to the carrier.</span>
          </div>
          <DialogFooter>
            <Button disabled={loadingSubmissionUpdate || loadingTransition} type="submit">
              Send Referral To Carrier
            </Button>
          </DialogFooter>
        </Reform>
      </DialogContent>
    </Dialog>
  );
}

function AddRateForm({ hazards }: { hazards: Array<{ id: string; cgl?: string | null }> }) {
  const error = useFieldError("locationId");
  const { getValues, setValue } = useFormContext<z.infer<typeof AddRateSchema>>();

  return (
    <div className="flex flex-col gap-4">
      <h3>Add Rate</h3>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <h4 className={cn({ "text-red-500": !!error })}>CGL{error ? `- ${error.message?.toString()}` : null}</h4>
          <Select defaultValue={getValues().hazardId} onValueChange={(v) => setValue("hazardId", v)}>
            <SelectTrigger
              className={cn({
                "border-red-500": !!error,
              })}
            >
              <SelectValue placeholder="Select CGL" />
            </SelectTrigger>
            <SelectContent>
              {hazards.map((hazard) => (
                <SelectItem key={hazard.id} value={hazard.id}>
                  {hazard.cgl}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <h4>Carrier Name</h4>
          <InputFormField type="text" name="carrierName" />
        </div>

        <div className="flex flex-col">
          <h4>LOB</h4>
          <InputFormField type="text" name="lob" />
        </div>

        <div className="flex flex-col">
          <h4>Product Rate</h4>
          <InputFormField type="text" name="productRate" />
        </div>

        <div className="flex flex-col">
          <h4>Premises Rate</h4>
          <InputFormField type="text" name="premisesRate" />
        </div>

        <div className="flex flex-col">
          <h4>Consolidated Rate</h4>
          <InputFormField type="text" name="consolidatedRate" />
        </div>
      </div>

      <div className="flex justify-between">
        <Button type="reset" variant="destructive">
          Cancel
        </Button>
        <Button type="submit">Add</Button>
      </div>
    </div>
  );
}
