import { groupBy, startCase, uniq } from "lodash";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Field } from "@/components/ui/field-inputs";
import { Form, FormField } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/utils";
import { abbreviateLob, formatMoney } from "@cp/toolkit";
import {
  BindingAuthority,
  MarketTypes,
  useExpeditedOpportunityTransitionMutation,
  useSearchAppetiteQuery,
} from "../../../../generated/graphql";
import { HasInternalRole } from "../../../components/has-role";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Loading } from "../../../components/ui/loading";
import { MarketingEfforts } from "../../details/marketing-efforts/marketing-efforts";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export function VerifyBindingMarketingPlan({ opportunity, refetch }: ExpeditedOpportunityStatusViewProps) {
  const { data, loading } = useSearchAppetiteQuery({
    variables: {
      id: opportunity.id,
    },
  });

  const bindingMarkets = data?.appetiteSearchForOpportunity.scoredProducts.filter(
    (p) => p.product.marketType === MarketTypes.BindingAuthority
  );

  const methods = useForm({
    defaultValues: { products: [] },
  });
  const [transition, { loading: loadingTransition }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: {
        opportunityId: opportunity.id,
      },
    },
    onCompleted: () => refetch(),
  });

  const transitionNext = () =>
    transition({
      variables: {
        input: {
          opportunityId: opportunity.id,
          payload: JSON.stringify(methods.getValues().products),
        },
      },
    });

  if (bindingMarkets?.length === 0 || loading) {
    return <Loading />;
  }

  const groups = groupBy(bindingMarkets, (m) => (uniq(m.product.authority) ?? []).join(" / ") || "Unknown");

  const indications = opportunity.hazards.reduce((acc, hazard) => {
    for (const indication of hazard.indications) {
      if (!indication.minPremium || !indication.maxPremium) {
        continue;
      }

      if (!acc[indication.carrierName]) {
        acc[indication.carrierName] = { lob: indication.lob, minTotalPremium: 0, maxTotalPremium: 0, rates: [] };
      }

      acc[indication.carrierName].minTotalPremium += Number.parseFloat(indication.minPremium.toString());
      acc[indication.carrierName].maxTotalPremium += Number.parseFloat(indication.maxPremium.toString());
      acc[indication.carrierName].rates.push({
        cgl: hazard.cgl ?? null,
        minRate: indication.minRate ?? null,
        maxRate: indication.maxRate ?? null,
      });
    }

    return acc;
  }, {} as Record<string, { minTotalPremium: number; maxTotalPremium: number; lob: string; rates: Array<{ cgl: string | null; minRate: string | null; maxRate: string | null }> }>);

  return (
    <Form {...methods}>
      <Card>
        <CardHeader>
          <CardTitle>Verify Binding Marketing Plan</CardTitle>
          <CardDescription>
            Please confirm the binding markets that you would like to quote this risk with.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <CardDescription>
            You will have a chance later to add Brokerage Markets as well if you would like to market more broadly.
          </CardDescription>
          <HasInternalRole>
            <div className="p-4 flex gap-2">
              {Object.entries(indications).map(([carrierName, { lob, minTotalPremium, maxTotalPremium, rates }]) => {
                const appetiteProduct = bindingMarkets?.find(
                  (m) =>
                    m.product.carrierName === carrierName &&
                    m.product.coreLines.length === 1 &&
                    abbreviateLob(m.product.coreLines[0]) === lob
                );
                return (
                  <FormField
                    key={carrierName}
                    name="products"
                    render={({ field }) => {
                      const checked = appetiteProduct ? field.value?.includes(appetiteProduct.product.id) : null;
                      return (
                        <Card className={cn("max-w-xs w-full", { ring: checked })}>
                          <CardHeader>
                            <CardTitle
                              className={cn("flex items-center justify-between", { "font-extrabold": checked })}
                            >
                              <span>{carrierName} - GL</span>
                              {appetiteProduct ? (
                                <Field className={cn("", checked ? "text-foreground" : "text-muted-foreground")}>
                                  <Switch
                                    checked={checked}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([...field.value, appetiteProduct.product.id])
                                        : field.onChange(
                                            field.value?.filter((value: string) => value !== appetiteProduct.product.id)
                                          );
                                    }}
                                  />
                                </Field>
                              ) : null}
                            </CardTitle>
                          </CardHeader>
                          <CardContent>
                            <dl>
                              {appetiteProduct ? (
                                <div className="flex gap-2 justify-between max-w-xs border-b p-2">
                                  <dt className="font-medium">Authority</dt>
                                  <dd>
                                    {appetiteProduct.product.bindingGuideResults.map((r) => {
                                      return <BindingGuideResult key={r.id} {...r} />;
                                    })}
                                  </dd>
                                </div>
                              ) : null}

                              {rates.map(({ cgl, minRate, maxRate }) => (
                                <div key={cgl} className="flex gap-2 justify-between max-w-xs border-b p-2">
                                  <dt className="font-medium">CGL {cgl} Rate</dt>
                                  <dd>
                                    {minRate} - {maxRate}
                                  </dd>
                                </div>
                              ))}

                              <div className="flex gap-2 justify-between max-w-xs border-b p-2">
                                <dt className="font-medium">Premium</dt>
                                <dd className={cn("", { "font-bold": checked })}>
                                  {formatMoney(minTotalPremium)} - {formatMoney(maxTotalPremium)}
                                </dd>
                              </div>
                            </dl>
                          </CardContent>
                        </Card>
                      );
                    }}
                  />
                );
              })}
            </div>
          </HasInternalRole>
          <div className="flex flex-col gap">
            {Object.entries(groups)
              .sort()
              .map(([authority, markets]) => (
                <div key={authority} className="bg-muted p-2">
                  <h3>{authority}</h3>
                  {markets
                    .sort((a, b) => (a.product.carrierName > b.product.carrierName ? 1 : -1))
                    .map((m) => (
                      <div key={m.product.id} className="flex flex-row items-center">
                        <FormField
                          key={m.product.id}
                          name="products"
                          render={({ field }) => {
                            const checked = field.value?.includes(m.product.id);
                            return (
                              <Field
                                className={cn("gap-6 bg-muted", checked ? "text-foreground" : "text-muted-foreground")}
                              >
                                <Switch
                                  checked={checked}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([...field.value, m.product.id])
                                      : field.onChange(field.value?.filter((value: string) => value !== m.product.id));
                                  }}
                                />
                                {m.product.carrierName} - {m.product.carrierProductName}
                              </Field>
                            );
                          }}
                        />
                        {m.product.bindingGuideResults.map((r) => {
                          return <BindingGuideResult key={r.id} {...r} />;
                        })}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </CardContent>
        <CardFooter>
          <Button
            size="sm"
            disabled={loadingTransition || bindingMarkets?.length === 0}
            onClick={() => transitionNext()}
          >
            Verify
          </Button>
        </CardFooter>
      </Card>
    </Form>
  );
}

export function VerifyBindingMarketingPlanDisplay({ opportunity }: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {opportunity.broker?.firstName} {opportunity.broker?.lastName} confirmed the markets for this opportunity.
        </CardTitle>
      </CardHeader>
      <CardContent>
        <MarketingEfforts opportunity={opportunity} submissions={opportunity.submissions} />
      </CardContent>
    </Card>
  );
}

function BindingGuideResult({
  id,
  result,
  explanation,
}: {
  id: string;
  result: BindingAuthority;
  explanation?: string | null;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div
          key={id}
          className={cn({
            "hover:cursor-pointer hover:underline": true,
            "text-success": result === BindingAuthority.InAuthority,
            "text-amber-500": result === BindingAuthority.Submit,
            "text-red-900": result === BindingAuthority.Prohibited,
          })}
        >
          {startCase(result)}
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="hidden">
          <DialogTitle>Result</DialogTitle>
        </DialogHeader>
        <div>{explanation}</div>
      </DialogContent>
    </Dialog>
  );
}
