import { UUID } from "@cp/toolkit";
import { useDocumentTitle } from "usehooks-ts";

import { Badge } from "@/components/ui/badge";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { cn, parseError } from "@/utils";
import {
  useAgenciesWithOwnerQuery,
  useBrokerAccountsQuery,
  useUpdateAgencyOwnerMutation,
} from "../../generated/graphql";

export function AgencyOwnership() {
  const { data: agenciesData, refetch } = useAgenciesWithOwnerQuery();
  const [updateAgencyOwner] = useUpdateAgencyOwnerMutation({
    onCompleted: (update) => {
      refetch();
      toast({ title: `Updated ${update.updateAgency.name} Owner` });
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });
  useDocumentTitle("Admin: Agency Ownership");

  return (
    <Table className="bg-background overflow-hidden rounded">
      <TableHeader>
        <TableRow>
          <TableHead>Agency</TableHead>
          <TableHead>Owner</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {agenciesData?.agencies.map((agency) => (
          <TableRow key={agency.id}>
            <TableCell>
              <span className="flex gap-2">
                {agency.name}
                {agency.isTest && <Badge>Test</Badge>}
              </span>
            </TableCell>
            <TableCell>
              <SelectBroker
                brokerId={agency.owner?.id}
                onSelect={(ownerId) => {
                  void updateAgencyOwner({
                    variables: { input: { agencyId: agency.id, ownerId: ownerId == "-" ? null : ownerId } },
                  });
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function SelectBroker({ brokerId, onSelect }: { brokerId?: UUID; onSelect: (ownerId: UUID) => void }) {
  const { data: brokersData } = useBrokerAccountsQuery();
  const selectedBroker = brokersData?.userAccounts.find((broker) => broker.id === brokerId);

  return (
    <Select value={selectedBroker?.id || "-"} onValueChange={onSelect}>
      <SelectTrigger className={cn({ "text-muted-foreground/50": !brokerId })}>
        <SelectValue placeholder="Agency Owner" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="-">Unassigned</SelectItem>
        {brokersData?.userAccounts.map((broker) => (
          <SelectItem key={broker.id} value={broker.id}>{`${broker.firstName} ${broker.lastName}`}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
