import { KnownClientData } from "@qw/qw-common";
import { TdInput } from "../components/td-fields";
import { Address } from "../fieldsets/Address";
import { LossHistory } from "../fieldsets/loss-history";

export const SubmissionTemplate = () => (
  <div>
    <section>
      <h1>Submission Template</h1>
    </section>
    <section>
      <Address header="Mailing Address" rootPath="mailing" />
    </section>
    <section>
      <table>
        <tbody>
          <tr>
            <TdInput colSpan={4} label="Description Of Operations" name={KnownClientData.DescriptionOfOperations} />
          </tr>
          <tr>
            <TdInput colSpan={4} label="Exposures" name="exposures" />
          </tr>
          <tr>
            <TdInput colSpan={4} label="Limits Requested" name="limitsRequested" />
          </tr>
          <tr>
            <TdInput colSpan={4} label="Attached Information" name="attachedInformation" />
          </tr>
          <tr>
            <TdInput colSpan={4} label="Pending Information" name="pendingInformation" />
          </tr>
        </tbody>
      </table>
    </section>
    <LossHistory rootPath="gl" source="submission" />
  </div>
);
