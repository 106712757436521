import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useBrokerAccountsQuery } from "src/generated/graphql";
import { SearchParamSelect } from "../../components/ui/search-param-select";

export const SelectBroker = ({ className }: { className?: string }) => {
  const { data } = useBrokerAccountsQuery({ fetchPolicy: "cache-first" });
  const brokerAccounts = data?.userAccounts;

  return (
    <SearchParamSelect name="brokerId">
      <SelectTrigger className={className}>
        <SelectValue placeholder="Select Assignee" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="">All</SelectItem>
        <SelectItem value="null">Unassigned</SelectItem>
        {brokerAccounts?.map((acc) => (
          <SelectItem key={acc.id} value={acc.id}>
            {acc.firstName} {acc.lastName}
          </SelectItem>
        ))}
      </SelectContent>
    </SearchParamSelect>
  );
};
