import { sortBy } from "lodash";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading, Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import {
  AgentActionType,
  OpportunityRequirementsQuery,
  SupplementalForm,
  useCompleteAgentActionMutation,
  useDeleteAgentActionMutation,
  useOpportunityRequirementsQuery,
} from "../../../../generated/graphql";
import { uploadFile } from "../../../../utils/file.utils";
import { AddRequirements } from "../../info/add-requirements";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export const WaitingForInitialRequirements = ({ opportunity }: ExpeditedOpportunityStatusViewProps) => (
  <Card>
    <CardHeader>
      <CardTitle>Add Additional Requirements</CardTitle>
      <CardDescription className="text-sm">
        Add any additional requirements needed from the agent. Information request emails will automatically send after
        completing this step, you will be notified when complete.
      </CardDescription>
    </CardHeader>
    <CardContent>
      <Requirements />
    </CardContent>
    <CardFooter>
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm">Add Requirement</Button>
        </DialogTrigger>
        <DialogHeader className="hidden">
          <DialogTitle>Add Requirement</DialogTitle>
        </DialogHeader>
        <AddRequirements opportunityId={opportunity.id} />
      </Dialog>
    </CardFooter>
  </Card>
);

const Requirements: React.FC = () => {
  const { opportunityId } = useParams();
  const { toast } = useToast();

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: {
      id: opportunityId!,
    },
    pollInterval: 1000,
  });

  const [triggerCompleteAgentAction] = useCompleteAgentActionMutation({
    onCompleted() {
      toast({ title: "Agent Action marked complete" });
      return refetch();
    },
    onError(e) {
      toast({ title: `Error marking agent action complete: ${e}` });
    },
  });
  const [triggerDeleteAgentAction] = useDeleteAgentActionMutation({
    onCompleted() {
      toast({ title: "Agent Action deleted" });
      return refetch();
    },
    onError(e) {
      toast({ title: `Error deleting agent action: ${e}` });
    },
  });

  const completeAgentAction = async (id: string) => {
    return triggerCompleteAgentAction({ variables: { input: { id } } });
  };

  const deleteAgentAction = async (id: string) => {
    return triggerDeleteAgentAction({ variables: { id } });
  };

  if (!loading && !opportunity) {
    return null;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : opportunity ? (
        <ul className="space-y-2">
          {/* Using order of the array for the key. Good enough for now */}
          {sortBy(opportunity?.agentActions, [(action) => !action.expeditedRequirement, "title"]).map((action, i) => (
            <li key={i} className="filled flex gap-2 items-center">
              <AgentAction
                action={action}
                opportunity={opportunity}
                clientId={opportunity.insured.id}
                onCompleteAgentAction={completeAgentAction}
                onDeleteAgentAction={deleteAgentAction}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

const AgentAction = ({
  action,
  opportunity,
  clientId,
  onCompleteAgentAction,
  onDeleteAgentAction,
}: {
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunity: NonNullable<OpportunityRequirementsQuery["opportunity"]>;
  clientId: string;
  onCompleteAgentAction: (id: string) => void;
  onDeleteAgentAction: (id: string) => void;
}) => {
  return (
    <AgentActionWrapper action={action} opportunityId={opportunity.id} clientId={clientId}>
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <Icon
            icon={
              action.complete
                ? "check_circle"
                : opportunity.filesProcessing && action.actionType === AgentActionType.UploadFile
                ? "cached"
                : "circle"
            }
            className={cn("", {
              "text-green-600": action.complete,
              "filled opacity-10": !action.complete,
              "animate-spin":
                opportunity.filesProcessing && !action.complete && action.actionType === AgentActionType.UploadFile,
            })}
          />
          <span>{action.title}</span>
        </div>
        {!action.complete && (
          <HasInternalRole>
            <div className="flex justify-end">
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  return onDeleteAgentAction(action.id);
                }}
              >
                <Icon icon="block" className="text-destructive" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.preventDefault();
                  return onCompleteAgentAction(action.id);
                }}
              >
                <Icon icon="check" className="text-success" />
              </Button>
            </div>
          </HasInternalRole>
        )}
      </div>
    </AgentActionWrapper>
  );
};

const AgentActionWrapper = ({
  action,
  opportunityId,
  clientId,
  children,
}: React.PropsWithChildren<{
  action: NonNullable<
    NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
  >["agentActions"][0];
  opportunityId: string;
  clientId: string;
}>) => {
  if (action.supplementalForm) {
    const link = (() => {
      switch (action.supplementalForm) {
        case SupplementalForm.Daycare:
          return "/ategrity-daycare-supplemental.pdf";
        case SupplementalForm.ResidentialLro:
          return "/Please fill out if vacant - GenStar - LRO Supplemental.pdf";
        default:
          return `/supplementals/${clientId}?form=${action.supplementalForm}&opportunityId=${opportunityId}`;
      }
    })();

    return (
      <Link to={link} target="_blank" className="w-full">
        {children}
      </Link>
    );
  }

  return <div className="w-full">{children}</div>;
};

export function WaitingForInitialRequirementsAgent({
  refetch,
  opportunity: oppData,
}: ExpeditedOpportunityStatusViewProps) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <FileDropZoneIntialRequirements>
      <Card>
        <CardHeader>
          <CardTitle>Hello {oppData.agent.firstName}!</CardTitle>
          <CardDescription>
            Thanks for submitting this risk to QuoteWell. We require the following information to move forward.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Requirements />
        </CardContent>
        <CardFooter>
          <Button size="sm">
            <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
              <input
                type="file"
                name="file"
                className="hidden"
                onChange={async (e) => {
                  setSubmitting(true);
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0]; // Access the first file

                    await uploadFile(file, oppData.id);
                    setSubmitting(false);
                    refetch();
                  }
                }}
              />
              {submitting ? <Spinner /> : "Upload"}
            </label>
          </Button>
        </CardFooter>
      </Card>
    </FileDropZoneIntialRequirements>
  );
}

export const FileDropZoneIntialRequirements = ({ children }: React.PropsWithChildren) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { toast } = useToast();

  return (
    <Dropzone
      noClick
      onDrop={async (files) => {
        toast({ title: "Loading...." });

        void files.map(async (f) => {
          return uploadFile(f, opportunityId ?? "");
        });
      }}
    >
      {({ getRootProps }) => <div {...getRootProps()}>{children}</div>}
    </Dropzone>
  );
};
