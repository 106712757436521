import { useParams } from "react-router";

import { useQuoteQuery } from "../../../generated/graphql";
import { QuoteStateRenderer } from "./quote";

export function QuoteDetails() {
  const { quoteId } = useParams<"quoteId">();
  const { data } = useQuoteQuery({
    variables: {
      id: quoteId ?? quoteId!,
    },
    skip: !quoteId,
  });

  const quote = data?.quote;

  if (!quote) {
    return null;
  }

  return <QuoteStateRenderer quote={quote} />;
}
