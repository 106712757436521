import React, { useEffect } from "react";

import { HasRole } from "@/components/has-role";
import { Label } from "@/components/ui/label";
import { Loading } from "@/components/ui/loading";
import { Pane } from "@/components/ui/pane";
import { Switch } from "@/components/ui/switch";
import {
  OnBrokerAssignmentQueueUpdatedDocument,
  OnBrokerAssignmentQueueUpdatedSubscription,
  OnBrokerAssignmentQueueUpdatedSubscriptionVariables,
  Roles,
  useAssignemntQueueQuery,
  useBrokerAccountsQuery,
  useUpdateUserAssignmentsEnabledMutation,
} from "../../generated/graphql";

export const TRIAGE_DATE_FORMAT = "yyyy-MM-dd";

export const KNOWN_BROKER_EMAILS = {
  QUOTEWELL_BROKER: "submissions@quotewell.com",
  ALBY_BROKER: "alby@quotewell.com",
  JAMES_BROKER: "jmonks+broker@quotewell.com",
};

export const BROKER_IGNORE_LIST = [
  KNOWN_BROKER_EMAILS.QUOTEWELL_BROKER,
  KNOWN_BROKER_EMAILS.ALBY_BROKER,
  KNOWN_BROKER_EMAILS.JAMES_BROKER,
];

export const Enablement: React.FC = () => {
  const { data, loading } = useBrokerAccountsQuery();
  const [mutate] = useUpdateUserAssignmentsEnabledMutation();
  const brokers = data?.userAccounts ?? [];

  const filteredBrokers = brokers.filter((broker) => !BROKER_IGNORE_LIST.includes(broker.email));

  const handleToggle = (brokerId: string, enabled: boolean) => {
    void mutate({
      variables: {
        input: {
          brokerId,
          enabled,
        },
      },
    });
  };

  return (
    <HasRole roles={[Roles.Admin, Roles.Dj]}>
      <Pane>
        {loading ? (
          <Loading />
        ) : (
          <div id="capacities" className="space-y-2 mr-4">
            <h2>Assignments Enabled For Brokers</h2>
            <p>Turn on/off to add/remove broker from the queues for their roles</p>

            <div className="flex justify-between">
              <div className="flex-col gap-3">
                {filteredBrokers.map(({ id, firstName, lastName, assignmentsEnabled, roles }) => {
                  return (
                    <div key={id} className="mx-2 my-6">
                      <div className="flex items-center space-x-2">
                        <Switch
                          id="airplane-mode"
                          checked={assignmentsEnabled}
                          onCheckedChange={(val) => handleToggle(id, val)}
                        />
                        <Label htmlFor="airplane-mode">{`${firstName} ${lastName}`}</Label>
                      </div>
                      <div className="text-xs pl-10">{roles.join(", ")}</div>
                    </div>
                  );
                })}
              </div>
              <div>
                <AssignmentQueue role={Roles.BindingAuthority} />
              </div>
              <div>
                <AssignmentQueue role={Roles.Brokerage} />
              </div>
              <div>
                <AssignmentQueue role={Roles.PreferredBroker} />
              </div>
            </div>
          </div>
        )}
      </Pane>
    </HasRole>
  );
};

interface AssignmentQueueProps {
  role?: Roles;
}

export const AssignmentQueue: React.FC<AssignmentQueueProps> = ({ role = Roles.Broker }) => {
  const { data, subscribeToMore } = useAssignemntQueueQuery();

  const assignmentQueue = data?.brokerAssignmentQueue.brokers ?? [];

  const filteredQueue = assignmentQueue.filter((broker) => broker.roles.includes(role));

  useEffect(() => {
    return subscribeToMore<
      OnBrokerAssignmentQueueUpdatedSubscription,
      OnBrokerAssignmentQueueUpdatedSubscriptionVariables
    >({
      document: OnBrokerAssignmentQueueUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newQueue = subscriptionData.data.brokerAssignmentQueueUpdated;

        const newRet = {
          brokerAssignmentQueue: newQueue,
        };

        return newRet;
      },
    });
  }, []);

  return (
    <div>
      <h2>{role} Queue</h2>

      <div>
        {filteredQueue?.map((broker) => {
          return (
            <div key={broker.id} className="flex items-center space-x-2 mx-2 my-3">
              <p>{`${broker.firstName} ${broker.lastName}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
