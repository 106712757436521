import { SearchParamSelect } from "@/components/ui/search-param-select";
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const SelectExpedited = () => (
  <SearchParamSelect name="isExpedited">
    <SelectTrigger>
      <SelectValue placeholder="Select Expedit...ion?" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="">All</SelectItem>
      <SelectItem value="true">Expedited Opportunities</SelectItem>
      <SelectItem value="false">Legacy Opportunities</SelectItem>
    </SelectContent>
  </SearchParamSelect>
);
