import { useParams } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import { Bar } from "@/components/ui/bar";
import { PaneGroup } from "@/components/ui/pane";
import { InsuredsList } from "./insureds-list";
import { InsuredsListFilter } from "./insureds-list-filter";

export const InsuredsRoot: React.FC = () => {
  const { data: user } = useMyAccount();
  const { agencyId } = useParams();

  if (!user) {
    return null;
  }

  return (
    <PaneGroup>
      <Bar as="header">
        <div className="space-y-1">
          <div className="font-semibold text-sm">
            {user.firstName} {user.lastName}
          </div>
          <div className="text-xs">{user.agency.name}</div>
        </div>
        <HasInternalRole>
          <InsuredsListFilter />
        </HasInternalRole>
      </Bar>
      <InsuredsList agencyId={agencyId ?? user.agencyId} />
    </PaneGroup>
  );
};
