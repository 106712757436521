import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { cn, parseError } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColumnDef, HeaderContext, Row } from "@tanstack/react-table";
import { format } from "date-fns";
import { join, uniq } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import {
  DesireToRemarket,
  LeadFragment,
  QwCarrierMatch,
  SubmissionStatusCategory,
  useUpdateLeadMutation,
  useUpdateManyLeadsMutation,
} from "../../../generated/graphql";
import { useMyAccount } from "../../auth/useMyAccount";
import { ValidSearchKey } from "../../components/adapt-search-params";
import { HasInternalRole, hasInternalRole } from "../../components/has-role";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { DataTable, DataTableColumnHeader } from "../../components/ui/data-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components/ui/tooltip";
import { toast, useToast } from "../../components/ui/use-toast";
import { FieldContainer, Input, TextArea } from "../../forms/components/FormFields";
import { Reform } from "../../forms/Reform";

interface Props {
  leads: LeadFragment[];
}

export const LeadsDataTable: React.FC<Props> = ({ leads }) => {
  const [selectedLeads, setSelectedLeads] = useState<Set<string>>(new Set());
  const columns = useColumnsWithControlledSelections(
    (leadId) => {
      const selected = new Set(selectedLeads);
      selected.add(leadId);
      setSelectedLeads(new Set(selected));
    },
    (leadId) => {
      const selected = new Set(selectedLeads);
      selected.delete(leadId);
      setSelectedLeads(new Set(selected));
    }
  );

  return (
    <>
      {leads.length > 0 ? (
        <DataTable columns={columns} data={leads} footer={<Actions />} />
      ) : (
        <div className="p-2">No Leads Found</div>
      )}
    </>
  );
};

export const Actions = ({ selected = [] }: { selected?: LeadFragment[] }) => {
  const [updateManyLeads] = useUpdateManyLeadsMutation();
  const { toast } = useToast();
  const leads = selected;
  const leadIds = leads.map((lead) => lead.id);
  const accountNames = uniq(leads.map((lead) => lead.accountName));
  const expirationDates = uniq(leads.map((lead) => lead.expirationDate));
  const archived = leads[0]?.archived;
  const highlighted = leads[0]?.highlighted;
  const multipleAccounts = accountNames.length > 1;
  const multipleDates = expirationDates.length > 1;
  const icon = multipleAccounts ? "error" : multipleDates ? "warning" : "check_circle";

  if (leads.length === 0) {
    return null;
  }

  return (
    <>
      <HasInternalRole>
        <div className="flex items-center gap-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  void updateManyLeads({
                    variables: {
                      input: {
                        ids: leadIds,
                        archived: !archived,
                        highlighted: false,
                      },
                    },
                    onError: (error) =>
                      toast({ title: "Error", description: parseError(error), variant: "destructive" }),
                  });
                }}
              >
                <Icon icon={archived ? "visibility" : "visibility_off"} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{archived ? "Unarchive" : "Archive"} selected leads</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  void updateManyLeads({
                    variables: {
                      input: {
                        ids: leadIds,
                        archived: false,
                        highlighted: !highlighted,
                      },
                    },
                    onError: (error) =>
                      toast({ title: "Error", description: parseError(error), variant: "destructive" }),
                  });
                }}
              >
                <Icon icon={highlighted ? "bookmark_remove" : "bookmark"} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{highlighted ? "Un-highlight" : "Highlight"} selected leads</TooltipContent>
          </Tooltip>
          <EditLeadNotes
            leads={leads}
            onSubmit={(leadId, notes) => {
              void updateManyLeads({
                variables: {
                  input: {
                    ids: [leadId],
                    notes,
                  },
                },
                onError: (error) => toast({ title: "Error", description: parseError(error), variant: "destructive" }),
              });
            }}
          />
        </div>
      </HasInternalRole>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button size="sm" asChild className="ml-2">
            <Link to={`/new?leadIds=${join(leadIds, ",")}`} target="_blank">
              <Icon icon={icon} />
              Create New Opportunity
            </Link>
          </Button>
        </TooltipTrigger>
        {(multipleAccounts || multipleDates) && (
          <TooltipContent>
            Multiple {accountNames.length > 1 && "clients and "}expiration dates have been selected.
          </TooltipContent>
        )}
      </Tooltip>
    </>
  );
};

const useColumnsWithControlledSelections = (
  onSelect: (leadId: string) => void,
  onDeselect: (leadId: string) => void
): Array<ColumnDef<LeadFragment>> => {
  const { data: user } = useMyAccount();

  return [
    {
      id: "select",
      header: ({ table }) => {
        const checked = table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate");
        return (
          <Checkbox
            className={cn(!checked && "border-muted-foreground")}
            checked={checked}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        );
      },
      cell: ({ row }) => {
        const checked = row.getIsSelected();
        return (
          <Checkbox
            className={cn(!checked && "border-muted-foreground")}
            checked={checked}
            onCheckedChange={(isChecked) => {
              row.toggleSelected(!!isChecked);
              isChecked ? onSelect(row.original.id) : onDeselect(row.original.id);
            }}
            aria-label="Select row"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "accountName",
      header: () => <div className={headerStyle}>Client</div>,
      cell: ({ row }) => {
        return <DisplayCell lead={row.original}>{row.getValue("accountName")}</DisplayCell>;
      },
    },
    ...(user && hasInternalRole(user)
      ? [
          {
            accessorKey: "archivedHighlightedIndicator",
            header: "",
            cell: ({ row }: { row: Row<LeadFragment> }) => {
              const lead = row.original;
              return (
                <DisplayCell lead={row.original} className="w-4">
                  {lead.archived && <Icon icon="visibility_off" />}
                  {lead.highlighted && <Icon icon="bookmark" />}
                </DisplayCell>
              );
            },
          },
          {
            accessorKey: "notesIndicator",
            header: "",
            cell: ({ row }: { row: Row<LeadFragment> }) => {
              const { notes } = row.original;
              return (
                <DisplayCell lead={row.original} className="w-4">
                  {notes && (
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button size="icon-sm" variant="ghost">
                          <Icon icon="edit_note" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Notes</DialogTitle>
                          <DialogDescription className="whitespace-pre-wrap">{notes}</DialogDescription>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  )}
                </DisplayCell>
              );
            },
          },
        ]
      : []),
    {
      accessorKey: "rawLines",
      header: () => <div className={headerStyle}>Lines</div>,
      cell: ({ row }) => {
        const rawLines: string[] = row.getValue("rawLines");
        return <DisplayCell lead={row.original}>{rawLines.join(", ")}</DisplayCell>;
      },
    },
    {
      accessorKey: "carriers",
      header: () => <div className={headerStyle}>Carrier</div>,
      cell: ({ row }) => {
        return <EditableCarrierCell lead={row.original} />;
      },
    },
    {
      accessorKey: "expirationDate",
      header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
        <DataTableColumnHeader column={column} title="Expiration" />
      ),
      cell: ({ row }) => (
        <DisplayCell lead={row.original}>{format(new Date(row.getValue("expirationDate")), "MMM d, y")}</DisplayCell>
      ),
    },
    {
      accessorKey: "premium",
      header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
        <DataTableColumnHeader column={column} title="Premium" />
      ),
      cell: ({ row }) => {
        const premium: string = row.getValue("premium") || "0";
        const formatted =
          premium === "0"
            ? "-"
            : Number.parseFloat(premium).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
              });
        return <DisplayCell lead={row.original}>{formatted}</DisplayCell>;
      },
    },
    {
      id: "agentName",
      accessorFn: (lead) => getAgentDisplayName(lead),
      header: () => <div className={headerStyle}>Agent</div>,
      cell: ({ row }) => {
        return <DisplayCell lead={row.original}>{getAgentDisplayName(row.original)}</DisplayCell>;
      },
    },
    {
      accessorKey: "naicsCode",
      header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
        <DataTableColumnHeader column={column} title="NAICS" />
      ),
      cell: ({ row }) => {
        return <DisplayCell lead={row.original}>{row.getValue("naicsCode") ?? "-"}</DisplayCell>;
      },
    },
    ...(user && hasInternalRole(user)
      ? [
          // {
          //   accessorKey: "isQwCarrier",
          //   header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
          //     <DataTableColumnHeader column={column} title="QW Carrier" />
          //   ),
          //   cell: ({ row }: { row: Row<LeadFragment> }) => {
          //     const isQwCarrier: boolean = row.getValue("isQwCarrier");
          //     return <DisplayCell lead={row.original}>{isQwCarrier ? <strong>Yes</strong> : "No"}</DisplayCell>;
          //   },
          // },
          {
            accessorKey: "pastQuotesForSimilarRisk",
            header: () => <div className={headerStyle}>Similar</div>,
            cell: ({ row }: { row: Row<LeadFragment> }) => {
              const lead = row.original;
              return (
                <DisplayCell lead={row.original}>
                  {lead.pastQuotesForSimilarRisk.length > 0 ? (
                    <Link
                      to={`/opportunity?${setSearchParams([
                        { term: lead.naicsCode || "" },
                        { submissionCoreLines: join(lead.coreLines) },
                        { submissionStatusCategory: SubmissionStatusCategory.PostQuoteStatuses },
                      ])}`}
                      className="hover:text-primary"
                      target="_blank"
                    >
                      {lead.pastQuotesForSimilarRisk.length}
                    </Link>
                  ) : (
                    <span className="text-muted-foreground">-</span>
                  )}
                </DisplayCell>
              );
            },
          },
          // {
          //   accessorKey: "topAppetiteProductScore",
          //   header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
          //     <DataTableColumnHeader column={column} title="Score" />
          //   ),
          //   cell: ({ row }: { row: Row<LeadFragment> }) => {
          //     const topAppetiteProductScore: string | null | undefined = row.getValue("topAppetiteProductScore");
          //     return (
          //       <DisplayCell lead={row.original}>
          //         {topAppetiteProductScore ? (
          //           Number(topAppetiteProductScore).toLocaleString(undefined, {
          //             style: "percent",
          //             maximumSignificantDigits: 2,
          //           })
          //         ) : (
          //           <span className="text-muted-foreground">Not calculated</span>
          //         )}
          //       </DisplayCell>
          //     );
          //   },
          // },
        ]
      : []),
    // {
    //   accessorKey: "overallFitScore",
    //   header: ({ column }: HeaderContext<LeadFragment, unknown>) => (
    //     <DataTableColumnHeader column={column} title="Fit" />
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <DisplayCell lead={row.original}>
    //         <OverallFitIndicator score={row.getValue("overallFitScore")} />
    //       </DisplayCell>
    //     );
    //   },
    // },
    {
      accessorKey: "qwCarrierMatch",
      header: () => <div className={headerStyle}>QW Carrier Match</div>,
      cell: ({ row }) => (
        <DropdownCell field="qwCarrierMatch" enumType={QwCarrierMatch} lead={row.original} internalEditsOnly={true} />
      ),
    },
    {
      accessorKey: "currentAgentCommission",
      header: ({ table }) => (
        <EditableHeader
          field="currentAgentCommission"
          label="Current Agent Commission"
          allLeadIds={table.getCoreRowModel().rows.map((r) => r.original.id)}
        />
      ),
      cell: ({ row }) => {
        return <EditableCell field="currentAgentCommission" lead={row.original} />;
      },
    },
    {
      accessorKey: "qwAgentCommission",
      header: ({ table }) => (
        <EditableHeader
          field="qwAgentCommission"
          label="QW-Proposed Agent Commission"
          allLeadIds={table.getCoreRowModel().rows.map((r) => r.original.id)}
          internalEditsOnly={true}
        />
      ),
      cell: ({ row }) => {
        return <EditableCell field="qwAgentCommission" lead={row.original} internalEditsOnly={true} />;
      },
    },
    {
      accessorKey: "desireToRemarket",
      header: () => <div className={headerStyle}>Desire To Remarket</div>,
      cell: ({ row }) => {
        return <DropdownCell field="desireToRemarket" enumType={DesireToRemarket} lead={row.original} />;
      },
    },
  ];
};

const DisplayCell = ({
  lead,
  className,
  children,
}: {
  lead: LeadFragment;
  className?: string;
  children: React.ReactNode;
}) => {
  const { data: user } = useMyAccount();
  return (
    <div
      className={cn(
        className,
        "text-xs",
        user && hasInternalRole(user) && lead.archived && "text-muted-foreground",
        lead.opportunityId && "text-muted-foreground"
      )}
    >
      {children}
    </div>
  );
};

const EditableCarrierCell: React.FC<{ lead: LeadFragment }> = ({ lead }) => {
  const [editing, setEditing] = useState(false);
  const [updateLead] = useUpdateLeadMutation({
    onError: (error) => toast({ title: "Error updating", description: parseError(error), variant: "destructive" }),
  });

  const carrier = lead.carrierOverride ?? lead.carriers.join(", ");

  if (!editing) {
    return (
      <span className="flex flex-row items-center gap-2">
        <DisplayCell lead={lead}>{carrier}</DisplayCell>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(true)}>
          <Icon icon="edit" />
        </Button>
      </span>
    );
  }

  return (
    <Reform
      schema={z.object({ carrierOverride: z.string().min(1) })}
      defaultValues={{ carrierOverride: lead.carrierOverride ?? "" }}
      onSubmit={(_, values) => {
        void updateLead({ variables: { input: { id: lead.id, carrierOverride: values.carrierOverride } } });
        setEditing(false);
      }}
    >
      <span className="flex flex-row items-center">
        <FieldContainer name="carrierOverride">
          <Input name="carrierOverride" />
        </FieldContainer>
        <Button type="submit" variant="ghost" size="icon" className="flex justify-start">
          <Icon icon="check" />
        </Button>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(false)}>
          <Icon icon="close" />
        </Button>
      </span>
    </Reform>
  );
};

const EditableCell: React.FC<{ field: keyof LeadFragment; lead: LeadFragment; internalEditsOnly?: boolean }> = ({
  field,
  lead,
  internalEditsOnly = false,
}) => {
  const { data: user } = useMyAccount();
  const [editing, setEditing] = useState(false);
  const [updateLead] = useUpdateLeadMutation({
    onError: (error) => toast({ title: "Error updating", description: parseError(error), variant: "destructive" }),
  });

  if (internalEditsOnly && user && !hasInternalRole(user)) {
    return <DisplayCell lead={lead}>{lead[field] ?? "-"}</DisplayCell>;
  }

  if (!editing) {
    return (
      <span className="flex flex-row items-center gap-2">
        <DisplayCell lead={lead}>{lead[field] ?? "-"}</DisplayCell>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(true)}>
          <Icon icon="edit" />
        </Button>
      </span>
    );
  }

  return (
    <Reform
      schema={z.object({ [field]: z.string().min(1) })}
      defaultValues={{ [field]: lead[field] ?? "" }}
      onSubmit={(_, values) => {
        void updateLead({ variables: { input: { id: lead.id, [field]: values[field] } } });
        setEditing(false);
      }}
    >
      <span className="flex flex-row items-center">
        <FieldContainer name={field}>
          <Input name={field} />
        </FieldContainer>
        <Button type="submit" variant="ghost" size="icon" className="flex justify-start">
          <Icon icon="check" />
        </Button>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(false)}>
          <Icon icon="close" />
        </Button>
      </span>
    </Reform>
  );
};

interface DropdownCellProps<T extends Record<string, string>> {
  field: keyof LeadFragment;
  enumType: T; // Accept any enum type
  lead: LeadFragment;
  internalEditsOnly?: boolean;
}

const DropdownCell = <T extends Record<string, string>>({
  field,
  enumType,
  lead,
  internalEditsOnly = false,
}: DropdownCellProps<T>) => {
  const { data: user } = useMyAccount();
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState("Select...");
  const [updateLead] = useUpdateLeadMutation({
    onError: (error) => toast({ title: "Error updating", description: parseError(error), variant: "destructive" }),
  });
  const FormSchema = z.object({ [field]: z.string().min(1) });
  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { [field]: lead[field] ?? "" },
  });

  if (internalEditsOnly && user && !hasInternalRole(user)) {
    return <DisplayCell lead={lead}>{lead[field] ?? "-"}</DisplayCell>;
  }

  if (!editing) {
    return (
      <span className="flex flex-row items-center gap-2">
        <DisplayCell lead={lead}>{lead[field] ?? "-"}</DisplayCell>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(true)}>
          <Icon icon="edit" />
        </Button>
      </span>
    );
  }

  return (
    <Reform
      schema={FormSchema}
      methods={formMethods}
      onSubmit={(_, values) => {
        void updateLead({ variables: { input: { id: lead.id, [field]: values[field] } } });
        setEditing(false);
      }}
    >
      <span className="flex flex-row items-center">
        <FieldContainer name={field}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">{selected}</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {Object.entries(enumType).map(([key, value]) => (
                <DropdownMenuItem
                  key={key}
                  onSelect={() => {
                    formMethods.setValue(field, value);
                    setSelected(value);
                    console.log(formMethods.getValues());
                  }}
                >
                  {value}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </FieldContainer>
        <Button type="submit" variant="ghost" size="icon" className="flex justify-start">
          <Icon icon="check" />
        </Button>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(false)}>
          <Icon icon="close" />
        </Button>
      </span>
    </Reform>
  );
};

// Pass in allLeadIds to update all Leads in the table
const EditableHeader: React.FC<{ field: string; label: string; allLeadIds: string[]; internalEditsOnly?: boolean }> = ({
  field,
  label,
  allLeadIds,
  internalEditsOnly = false,
}) => {
  const { data: user } = useMyAccount();
  const [editing, setEditing] = useState(false);
  const [updateManyLeads] = useUpdateManyLeadsMutation();

  if (internalEditsOnly && user && !hasInternalRole(user)) {
    return <div className={headerStyle}>{label}</div>;
  }

  if (!editing) {
    return (
      <span className="flex flex-row items-center gap-2">
        <div className={headerStyle}>{label}</div>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(true)}>
          <Icon icon="edit" />
        </Button>
      </span>
    );
  }

  return (
    <Reform
      schema={z.object({ [field]: z.string().min(1) })}
      onSubmit={(_, values) => {
        void updateManyLeads({ variables: { input: { ids: allLeadIds, [field]: values[field] } } });
        setEditing(false);
      }}
    >
      <span className="flex flex-row items-center">
        <FieldContainer name={field}>
          <Input name={field} />
        </FieldContainer>
        <Button type="submit" variant="ghost" size="icon" className="flex justify-start">
          <Icon icon="check" />
        </Button>
        <Button variant="ghost" size="icon" className="flex justify-start" onClick={() => setEditing(false)}>
          <Icon icon="close" />
        </Button>
      </span>
    </Reform>
  );
};

const getAgentDisplayName = (lead: LeadFragment): string => {
  return lead.agentFirstName || lead.agentLastName ? `${lead.agentFirstName} ${lead.agentLastName}` : "-";
};

// const OverallFitIndicator = ({ score }: { score: LeadFragment["overallFitScore"] }) => {
//   if (score === 4) {
//     return (
//       <>
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//       </>
//     );
//   }
//   if (score === 3) {
//     return (
//       <>
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//       </>
//     );
//   }
//   if (score === 2) {
//     return (
//       <>
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//       </>
//     );
//   }
//   if (score === 1) {
//     return (
//       <>
//         <Icon icon="fiber_manual_record" className="filled" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//       </>
//     );
//   }
//   if (score === 0) {
//     return (
//       <>
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//         <Icon icon="fiber_manual_record" className="text-muted-foreground/50" />
//       </>
//     );
//   }

//   return <span className="text-muted-foreground">Unknown</span>;
// };

export const EditLeadNotes = ({
  leads,
  onSubmit,
}: {
  leads: LeadFragment[];
  onSubmit: (leadId: string, notes?: string | null) => void;
}) => {
  return (
    <Dialog>
      <Tooltip>
        <TooltipTrigger>
          <DialogTrigger asChild>
            <Button size="icon" variant="ghost" disabled={leads.length > 1}>
              <Icon icon="edit_note" />
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipContent>
            {leads.length > 1 ? "Please select a single Lead to add notes" : "Add notes to selected lead"}
          </TooltipContent>
        </TooltipContent>
      </Tooltip>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Notes</DialogTitle>
        </DialogHeader>
        <Reform
          className="py-4 space-y-4"
          schema={z.object({ notes: z.string().nullable().optional() })}
          onSubmit={(_, values) => onSubmit(leads[0]?.id, values?.notes)}
          defaultValues={{
            notes: leads[0]?.notes,
          }}
        >
          <FieldContainer name="notes">
            <TextArea name="notes" />
          </FieldContainer>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="submit">Save changes</Button>
            </DialogClose>
          </DialogFooter>
        </Reform>
      </DialogContent>
    </Dialog>
  );
};

export const setSearchParams = (params: Array<{ [K in ValidSearchKey]?: string }>) => {
  return join(
    params.map((param) => Object.entries(param).map(([key, value]) => `${key}=${encodeURIComponent(value)}`)),
    "&"
  );
};

const headerStyle = "text-xs";
