import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  useCompleteAgentActionMutation,
  useCreateClientDataMutation,
  useOpportunityRequirementsQuery,
} from "../../../generated/graphql";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Icon } from "../../components/ui/icon";
import { Loading } from "../../components/ui/loading";
import { useToast } from "../../components/ui/use-toast";
import { useOpportunity } from "../loaders";
import ElementRegister from "./element-register";
import { RequirementElementProps } from "./requirement-element-props";

export default function RequirementForm() {
  const { opportunityId, requirementId } = useParams();
  const navTo = useNavigate();
  const { toast } = useToast();
  const formMethods = useForm();
  const [step, setStep] = useState(0);
  const { opportunity, loading: loadingOpportunity, refetch } = useOpportunity();
  const {
    data,
    loading,
    refetch: refetchRequirements,
  } = useOpportunityRequirementsQuery({
    variables: { id: opportunityId! },
    skip: !opportunityId,
  });
  const [completeAction] = useCompleteAgentActionMutation({ variables: { input: { id: requirementId! } } });
  const [submissionRequirementsMet, setSubmissionRequirementsMet] = useState<{
    requirementsMet: boolean;
    reason: string | null;
  }>({ requirementsMet: true, reason: null });

  const agentAction = data?.opportunity?.agentActions.find(
    (agentAction) => agentAction.id.toString() === requirementId
  );

  const [createClientData] = useCreateClientDataMutation({
    onCompleted() {
      toast({ title: "Progress saved" });

      if (agentAction?.ui && step < agentAction.ui.steps.length - 1) {
        setStep((step) => step + 1);
      } else {
        void completeAction()
          .then(() => Promise.all([refetch(), refetchRequirements()]))
          .then(() => {
            setStep(0);
            navTo(`/opportunity/${opportunityId}`);
          });
      }
    },
    onError() {
      toast({ title: "Unable to save progress" });
    },
  });

  if (loading || loadingOpportunity || !data || !opportunity || !agentAction) {
    return <Loading />;
  }

  const handleSubmit: SubmitHandler<Record<string, string>> = async (fd) => {
    const { req, ...clientData } = fd;

    await createClientData({
      variables: {
        input: {
          insuredId: opportunity.insured.id,
          source: "EASY_ACORD",
          clientDataInputs: Object.entries(clientData).map(([keyAndIndex, value]) => {
            const [key, index] = keyAndIndex.split("::");

            return {
              index: index ? Number(index) : undefined,
              key,
              value,
            };
          }),
        },
      },
    });
  };

  const steps = agentAction.ui?.steps.length;

  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle>{agentAction.ui?.title ?? agentAction.title}</CardTitle>
        <CardDescription className="font-semibold pt-1 text-muted-foreground text-xs">
          Step {step + 1} of {steps}: {agentAction.ui?.steps[step]?.title}
        </CardDescription>
      </CardHeader>
      {agentAction.ui ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <CardContent className="grid @lg/opp:grid-cols-2 @4xl/opp:grid-cols-3 gap-4 pt-4">
              {agentAction.ui.steps[step].elements.map((element: any, i: number) => (
                <RequirementElement
                  key={i}
                  {...element}
                  onSubmissionRequirementsMet={() =>
                    setSubmissionRequirementsMet({ requirementsMet: true, reason: null })
                  }
                  onSubmissionRequirementsNotMet={(reason: string) =>
                    setSubmissionRequirementsMet({ requirementsMet: false, reason })
                  }
                />
              ))}
            </CardContent>
            <CardFooter className="border-t pt-4">
              <Button type="submit" disabled={!submissionRequirementsMet.requirementsMet} className="ml-auto">
                {submissionRequirementsMet.requirementsMet ? "Save & Continue" : submissionRequirementsMet.reason}
              </Button>
            </CardFooter>
          </form>
        </FormProvider>
      ) : (
        <CardFooter className="p-6">
          <Button>Continue</Button>
        </CardFooter>
      )}
    </Card>
  );
}

function RequirementElement({
  props,
  array,
  ...rest
}: {
  element: string;
  props: any;
  array: boolean;
} & RequirementElementProps) {
  return array ? (
    <ArrayClientDataField props={JSON.parse(props)} {...rest} />
  ) : (
    <ElementRegister props={JSON.parse(props)} {...rest} />
  );
}

function ArrayClientDataField({
  element,
  props,
  clientDataKey,
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: {
  element: string;
  props: any;
} & RequirementElementProps) {
  const [itemCount, setItemCount] = useState(1);

  return (
    <div className="col-span-full">
      <div>
        {Array.from({ length: itemCount }).map((_, i) => (
          <div key={i} className="flex flex-col gap-2">
            <ElementRegister
              element={element}
              props={{ ...props, index: i }}
              clientDataKey={clientDataKey}
              onSubmissionRequirementsMet={onSubmissionRequirementsMet}
              onSubmissionRequirementsNotMet={onSubmissionRequirementsNotMet}
            />
          </div>
        ))}
        <div className="flex justify-center my-2 w-96">
          <Button type="button" size="icon-sm" onClick={() => setItemCount((prevCount) => prevCount + 1)}>
            <Icon icon="add" />
          </Button>
        </div>
      </div>
    </div>
  );
}
