import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { HasInternalRole } from "@/components/has-role";
import { Spinner } from "@/components/ui/loading";
import { ErrorPage } from "@/error";
import { OpportunityHeader } from "./components/opportunity-header";
import { UndeleteOpportunity } from "./components/undelete-opportunity";
import { OpportunityInfo } from "./info/info";
import { useOpportunity } from "./loaders";

export const OpportunityRoot: React.FC<{ path: string }> = ({ path }) => {
  const { opportunity, loading, error } = useOpportunity();

  useDocumentTitle(`${opportunity ? opportunity.insured.name : "Opportunity"}: QuoteWell`);

  return loading ? (
    <div className="flex flex-auto justify-center self-center">
      <Spinner />
    </div>
  ) : error ? (
    <ErrorPage />
  ) : opportunity ? (
    <div className="@container/opp flex flex-auto flex-col min-w-0" id="OpportunityRoot">
      <OpportunityHeader opportunity={opportunity} path={path} />
      <div className="flex flex-auto items-stretch relative">
        <div className="flex-auto p-3 space-y-3">
          <Outlet />
        </div>
        <OpportunityInfo />
      </div>
    </div>
  ) : (
    <HasInternalRole>
      <div className="flex flex-auto justify-center self-center">
        <UndeleteOpportunity />
      </div>
    </HasInternalRole>
  );
};
