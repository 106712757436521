import React from "react";
import { useParams } from "react-router";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

import { z } from "zod";
import { useCreateTagMutation, useFileTagsForSelectorQuery, useFileTagsQuery } from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "../../components/ui/table";
import { useToast } from "../../components/ui/use-toast";
import { Input } from "../../forms/components/FormFields";

export const TagsCard: React.FC = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { openForm } = useModal();

  const { data: tags } = useFileTagsForSelectorQuery({
    variables: {
      input: {
        opportunityId: opportunityId ?? "",
      },
    },
  });

  const [createTag] = useCreateTagMutation();
  const { toast } = useToast();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex flex-row gap-4 items-center justify-between">
          <>Tags</>
          <Button
            onClick={async () => {
              const formData = await openForm(
                z.object({
                  label: z.string(),
                }),
                <div>
                  <div className="grid  gap-2">
                    <div className="flex flex-col">
                      <h4>Label</h4>
                      <Input name="label" />
                    </div>
                  </div>
                  <div className="flex justify-end m-4">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>,
                {
                  defaultValues: {
                    label: "",
                  },
                }
              );

              if (formData) {
                void createTag({
                  variables: {
                    input: {
                      label: formData.label,
                    },
                  },
                  onError: (e) => {
                    toast({ title: e.message });
                  },
                  onCompleted: () => {
                    toast({ title: `label created` });
                  },
                  refetchQueries: ["FileTags"],
                });
              }
            }}
          >
            Add
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="text-xs">
        <div className="grid grid-cols-3 gap">
          <Card className="col-span-3">
            <CardHeader>
              <CardTitle className="flex flex-row gap-4 items-center justify-between">Select Tags</CardTitle>
            </CardHeader>
            <CardContent className="text-xs">
              <Table className="text-xs">
                <TableHeader>
                  <TableRow>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {tags?.fileTags
                    .filter((t) => t?.parentTags?.length === 0)
                    .map((t) => {
                      return <TagRow key={t.label} label={t.label} depth={0} />;
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </CardContent>
    </Card>
  );
};

import { first } from "lodash";
import { Icon } from "../../components/ui/icon";
import { TagMenu } from "./tag-menu";

const TagRow = ({ label, depth }: { label: string; depth: number }) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: tagData } = useFileTagsQuery({
    variables: {
      input: {
        label,
        opportunityId: opportunityId ?? "",
      },
    },
  });
  const tag = first(tagData?.fileTags);
  const marginLeft = `${depth * 1}rem`;

  if (!tag) {
    return null;
  }

  return (
    <div style={{ marginLeft }} className="my-1 w-full">
      <TableRow key={tag?.label}>
        <TableCell className="flex items-center w-full">
          <Icon icon="folder" className="text-2xl text-primary mr-2" />
          <span>{tag?.label}</span>
          <TagMenu labelId={tag.label} />
        </TableCell>
      </TableRow>
      {tag.label !== "Acords" &&
        tag?.childTags?.map((t) => {
          return (
            <>
              <TagRow label={t?.label} depth={depth + 1} />
            </>
          );
        })}
    </div>
  );
};
