import { Maps } from "@cp/toolkit";
import { Link } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import {
  AppetiteGuidesForOpportunityQuery,
  MarketTypes,
  OpportunityDetailsFragment,
  QuoteSubmissionFragment,
  SubmissionFragment,
  useAppetiteGuidesForOpportunityQuery,
} from "../../../../generated/graphql";
import { Cell, MarketingEffort, Row } from "./marketing-effort";

export type MarketingEffortSubmission = SubmissionFragment &
  QuoteSubmissionFragment & {
    appetiteGuide?: AppetiteGuidesForOpportunityQuery["appetiteGuidesForOpportunity"][number];
  };

interface Props {
  opportunity: OpportunityDetailsFragment;
  submissions: MarketingEffortSubmission[];
}

export const MarketingEfforts: React.FC<Props> = ({ opportunity, submissions }) => {
  const { data: user } = useMyAccount();
  const { data: bindingGuides } = useAppetiteGuidesForOpportunityQuery({
    variables: { opportunityId: opportunity.id },
  });
  const bindingGuidesByCarrier = Maps.keyBy(bindingGuides?.appetiteGuidesForOpportunity ?? [], (bg) => bg.carrierName);

  // TODO: Figure out something better than just the first core line
  const submissionsByLine = Maps.multiMap(
    submissions,
    ({ appetiteProduct }) =>
      appetiteProduct.coreLines.includes("General Liability") ? "GL" : appetiteProduct.coreLines[0],
    (s) => ({
      ...s,
      appetiteGuide:
        s.appetiteProduct.marketType === MarketTypes.BindingAuthority
          ? bindingGuidesByCarrier.get(s.appetiteProduct.carrierName)
          : undefined,
    })
  );

  const marketingEfforts = [...submissionsByLine.entries()];
  marketingEfforts.sort(([a], [b]) => coreLineSortOrder(a, b));
  return (
    (marketingEfforts[0] || user?.internal) && (
      <Card className="@container/efforts">
        <CardHeader>
          <CardTitle className="flex gap-4 items-center justify-between">
            Markets
            <HasInternalRole>
              <Button asChild variant="outline" size="sm">
                <Link to="markets">Select Markets</Link>
              </Button>
            </HasInternalRole>
          </CardTitle>
        </CardHeader>
        {marketingEfforts[0] && (
          <>
            <Row className="bg-accent border-y font-semibold h-10 -mb-px text-2xs text-muted-foreground">
              <Cell>Carrier / Product</Cell>
              <Cell>Lines / Premium</Cell>
              <Cell className="hidden @3xl/efforts:flex">Status</Cell>
            </Row>
            {marketingEfforts.map(([key, submissions]) => (
              <MarketingEffort key={key} opportunity={opportunity} submissions={submissions} />
            ))}
          </>
        )}
      </Card>
    )
  );
};

const coreLineSortOrder = (a: string, b: string) => {
  if (a === "GL" && b === "GL") {
    return 0;
  } else if (a === "GL") {
    return -1;
  } else if (b === "GL") {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};
