import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { FormEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { Reform } from "@/forms/Reform";
import FileJobs from "@/opportunity/quote-proposal/jobs";
import {
  QuoteFragment,
  SubmissionDetailsQueryResult,
  useCreateProcessedQuotePdfMutation,
  useProposalValuesQuery,
  useSubmissionQuery,
} from "../../../generated/graphql";

export type Submission = NonNullable<NonNullable<SubmissionDetailsQueryResult["data"]>["submission"]>;

const QuoteProposalValuesSchema = z.object({
  carrierName: z
    .string({
      required_error: "Carrier name is required",
      invalid_type_error: "Carrier name must be a string",
    })
    .min(1, "Carrier name cannot be empty"),

  subjectivities: z
    .string({
      required_error: "Subjectivities are required",
      invalid_type_error: "Subjectivities must be a string",
    })
    .min(1, "Subjectivities cannot be empty"),

  insuredName: z
    .string({
      required_error: "Insured name is required",
      invalid_type_error: "Insured name must be a string",
    })
    .min(1, "Insured name cannot be empty"),

  effectiveDate: z
    .string({
      required_error: "Effective date is required",
      invalid_type_error: "Effective date must be a string",
    })
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Effective date must be in YYYY-MM-DD format"),

  homeState: z
    .string({
      required_error: "Home state is required",
      invalid_type_error: "Home state must be a string",
    })
    .length(2, "Home state must be a 2-letter state code"),

  premium: z.coerce.number().gt(0, "Broker fee must be greater than 0").optional().nullable(),

  commission: z.coerce
    .number()
    .min(0, "Commission must be greater than 0")
    .max(100, "Commission must be 100 or less")
    .optional()
    .nullable(),

  brokerFee: z.coerce.number().gt(0, "Broker fee must be greater than 0").optional().nullable(),

  inspectionFee: z.coerce.number().gt(0, "Inspection fee must be greater than 0").optional().nullable(),

  carrierFee: z.coerce.number().gt(0, "Carrier fee must be greater than 0").optional().nullable(),

  mep: z.coerce.number().gt(0, "MEP must be greater than 0").optional().nullable(),
});

export const QuoteProposalForm = ({ quote }: { quote: QuoteFragment }) => {
  const {
    data: quoteProposalData,
    error,
    loading: loadingValues,
  } = useProposalValuesQuery({
    variables: {
      input: {
        quoteId: quote.id,
      },
    },
    pollInterval: 2000,
    skip: !quote.redactedQuote,
  });

  const { data: submissionData } = useSubmissionQuery({
    variables: {
      id: quote.submissionId,
    },
  });

  const submission = submissionData?.submission;
  const QUOTE_FORM_ID = `QUOTE_PROPOSAL_FORM_${quote.id}`;

  const quoteValues = quoteProposalData?.proposalValues;

  const formMethods = useForm<z.infer<typeof QuoteProposalValuesSchema>>({
    resolver: zodResolver(QuoteProposalValuesSchema),
  });

  useEffect(() => {
    if (quoteValues) {
      const defaultValues = {
        carrierName: quoteValues?.carrierName ?? "",
        insuredName: quoteValues?.insuredName ?? "",
        homeState: quoteValues?.homeState ?? "",
        effectiveDate: format(new Date(quoteValues?.effectiveDate ?? new Date()), "yyyy-MM-dd"),
        subjectivities: (quoteValues.subjectivities ?? []).join("\n"),
        premium: quoteValues?.premium,
        commission: quoteValues?.commission,
        brokerFee: quoteValues?.brokerFee,
        inspectionFee: quoteValues?.inspectionFee,
        carrierFee: null,
        mep: quoteValues?.mep ?? 25,
      };
      formMethods.reset(defaultValues);
    }
  }, [quoteValues, formMethods]);

  const { toast } = useToast();

  const [createQuotePDF, { loading: creatingPDF }] = useCreateProcessedQuotePdfMutation();

  const generateQuote = async (_e: FormEvent<HTMLFormElement>, values: z.infer<typeof QuoteProposalValuesSchema>) => {
    return createQuotePDF({
      variables: {
        input: {
          homeState: values.homeState,
          quoteId: quote.id,
          submissionId: quote.submissionId,
          insuredName: values.insuredName,
          carrierName: values.carrierName ?? "",
          inspectionFee: values.inspectionFee,
          commission: Number(values.commission),
          brokerageFee: Number(values.brokerFee),
          mep: Number(values.mep),
          premium: Number(values.premium),
          carrierFee: Number(values.carrierFee),
          subjectivities:
            values.subjectivities
              ?.replaceAll("-", "")
              .split("\n")
              .map((s: string) => s.trim()) ?? [],
          effectiveDate: new Date(values.effectiveDate),
        },
      },

      onError(error) {
        toast({ title: error.message });
      },

      onCompleted() {
        toast({ title: "Quote proposal generated" });
      },
      refetchQueries: ["SubmissionDetails", "Quote"],
    });
  };

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Sorry! An error occurred while fetching the proposal data.</CardTitle>
          <CardDescription>
            Message: {error.message ?? "An error occurred while fetching the proposal data."}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  if (!quote.redactedQuote) {
    return <EnhancedLoading label="Waiting for file upload" />;
  }

  if (loadingValues || quoteValues === null || !submission) {
    return <EnhancedLoading label="Loading quote data" />;
  }

  if (creatingPDF) {
    return <EnhancedLoading label="Creating Quote Proposal" />;
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            {submission.appetiteProduct.carrierName}: {submission.appetiteProduct.carrierProductName}
          </CardTitle>
        </CardHeader>
        <Reform schema={QuoteProposalValuesSchema} id={QUOTE_FORM_ID} onSubmit={generateQuote} methods={formMethods}>
          <CardContent className="grid grid-cols-2 gap-4">
            <dl>
              <dt>Carrier Name</dt>
              <dd>
                <Input {...formMethods.register("carrierName")} />
                <h6 className="text-destructive">{formMethods.formState.errors?.carrierName?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Effective Date</dt>
              <dd>
                <Input type="date" {...formMethods.register("effectiveDate")} />
                <h6 className="text-destructive">{formMethods.formState.errors?.effectiveDate?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Insured Name</dt>
              <dd>
                <Input {...formMethods.register("insuredName")} />
                <h6 className="text-destructive">{formMethods.formState.errors?.insuredName?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Home State</dt>
              <dd>
                <Input {...formMethods.register("homeState")} />
                <h6 className="text-destructive">{formMethods.formState.errors?.homeState?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Premium</dt>
              <dd>
                <Input {...formMethods.register("premium")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.premium?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Broker Fee (total)</dt>
              <dd>
                <Input {...formMethods.register("brokerFee")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.brokerFee?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Inspection Fee (total)</dt>
              <dd>
                <Input {...formMethods.register("inspectionFee")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.inspectionFee?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>Carrier Fee (if applicable)</dt>
              <dd>
                <Input {...formMethods.register("carrierFee")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.carrierFee?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt> Commission % </dt>
              <dd>
                <Input {...formMethods.register("commission")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.commission?.message}</h6>
              </dd>
            </dl>
            <dl>
              <dt>MEP</dt>
              <dd>
                <Input {...formMethods.register("mep")} type="number" step="0.01" />
                <h6 className="text-destructive">{formMethods.formState.errors?.mep?.message}</h6>
              </dd>
            </dl>
            <dl className="col-span-2">
              <dt>Subjectivities</dt>
              <dd>
                <Textarea
                  {...formMethods.register("subjectivities")}
                  rows={8}
                  name="subjectivities"
                  placeholder="List Subjectivities"
                />
                <h6 className="text-destructive">{formMethods.formState.errors?.subjectivities?.message}</h6>
              </dd>
            </dl>
          </CardContent>
          <CardFooter>
            <Button type="submit" form={QUOTE_FORM_ID} variant="outline" size="sm">
              Save
            </Button>
          </CardFooter>
        </Reform>
      </Card>
      {quote.redactedQuote && <FileJobs fileId={quote.redactedQuote.id} />}
    </>
  );
};

const EnhancedLoading = ({ label }: { label: string }) => (
  <Card className="w-full max-w-md mx-auto my-8">
    <CardContent className="flex flex-col items-center justify-center p-8">
      <Spinner className="w-8 h-8 mb-4" />
      <p className="text-lg font-medium text-center text-gray-700">{label}</p>
    </CardContent>
  </Card>
);
