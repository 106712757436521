import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import {
  AgentActionType,
  useBrokerCreateAgentActionMutation,
  useExpeditedOpportunityTransitionMutation,
} from "../../../../generated/graphql";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Textarea } from "../../../components/ui/textarea";
import { toast } from "../../../components/ui/use-toast";
import { parseError } from "../../../utils";
import { AgentActionTypeDisplay, CustomRequirementForm, SelectRequirement } from "../../info/add-requirements";
import { Requirements } from "../../info/requirements-list";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

const formId = "createAgentActionForm";
const FormSchema = z.object({
  submissionId: z.string().optional(),
  title: z.string().min(2),
  reason: z.string().min(2),
  externalReason: z.string().optional(),
});
function RequirementDialog({ opportunityId }: { opportunityId: string }) {
  const [createAgentAction] = useBrokerCreateAgentActionMutation();
  const [agentActionType, setAgentActionType] = useState<AgentActionType>(AgentActionType.UploadFile);

  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const [open, setOpen] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const data = formMethods.getValues();

      void createAgentAction({
        variables: {
          input: {
            opportunityId,
            submissionId: data.submissionId,
            title: data.title,
            reason: data.reason,
            externalReason: data.externalReason,
          },
        },
        onError: (e) => toast({ title: `Error`, description: parseError(e), variant: "destructive" }),
        onCompleted: () => {
          toast({ title: "Requirement Added" });
          setOpen(false);
          formMethods.reset();
        },
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Request</Button>
      </DialogTrigger>
      <DialogContent className="max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Add Requirements</DialogTitle>
        </DialogHeader>
        <Label>Type</Label>
        <Select onValueChange={(value) => setAgentActionType(value as AgentActionType)} value={agentActionType}>
          <SelectTrigger>
            <SelectValue placeholder={"Select an Option"} />
          </SelectTrigger>
          <SelectContent>
            {Object.keys(AgentActionTypeDisplay).map((type) => (
              <SelectItem key={type} value={type}>
                {AgentActionTypeDisplay[type as keyof typeof AgentActionTypeDisplay]}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <FormProvider {...formMethods}>
          <form id={formId} onSubmit={(e) => onSubmit(e)}>
            {agentActionType === AgentActionType.Custom ? (
              <CustomRequirementForm formMethods={formMethods} />
            ) : (
              <SelectRequirement agentActionType={agentActionType} formMethods={formMethods} />
            )}
            <div className="mt-4">
              <Label>Reason (Required)</Label>
              <Textarea
                {...formMethods.register("reason")}
                placeholder="Please describe the reason we need this item. (Example: 'X market requires this item if revenue is above $Y'). This will help our models learn when to request this automatically in the future!"
                rows={5}
              />
            </div>
            <div className="mt-4">
              <Label>External Reason (Optional)</Label>
              <Textarea
                {...formMethods.register("externalReason")}
                placeholder="This is agent facing and will be used to explain why we are requesting this information!"
                rows={5}
              />
            </div>
          </form>
        </FormProvider>
        <DialogFooter>
          <Button type="submit" form={formId} className="mt-4 w-full">
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function AddInformationRequests({ opportunity, refetch }: ExpeditedOpportunityStatusViewProps) {
  const [transition, { loading }] = useExpeditedOpportunityTransitionMutation({
    variables: {
      input: { opportunityId: opportunity.id },
    },
    onCompleted: () => refetch(),
  });

  return (
    <Card>
      <CardContent className="space-y-4">
        <Requirements />
      </CardContent>
      <CardFooter className="flex flex-row justify-between">
        <RequirementDialog opportunityId={opportunity.id} />
        <Button
          size="sm"
          disabled={loading}
          onClick={() =>
            transition({
              variables: {
                input: {
                  opportunityId: opportunity.id,
                },
              },
            })
          }
        >
          Continue
        </Button>
      </CardFooter>
    </Card>
  );
}
