import { Activity } from "../activity/activity";
import ExpeditedActions from "../expedited/expedited-actions";
import { useOpportunity } from "../loaders";
import { MarketingEfforts } from "./marketing-efforts/marketing-efforts";

export const Details: React.FC = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  const { isExpedited, submissions } = opportunity;

  return (
    <>
      <MarketingEfforts opportunity={opportunity} submissions={submissions} />
      {isExpedited && <ExpeditedActions />}
      <Activity />
    </>
  );
};
