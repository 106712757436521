import { addDays } from "date-fns";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { cn } from "@/utils";
import { ActionsQuery, useCreateActionMutation } from "../../../../generated/graphql";
import { ExecutedAction, ScheduledAction } from "./actions";

interface ActionsTableProps {
  actions: ActionsQuery["actions"];
  opportunityId: string;
}

export const ActionsTable: React.FC<ActionsTableProps> = ({ actions, opportunityId }) => {
  const [help, setHelp] = useState(false);

  const [createAction] = useCreateActionMutation({
    variables: {
      input: {
        opportunityId,
        actionType: actions[0].actionType,
        title: actions[0].title,
        description: actions[0].description,
        // Get the greater of [tomorrow, or the Action with the latest autoExecuteAt]
        autoExecuteAt: new Date(
          Math.max(
            addDays(Date.now(), 1).getTime(),
            addDays(new Date(actions[actions.length - 1].autoExecuteAt), 1).getTime()
          )
        ),
      },
    },
    refetchQueries: ["Actions"],
  });

  return (
    <>
      <div className="bg-background text-xs">
        <header className="bg-accent flex gap-1 items-center px-6 py-2">
          <span className="capitalize font-medium mr-auto">{actions[0].title}</span>
          <Button variant="ghost" size="icon" onClick={() => setHelp((help) => !help)} className={cn(help && "filled")}>
            <Icon icon="help" />
          </Button>
          <Button variant="outline" size="icon" onClick={() => createAction({})}>
            <Icon icon="add" />
          </Button>
        </header>
        {help && (
          <aside className="bg-accent border-t px-6 py-4 text-muted-foreground text-2xs">
            {actions[0].description}
          </aside>
        )}
        {actions.length === 0 && <h4>No actions yet to approve</h4>}
        {actions.map((action) =>
          action.executedAt ? (
            <ExecutedAction key={action.id} action={action} />
          ) : (
            <ScheduledAction key={action.id} action={action} />
          )
        )}
      </div>
      <div className="bg-background pt-px" />
    </>
  );
};
