import { useParams } from "react-router";
import { useOpportunityAndAgencyAffiliationQuery } from "../../../generated/graphql";
import { Card, CardHeader, CardTitle } from "../../components/ui/card";
import { Separator } from "../../components/ui/separator";

export const AgencyAffiliationBanner = () => {
  const { opportunityId } = useParams<"opportunityId">();

  const { data } = useOpportunityAndAgencyAffiliationQuery({
    variables: {
      id: opportunityId ?? "",
    },
  });
  const affiliations = data?.opportunity?.agent.agency.affiliations;

  if (!data || affiliations?.length === 0) {
    return null;
  }

  return (
    <Card>
      {affiliations?.map((affiliation) => {
        return (
          <>
            <CardHeader key={affiliation.name} className="grid grid-cols- gap-2">
              <CardTitle className="grid grid-cols-2">
                <div className="text-sm flex items-center">
                  <span className="mr-2">
                    {affiliation.logoUrl ? (
                      <img src={affiliation?.logoUrl ?? ""} className="inline w-9 rounded-full mr-2" />
                    ) : (
                      `(${affiliation.shortName})`
                    )}
                  </span>
                  <span className="text-sm">{affiliation?.name}</span>
                </div>
                <span className="text-2xs grid grid-cols-1 gap-2">
                  {affiliation.brokerageCommission && (
                    <span>Brokerage Commission: {`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
                  )}

                  {affiliation.bindingCommission && (
                    <span>Binding Commission: {`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
                  )}
                </span>
              </CardTitle>
            </CardHeader>
            <Separator className="my-2" />
          </>
        );
      })}
    </Card>
  );
};
