import { RouteObject } from "react-router";

import { ErrorPage } from "../error";
import { opportunityDetailsAction } from "./actions";
import CreateBindHqPolicy from "./components/actions/create-bind-hq-policy";
import { Coverage } from "./coverage";
import OpportunityData from "./data";
import { Details } from "./details/details";
import { EscapeHatch } from "./escape-hatch";
import { Files } from "./files/files";
import { TagsCard } from "./files/tags-card";
import { History } from "./history";
import { Markets } from "./markets/markets";
import OpportunityNotes from "./notes";
import { OpportunityRoot } from "./opportunity-root";
import { GenerateQuote } from "./quote-proposal/generate-quote-proposal";
import { requirementsRoutes } from "./requirements/routes";

export const opportunityRoutes = ({ path }: { path: string }): RouteObject[] => [
  {
    path: ":opportunityId",
    element: <OpportunityRoot path={path} />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        action: opportunityDetailsAction,
        element: <Details />,
      },
      {
        path: "coverage",
        element: <Coverage />,
      },
      {
        path: "markets",
        element: <Markets />,
      },
      {
        path: "escape-hatch",
        element: <EscapeHatch />,
      },
      {
        path: "files",
        element: <Files />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "notes",
        element: <OpportunityNotes />,
      },
      {
        path: "data",
        element: <OpportunityData />,
      },
      {
        path: "tags",
        element: <TagsCard />,
      },
      {
        path: ":submissionId",
        element: <GenerateQuote />,
      },
      {
        path: "create-policy",
        children: [
          {
            path: ":actionId",
            element: <CreateBindHqPolicy />,
          },
        ],
      },
      ...requirementsRoutes(),
    ],
  },
];

// export const marketingPlanRoutes = (): RouteObject[] => [
//   {
//     path: ":marketingPlanId",
//     element: <MarketingPlan />,
//     errorElement: <ErrorPage />,
//   },
// ];
