import { Link } from "react-router-dom";

import { Icon } from "@/components/ui/icon";
import { Panel } from "@/components/ui/panel";
import { useOpportunity } from "../loaders";

export const Renewal = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  const { renewal, renewalOf } = opportunity;

  return (
    <Panel className="p-0">
      {renewal && (
        <RenewalLink
          to={`/opportunity/${renewal.id}`}
          title="This plan has a renewal."
          subtitle="View current plan &rarr;"
        />
      )}
      {renewalOf && (
        <RenewalLink to={`/opportunity/${renewalOf}`} title="This is a renewal." subtitle="View previous plan &rarr;" />
      )}
    </Panel>
  );
};

const RenewalLink = ({ title, subtitle, to }: { title: string; subtitle: string; to: string }) => (
  <Link to={to} className="bg-amber-500/5 flex gap-4 items-start px-6 py-4 text-amber-600 text-sm">
    <Icon icon="cycle" className="text-amber-600 mt-1" />
    <span className="flex flex-col gap-1">
      <span className="font-semibold ">{title}</span>
      <span className="text-xs">{subtitle}</span>
    </span>
  </Link>
);
