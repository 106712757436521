import { FormFieldSupplemental } from "../inputs/supplemental";

interface Props {
  colSpan?: number;
  label?: string;
  name: string;
  index?: number;
  rowSpan?: number;
}

export const TdInput: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <TdLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <FormFieldSupplemental.Input type={type} name={name} {...props} />
  </TdLabel>
);
export const TdDate: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <TdLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <FormFieldSupplemental.Date type={type} name={name} {...props} />
  </TdLabel>
);
export const TdNumber: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <TdLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <FormFieldSupplemental.Number type={type} name={name} {...props} />
  </TdLabel>
);
export const TdMoney: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <TdLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <FormFieldSupplemental.Money type={type} name={name} {...props} />
  </TdLabel>
);

export const TdTextarea: React.FC<Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  ...props
}) => (
  <TdLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <FormFieldSupplemental.TextArea name={name} rows={3} {...props} />
  </TdLabel>
);

const TdLabel = ({
  children,
  colSpan,
  label,
  rowSpan,
  name,
}: {
  children: React.ReactNode;
  colSpan: number;
  label?: string;
  rowSpan: number;
  name: string;
}) => (
  <td colSpan={colSpan} rowSpan={rowSpan} style={{ padding: 0 }}>
    <FormFieldSupplemental.Label style={{ padding: label ? "5pt 8pt" : "0pt 8pt" }} name={name}>
      {label ?? null}
      {children}
    </FormFieldSupplemental.Label>
  </td>
);

export const TdYesNo = ({ colSpan = 1, label, name, rowSpan = 1 }: Props) => {
  return (
    <td colSpan={colSpan} rowSpan={rowSpan}>
      <h4 style={{ fontWeight: 400, marginBottom: "8pt" }}>{label}</h4>
      <FormFieldSupplemental.RadioBoolean name={name} />
    </td>
  );
};
