import { useState } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import {
  OpportunityDetailsFragment,
  OpportunityEmailTemplate,
  useCreateEmailTemplateDraftMutation,
} from "../../../../generated/graphql";
import { useToast } from "../../../components/ui/use-toast";
import { parseError } from "../../../utils";
import { MarketingEffortSubmission } from "./marketing-efforts";
import { SubmissionTemplateModal } from "./submission-template-modal";

interface Props {
  opportunity: OpportunityDetailsFragment;
  submission: MarketingEffortSubmission;
}

export const EmailTemplatesButton: React.FC<Props> = ({ opportunity, submission }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <HasInternalRole>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen} modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" onClick={() => setDropdownOpen(true)}>
            <Icon icon="mail" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full">
          <DropdownMenuLabel>Email Templates</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <EmailTemplateAction
            label="Send Submission to Carrier"
            template={OpportunityEmailTemplate.SubmitToCarrier}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <EmailTemplateAction
            label="Send Quote for Processing (Gmail)"
            template={OpportunityEmailTemplate.RequestQuote}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <DropdownMenuItem>
            <a
              href={`https://quotewell.palantirfoundry.com/workspace/module/view/latest/ri.workshop.main.module.94a33c66-6eb8-44bd-9ed6-55c689e1c317?submissionId=${submission.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {"Send Quote for Processing (Foundry)"}
            </a>
          </DropdownMenuItem>
          <EmailTemplateAction
            label="Send Quote to Agent"
            template={OpportunityEmailTemplate.SendQuote}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <EmailTemplateAction
            label="Send Binder for Processing"
            template={OpportunityEmailTemplate.RequestBinder}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <EmailTemplateAction
            label="Send Binder to Agent"
            template={OpportunityEmailTemplate.SendBinder}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <EmailTemplateAction
            label="Initiate Policy Check"
            template={OpportunityEmailTemplate.PolicyCheck}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
          <EmailTemplateAction
            label="Send Policy to Agent"
            template={OpportunityEmailTemplate.SendPolicy}
            clientId={opportunity.insured.id}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </HasInternalRole>
  );
};

interface EmailTemplateActionProps {
  label: string;
  clientId: string;
  submissionId: string;
  template: OpportunityEmailTemplate;
  onModalClose: () => void;
}

const EmailTemplateAction: React.FC<EmailTemplateActionProps> = ({
  label,
  clientId,
  submissionId,
  template,
  onModalClose,
}) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { toast } = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const [createEmailTemplateDraft] = useCreateEmailTemplateDraftMutation();

  const handleCreateEmailDraft = async (fileIds: string[]) => {
    toast({ title: "Creating Email Draft", description: "Please wait..." });
    await createEmailTemplateDraft({
      variables: {
        input: {
          submissionId,
          template,
          fileIds: fileIds,
        },
      },
      onCompleted: (data) => {
        if (data?.createEmailTemplateDraft) {
          window.open(data.createEmailTemplateDraft, "_blank");
          setModalOpen(false);
        }
      },
      onError: (error) => {
        toast({ title: "Error", description: parseError(error), variant: "destructive" });
      },
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (template === OpportunityEmailTemplate.SubmitToCarrier) {
      e.preventDefault();
      setModalOpen(true);
    } else {
      void handleCreateEmailDraft([]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    onModalClose();
  };

  return (
    <>
      <DropdownMenuItem onClick={handleClick}>{label}</DropdownMenuItem>
      <SubmissionTemplateModal
        open={modalOpen}
        onClose={handleModalClose}
        clientId={clientId}
        opportunityId={opportunityId ?? ""}
        handleCreateEmailDraft={handleCreateEmailDraft}
      />
    </>
  );
};
