import showdown from "showdown";

export function markdownToHtml(markdown: string): string {
  const converter = new showdown.Converter();
  converter.setFlavor("github");

  return converter.makeHtml(markdown);
}

export function htmlToMarkdown(html: string): string {
  const converter = new showdown.Converter();
  converter.setFlavor("github");

  return converter.makeMarkdown(html);
}
