import { Outlet, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { cn } from "@/utils";
import { ActionsList } from "./actions-list";

const transitionClasses = "lg:transition-all lg:duration-500 lg:ease-in-out-quart";

export const ActionsRoot: React.FC = () => {
  useDocumentTitle("Actions: QuoteWell");
  const { opportunityId } = useParams();

  return (
    <>
      <div
        className={cn(
          "flex flex-auto lg:flex-none flex-col gap relative lg:w-80 z-1",
          // hide if opp selected at narrow width but not wide width:
          opportunityId && "hidden lg:flex",
          transitionClasses
        )}
      >
        <ActionsList />
      </div>
      <div className={cn("flex flex-none w-0 z-[2]", opportunityId && "flex-auto w-full", transitionClasses)}>
        <Outlet />
      </div>
    </>
  );
};
