import { ApolloProvider } from "@apollo/client";
import { Analytics } from "@vercel/analytics/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";

import { apolloClient } from "../graphql/client";
import { ModalProvider } from "./components/modal-provider";
import { ThemeProvider } from "./components/theme-provider";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import "./globals.css";
import { router } from "./router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: false,
    },
  },
});

export const App: React.FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider defaultTheme="light">
            <TooltipProvider>
              <ModalProvider>
                <RouterProvider router={router} />
                <Toaster />
              </ModalProvider>
            </TooltipProvider>
          </ThemeProvider>
        </ApolloProvider>
      </QueryClientProvider>
      <Analytics />
    </>
  );
};
