import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { SentryLogger } from "@qw/sentry";

import { downloadBlob } from "../../../utils/file.utils";
import { BaseProps } from "../../components/layout";
import anvilCss from "../anvil-pdf-generation.css?inline";

export const anvilizeElement = (e: Element) => {
  // Clone the DOM tree since we're mutating it
  const element = e.cloneNode(true) as Element;

  element.querySelectorAll("input").forEach((input) => {
    if (input.type === "checkbox" || input.type === "radio") {
      input.checked ? input.setAttribute("checked", "checked") : input.removeAttribute("checked");
    } else {
      input.setAttribute("value", input.value);
    }
  });

  // TODO: Get select elements working
  // element.querySelectorAll("select").forEach((select) => {
  //   select.querySelectorAll("option").forEach((option) => {
  //     option.selected ? option.setAttribute("selected", "selected") : option.removeAttribute("selected");
  //   });
  // });

  // Handle textarea elements
  element.querySelectorAll("textarea").forEach((textarea) => {
    textarea.textContent = textarea.value;
  });

  return element.outerHTML;
};

export const generatePdfInput = async () => {
  const element = document.querySelector("#pdf-input");
  if (element === null) {
    throw new Error("An error occured");
  }
  const anviled = anvilizeElement(element);

  return {
    html: anviled,
    css: anvilCss,
  };
};

export const GenerateAnvilButton: React.FC<BaseProps & { filename: string }> = ({ children, className, filename }) => {
  const { toast } = useToast();
  return (
    <Button
      variant="secondary"
      type="button"
      className={className}
      onClick={async () => {
        const body = await generatePdfInput();
        const url = new URL("/api/anvil/generate", window.location as any);
        const result = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (result.ok) {
          downloadBlob(await result.blob(), `${filename}.pdf`);
        } else {
          SentryLogger.error(await result.text());
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      }}
    >
      {children}
    </Button>
  );
};
