import { Check } from "lucide-react";
import { useNavigate, useParams } from "react-router";

import { Badge } from "@/components/ui/badge";
import { Combobox } from "@/components/ui/combobox";
import { CommandItem } from "@/components/ui/command";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { cn } from "@/utils";
import { useAgenciesQuery } from "../../../generated/graphql";

interface Props {
  path?: string;
  hasLeads?: boolean;
}

export const SelectAgency = ({ path, hasLeads }: Props) => {
  const { data: agenciesData } = useAgenciesQuery({
    variables: { input: { hasLeads } },
    fetchPolicy: "cache-first",
  });
  const [searchParams, upsertSearchParams] = useUpsertSearchParams();
  const { agencyId } = useParams();
  const navigate = useNavigate();

  const value = path ? agencyId : searchParams.get("agencyId");

  return (
    <Combobox
      value={value ? agenciesData?.agencies.find((agency) => agency.id === value)?.name : "Select Agency"}
      placeholder="Search Agencies..."
      empty="No agencies found."
    >
      {agenciesData?.agencies.map((agency) => (
        <CommandItem
          key={agency.id}
          value={agency.name.toLowerCase().trim()}
          onSelect={() => {
            path ? navigate(`${path}/${agency.id}`) : upsertSearchParams({ agencyId: agency.id });
          }}
        >
          <Check className={cn("mr-2 h-4 w-4", value === agency.id ? "opacity-100" : "opacity-0")} />
          <span className="flex gap-2">
            <span className="truncate">{agency.name}</span>
            {agency.isTest && <Badge>Test</Badge>}
          </span>
        </CommandItem>
      ))}
    </Combobox>
  );
};
