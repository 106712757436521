import { RouteObject } from "react-router";

import { ErrorPage } from "../../error";
import { opportunityDetailsAction } from "../../opportunity/actions";
import CreateBindHqPolicy from "../../opportunity/components/actions/create-bind-hq-policy";
import { Coverage } from "../../opportunity/coverage";
import OpportunityData from "../../opportunity/data";
import { Details } from "../../opportunity/details/details";
import { EscapeHatch } from "../../opportunity/escape-hatch";
import { Files } from "../../opportunity/files/files";
import { TagsCard } from "../../opportunity/files/tags-card";
import { History } from "../../opportunity/history";
import { Markets } from "../../opportunity/markets/markets";
import OpportunityNotes from "../../opportunity/notes";
import { GenerateQuote } from "../../opportunity/quote-proposal/generate-quote-proposal";
import { requirementsRoutes } from "../../opportunity/requirements/routes";
import { Plan } from "./plan";

export const planRoutes = (): RouteObject[] => [
  {
    path: ":opportunityId",
    element: <Plan />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        action: opportunityDetailsAction,
        element: <Details />,
      },
      {
        path: "coverage",
        element: <Coverage navigateTo=".." />,
      },
      {
        path: "markets",
        element: <Markets />,
      },
      {
        path: "escape-hatch",
        element: <EscapeHatch />,
      },
      {
        path: "files",
        element: <Files />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "notes",
        element: <OpportunityNotes />,
      },
      {
        path: "data",
        element: <OpportunityData />,
      },
      {
        path: "tags",
        element: <TagsCard />,
      },
      {
        path: ":submissionId",
        element: <GenerateQuote />,
      },
      {
        path: "create-policy",
        children: [
          {
            path: ":actionId",
            element: <CreateBindHqPolicy />,
          },
        ],
      },
      ...requirementsRoutes(),
    ],
  },
];
