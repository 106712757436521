import { ChevronDown } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { cn } from "@/utils";
import { OpportunitiesFilterInput } from "../../../generated/graphql";
import { adaptSearchParams } from "../adapt-search-params";

interface Props {
  name: keyof OpportunitiesFilterInput;
  metadata: Record<string, { label: string }>;
  className?: string;
}

export const SearchParamMultiselect = ({ name, metadata, className }: Props) => {
  const [searchParams, upsertSearchParams, setSearchParams] = useUpsertSearchParams();
  const multiParam = (adaptSearchParams(searchParams)[name] as string[]) ?? [];
  const keys = Object.keys(metadata);

  const handleChange = (checked: boolean, param: string) => {
    const newParams = checked ? [param, ...multiParam] : multiParam.filter((v) => v !== param);

    if (newParams.length > 0) {
      upsertSearchParams({ [name]: newParams.join(",") });
    } else {
      const { [name]: removed, page, ...rest } = Object.fromEntries(searchParams);
      setSearchParams(rest);
    }
  };

  const label = multiParam[1] ? `${multiParam.length} selected` : multiParam[0] ? metadata[multiParam[0]].label : "All";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className={cn("font-normal justify-between px-3 w-full truncate", className)}>
          <span className="truncate">{label}</span>
          <ChevronDown className="h-4 w-4 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {keys.map((key) => (
          <DropdownMenuCheckboxItem
            key={key}
            checked={multiParam.includes(key)}
            onCheckedChange={(v) => handleChange(v, key)}
          >
            {metadata[key].label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
