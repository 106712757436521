import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export default function WaitingOnCarrier(_: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Waiting For Quote</CardTitle>
      </CardHeader>
    </Card>
  );
}
