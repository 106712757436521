import { Check } from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Combobox } from "@/components/ui/combobox";
import { CommandItem } from "@/components/ui/command";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { cn } from "@/utils";
import { Roles, useAgencyAccountsQuery, UserAccountFragment, useUserAccountsQuery } from "../../../generated/graphql";

export const SelectAgent = () => {
  const { data } = useUserAccountsQuery({ variables: { input: { roles: [Roles.Agent] } }, fetchPolicy: "cache-first" });
  return <SelectAgentView agents={data?.userAccounts} />;
};

export const SelectAgencyAgent = () => {
  const { data } = useAgencyAccountsQuery({ variables: { input: { roles: [Roles.Agent] } } });
  return <SelectAgentView agents={data?.agencyAccounts} />;
};

const SelectAgentView = ({ agents }: { agents?: UserAccountFragment[] }) => {
  const [searchParams, upsertSearchParams] = useUpsertSearchParams();

  const value = searchParams.get("agentId");
  const agent = agents?.find((agent) => agent.id === value);
  const agentName = `${agent?.firstName} ${agent?.lastName}`;

  return (
    <Combobox value={value ? agentName : "Select Agent"} placeholder="Search Agents..." empty="No agents found.">
      {agents?.map((agent) => (
        <CommandItem
          key={agent.id}
          value={`${agent.firstName} ${agent.lastName}`.toLowerCase().trim()}
          onSelect={() => {
            upsertSearchParams({ agentId: agent.id });
          }}
        >
          <Check className={cn("mr-2 h-4 w-4", value === agent.id ? "opacity-100" : "opacity-0")} />
          <span className="flex gap-2">
            <span className="truncate">{`${agent.firstName} ${agent.lastName}`}</span>
            {agent.agency.isTest && <Badge>Test</Badge>}
          </span>
        </CommandItem>
      ))}
    </Combobox>
  );
};
