import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import ExpeditedOpportunityStatusViewProps from "./expedited-opportunity-status-view-props";

export default function Declined(_: ExpeditedOpportunityStatusViewProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>This opportunity was Declined.</CardTitle>
      </CardHeader>
    </Card>
  );
}
