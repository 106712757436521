import { uniqBy } from "lodash";
import { useState } from "react";
import { z } from "zod";
import {
  useAddProductToVerticalMarketingPlanTemplateMutation,
  useAppetiteFilterOptionsQuery,
  useAppetiteProductsQuery,
  useCreateVerticalMarketingPlanTemplateMutation,
  useUpdateVerticalMarketingPlanTemplateProductRankMutation,
  useUpdateVerticalMarketingPlanTemplateProductRulesMutation,
  useVerticalQuery,
  VerticalFragment,
  VerticalMarketingPlanTemplateProductFragment,
} from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Checkbox } from "../../components/ui/checkbox";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../components/ui/collapsible";
import { Field } from "../../components/ui/field-inputs";
import { FormField } from "../../components/ui/form";
import { Icon } from "../../components/ui/icon";
import { SearchInput } from "../../components/ui/input";
import { Loading } from "../../components/ui/loading";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Input } from "../../forms/components/FormFields";
import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "../../opportunity/components/editor";
import { cn } from "../../utils";
import EditRules from "./edit-rules";
import { EditVerticalRequirements } from "./edit-vertical-requirements";
import { RuleSchema } from "./rule";
import VerticalMarketingPlanRules from "./vertical-marketing-plan-rules";

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export function EditVerticalMarketingPlan({ vertical: _vertical }: { vertical?: VerticalFragment }) {
  const { openForm } = useModal();
  const { data, loading, refetch } = useVerticalQuery({ variables: { id: _vertical?.id ?? "" }, skip: !_vertical });
  const [createVerticalMarketingPlanTemplateTrigger] = useCreateVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });
  const [addProductToVerticalMarketingPlanTemplateTrigger] = useAddProductToVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });

  const [updateVerticalMarketingPlanProductMarketRankTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRankMutation({
      onCompleted() {
        void refetch();
      },
    });

  const [updateVerticalMarketingPlanTemplateProductRulesTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
      onCompleted() {
        void refetch();
      },
    });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <h2>Error loading Marketing Plan Templates</h2>;
  }

  const vertical = data.vertical;

  const createVerticalMarketingPlan = async () => {
    const fd = await openForm(
      z.object({
        lobs: z.array(z.string()),
      }),
      <SelectLinesOfBusiness />,
      { defaultValues: { lobs: [] } }
    );

    if (!fd) {
      return;
    }

    await createVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalId: vertical.id, lobs: fd.lobs.sort() } },
    });
  };

  const addProductToVerticalMarketingPlan = async (verticalMarketingPlanTemplateId: string) => {
    const fd = await openForm(
      z.object({
        products: z.array(z.string()),
      }),
      <SelectProducts />,
      { defaultValues: { products: [] } }
    );

    if (!fd) {
      return;
    }

    await addProductToVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalMarketingPlanTemplateId, appetiteProductIds: fd.products } },
    });
  };

  const updateRules = async (product: VerticalMarketingPlanTemplateProductFragment, index: number) => {
    const rules = JSON.parse(product.rules);
    const fd = await openForm(
      z.object({
        rule: RuleSchema,
      }),
      <EditRules rule={rules[index][0]} />
    );

    if (!fd) {
      return;
    }

    const updatedRules = rules.map((rule: any, i: number) => {
      if (i !== index) {
        return rule;
      }

      return [fd.rule];
    });

    await updateVerticalMarketingPlanTemplateProductRulesTrigger({
      variables: { input: { id: product.id, rules: JSON.stringify(updatedRules) } },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span>Marketing Plan Templates</span>
          <Button type="button" variant="outline" size="icon-sm" onClick={createVerticalMarketingPlan}>
            <Icon icon="add" />
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {[...vertical.marketingPlanTemplates]
          .sort((a, b) => a.lobs[0]?.charCodeAt(0) - b.lobs[0]?.charCodeAt(0))
          .sort((a, b) => b.lobs.length - a.lobs.length)
          .map((template) => (
            <Tabs key={template.id} defaultValue="rank">
              <Card>
                <CardHeader>
                  <CardTitle>
                    <div key={template.id} className="flex justify-between items-center">
                      <div className="flex gap">
                        {template.lobs.map((lob) => (
                          <Badge key={lob}>{lob}</Badge>
                        ))}
                      </div>
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon-sm"
                        onClick={() => addProductToVerticalMarketingPlan(template.id)}
                      >
                        <Icon icon="add" />
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>

                <CardContent>
                  <TabsList>
                    <TabsTrigger value="rank">Hierarchy</TabsTrigger>
                    <TabsTrigger value="rules">Rules</TabsTrigger>
                    <TabsTrigger value="gathering">Information Gathering</TabsTrigger>
                  </TabsList>
                  <TabsContent value="rank">
                    <Editor>
                      <EditorHeader columns={["Rank", "Carrier Name", "Carrier Product", "Rules", ""]} />
                      {template.products.map((product) => (
                        <Collapsible key={product.id}>
                          <EditorItem>
                            <EditorRow>
                              <div>
                                <EditorCount>{product.rank}</EditorCount>
                              </div>
                              <div>{product.appetiteProduct.carrierName}</div>
                              <div>{product.appetiteProduct.carrierProductName}</div>
                              <div className="flex gap">
                                <CollapsibleTrigger asChild>
                                  <Button size="sm" variant="outline" className="group" disabled={false}>
                                    <span className="text-xs">{JSON.parse(product.rules).length}</span>
                                    <Icon icon="unfold_more" className="group-data-[state=open]:hidden" />
                                    <Icon icon="unfold_less" className="group-data-[state=closed]:hidden" />
                                  </Button>
                                </CollapsibleTrigger>
                              </div>
                              <div>
                                <Button
                                  type="button"
                                  size="icon-sm"
                                  variant="ghost"
                                  disabled={product.rank === 0}
                                  onClick={() =>
                                    updateVerticalMarketingPlanProductMarketRankTrigger({
                                      variables: { input: { id: product.id, rank: product.rank - 1 } },
                                    })
                                  }
                                >
                                  <Icon icon="keyboard_arrow_up" />
                                </Button>
                                <Button
                                  type="button"
                                  size="icon-sm"
                                  variant="ghost"
                                  disabled={product.rank === template.products.length - 1}
                                  onClick={() =>
                                    updateVerticalMarketingPlanProductMarketRankTrigger({
                                      variables: { input: { id: product.id, rank: product.rank + 1 } },
                                    })
                                  }
                                >
                                  <Icon icon="keyboard_arrow_down" />
                                </Button>
                              </div>
                            </EditorRow>
                            <CollapsibleContent>
                              <Editor>
                                <EditorHeader columns={["type", "key", "operator", "value", ""]} />
                                {JSON.parse(product.rules).flatMap((rule: any, i: number) => (
                                  <EditorItem key={i}>
                                    <EditorRow>
                                      <div>{rule[0].type}</div>
                                      <div>{rule[0].key}</div>
                                      <div>{rule[0].operator}</div>
                                      <div>{rule[0].value}</div>
                                      <div className="flex gap">
                                        <Button
                                          variant="outline"
                                          size="icon-sm"
                                          onClick={() => updateRules(product, i)}
                                        >
                                          <Icon icon="edit" />
                                        </Button>
                                      </div>
                                    </EditorRow>
                                  </EditorItem>
                                ))}
                              </Editor>
                            </CollapsibleContent>
                          </EditorItem>
                        </Collapsible>
                      ))}
                    </Editor>
                  </TabsContent>

                  <TabsContent value="rules">
                    <VerticalMarketingPlanRules template={template} refetchVertical={refetch} />
                  </TabsContent>
                  <TabsContent value="gathering">
                    <EditVerticalRequirements refetchVertical={refetch} products={template.products} />
                  </TabsContent>
                </CardContent>
              </Card>
            </Tabs>
          ))}
      </CardContent>
    </Card>
  );
}

function SelectLinesOfBusiness() {
  const [term, setTerm] = useState("");
  const { data } = useAppetiteFilterOptionsQuery({});

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(term.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  return (
    <div className="grid grid-flow-col gap-2 grid-rows-12 mt-4 h-full max-h-96">
      <div className="flex-auto space-y-4 row-span-11 overflow-scroll">
        <Card className="flex gap-5 p-6 pl-5">
          <Icon icon="verified" className="filled text-primary text-2xl" />
          <h2>Select Lines of Business</h2>
        </Card>
        <Input
          name="search"
          type="text"
          placeholder="Search for lobs"
          className="px-4"
          onChange={(e) => {
            setTerm(e.target.value);
          }}
        />
        <Card className="bg-muted overflow-hidden space-y-px">
          <CardHeader className="bg-background">
            <CardTitle>Core Lines</CardTitle>
          </CardHeader>
          {coreLines.map(({ name }) => (
            <>
              <FieldCheckbox key={name} value={name} name="lobs" />
            </>
          ))}
        </Card>
        <Card className="bg-muted overflow-hidden space-y-px">
          <CardHeader className="bg-background">
            <CardTitle>Other</CardTitle>
          </CardHeader>
          {otherLines.map(({ name }) => (
            <FieldCheckbox key={name} value={name} name="lobs" />
          ))}
        </Card>
      </div>
      <Button>Create</Button>
    </div>
  );
}

function SelectProducts() {
  const { data } = useAppetiteProductsQuery();
  const [term, setTerm] = useState("");

  return (
    <div className="mt-8">
      <div className="max-h-96 overflow-scroll">
        <SearchInput
          className="min-w-full w-full"
          name="search"
          placeholder="Search Products"
          value={term}
          onChange={(event) => setTerm(event.currentTarget.value)}
        />
        {[...(data?.appetiteProducts ?? [])]
          .sort((a, b) => a.carrierName.localeCompare(b.carrierName))
          .filter((p) => {
            return (
              p.carrierName.toLowerCase().includes(term.toLowerCase()) ||
              p?.carrierProductName?.toLowerCase().includes(term.toLowerCase())
            );
          })
          .map((product) => (
            <FieldCheckbox
              key={product.id}
              label={`${product.carrierName} ${product.carrierProductName}`}
              value={product.id}
              name="products"
            />
          ))}
      </div>
      <div className="flex justify-center">
        <Button>Add Products</Button>
      </div>
    </div>
  );
}

const FieldCheckbox = ({ label, value, name }: { label?: string; value: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(value);
      return (
        <Field className={cn("gap-6", checked ? "text-foreground" : "text-muted-foreground")}>
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-foreground" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, value])
                : field.onChange(field.value?.filter((fieldValue: string) => fieldValue !== value));
            }}
          />
          {label ?? value}
        </Field>
      );
    }}
  />
);
