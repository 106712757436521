import { UUID } from "@cp/toolkit";
import { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SwitchParam } from "@/components/ui/switch-param";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { cn } from "@/utils";
import { LeadAgentNamesQuery, useLeadAgentNamesQuery } from "../../../generated/graphql";

const clearAgentName: LeadAgentNamesQuery["leadAgentNames"][number] = {
  __typename: "LeadAgentName",
  firstName: "All Agents",
  lastName: "",
};

export const BookToolbar: React.FC<PropsWithChildren<{}>> = () => {
  const [searchParams] = useSearchParams();
  const filters = Object.fromEntries(searchParams);
  const numFilters = Object.keys(filters).length;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          size="sm"
          className={cn("gap-2 px-3 rounded-full z-1", numFilters === 0 && "aspect-square")}
        >
          {numFilters > 0 && <span className="font-semibold px-0.5 text-xs">{numFilters}</span>}
          <Icon icon="filter_list" />
        </Button>
      </PopoverTrigger>
      <LeadsFilters />
    </Popover>
  );
};

export const LeadsFilters = () => {
  const [searchParams, upsertSearchParams, setSearchParams, removeSearchParams] = useUpsertSearchParams();
  const agencyId = (searchParams.get("agencyId") as UUID) || undefined;
  const agentFirstName = searchParams.get("agentFirstName");
  const agentLastName = searchParams.get("agentLastName");
  const expirationDateAfter = searchParams.get("expirationDateAfter") || undefined;
  const expirationDateBefore = searchParams.get("expirationDateBefore") || undefined;
  const { data: { leadAgentNames = [] } = {} } = useLeadAgentNamesQuery({ variables: { agencyId } });

  const resetParams = () => setSearchParams({});

  return (
    <PopoverContent className="overflow-hidden p-0 w-72">
      <div className="flex flex-col gap-2 px-4 py-3">
        <Autocomplete
          placeholder="Search Agents"
          options={[clearAgentName, ...leadAgentNames]}
          alwaysVisibleOptions={[clearAgentName]}
          selected={
            agentFirstName || agentLastName
              ? { firstName: agentFirstName || "", lastName: agentLastName || "" }
              : undefined
          }
          onSelect={(agent) => {
            if (agent.firstName === "All Agents") {
              removeSearchParams(["agentFirstName", "agentLastName"]);
            } else {
              upsertSearchParams({ agentFirstName: agent.firstName || "", agentLastName: agent.lastName || "" });
            }
          }}
          toValue={(agent) => `${agent.firstName} ${agent.lastName}`}
          toLabel={(agent) => `${agent.firstName} ${agent.lastName}`}
        />
        <div className="flex gap-2">
          <DatePicker
            placeholder="Start Date"
            selected={expirationDateAfter}
            onSelect={(e) => {
              e && upsertSearchParams({ expirationDateAfter: e.toISOString() });
            }}
            dateFormat="M/dd/yy"
            className="border"
          />
          <span className="self-center text-xs text-muted-foreground">to</span>
          <DatePicker
            placeholder="End Date"
            selected={expirationDateBefore}
            onSelect={(e) => {
              e && upsertSearchParams({ expirationDateBefore: e.toISOString() });
            }}
            dateFormat="M/dd/yy"
            className="border"
          />
        </div>
      </div>
      <div className="bg-accent border-t flex items-center px-4 py-3">
        <HasInternalRole>
          <SwitchParam name="present" label="Present" />
        </HasInternalRole>
        <Button variant="outline" size="sm" className="ml-auto" onClick={resetParams}>
          Reset
        </Button>
      </div>
    </PopoverContent>
  );
};
