import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router";
import { z } from "zod";
import {
  Roles,
  useAgentCreateOpportunityMutation,
  useBrokerCreateOpportunityMutation,
} from "../../../../generated/graphql";
import { useToast } from "../../../components/ui/use-toast";

const BaseFormSchema = z.object({
  clientName: z.string().min(1, { message: "Business name is required" }),
  desiredEffectiveDate: z.string().min(1, { message: "Desired effective date is required" }),
  needByDate: z.string().optional(),
  state: z.string().length(2, { message: "State is required" }),
  revenue: z.number().optional(),
  description: z.string().min(1, { message: "Business description is required" }),
  leadIds: z.array(z.string()).optional(),
});

export const BrokerFormSchema = BaseFormSchema.extend({
  firstName: z.string(),
  lastName: z.string(),
  agentEmail: z.string().email(),
  agencyId: z.string(),
});

const baseCreateOpportunityInput = (data: z.infer<typeof BaseFormSchema>) => {
  return {
    clientInput: {
      name: data.clientName,
      primaryState: data.state,
      revenue: data.revenue,
      description: data.description,
    },
    desiredEffectiveDate: data.desiredEffectiveDate,
    needByDate: data.needByDate,
    leadIds: data.leadIds,
  };
};

const useAgentCreateOpportunity = () => {
  const [mutation] = useAgentCreateOpportunityMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof BaseFormSchema>) => {
    return await mutation({
      variables: {
        input: baseCreateOpportunityInput(data),
      },
      onCompleted: (data) => {
        navigate(`./${data.agentCreateOpportunity.id}/coverage`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/opportunity/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

const useBrokerCreateOpportunity = () => {
  const [mutation] = useBrokerCreateOpportunityMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof BrokerFormSchema>) => {
    return await mutation({
      variables: {
        input: {
          ...baseCreateOpportunityInput(data),
          agentInput: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.agentEmail,
            agencyId: data.agencyId,
          },
        },
      },
      onCompleted: (data) => {
        navigate(`./${data.brokerCreateOpportunity.id}/coverage`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/opportunity/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

export const useCreateOpportunity = (user: { roles: Roles[]; internal: boolean } | undefined) => {
  return {
    zodResolver: zodResolver(user?.internal ? BrokerFormSchema : BaseFormSchema),
    createOpportunity: user?.internal ? useBrokerCreateOpportunity() : useAgentCreateOpportunity(),
  };
};
