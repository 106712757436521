import { groupBy, mapValues, sortBy } from "lodash";
import { useParams } from "react-router";

import { Loading } from "@/components/ui/loading";
import { Panel } from "@/components/ui/panel";
import { ActionExecutionOperation, useActionsQuery } from "../../../../generated/graphql";
import { ActionsTable } from "./actions-table";

export const FollowUpActions: React.FC = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: { actions = [] } = {}, loading } = useActionsQuery({
    variables: {
      input: {
        opportunityId: opportunityId!,
        autoExecute: true,
      },
    },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return (
      <Panel>
        <Loading />
      </Panel>
    );
  }

  const groups = mapValues(
    groupBy(actions, (action) => action.actionType),
    (group) =>
      sortBy(group, [
        (action) =>
          // If not executed, sort by scheduled date; if executed, sort by executed date
          action.executedOperation === ActionExecutionOperation.None ? action.autoExecuteAt : action.executedAt,
      ])
  );

  return (
    <>
      {Object.entries(groups).map(([actionType, actions]) => (
        <ActionsTable key={actionType} actions={actions} opportunityId={opportunityId!} />
      ))}
    </>
  );
};
