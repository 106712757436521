import { RequirementElementProps } from "./requirement-element-props";
import BuildingElement from "./requirement-elements/building-element";
import CheckboxElement from "./requirement-elements/checkbox-element";
import DateElement from "./requirement-elements/date-element";
import FreeFillWithMoney from "./requirement-elements/free-fill-with-money-element";
import InputElement from "./requirement-elements/input-element";
import PropertyDetailsElement from "./requirement-elements/property-details-element";
import ScheduleOfHazardsElement from "./requirement-elements/schedule-of-hazards-element";
import SelectWithMoneyElement from "./requirement-elements/select-with-money-element";
import SubjectOfInsuranceElement from "./requirement-elements/subject-of-insurance-element";
import TextAreaElement from "./requirement-elements/textarea-element";

export default function ElementRegister({
  element,
  props,
  ...requirementsProps
}: {
  element: string;
  props: any;
} & RequirementElementProps) {
  switch (element) {
    case "input":
      return <InputElement {...props} {...requirementsProps} />;
    case "date":
      return <DateElement {...props} {...requirementsProps} />;
    case "textarea":
      return <TextAreaElement {...props} {...requirementsProps} />;
    case "checkbox":
      return <CheckboxElement {...props} {...requirementsProps} />;
    case "building":
      return <BuildingElement {...props} {...requirementsProps} />;
    case "schedule-of-hazards":
      return <ScheduleOfHazardsElement {...props} {...requirementsProps} />;
    case "select-with-money":
      return <SelectWithMoneyElement {...props} {...requirementsProps} />;
    case "free-fill-with-money":
      return <FreeFillWithMoney {...props} {...requirementsProps} />;
    case "property-details":
      return <PropertyDetailsElement {...props} {...requirementsProps} />;
    case "subject-of-insurance":
      return <SubjectOfInsuranceElement {...props} {...requirementsProps} />;
    default:
      return null;
  }
}
