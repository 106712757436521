import { SearchParamMultiselect } from "@/components/ui/search-param-multiselect";
import { STATUS_METADATA } from "@/metadata";
import { OpportunityStatus } from "../../../generated/graphql";
import { useMyAccount } from "../../auth/useMyAccount";

export const SelectOpportunityStatus = ({ className }: { className?: string }) => {
  const { data: user } = useMyAccount();
  const { [OpportunityStatus.BookRollPending]: pending, ...publicStatuses } = STATUS_METADATA;

  return (
    <SearchParamMultiselect
      name="statuses"
      metadata={{ ...(user && user.internal ? STATUS_METADATA : publicStatuses) }}
      className={className}
    />
  );
};
