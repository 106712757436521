import { SearchParamSelect } from "@/components/ui/search-param-select";
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const SelectEffectiveDate = () => (
  <SearchParamSelect name="effectiveDate">
    <SelectTrigger>
      <SelectValue placeholder="Select Effective Date" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="">All</SelectItem>
      <SelectItem value="24">Next 24 hours</SelectItem>
      <SelectItem value="72">Next 3 days</SelectItem>
      <SelectItem value="168">Next 7 days</SelectItem>
      <SelectItem value="720">Next 30 days</SelectItem>
    </SelectContent>
  </SearchParamSelect>
);
