import { useParams } from "react-router";

import { Spinner } from "@/components/ui/loading";
import { cn } from "@/utils";
import { useActionsQuery } from "../../../../generated/graphql";

export const ScheduledFollowUpCount: React.FC = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data: { actions = [] } = {}, loading } = useActionsQuery({
    variables: {
      input: {
        opportunityId: opportunityId!,
        autoExecute: true,
      },
    },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
  });

  const count = actions.filter((a) => a.executedAt === null).length;

  return loading ? (
    <Spinner className="text-2xs" />
  ) : (
    <span
      className={cn(
        "inline-flex font-semibold h-4 items-center justify-center min-w-4 rounded px-1 text-2xs",
        count ? "text-primary-foreground bg-primary" : "text-foreground bg-foreground/5"
      )}
    >
      {count}
    </span>
  );
};
